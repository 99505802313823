import { useState } from 'react';

import { Panel, PanelDivider, PanelHeader, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { ControlledRadioButtonGroup } from '@deepstream/ui/ui/RadioButton';

import { localeFormatPrice } from '@deepstream/utils';
import { APP_ADMIN_LOCALE } from '@deepstream/common/constants';
import { getFeatureAdoptionInfo, renderPercentage } from '../reports/utils';
import { ClientAdoptionRateModal } from '../reports/ClientAdoptionRateModal';
import { ProductDashboardMetric, ProductDashboardSection } from './ProductDashboardUtils';

const formatDuration = (durationInMinutes: number, unit: 'weeks' | 'days' | 'hours') => {
  if (unit === 'hours') {
    const hours = Math.round((durationInMinutes * 10) / 60) / 10;
    return `${hours.toFixed(1)} hours`;
  } else if (unit === 'days') {
    const days = Math.round((durationInMinutes * 10) / 60 / 24) / 10;
    return `${days.toFixed(1)} days`;
  } else if (unit === 'weeks') {
    const weeks = Math.round((durationInMinutes * 10) / 60 / 24 / 7) / 10;
    return `${weeks.toFixed(1)} weeks`;
  }
};

export const ProductDashboardContractsPanel = ({ status, data }) => {
  const contractManagementAdoptionRateModal = useModalState();
  const contractSignedAdoptionRateModal = useModalState();
  const [timeUnit, setTimeUnit] = useState<'weeks' | 'days' | 'hours'>('weeks');

  return (
    <Panel>
      <PanelHeader heading="Contracts" />
      <PanelDivider />
      {status === 'loading' ? (
        <PanelText>
          <Loading />
        </PanelText>
      ) : status === 'error' ? (
        <PanelText>Oh no</PanelText>
      ) : data.data ? (
        <>
          {data.data.contractManagementFeatureAdoption && (
            <ProductDashboardSection heading="Enabled clients" icon="person">
              <ProductDashboardMetric
                label="Whole number (now)"
                value={data.data.contractManagementFeatureAdoption.filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length}
              />
              <ProductDashboardMetric
                label="Client adoption rate (now)"
                value={renderPercentage({
                  count: data.data.contractManagementFeatureAdoption.filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length,
                  total: data.data.contractManagementFeatureAdoption.length,
                })}
                subValue={getFeatureAdoptionInfo(data.data.contractManagementFeatureAdoption)}
                onViewMoreClick={data.data.contractManagementFeatureAdoption.length ? contractManagementAdoptionRateModal.open : undefined}
              />
            </ProductDashboardSection>
          )}
          {data.data.contractSignedFeatureAdoption && (
            <ProductDashboardSection heading="Enabled clients with 1+ signed contract" icon="person">
              <ProductDashboardMetric
                label="Whole number (now)"
                value={data.data.contractSignedFeatureAdoption.filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length}
              />
              <ProductDashboardMetric
                label="Client adoption rate (now)"
                value={renderPercentage({
                  count: data.data.contractSignedFeatureAdoption.filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length,
                  total: data.data.contractSignedFeatureAdoption.length,
                })}
                subValue={getFeatureAdoptionInfo(data.data.contractSignedFeatureAdoption)}
                onViewMoreClick={data.data.contractSignedFeatureAdoption.length ? contractSignedAdoptionRateModal.open : undefined}
              />
            </ProductDashboardSection>
          )}
          {data.data.contractsIssued && (
            <ProductDashboardSection heading="Contracts issued" icon="share">
              <ProductDashboardMetric label="This month" value={data.data.contractsIssued.thisMonth} />
              <ProductDashboardMetric label="Last month" value={data.data.contractsIssued.lastMonth} />
              <ProductDashboardMetric label="All time" value={data.data.contractsIssued.allTime} />
            </ProductDashboardSection>
          )}
          {data.data.contractsAverageCycleTime && (
            <ProductDashboardSection
              heading="Average cycle times"
              icon="clock"
              headerSx={{ alignItems: 'flex-start' }}
              right={(
                <ControlledRadioButtonGroup
                  name="Average cycle time unit"
                  onChange={setTimeUnit}
                  value={timeUnit}
                  inputs={[
                    { label: 'Weeks', value: 'weeks' },
                    { label: 'Days', value: 'days' },
                    { label: 'Hours', value: 'hours' },
                  ]}
                />
              )}
            >
              <ProductDashboardMetric
                label="Draft to issue"
                value={formatDuration(data.data.contractsAverageCycleTime.draftToIssue, timeUnit)}
              />
              <ProductDashboardMetric
                label="Issue to signature"
                value={formatDuration(data.data.contractsAverageCycleTime.issueToSignature, timeUnit)}
              />
              <ProductDashboardMetric
                label="Draft to signature"
                value={formatDuration(data.data.contractsAverageCycleTime.draftToSignature, timeUnit)}
              />
            </ProductDashboardSection>
          )}
          {data.data.contractsTotalValue && (
            <ProductDashboardSection heading="Contract value" icon="circle-dollar" isIconRegular>
              <ProductDashboardMetric
                label="All time"
                value={(
                  localeFormatPrice(data.data.contractsTotalValue.allTime, 'USD', { locale: APP_ADMIN_LOCALE, decimalPlaces: 2 })
                )}
              />
              <ProductDashboardMetric
                label="This month"
                value={(
                  localeFormatPrice(data.data.contractsTotalValue.thisMonth, 'USD', { locale: APP_ADMIN_LOCALE, decimalPlaces: 2 })
                )}
              />
              <ProductDashboardMetric
                label="Last month"
                value={(
                  localeFormatPrice(data.data.contractsTotalValue.lastMonth, 'USD', { locale: APP_ADMIN_LOCALE, decimalPlaces: 2 })
                )}
              />
            </ProductDashboardSection>
          )}
        </>
      ) : data ? (
        <PanelText>No data</PanelText>
      ) : (
        null
      )}
      {contractManagementAdoptionRateModal.isOpen && (
        <ClientAdoptionRateModal
          isOpen={contractManagementAdoptionRateModal.isOpen}
          onOk={contractManagementAdoptionRateModal.close}
          onCancel={contractManagementAdoptionRateModal.close}
          heading="Contracts Hub – Enabled clients"
          featureAdoption={data.data.contractManagementFeatureAdoption}
        />
      )}
      {contractSignedAdoptionRateModal.isOpen && (
        <ClientAdoptionRateModal
          isOpen={contractSignedAdoptionRateModal.isOpen}
          onOk={contractSignedAdoptionRateModal.close}
          onCancel={contractSignedAdoptionRateModal.close}
          heading="Contracts Hub – Enabled clients with 1+ contract"
          featureAdoption={data.data.contractSignedFeatureAdoption}
        />
      )}
    </Panel>
  );
};
