/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/no-autofocus */
import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { get, isNil, isFinite, isFunction, isNumber, isEmpty, trim, noop, first } from 'lodash';
import {
  localeFormatDate,
  localeFormatNumber,
  localeFormatPrice,
  localeFormatFactorAsPercent,
  DateFormat,
} from '@deepstream/utils';
import { AuctionStatus, Live, LotIntentionStatus, Company, StatusIconConfig, TotalSavingsCalculationMethod, requestStatusesConfig, ExchangeStatus } from '@deepstream/common/rfq-utils';
import { Icon, IconProps, ObsoleteIcon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { DataCellProps } from '@deepstream/ui-kit/grid/core/utils';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { getCellValue } from '@deepstream/ui-kit/grid/EditableGrid/getCellValue';
import { FieldType } from '@deepstream/common/exchangesConfig';
import { isMultiClickEvent, stopEvent, stopPropagation } from '@deepstream/ui-utils/domEvent';
import { useGridSelection } from '@deepstream/ui-kit/grid/EditableGrid/GridSelectionContext';
import styled from 'styled-components';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { UsersAvatarList } from '../../UsersAvatarList';
import { spendSectionStatusIconProps } from '../../modules/Request/Spend/spendSectionStatus';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { getFormattedDate } from '../../Datetime';
import { ErrorMessage } from '../ErrorMessage';
import { getFormattedNumber } from './validationAwareValueCell';
import * as rfx from '../../rfx';
import { useStageName } from '../../draft/useStageName';
import { Badge } from '../../Badge';
import { CompanyLogo } from '../../CompanyLogo';
import { iconPropsByBidIntentionStatus } from '../../modules/Request/Live/iconPropsByBidIntentionStatus';
import { iconPropsByBidOutcomeStatus } from '../../modules/Request/Live/iconPropsByBidOutcomeStatus';
import { ProgressPercentage } from '../ProgressPercentage';
import { GridAwardDecision } from '../../modules/Request/AwardFlow/types';
import { requestBidStatusAllowsScoreSubmissions } from '../../modules/NewEvaluation/utils';

const StyledInput = styled.input`
  accent-color: ${props => props.theme.colors.primary};
  cursor: pointer;
`;

export const RowSelectionCheckboxCell = ({ row }: DataCellProps<EditableGridColumn, any, any>) => {
  const { isSelected, toggle } = useGridSelection();

  const selected = isSelected(row.original._id);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%', height: '100%' }}
      mb="1px"
      onClick={stopEvent}
    >
      <StyledInput
        type="checkbox"
        checked={selected}
        onChange={noop}
        onClick={(event) => {
          toggle(row.original._id);
          stopPropagation(event);
        }}
      />
    </Flex>
  );
};

export const ExpandOverlay = () => {
  return (
    <Flex
      className="hover-overlay"
      justifyContent="center"
      alignItems="center"
      sx={{ cursor: 'pointer' }}
    >
      <Icon icon="expand" color="primary" />
    </Flex>
  );
};

export const LinkIconOverlay = () => {
  return (
    <Flex
      className="hover-overlay"
      justifyContent="center"
      alignItems="center"
      sx={{ cursor: 'pointer' }}
    >
      <Icon icon="expand" color="primary" />
    </Flex>
  );
};

export const SimpleRowNumberCell = ({
  row,
  rowNumberPrefix,
  HoverOverlay,
}: DataCellProps<EditableGridColumn, any, any> & { rowNumberPrefix?: string | number; HoverOverlay?: () => React.ReactElement }) => {
  return (
    <>
      <div className="number-cell">
        {rowNumberPrefix}{(row.original.index ?? row.index) + 1}
      </div>
      {HoverOverlay && <HoverOverlay />}
    </>
  );
};

export const TextOrDashCell = ({ row, column, truncate = true }: DataCellProps<EditableGridColumn, any, any>) => {
  const value = getCellValue(row.original, column.original);

  return (
    <div className="cell-content">
      {truncate ? (
        <Tooltip content={value}>
          <Truncate>
            {value || <EmDash />}
          </Truncate>
        </Tooltip>
      ) : (
        <>
          {value || <EmDash />}
        </>
      )}
    </div>
  );
};

export const TextWithInfoTooltipCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const value = getCellValue(props.row.original, props.column.original);
  const { infoTooltipKey } = props.column.original;
  const infoTooltip = infoTooltipKey
    ? get(props.row.original, infoTooltipKey)
    : null;

  return infoTooltip ? (
    <div className="cell-content">
      <Tooltip content={infoTooltip}>
        <Truncate>
          {value || <EmDash />}
          <Icon icon="question-circle" regular color="subtext" ml={1} fontWeight={400} />
        </Truncate>
      </Tooltip>
    </div>
  ) : (
    <div className="cell-content">
      <Truncate>
        {value || <EmDash />}
      </Truncate>
    </div>
  );
};

export const RequestStatusCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');
  const value = getCellValue(props.row.original, props.column.original);
  const icon = requestStatusesConfig[value]?.icon;

  return (
    <div className="cell-content">
      <Truncate>
        <Flex alignItems="center" fontSize={2}>
          {icon && <Icon fixedWidth color={icon.color} icon={icon.value} mr={1} />}
          {t(`request.status.${value}`)}
        </Flex>
      </Truncate>
    </div>
  );
};

export const SectionStatusCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');
  const value = getCellValue(props.row.original, props.column.original);

  return (
    <div className="cell-content">
      <Truncate>
        <IconText
          {...spendSectionStatusIconProps[value]}
          text={t(`request.spendAndSavings.sectionStatus.${value}`)}
        />
      </Truncate>
    </div>
  );
};

export const CurrencyAmountOrDashCell = ({
  row,
  column,
  getCurrencyCode,
  highlighted,
  hasDefaultCursor,
}: DataCellProps<EditableGridColumn, any, any> & {
  getCurrencyCode?: (rowData) => string;
  highlighted?: boolean;
  hasDefaultCursor?: boolean;
}) => {
  const value = getCellValue(row.original, column.original);
  const currencyCode = getCurrencyCode
    ? getCurrencyCode(row.original)
    : row.original.currencyCode;

  const locale = useCurrentUserLocale();
  const formattedCurrency = isFinite(value)
    ? localeFormatPrice(value, currencyCode, { locale, showCode: true })
    : null;

  return (
    <div
      className={clsx({
        'cell-content': true,
        'highlighted': highlighted,
      })}
      style={hasDefaultCursor ? { cursor: 'default' } : undefined}
    >
      {isNil(formattedCurrency) ? (
        <Box textAlign="right" sx={{ width: '100%' }}>
          <EmDash />
        </Box>
      ) : (
        <Tooltip content={formattedCurrency}>
          <Truncate textAlign="right">
            {formattedCurrency}
          </Truncate>
        </Tooltip>
      )}
    </div>
  );
};

export const NumberOrDashCell = (props: DataCellProps<EditableGridColumn, any, any> & {
  hasDefaultCursor?: boolean;
}) => {
  const value = getCellValue(props.row.original, props.column.original);

  const locale = useCurrentUserLocale();
  const formattedNumber = isFinite(value)
    ? localeFormatNumber(value, { locale })
    : null;

  const isDisabled = props.column.original.disabled;

  return (
    <div
      className={clsx({
        'cell-content': true,
        disabled: isDisabled,
      })}
      style={props.hasDefaultCursor ? { cursor: 'default' } : undefined}
    >
      {isNil(formattedNumber) ? (
        <Box textAlign="right" sx={{ width: '100%' }}>
          <EmDash />
        </Box>
      ) : (
        <Tooltip content={formattedNumber}>
          <Truncate textAlign="right">
            {formattedNumber}
          </Truncate>
        </Tooltip>
      )}
    </div>
  );
};

export const PercentOrDashCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const value = getCellValue(props.row.original, props.column.original);

  const locale = useCurrentUserLocale();
  const formattedCurrency = isFinite(value)
    ? localeFormatFactorAsPercent(value, { locale, decimalPlaces: 1 })
    : null;

  const isDisabled = props.column.original.disabled;

  return (
    <div
      className={clsx({
        'cell-content': true,
        disabled: isDisabled,
      })}
    >
      {isNil(formattedCurrency) ? (
        <Box textAlign="right" sx={{ width: '100%' }}>
          <EmDash />
        </Box>
      ) : (
        <Truncate textAlign="right">
          {formattedCurrency}
        </Truncate>
      )}
    </div>
  );
};

export const DateOrDashCell = (props: DataCellProps<EditableGridColumn, any, any> & { dateFormat?: DateFormat }) => {
  const locale = useCurrentUserLocale();
  const value = getCellValue(props.row.original, props.column.original);

  return (
    <div className="cell-content">
      {value ? (
        <Tooltip content={localeFormatDate(new Date(value), DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ, { locale })}>
          <Truncate>
            {localeFormatDate(new Date(value), props.dateFormat || DateFormat.DD_MMM_YYYY, { locale })}
          </Truncate>
        </Tooltip>
      ) : (
        <EmDash />
      )}
    </div>
  );
};

export const OwnersCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const value = getCellValue(props.row.original, props.column.original);

  return (
    <div className="cell-content">
      {value ? (
        <UsersAvatarList users={value} width="xxs" />
      ) : (
        <EmDash />
      )}
    </div>
  );
};

export const ValueConfirmedOrDashCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');
  const value = getCellValue(props.row.original, props.column.original);

  return (
    <div className="cell-content">
      <Truncate>
        {isNil(value) ? (
          <EmDash />
        ) : value ? (
          <IconText
            text={t('request.spendAndSavings.valueConfirmed')}
            icon="check-circle"
            isIconRegular
            iconColor="success"
            iconFontWeight="400"
          />
        ) : (
          <IconText
            text={t('request.spendAndSavings.valueUnconfirmed')}
            icon="exclamation-circle"
            iconColor="danger"
          />
        )}
      </Truncate>
    </div>
  );
};

export const SavingsConfirmedOrDashCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');
  const value = getCellValue(props.row.original, props.column.original);

  return (
    <div className="cell-content">
      <Truncate>
        {isNil(value) ? (
          <EmDash />
        ) : value ? (
          <IconText
            text={t('request.spendAndSavings.savingsConfirmed')}
            icon="check-circle"
            isIconRegular
            iconColor="success"
            iconFontWeight="400"
          />
        ) : (
          <IconText
            text={t('request.spendAndSavings.savingsUnconfirmed')}
            icon="exclamation-circle"
            iconColor="danger"
          />
        )}
      </Truncate>
    </div>
  );
};

export const ValueTypeOrDashCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');
  const value = getCellValue(props.row.original, props.column.original);

  return (
    <div className="cell-content">
      <Truncate>
        {isNil(value) ? (
          <EmDash />
        ) : value === 'calculated' ? (
          <IconText
            text={t('request.spendAndSavings.valueType.calculated')}
            icon="function"
            iconColor="subtext"
          />
        ) : value === 'manual' ? (
          <IconText
            text={t('request.spendAndSavings.valueType.manual')}
            icon="pencil"
            iconColor="subtext"
          />
        ) : (
          null
        )}
      </Truncate>
    </div>
  );
};

export const SavingsCalculationMethodOrDashCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');
  const value = getCellValue(props.row.original, props.column.original);

  return (
    <div className="cell-content">
      <Truncate>
        {isNil(value) ? (
          <EmDash />
        ) : (
          <IconText
            text={t(`request.spendAndSavings.totalSavingsCalculationMethods.${value}`)}
            icon={value === TotalSavingsCalculationMethod.MANUAL ? 'pencil' : 'function'}
            iconColor="subtext"
          />
        )}
      </Truncate>
    </div>
  );
};

export const UsedCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');
  const value = getCellValue(props.row.original, props.column.original);

  return (
    <div className="cell-content">
      <Truncate>
        {value ? (
          <IconText
            text={t('request.spendAndSavings.lineItemsUsed')}
            icon="check"
            iconColor="subtext"
          />
        ) : (
          <IconText
            text={t('request.spendAndSavings.lineItemsNotUsed')}
            icon="xmark"
            iconColor="subtext"
          />
        )}
      </Truncate>
    </div>
  );
};

export const SupplierNumberValueCell = ({
  row,
  column,
  isActive,
}: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation();
  const {
    prefix,
    format,
    startEditingCell,
  } = column.original;
  const value = getCellValue(row.original, column.original) as number | null | undefined;
  const decimalPlaces = row.original.fields?.[column.original._id]?.decimalPlaces;
  const locale = useCurrentUserLocale();
  const formattedValue = getFormattedNumber(value, format, locale, null, decimalPlaces);

  const isInvalid = !isNumber(value);

  return (
    <div
      className={clsx({
        'invalid': isInvalid,
        'cell-content': true,
      })}
      onClick={(event) => {
        if (isActive || isMultiClickEvent(event)) {
          startEditingCell?.(row, column);
        }
      }}
      style={prefix ? { padding: 0 } : undefined}
    >
      {isInvalid ? (
        <ErrorMessage fontSize={2} error={t('general.required')} />
      ) : prefix ? (
        <Tooltip content={formattedValue ? `${prefix ? `${prefix} ` : ''}${formattedValue}` : undefined}>
          <Flex height="100%" width="100%">
            <div className="prefix">
              {isFunction(prefix) ? prefix(row) : prefix}
            </div>
            <Truncate className="post-prefix" style={{ textAlign: 'right' }}>
              {formattedValue ?? <EmDash />}
            </Truncate>
          </Flex>
        </Tooltip>
      ) : (
        <Tooltip content={formattedValue ? `${prefix ? `${prefix} ` : ''}${formattedValue}` : undefined}>
          <Truncate style={{ textAlign: 'right' }}>
            {formattedValue ?? <EmDash />}
          </Truncate>
        </Tooltip>
      )}
    </div>
  );
};

export const getSupplierProvidedValueCell = ({
  exchangeDef,
  columnConfig,
  locale,
  t,
}) => {
  const field = exchangeDef.fields[columnConfig._id];

  const fieldType = field.type;
  const fieldSourceType = field.source?.type;
  const { exchange } = exchangeDef;
  const rawValue = fieldSourceType === 'formula'
    ? exchange?.computedFormulas?.[field._id]
    : exchange?.latestReply?.[field._id];

  if (!rawValue && typeof rawValue !== 'boolean') {
    if (fieldType === FieldType.BOOLEAN) {
      return {
        value: t('request.lineItems.supplierToAcceptOrReject'),
      };
    }

    if (fieldSourceType === 'formula') {
      return {
        value: t('request.lineItems.formulaResult'),
      };
    }

    return {
      value: t('request.lineItems.supplierToProvide'),
    };
  }

  if (fieldType === FieldType.DATE) {
    return {
      value: getFormattedDate(rawValue, columnConfig.format, locale),
      suffix: (
        <Icon
          icon="calendar-alt"
          color="subtext50"
          fixedWidth
          fontSize={2}
        />
      ),
    };
  }

  if (fieldType === FieldType.PRICE || fieldType === FieldType.NUMBER) {
    const decimalPlaces = exchangeDef.fields?.[field._id]?.decimalPlaces;
    const { prefix, format } = columnConfig;

    const actualPrefix = isFunction(prefix) ? prefix(exchangeDef) : prefix;

    return {
      value: getFormattedNumber(rawValue, format, locale, actualPrefix, decimalPlaces),
      prefix: actualPrefix,
    };
  }

  if (fieldType === FieldType.BOOLEAN) {
    return {
      value: rawValue ? t('lineItems.cell.boolean.accepted', { ns: 'request' }) : t('lineItems.cell.boolean.rejected', { ns: 'request' }),
    };
  }

  if (fieldType === FieldType.STRING) {
    return { value: rawValue };
  }

  return { value: rawValue };
};

export const SupplierProvidedValueCell = ({
  row,
  column,
}: DataCellProps<EditableGridColumn, any, any>) => {
  const locale = useCurrentUserLocale();
  const { t } = useTranslation();

  const cell = React.useMemo(() => {
    return getSupplierProvidedValueCell({
      exchangeDef: row.original,
      columnConfig: column.original,
      locale,
      t,
    });
  }, [row, column, locale, t]);

  return (
    <div style={{ paddingLeft: 0, paddingRight: 0 }} className="cell-content disabled">
      <Flex height="100%" width="100%">
        {!!cell.prefix && (
          <div className="prefix">
            {cell.prefix}
          </div>
        )}
        <Truncate
          style={{
            textAlign: cell.prefix ? 'right' : 'left',
            padding: '11px 10px 0',
          }}
        >
          {cell.value}
        </Truncate>
        {!!cell.suffix && (
          <div className="suffix">
            {cell.suffix}
          </div>
        )}
      </Flex>
    </div>
  );
};

export const SupplierDescriptionValueCell = ({
  row,
  column,
  isActive,
}: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation();
  const { startEditingCell } = column.original;

  const value = getCellValue(row.original, column.original) as string | null | undefined;

  const isInvalid = isEmpty(trim(value));
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <div
      className={clsx({
        'invalid': isInvalid,
        'cell-content': true,
        disabled: isDisabled,
      })}
      onClick={(event) => {
        if (isActive || isMultiClickEvent(event)) {
          startEditingCell?.(row, column);
        }
      }}
    >
      {isInvalid ? (
        <ErrorMessage fontSize={2} error={t('general.required')} />
      ) : (
        <Tooltip content={value ?? undefined}>
          <Truncate>
            {row.original.isObsolete ? <ObsoleteIcon display="inline" mr={2} /> : null}
            {value || <EmDash />}
          </Truncate>
        </Tooltip>
      )}
    </div>
  );
};

export const SupplierTextValueCell = ({
  row,
  column,
  isActive,
}: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation();
  const { startEditingCell } = column.original;

  const value = getCellValue(row.original, column.original) as string | null | undefined;
  const isInvalid = isEmpty(trim(value));
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <div
      className={clsx({
        'invalid': isInvalid,
        'cell-content': true,
        disabled: isDisabled,
      })}
      onClick={(event) => {
        if (isActive || isMultiClickEvent(event)) {
          startEditingCell?.(row, column);
        }
      }}
    >
      {isInvalid ? (
        <ErrorMessage fontSize={2} error={t('general.required')} />
      ) : (
        <Tooltip content={value ?? undefined}>
          <Truncate>
            {value || <EmDash />}
          </Truncate>
        </Tooltip>
      )}
    </div>
  );
};

export const StageCell = (
  props: DataCellProps<EditableGridColumn, any, any>,
) => {
  const stageIds = getCellValue(props.row.original, props.column.original);
  const stages = rfx.useStages();
  const stageName = useStageName(stageIds?.[0] ?? stages[0]?._id);

  return (
    <div className="cell-content">
      <Truncate>{stageName}</Truncate>
    </div>
  );
};

/**
 * Cell component rendering name and logo of a `Company`.
 */
export const CompanyNameAndLogoCell = ({
  row,
  column,
  hasDefaultCursor,
}: DataCellProps<EditableGridColumn, any, any> & { hasDefaultCursor?: boolean }) => {
  const { t } = useTranslation('translation');
  const company = getCellValue(row.original, column.original);

  return (
    <div className="cell-content" style={hasDefaultCursor ? { cursor: 'default' } : undefined}>
      <Tooltip content={company.name}>
        <Truncate>
          <CompanyLogo companyId={company._id} size="xs" />
          <Box
            as="span"
            verticalAlign="middle"
            color={company.isPending ? 'subtext' : 'text'}
          >
            {company.name || `(${t('general.notSet')})`}
          </Box>
        </Truncate>
      </Tooltip>
      {company.isPending && (
        <Badge ml={2} tooltip={t('general.thisCompanyHasntSignedUpYet')}>
          {t('invite.status.invited')}
        </Badge>
      )}
    </div>
  );
};

export const ValueOrNotMappedCellWithTooltip = ({
  row,
  column,
  externalSystemName,
}: DataCellProps<EditableGridColumn, any, any> & { externalSystemName: string }) => {
  const { t } = useTranslation('integration');

  const value = getCellValue(row.original, column.original);

  return (
    <div className="cell-content">
      {value || (
        <Box alignItems="center" sx={{ mr: 2, display: 'inline-flex' }}>
          <Tooltip content={t('externalStatus.notMappedTooltip', { externalSystemName })}>
            <Icon icon="exclamation-circle" color="danger" mr={1} fixedWidth fontSize={1} />
          </Tooltip>
          <Text>{t('notMapped')}</Text>
        </Box>)
      }
    </div>
  );
};

export const BidIntentionStatusCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');
  const value = getCellValue(props.row.original, props.column.original);

  if (!value) {
    return (
      <div className="cell-content disabled">
        {t('general.notAvailable')}
      </div>
    );
  }

  const statusText = t(`request.bidIntentionStatus.${value}.status`);

  return (
    <div className="cell-content">
      <Tooltip content={statusText}>
        <Truncate>
          <Flex alignItems="center" fontSize={2}>
            <Icon fixedWidth mr={1} {...iconPropsByBidIntentionStatus.buyer[value]} />
            {statusText}
          </Flex>
        </Truncate>
      </Tooltip>
    </div>
  );
};

export const BidOutcomeStatusCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');
  const value = getCellValue(props.row.original, props.column.original);

  const statusText = t(`request.bidOutcomeStatus.${value}.status`);

  return (
    <div className="cell-content">
      <Tooltip content={statusText}>
        <Truncate>
          <Flex alignItems="center" fontSize={2}>
            <Icon fixedWidth mr={1} {...iconPropsByBidOutcomeStatus[value]} />
            {statusText}
          </Flex>
        </Truncate>
      </Tooltip>
    </div>
  );
};

export const LockStatusCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation();
  const value = getCellValue(props.row.original, props.column.original);

  if (isNil(value)) {
    return (
      <div className="cell-content disabled">
        {t('general.notAvailable')}
      </div>
    );
  }

  return (
    <div className="cell-content">
      <IconText
        text={value ? (
          t('request.lock.lockedCount', { count: value })
        ) : (
          t('request.lock.unlocked')
        )}
        icon={value ? 'lock' : 'unlock'}
        fontSize={2}
        gap={1}
        fixedWidth
      />
    </div>
  );
};

type StatusProps = {
  label: string;
  icon?: StatusIconConfig;
};

const Status: React.FC<StatusProps> = ({ label, icon }) => {
  return (
    <Flex alignItems="center">
      {icon && (
        <Icon
          icon={icon.value as IconProps['icon']}
          color={icon.color}
          regular={icon.isRegular}
          style={{ width: '18px', textAlign: 'center' }}
        />
      )}
      <Box ml={label ? '6px' : 0}>
        {label || <Box color="subtext"><EmDash /></Box>}
      </Box>
    </Flex>
  );
};

export const StatusCell = (props: DataCellProps<EditableGridColumn, any, any> & { config?: unknown }) => {
  const { t } = useTranslation('translation');
  const value = getCellValue(props.row.original, props.column.original);

  if (!value) {
    return (
      <div className="cell-content disabled">
        {t('general.notAvailable')}
      </div>
    );
  }

  const { label, icon } = props.config[value];

  return (
    <Box className="cell-content">
      <Status label={label} icon={icon} />
    </Box>
  );
};

export const EvaluationStatusCell = (props: DataCellProps<EditableGridColumn, any, any> & { config?: unknown }) => {
  const { t } = useTranslation('translation');
  const value = getCellValue(props.row.original, props.column.original);
  const { bidById } = rfx.useStructure<Live>();

  const recipientId = props.row.original._id;

  if (!value) {
    return (
      <div className="cell-content disabled">
        {t('general.notAvailable')}
      </div>
    );
  }

  const { icon, label } = props.config[value];

  // For now, we'll match the status icon adjustments of evaluation exchanges
  // (see the EvaluationStatus component) -- this should only be temporary
  // because that solution is not very clear.
  const adjustedIcon = requestBidStatusAllowsScoreSubmissions(bidById[recipientId].status)
    ? icon
    : { value: 'circle', color: 'secondary', isRegular: true };

  return (
    <Box className="cell-content">
      <Status label={label} icon={adjustedIcon} />
    </Box>
  );
};

export enum AuctionBidderAgreementStatus {
  ACCEPTED = 'accepted',
  NOT_ACCEPTED = 'notAccepted',
}

export const AuctionBidderAgreementStatusCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');
  const { auction } = rfx.useStructure<Live>();
  const value = getCellValue(props.row.original, props.column.original);

  if (!value) {
    return (
      <div className="cell-content disabled">
        {t('general.notAvailable')}
      </div>
    );
  }

  return (
    <Box className="cell-content" style={{ opacity: auction.status === AuctionStatus.CANCELLED ? 0.4 : undefined }}>
      {value ? (
        <Status
          label={t(`request.suppliersTable.auctionBidderAgreementStatus.${value}`)}
          icon={value === AuctionBidderAgreementStatus.ACCEPTED ? ({
            value: 'check-circle',
            color: 'success',
          }) : ({
            value: 'circle-dashed',
            color: 'subtext',
          })}
        />
      ) : (
        <EmDash />
      )}
    </Box>
  );
};

export const CurrentAuctionBidCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');
  const { auction } = rfx.useStructure<Live>();
  const value = getCellValue(props.row.original, props.column.original);
  const locale = useCurrentUserLocale();

  if (isNil(value)) {
    return (
      <div className="cell-content disabled">
        {t('general.notAvailable')}
      </div>
    );
  }

  const currencyCode = auction.lots[0].rules.currency;

  const formattedCurrency = value
    ? localeFormatPrice(value, currencyCode, { locale, showCode: true })
    : null;

  return (
    <Box className="cell-content" style={{ opacity: auction.status === AuctionStatus.CANCELLED ? 0.4 : undefined }}>
      {formattedCurrency || <EmDash />}
    </Box>
  );
};

export const UnreadCommentsCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');
  const value = getCellValue(props.row.original, props.column.original);

  if (isNil(value)) {
    return (
      <div className="cell-content disabled">
        {t('general.notAvailable')}
      </div>
    );
  }

  if (value === 0) {
    return (
      <div className="cell-content disabled">
        <Icon icon="check" mr={1} color="subtext" />
        {t('general.none')}
      </div>
    );
  }

  return (
    <div className="cell-content">
      <Icon icon="comment" regular dot mr={1} color="subtext" />
      {value}
    </div>
  );
};

export const ProgressPercentCell = (props: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const value = getCellValue(props.row.original, props.column.original);

  if (isNil(value)) {
    return (
      <div className="cell-content disabled">
        {t('general.notAvailable')}
      </div>
    );
  }

  return (
    <div className="cell-content">
      <ProgressPercentage progress={value} width={14} colorPrimary={theme.colors.success} />
    </div>
  );
};

export const AwardDecisionLabelCell = ({ row, column }: DataCellProps<EditableGridColumn, any, any>) => {
  const value = getCellValue(row.original, column.original);

  if (row.original.isSubHeader) {
    return (
      <div className="cell-content disabled">
        <Tooltip content={value}>
          <Truncate>
            <Box as="span" color="lightNavy" fontWeight={500}>
              {value || <EmDash />}
            </Box>
            <Box as="span" color="subtext" ml={2}>
              {row.original.numItems}
            </Box>
          </Truncate>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className="cell-content" style={{ cursor: 'default' }}>
      <Tooltip content={value}>
        <Truncate>
          {value || <EmDash />}
        </Truncate>
      </Tooltip>
    </div>
  );
};

export const AwardedSupplierValueCell = ({
  row,
  column,
  recipientById,
  isActive,
}: DataCellProps<EditableGridColumn, any, any> & { recipientById: Record<string, Company> }) => {
  const { t } = useTranslation('translation');

  if (row.original.isSubHeader) {
    return (
      <div className="cell-content disabled" />
    );
  }

  const awardDecision = getCellValue(row.original, column.original) as GridAwardDecision | null;

  const isDisabled = isEmpty(row.original.awardableRecipientIds);

  return (
    <div
      className={clsx({
        'cell-content': true,
        disabled: ['noAward', 'markAsObsolete'].includes(awardDecision?.value),
        highlighted: awardDecision?.value === 'award',
      })}
      style={isDisabled ? undefined : { cursor: 'pointer', fontWeight: 400 }}
      onClick={(event) => {
        if (!isDisabled && (isActive || isMultiClickEvent(event))) {
          column.original.startEditingCell?.(row, column);
        }
      }}
    >
      {!awardDecision ? (
        <Truncate color={isDisabled ? undefined : 'text'}>
          {t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.selectableCellPlaceholder')}
        </Truncate>
      ) : awardDecision.value === 'noAward' ? (
        <Truncate color={isDisabled ? undefined : 'text'}>
          <Icon icon="circle-xmark" regular mr={1} />
          {t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.noAward')}
        </Truncate>
      ) : awardDecision.value === 'markAsObsolete' ? (
        <Truncate color={isDisabled ? undefined : 'text'}>
          <Icon icon="ban" regular mr={1} />
          {t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.markAsObsolete')}
        </Truncate>
      ) : awardDecision.awardedSupplierIds.length > 1 ? (
        <Truncate color={isDisabled ? undefined : 'text'}>
          <Icon icon="trophy" regular mr={1} />
          {t('general.supplierCount', { count: awardDecision.awardedSupplierIds.length })}
        </Truncate>
      ) : (
        <Tooltip content={recipientById[first(awardDecision.awardedSupplierIds)].company.name}>
          <Truncate color={isDisabled ? undefined : 'text'}>
            <Icon icon="trophy" regular mr={1} />
            {recipientById[first(awardDecision.awardedSupplierIds)].company.name}
          </Truncate>
        </Tooltip>
      )}
      {!isDisabled && (
        <Icon icon="caret-down" ml={2} mt="2px" color="text" />
      )}
    </div>
  );
};

export const AwardLotsRecipientCell = ({
  row,
  column,
  currencyCode,
  showTotalCost,
}: DataCellProps<EditableGridColumn, any, any> & { currencyCode: string, showTotalCost?: boolean }) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();

  if (row.original.isSubHeader) {
    return (
      <div className="cell-content disabled" />
    );
  }

  const recipientId = column.original._id;
  const lotIntentionStatus = row.original.lotIntentionStatusByRecipientId?.[recipientId];

  if (lotIntentionStatus === LotIntentionStatus.BIDDING) {
    if (showTotalCost) {
      const bidAmount = row.original.bidAmountByRecipientId[recipientId];

      const formattedCurrency = isFinite(bidAmount)
        ? localeFormatPrice(bidAmount, currencyCode, { locale, showCode: true })
        : null;

      const { awardDecision } = row.original;

      return (
        <div
          className={clsx({
            'cell-content': true,
            disabled: awardDecision && !awardDecision?.awardedSupplierIds?.includes(recipientId),
            highlighted: awardDecision && awardDecision?.awardedSupplierIds?.includes(recipientId),
          })}
          style={{ cursor: 'default' }}
        >
          {isNil(formattedCurrency) ? (
            <Box textAlign="right" sx={{ width: '100%' }}>
              <EmDash />
            </Box>
          ) : (
            <Tooltip content={formattedCurrency}>
              <Truncate textAlign="right">
                {formattedCurrency}
              </Truncate>
            </Tooltip>
          )}
        </div>
      );
    } else {
      const { awardDecision } = row.original;

      return (
        <div
          className={clsx({
            'cell-content': true,
            disabled: awardDecision && !awardDecision?.awardedSupplierIds?.includes(recipientId),
            highlighted: awardDecision && awardDecision?.awardedSupplierIds?.includes(recipientId),
          })}
          style={{ cursor: 'default', justifyContent: 'flex-start' }}
        >
          <Icon
            fixedWidth
            mt="2px"
            mr={1}
            icon="circle-check"
            regular
            color="disabledText"
          />
          {t(`request.lots.lotIntentionStatus.${lotIntentionStatus}.status`)}
        </div>
      );
    }
  }

  return (
    <div className="cell-content disabled" style={{ justifyContent: 'flex-start' }}>
      <Icon
        fixedWidth
        mt="2px"
        mr={1}
        icon={lotIntentionStatus === LotIntentionStatus.NO_RESPONSE ? 'circle' : 'xmark'}
        regular={lotIntentionStatus === LotIntentionStatus.NO_RESPONSE}
        color="disabledText"
      />
      {t(`request.lots.lotIntentionStatus.${lotIntentionStatus}.status`)}
    </div>
  );
};

export const AwardLineItemsRecipientCell = ({
  row,
  column,
  currencyCode,
}: DataCellProps<EditableGridColumn, any, any> & { currencyCode: string }) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();

  if (row.original.isSubHeader) {
    return (
      <div className="cell-content disabled" />
    );
  }

  const recipientId = column.original._id;
  const isNotAvailable = isEmpty(row.original.awardableRecipientIds);

  const { awardDecision } = row.original;

  if (isNotAvailable) {
    return (
      <div className="cell-content disabled" style={{ justifyContent: 'flex-start' }}>
        {t('general.notAvailable')}
      </div>
    );
  } else if (awardDecision?.awardedSupplierIds?.includes(recipientId) && awardDecision.awardedSupplierIds.length > 1) {
    return (
      <div
        className={clsx({
          'cell-content': true,
          highlighted: true,
        })}
        style={{ cursor: 'default' }}
      >
        <Box textAlign="right" sx={{ width: '100%' }}>
          {t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.toBeDeterminedAbbreviation')}
        </Box>
      </div>
    );
  } else {
    const bidAmount = row.original.bidAmountByRecipientId[recipientId];

    const formattedCurrency = isFinite(bidAmount)
      ? localeFormatPrice(bidAmount, currencyCode, { locale, showCode: true })
      : null;

    return (
      <div
        className={clsx({
          'cell-content': true,
          disabled: awardDecision && !awardDecision?.awardedSupplierIds?.includes(recipientId),
          highlighted: awardDecision && awardDecision?.awardedSupplierIds?.includes(recipientId),
        })}
        style={{ cursor: 'default' }}
      >
        {isNil(formattedCurrency) ? (
          <Box textAlign="right" sx={{ width: '100%' }}>
            <EmDash />
          </Box>
        ) : (
          <Tooltip content={formattedCurrency}>
            <Truncate textAlign="right">
              {formattedCurrency}
            </Truncate>
          </Tooltip>
        )}
      </div>
    );
  }
};

export const SelectValueCell = ({
  row,
  column,
  isActive,
  options,
  placeholder,
}: DataCellProps<EditableGridColumn, any, any> & { options: { value: string; label: any }[]; placeholder: string }) => {
  const value = getCellValue(row.original, column.original) as string | null;

  const option = value
    ? options.find(option => option.value === value)
    : null;

  return (
    <div
      className="cell-content"
      style={{ cursor: 'pointer' }}
      onClick={(event) => {
        if (isActive || isMultiClickEvent(event)) {
          column.original.startEditingCell?.(row, column);
        }
      }}
    >
      <Truncate>
        {option?.label || placeholder}
      </Truncate>
      <Icon icon="caret-down" ml={2} mt="2px" color="text" />
    </div>
  );
};
