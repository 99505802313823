import { useMemo } from 'react';

import { IntegrationTableStyle } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { NumberCell } from '@deepstream/ui/draft/cell';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';

export const SearchesTable = ({
  searches,
}) => {
  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        id: 'query',
        Header: 'Query',
        accessor: 'query',
        Cell: TruncateCell,
        width: 607,
      },
      {
        Header: 'Results',
        accessor: 'results',
        Cell: NumberCell,
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        Cell: NumberCell,
      },
    ],
    [],
  );

  return (
    <IntegrationTableStyle
      bordered
      headerBackgroundColor={theme.colors.lightGray3}
      hoverCursor="default"
      stickyHeader
      height="236px"
    >
      <Table
        columns={columns}
        data={searches}
        isSortable
        initialSortBy={[{ id: 'frequency', desc: true }]}
      />
    </IntegrationTableStyle>
  );
};
