import * as React from 'react';
import { Text, TextProps } from 'rebass/styled-components';

export const Capitalize: React.FC<TextProps> = ({ sx = {}, ...props }) => (
  <Text
    as="span"
    sx={{
      textTransform: 'capitalize',
      ...sx,
    }}
    {...props}
  />
);
