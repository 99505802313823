import { useCallback, useMemo, useState, useEffect } from 'react';
import { Box, Flex, Heading, Text } from 'rebass/styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { EntityTeamMemberOverview, emptyRoles, getNameOrEmail, isEntityOwner } from '@deepstream/common/user-utils';
import * as yup from 'yup';
import { Formik, Form, useField, useFormikContext } from 'formik';
import { compact, constant, first, includes, isEmpty, last, partition } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { UserDetails } from '../../../UserDetails';
import { User } from '../../../types';
import { LabeledValue } from '../../../draft/LabeledValue';
import { useCompanyUsers } from './useCompanyUsers';
import { StepProgress } from '../../../ui/StepProgress';
import { isAppAdminCompany } from '../../../useIsAppAdmin';
import { RadioField } from '../../../form/RadioField';
import { SelectSoleOwnerStep } from './SelectSoleOwnerStep';
import { APP_COMPANY_ROLES, DEFAULT_COMPANY_ROLES, LearnMoreAboutUserRoles, useGlobalRoleOptions } from './RoleField';
import { FULL_USER_EDITABLE_PERMISSIONS, STAKEHOLDER_EDITABLE_PERMISSIONS, SUPER_USER_EDITABLE_PERMISSIONS, roleRestrictionProfileToGlobalRole } from './utils';
import { GlobalRole } from './types';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '../../../ui/Tabs';
import { EditContractsPermissionList, EditPermissionList, EditPreQualPermissionList, EditReportingPermissionList, EditRequestsPermissionList } from './EditPermissionList';
import { UserRequestsList } from './UserRequestsList';
import { UserContractsList } from './UserContractsList';
import { EntityTeamMemberOverviewList } from './EntityTeamMemberOverviewList';
import { UserQuestionnairesList } from './UserQuestionnairesList';

const ChooseRoleStep = ({
  companyId,
  isPayingCompany,
  user,
}: {
  companyId: string;
  isPayingCompany: boolean;
  user: User;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const initialGlobalRole = roleRestrictionProfileToGlobalRole(user.roleRestrictionProfiles?.[companyId]);
  const globalRoleOptions = useGlobalRoleOptions(
    isAppAdminCompany(companyId) ? APP_COMPANY_ROLES : DEFAULT_COMPANY_ROLES,
  );
  const [,, globalRoleFieldHelpers] = useField('globalRole');
  const [,, adminFieldHelpers] = useField('admin');
  const [,, editorFieldHelpers] = useField('editor');
  const [,, managePreQualFieldHelpers] = useField('managePreQual');
  const [,, sendQuestionnairesFieldHelpers] = useField('sendQuestionnaires');
  const [,, sendRFQFieldHelpers] = useField('sendRFQ');
  const [,, sendContractsFieldHelpers] = useField('sendContracts');
  const [,, accessReportingForRequestsFieldHelpers] = useField('accessReportingForRequests');
  const [,, accessReportingForContractsFieldHelpers] = useField('accessReportingForContracts');

  const [selectedRoleOptions, availableRoleOptions] = partition(
    globalRoleOptions,
    ({ value }) => value === initialGlobalRole,
  );
  const currentRoleOption = first(selectedRoleOptions);

  return (
    <Stack gap={3}>
      <UserDetails user={user} />
      <LabeledValue
        align="left"
        label={t('teamManagement.dialog.changeGlobalRole.currentRole')}
        value={currentRoleOption ? (
          <Box>
            <Text>
              {currentRoleOption.label}
            </Text>
            <Text color="subtext" fontSize={1}>
              {currentRoleOption.description}
            </Text>
          </Box>
        ) : (
          <EmDash />
        )}
        valueProps={{}}
      />
      <RadioField
        name="globalRole"
        label={t('teamManagement.dialog.changeGlobalRole.newRole')}
        required
        options={availableRoleOptions}
        gap={2}
        labelStyle={{
          fontWeight: 'normal',
          color: theme.colors.text,
          whiteSpace: 'pre-wrap',
          lineHeight: '15px',
        }}
        onChange={(globalRole: GlobalRole) => {
          globalRoleFieldHelpers.setValue(globalRole);
          switch (globalRole) {
            case GlobalRole.AUTHORIZED_STAKEHOLDER:
              adminFieldHelpers.setValue(false);
              editorFieldHelpers.setValue(false);
              managePreQualFieldHelpers.setValue(false);
              sendQuestionnairesFieldHelpers.setValue(false);
              sendRFQFieldHelpers.setValue(false);
              sendContractsFieldHelpers.setValue(false);
              accessReportingForRequestsFieldHelpers.setValue(false);
              accessReportingForContractsFieldHelpers.setValue(false);
              break;
            case GlobalRole.FULL_USER:
              adminFieldHelpers.setValue(false);
              break;
            case GlobalRole.SUPER_USER:
              adminFieldHelpers.setValue(true);
              break;
            default:
              throw new Error(`Global role invalid value: ${globalRole}`);
          }
        }}
      />
      <Text fontSize={1}>
        <LearnMoreAboutUserRoles />
      </Text>
      {isPayingCompany && initialGlobalRole === GlobalRole.AUTHORIZED_STAKEHOLDER && (
        <MessageBlock variant="warn" mt={0}>
          { t('teamManagement.dialog.changeGlobalRole.upgradingMayAffectBilling')}
        </MessageBlock>
      )}
    </Stack>
  );
};

const ReviewRoleChangesStep = ({
  affectedByUpgrade,
  affectedByDowngrade,
}: {
  affectedByUpgrade: EntityTeamMemberOverview[];
  affectedByDowngrade: EntityTeamMemberOverview[];
}) => {
  const [{ value: selectedGlobalRole }] = useField('globalRole');

  const isUpgrade = selectedGlobalRole === GlobalRole.SUPER_USER;

  return (
    <Stack gap={3}>
      <Text>
        <Trans
          i18nKey={isUpgrade ? (
            'teamManagement.dialog.changeGlobalRole.roleWillChangeToOwner'
          ) : (
            'teamManagement.dialog.changeGlobalRole.roleWillChangeToTeamMember'
          )}
          components={[<strong key={0} />]}
        />
      </Text>
      <EntityTeamMemberOverviewList
        items={isUpgrade ? affectedByUpgrade : affectedByDowngrade}
        height="200px"
        showType
      />
      <MessageBlock variant="info" mt={0}>
        <Trans
          i18nKey={isUpgrade ? (
            'teamManagement.dialog.changeGlobalRole.upgradeRequiredInfoText'
          ) : (
            'teamManagement.dialog.changeGlobalRole.downgradeRequiredInfoText'
          )}
          components={[<strong key={0} />]}
        />
      </MessageBlock>
    </Stack>
  );
};

const ReviewPermissionsStep = ({
  companyId,
  requests,
  contracts,
  questionnaires,
}: {
  companyId: string;
  requests: EntityTeamMemberOverview[];
  contracts: EntityTeamMemberOverview[];
  questionnaires: EntityTeamMemberOverview[];
}) => {
  const { t } = useTranslation(['translation', 'general']);
  const [{ value: selectedGlobalRole }] = useField('globalRole');

  const isPermissionDisabled = useCallback((itemId, globalRole) => {
    if (globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER) {
      return !includes(STAKEHOLDER_EDITABLE_PERMISSIONS, itemId);
    }

    if (globalRole === GlobalRole.FULL_USER) {
      return !includes(FULL_USER_EDITABLE_PERMISSIONS, itemId);
    }

    if (globalRole === GlobalRole.SUPER_USER) {
      return !includes(SUPER_USER_EDITABLE_PERMISSIONS, itemId);
    }
  }, []);

  return (
    <Stack gap={3}>
      <Tabs>
        <TabList style={{ backgroundColor: 'white' }}>
          <Tab>
            {t('general', { ns: 'general' })}
          </Tab>
          <Tab disabled={isAppAdminCompany(companyId)}>
            <Icon
              style={{ fontSize: '14px', marginRight: 4 }}
              icon="file-check"
              regular
            />
            {t('preQualification', { ns: 'general' })}
          </Tab>
          <Tab disabled={isAppAdminCompany(companyId)}>
            <Icon
              style={{ fontSize: '14px', marginRight: 4 }}
              icon="file-text-o"
            />
            {t('general.request_other')}
          </Tab>
          <Tab disabled={isAppAdminCompany(companyId)}>
            <Icon
              style={{ fontSize: '14px', marginRight: 4 }}
              icon="file-contract"
              regular
            />
            {t('general.contract_other')}
          </Tab>
          <Tab disabled={isAppAdminCompany(companyId)}>
            <Icon
              style={{ fontSize: '14px', marginRight: 4 }}
              icon="chart-bar"
              regular
            />
            {t('general.reporting')}
          </Tab>
        </TabList>
        <Box mt="20px">
          <TabPanels>
            <TabPanel>
              <EditPermissionList
                isItemDisabled={(itemId) => isPermissionDisabled(itemId, selectedGlobalRole)}
                globalRole={selectedGlobalRole}
              />
            </TabPanel>
            <TabPanel>
              <EditPreQualPermissionList
                isItemDisabled={(itemId) => isPermissionDisabled(itemId, selectedGlobalRole)}
                globalRole={selectedGlobalRole}
              />
              <UserQuestionnairesList items={questionnaires} />
            </TabPanel>
            <TabPanel>
              <EditRequestsPermissionList
                isItemDisabled={(itemId) => isPermissionDisabled(itemId, selectedGlobalRole)}
                globalRole={selectedGlobalRole}
              />
              <UserRequestsList items={requests} />
              <MessageBlock variant="info" mt={3} width="100%">
                {t('teamManagement.dialog.editPermissions.accessInfo')}
              </MessageBlock>
            </TabPanel>
            <TabPanel>
              <EditContractsPermissionList
                isItemDisabled={(itemId) => isPermissionDisabled(itemId, selectedGlobalRole)}
                globalRole={selectedGlobalRole}
              />
              <UserContractsList items={contracts} />
            </TabPanel>
            <TabPanel>
              <EditReportingPermissionList
                isItemDisabled={(itemId) => isPermissionDisabled(itemId, selectedGlobalRole)}
                globalRole={selectedGlobalRole}
              />
            </TabPanel>
          </TabPanels>
        </Box>
      </Tabs>
    </Stack>
  );
};

const ReviewAndConfirmStep = ({
  companyId,
  user,
  companyUsers,
  affectedByUpgrade,
  affectedByDowngrade,
}: {
  companyId: string;
  user: User;
  companyUsers: User[];
  affectedByUpgrade: EntityTeamMemberOverview[];
  affectedByDowngrade: EntityTeamMemberOverview[];
}) => {
  const { t } = useTranslation();

  const initialGlobalRole = roleRestrictionProfileToGlobalRole(user.roleRestrictionProfiles?.[companyId]);

  const { values } = useFormikContext<any>();

  const questionnaireReplacementUser = companyUsers.find(user => user._id === values.questionnaireReplacementUserId);
  const rfxReplacementUser = companyUsers.find(user => user._id === values.rfxReplacementUserId);
  const contractReplacementUser = companyUsers.find(user => user._id === values.contractReplacementUserId);

  const hasSelectedStakeholderRole = values.globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER;
  const hasSelectedSuperUserRole = values.globalRole === GlobalRole.SUPER_USER;

  const newPermissions = Object.keys(emptyRoles).filter(role => values[role]);

  const hasDowngradeChanges = hasSelectedStakeholderRole && !isEmpty(affectedByDowngrade);
  const hasUpgradeChanges = hasSelectedSuperUserRole && !isEmpty(affectedByUpgrade);

  return (
    <Stack gap={3}>
      <UserDetails user={user} />
      <LabeledValue
        align="left"
        label={t('teamManagement.dialog.changeGlobalRole.roleChange')}
        value={(
          `${t(`user.globalRole.${initialGlobalRole}`)} → ${t(`user.globalRole.${values.globalRole}`)}`
        )}
        valueProps={{}}
      />
      {hasDowngradeChanges ? (
        <LabeledValue
          align="left"
          label={t('teamManagement.dialog.changeGlobalRole.roleChanges')}
          value={`${t('request.team.requestRole.owner')} → ${t('request.team.requestRole.teamMember')}`}
          valueProps={{}}
        />
      ) : hasUpgradeChanges ? (
        <LabeledValue
          align="left"
          label={t('teamManagement.dialog.changeGlobalRole.roleChanges')}
          value={`${t('request.team.requestRole.teamMember')} → ${t('request.team.requestRole.owner')}`}
          valueProps={{}}
        />
      ) : (
        null
      )}
      {hasSelectedStakeholderRole && questionnaireReplacementUser && (
        <LabeledValue
          align="left"
          label={t('teamManagement.dialog.changeGlobalRole.newOwnerForSentQuestionnaires')}
          value={getNameOrEmail(questionnaireReplacementUser)}
          valueProps={{}}
        />
      )}
      {hasSelectedStakeholderRole && rfxReplacementUser && (
        <LabeledValue
          align="left"
          label={t('teamManagement.dialog.removeSoleOwner.newOwnerForRequests')}
          value={getNameOrEmail(rfxReplacementUser)}
          valueProps={{}}
        />
      )}
      {hasSelectedStakeholderRole && contractReplacementUser && (
        <LabeledValue
          align="left"
          label={t('teamManagement.dialog.removeSoleOwner.newOwnerForContracts')}
          value={getNameOrEmail(contractReplacementUser)}
          valueProps={{}}
        />
      )}
      <LabeledValue
        align="left"
        label={t('teamManagement.dialog.changeGlobalRole.newPermissions')}
        value={isEmpty(newPermissions) ? (
          t('general.none')
        ) : (
          newPermissions
            .map(permission => t(`teamManagement.permissions.${permission}`))
            .join(', ')
        )}
        valueProps={{}}
      />
      {hasUpgradeChanges || hasDowngradeChanges ? (
        <MessageBlock variant="warn" mt={0}>
          {t('teamManagement.dialog.changeGlobalRole.ownershipChangesCanOnlyBeAdjusted')}
        </MessageBlock>
      ) : (
        null
      )}
    </Stack>
  );
};

const useSteps = ({
  companyId,
  isPayingCompany,
  requests,
  contracts,
  questionnaires,
  soleOwnerSentQuestionnaires,
  soleOwnerRequests,
  soleOwnerContracts,
  user,
  companyUsers,
}: {
  companyId: string;
  isPayingCompany: boolean;
  requests: EntityTeamMemberOverview[];
  contracts: EntityTeamMemberOverview[];
  questionnaires: EntityTeamMemberOverview[];
  soleOwnerSentQuestionnaires: EntityTeamMemberOverview[];
  soleOwnerRequests: EntityTeamMemberOverview[];
  soleOwnerContracts: EntityTeamMemberOverview[];
  user: User;
  companyUsers: User[];
}) => {
  const { t } = useTranslation();

  const entityOverviews = useMemo(
    () => [...requests, ...contracts, ...questionnaires],
    [requests, contracts, questionnaires],
  );

  const {
    affectedByUpgrade,
    affectedByDowngrade,
  } = useMemo(() => {
    return {
      affectedByUpgrade: entityOverviews.filter(entityTeamMemberOverview => (
        !isEntityOwner(entityTeamMemberOverview)
      )),
      affectedByDowngrade: entityOverviews.filter(entityTeamMemberOverview => (
        isEntityOwner(entityTeamMemberOverview) && entityTeamMemberOverview.isSender
      )),
    };
  }, [entityOverviews]);

  return useMemo(() => {
    return compact([
      {
        id: 'chooseRoleStep',
        name: t('teamManagement.dialog.changeGlobalRole.chooseANewRole'),
        render: () => (
          <ChooseRoleStep
            companyId={companyId}
            isPayingCompany={isPayingCompany}
            user={user}
          />
        ),
        canProceed: values => values.globalRole,
      },
      {
        id: 'reviewRoleChanges',
        name: t('teamManagement.dialog.changeGlobalRole.reviewRoleChanges'),
        render: () => (
          <ReviewRoleChangesStep
            affectedByUpgrade={affectedByUpgrade}
            affectedByDowngrade={affectedByDowngrade}
          />
        ),
        includeIf: values => (
          (values.globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER && !isEmpty(affectedByDowngrade)) ||
          (values.globalRole === GlobalRole.SUPER_USER && !isEmpty(affectedByUpgrade))
        ),
        canProceed: constant(true),
      },
      !isEmpty(soleOwnerSentQuestionnaires) && {
        id: 'updateQuestionnaireOwnership',
        name: t('teamManagement.dialog.removeSoleOwner.updateQuestionnaireOwnership'),
        render: () => (
          <SelectSoleOwnerStep
            type="questionnaire"
            newOwnerDescription={t('teamManagement.dialog.changeGlobalRole.chooseNewQuestionnaireOwner')}
            companyUsers={companyUsers}
            user={user}
            items={soleOwnerSentQuestionnaires}
          />
        ),
        includeIf: values => values.globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER,
        canProceed: values => values.questionnaireReplacementUserId,
      },
      !isEmpty(soleOwnerRequests) && {
        id: 'updateRequestOwnership',
        name: t('teamManagement.dialog.removeSoleOwner.updateRequestOwnership'),
        render: () => (
          <SelectSoleOwnerStep
            type="request"
            newOwnerDescription={t('teamManagement.dialog.changeGlobalRole.chooseNewRequestOwner')}
            companyUsers={companyUsers}
            user={user}
            items={soleOwnerRequests}
          />
        ),
        includeIf: values => values.globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER,
        canProceed: values => values.rfxReplacementUserId,
      },
      !isEmpty(soleOwnerContracts) && {
        id: 'updateContractOwnership',
        name: t('teamManagement.dialog.removeSoleOwner.updateContractOwnership'),
        render: () => (
          <SelectSoleOwnerStep
            type="contract"
            newOwnerDescription={t('teamManagement.dialog.changeGlobalRole.chooseNewContractOwner')}
            companyUsers={companyUsers}
            user={user}
            items={soleOwnerContracts}
          />
        ),
        includeIf: values => values.globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER,
        canProceed: values => values.contractReplacementUserId,
      },
      {
        id: 'reviewPermissions',
        name: t('teamManagement.dialog.changeGlobalRole.reviewPermissions'),
        render: () => (
          <ReviewPermissionsStep
            companyId={companyId}
            requests={requests}
            contracts={contracts}
            questionnaires={questionnaires}
          />
          ),
          canProceed: constant(true),
        },
        {
          id: 'reviewAndConfirm',
          name: t('teamManagement.dialog.removeSoleOwner.reviewAndConfirm'),
          render: () => (
            <ReviewAndConfirmStep
              companyId={companyId}
              user={user}
              companyUsers={companyUsers}
              affectedByUpgrade={affectedByUpgrade}
              affectedByDowngrade={affectedByDowngrade}
            />
        ),
      },
    ]);
  }, [
    t,
    soleOwnerSentQuestionnaires,
    soleOwnerRequests,
    soleOwnerContracts,
    companyId,
    user,
    affectedByUpgrade,
    affectedByDowngrade,
    companyUsers,
    requests,
    contracts,
    questionnaires,
    isPayingCompany,
  ]);
};

export const ChangeGlobalRoleModal = ({
  companyId,
  isPayingCompany,
  requests,
  contracts,
  questionnaires,
  soleOwnerSentQuestionnaires,
  soleOwnerRequests,
  soleOwnerContracts,
  user,
  close,
  changeGlobalRole,
  ...props
}: ModalProps & {
  companyId: string;
  isPayingCompany: boolean;
  requests: EntityTeamMemberOverview[];
  contracts: EntityTeamMemberOverview[];
  questionnaires: EntityTeamMemberOverview[];
  soleOwnerSentQuestionnaires: EntityTeamMemberOverview[];
  soleOwnerRequests: EntityTeamMemberOverview[];
  soleOwnerContracts: EntityTeamMemberOverview[];
  user: User;
  close: () => void;
  changeGlobalRole: (args: {
    user: User;
    questionnaireReplacementUserId?: string;
    rfxReplacementUserId?: string;
    contractReplacementUserId?: string;
  }) => void;
}) => {
  const { t } = useTranslation(['translation', 'general']);
  const { data: companyUsers = [] } = useCompanyUsers(companyId);
  const [currentStepId, setCurrentStepId] = useState<string | null>(null);

  const allSteps = useSteps({
    companyId,
    isPayingCompany,
    requests,
    contracts,
    questionnaires,
    soleOwnerSentQuestionnaires,
    soleOwnerRequests,
    soleOwnerContracts,
    user,
    companyUsers,
  });

  useEffect(
    () => {
      if (props.isOpen) {
        setCurrentStepId('chooseRoleStep');
      }
    },
    [props.isOpen],
  );

  return (
    <Modal style={{ content: { width: '650px' } }} {...props}>
      <Formik
        initialValues={{
          globalRole: undefined,
          ...emptyRoles,
          ...user.roles[companyId],
          questionnaireReplacementUserId: undefined,
          rfxReplacementUserId: undefined,
          contractReplacementUserId: undefined,
        }}
        validationSchema={yup.object()}
        onSubmit={async values => changeGlobalRole({ user, ...values })}
      >
        {({ isSubmitting, isValid, dirty, values }) => {
          const steps = allSteps.filter(step => !step.includeIf || step.includeIf(values));

          const isFirstStep = currentStepId === first(steps)?.id;
          const isLastStep = currentStepId === last(steps)?.id;
          const currentStepConfig = steps.find(config => config.id === currentStepId);
          const currentStepIndex = steps.indexOf(currentStepConfig);

          return (
            <Form>
              <ModalHeader onClose={close}>
                {t('teamManagement.dialog.changeGlobalRole.heading')}
              </ModalHeader>
              <ModalBody>
                <Box minHeight="450px">
                  <Heading as="h3" fontSize={4} mb="12px" fontWeight={500}>
                    {currentStepConfig?.name}
                  </Heading>
                  {currentStepConfig?.render()}
                </Box>
              </ModalBody>
              <ModalFooter justifyContent="space-between" alignItems="center" py={2} height="100%">
                <StepProgress
                  totalStepCount={steps.length}
                  currentStepIndex={currentStepIndex}
                  currentStepName={currentStepConfig?.name}
                />
                <Flex flex="0 0 auto" height="100%" alignItems="center">
                  {!isFirstStep && (
                    <Button
                      ml={2}
                      variant="secondary"
                      key="previous"
                      type="button"
                      onClick={() => setCurrentStepId(steps[currentStepIndex - 1]?.id)}
                    >
                      {t('back', { ns: 'general' })}
                    </Button>
                  )}
                  {!isLastStep ? (
                    <Button
                      ml={2}
                      key="next"
                      type="button"
                      onClick={() => setCurrentStepId(steps[currentStepIndex + 1]?.id)}
                      disabled={!currentStepConfig?.canProceed(values)}
                    >
                      {t('next', { ns: 'general' })}
                    </Button>
                  ) : (
                    <Button
                      ml={2}
                      key="submit"
                      type="submit"
                      disabled={isSubmitting || !dirty || !isValid}
                    >
                      {t('general.confirm')}
                    </Button>
                  )}
                </Flex>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
