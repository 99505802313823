import { Form, Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { useQuery } from 'react-query';
import * as yup from 'yup';
import { Flex, Text } from 'rebass/styled-components';
import { map, values } from 'lodash';

import { useAdminApi, useApi, wrap } from '@deepstream/ui/api';
import { PropertyList, ValueOrEmpty } from '@deepstream/ui/PropertyList';
import { useToaster } from '@deepstream/ui/toast';
import { Button, CancelButton, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { PanelLoadingWrapper } from '@deepstream/ui/ui/Loading';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { useModalState, useConfirmDialog } from '@deepstream/ui/ui/useModalState';
import { callAll } from '@deepstream/utils/callAll';
import { useMutation } from '@deepstream/ui/useMutation';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { CompanyFinderField } from '@deepstream/ui/form/CompanyFinderField';
import { CheckboxFieldArray } from '@deepstream/ui/form/CheckboxField';
import { Resource } from '@deepstream/ui/types';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { APP_ADMIN_COMPANY_ID } from '@deepstream/ui/constants';
import { Tab, TabListPanel, TabPanel, TabPanels, Tabs } from '@deepstream/ui/ui/TabsVertical';
import { SidebarLayout } from '@deepstream/ui/ui/ProfileLayout';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { IconValue } from '@deepstream/common/icons';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { CacheType } from '@deepstream/ui/adminApiClient';
import { useWaitForProcessingTask } from '@deepstream/ui/useWaitForProcessingTask';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { ConfigProvider, useConfig } from './ConfigProvider';
import * as title from './title';
import { Page } from './Page';
import { toolsRoute, useAdminNavigation } from './AppRouting';

const ActionButton: React.FC<
  ButtonProps & {
    label: string;
    isDeleteAction?: boolean;
    disabledTooltip?: string;
  }
> = ({ label, onClick, isDeleteAction = false, disabledTooltip, ...props }) => (
  <Tooltip content={disabledTooltip}>
    <Button
      variant={isDeleteAction ? 'danger-outline' : 'primary-outline'}
      iconLeft={isDeleteAction ? 'close' : 'play'}
      iconRight={disabledTooltip ? 'info-circle' : undefined}
      onClick={onClick}
      small
      disabled={Boolean(disabledTooltip)}
      {...props}
    >
      {label}
    </Button>
  </Tooltip>
);

const actionButtonLabels = {
  delete: 'Delete',
  clear: 'Clear',
  run: 'Run',
} as const;

const actions = {
  deleteSessions: 'deleteSessions',
  clearRfxTemplateActorCache: 'clearRfxTemplateActorCache',
  clearRfxRequestActorCache: 'clearRfxRequestActorCache',
  clearContractActorCache: 'clearContractActorCache',
  clearQuestionnaireTemplateActorCache: 'clearQuestionnaireTemplateActorCache',
  clearQuestionnaireActorCache: 'clearQuestionnaireActorCache',
  reindexProducts: 'reindexProducts',
  reindexCompanies: 'reindexCompanies',
  reindexRfqDashboardOverviews: 'reindexRfqDashboardOverviews',
  reindexContractOverviews: 'reindexContractOverviews',
  recalculateRequestData: 'recalculateRequestData',
  recalculateContractData: 'recalculateContractData',
  recalculateQuestionnaireData: 'recalculateQuestionnaireData',
  recalculateQuestionnaireTemplateData: 'recalculateQuestionnaireTemplateData',
  sendScheduledEmails: 'sendScheduledEmails',
} as const;

const cacheName = {
  rfxTemplateActorCache: 'rfxTemplateActorCache',
  rfxRequestActorCache: 'rfxRequestActorCache',
  contractActorCache: 'contractActorCache',
  questionnaireTemplateActorCache: 'questionnaireTemplateActorCache',
  questionnaireActorCache: 'questionnaireActorCache',
} as const;

const sessionsConfig = {
  [actions.deleteSessions]: {
    name: 'Delete all sessions',
    label: actionButtonLabels.delete,
    isDeleteAction: true,
  },
};

const cachesConfig = {
  [actions.clearRfxTemplateActorCache]: {
    name: `Clear ${cacheName.rfxTemplateActorCache}`,
    label: actionButtonLabels.clear,
    isDeleteAction: true,
    cacheType: cacheName.rfxTemplateActorCache,
    disabledTooltip: 'Clearing the rfx template actor cache can cause the API server to get unresponsive when trying to load large requests. Please ask a dev to run the populate-caches script instead.',
  },
  [actions.clearRfxRequestActorCache]: {
    name: `Clear ${cacheName.rfxRequestActorCache}`,
    label: actionButtonLabels.clear,
    isDeleteAction: true,
    cacheType: cacheName.rfxRequestActorCache,
    disabledTooltip: 'Clearing the rfx actor cache can cause the API server to get unresponsive when trying to load large requests. Please ask a dev to run the populate-caches script instead.',
  },
  [actions.clearContractActorCache]: {
    name: `Clear ${cacheName.contractActorCache}`,
    label: actionButtonLabels.clear,
    isDeleteAction: true,
    cacheType: cacheName.contractActorCache,
  },
  [actions.clearQuestionnaireTemplateActorCache]: {
    name: `Clear ${cacheName.questionnaireTemplateActorCache}`,
    label: actionButtonLabels.clear,
    isDeleteAction: true,
    cacheType: cacheName.questionnaireTemplateActorCache,
  },
  [actions.clearQuestionnaireActorCache]: {
    name: `Clear ${cacheName.questionnaireActorCache}`,
    label: actionButtonLabels.clear,
    isDeleteAction: true,
    cacheType: cacheName.questionnaireActorCache,
  },
};

const indexesConfig = {
  [actions.reindexProducts]: {
    name: 'Reindex products and services',
    label: actionButtonLabels.run,
  },
  [actions.reindexCompanies]: {
    name: 'Reindex companies',
    label: actionButtonLabels.run,
  },
  [actions.reindexRfqDashboardOverviews]: {
    name: 'Reindex rfqDashboardOverviews',
    label: actionButtonLabels.run,
  },
  [actions.reindexContractOverviews]: {
    name: 'Reindex contractOverviews',
    label: actionButtonLabels.run,
  },
};

const derivedDataConfig = {
  [actions.recalculateRequestData]: {
    name: 'Recalculate derived request data',
    label: actionButtonLabels.run,
  },
  [actions.recalculateContractData]: {
    name: 'Recalculate derived contract data',
    label: actionButtonLabels.run,
  },
  [actions.recalculateQuestionnaireTemplateData]: {
    name: 'Recalculate derived questionnaire template data',
    label: actionButtonLabels.run,
  },
  [actions.recalculateQuestionnaireData]: {
    name: 'Recalculate derived questionnaire data',
    label: actionButtonLabels.run,
  },
};

const emailsConfig = {
  [actions.sendScheduledEmails]: {
    name: 'Send all scheduled emails immediately',
    label: actionButtonLabels.run,
  },
};

const configToEmptyPropertyList = (config: Record<string, { name: string }>) =>
  Object.values(config).map((property) => {
    return {
      name: property.name,
      labelWidth: 300,
      value: null,
    };
  });

export const DeveloperToolsPanel: React.FC = () => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const { confirm, ...dialogProps } = useConfirmDialog();

  const sessionProperties = useMemo(() => configToEmptyPropertyList(sessionsConfig), []);
  const cacheProperties = useMemo(() => configToEmptyPropertyList(cachesConfig), []);
  const indexesProperties = useMemo(() => configToEmptyPropertyList(indexesConfig), []);
  const derivedDataProperties = useMemo(() => configToEmptyPropertyList(derivedDataConfig), []);
  const emailsProperties = useMemo(() => configToEmptyPropertyList(emailsConfig), []);

  const [confirmDialogText, setConfirmDialogText] = useState<{ heading: string; message: string }>({
    heading: '',
    message: '',
  });

  const [deleteAllSessions] = useMutation(adminApi.deleteAllSessions, {
    onError: () => toaster.error('Could not delete sessions'),
    onSuccess: () => toaster.success('Deleted sessions'),
  });

  const [clearCache] = useMutation(adminApi.clearCache, {
    onError: (_, { cacheType }) => toaster.error(`Could not clear ${cacheType}`),
    onSuccess: (_, { cacheType }) => toaster.success(`Cleared ${cacheType}`),
  });

  const [refreshProductsIndex, { isLoading: isRefreshProductsLoading }] = useMutation(adminApi.refreshProductsIndex, {
    onError: () => toaster.error('Could not re-index products and services'),
    onSuccess: () => toaster.success('Products and services re-indexed successfully'),
  });

  const [refreshCompaniesIndex, { isLoading: isRefreshCompaniesLoading }] = useMutation(adminApi.refreshCompaniesIndex, {
    onError: () => toaster.error('Could not re-index companies'),
    onSuccess: () => toaster.success('Companies re-indexing requested successfully'),
  });

  const [refreshRfqDashboardOverviewsIndex, { isLoading: isRefreshRfqDashboardOverviewsLoading }] = useMutation(adminApi.refreshRfqDashboardOverviewsIndex, {
    onError: () => toaster.error('Could not re-index rfqDashboardOverviews'),
    onSuccess: () => toaster.success('RfqDashboardOverviews re-indexed successfully'),
  });

  const [refreshContractOverviewsIndex, { isLoading: isRefreshContractOverviewsLoading }] = useMutation(adminApi.refreshContractOverviewsIndex, {
    onError: () => toaster.error('Could not re-index contractOverviews'),
    onSuccess: () => toaster.success('ContractOverviews re-indexed successfully'),
  });

  const [recalculateDerivedRequestData, { isLoading: isRecalculateRequestsLoading }] = useMutation(adminApi.recalculateDerivedRfqData, {
    onError: () => toaster.error('Could not trigger recalculation of derived request data'),
    onSuccess: () => toaster.success('Recalculation of derived request data triggered successfully'),
  });

  const [recalculateDerivedContractData, { isLoading: isRecalculateContractsLoading }] = useMutation(
    adminApi.recalculateDerivedContractData,
    {
      onError: () => toaster.error('Could not trigger recalculation of derived contract data'),
      onSuccess: () => toaster.success('Recalculation of derived contract data triggered successfully'),
    },
  );

  const [recalculateDerivedQuestionnaireTemplateData, { isLoading: isRecalculateQuestionnaireTemplatesLoading }] = useMutation(
    adminApi.recalculateDerivedQuestionnaireTemplateData,
    {
      onError: () => toaster.error('Could not trigger recalculation of derived questionnaire template data'),
      onSuccess: () => toaster.success('Recalculation of derived questionnaire template data triggered successfully'),
    },
  );

  const [recalculateDerivedQuestionnaireData, { isLoading: isRecalculateQuestionnairesLoading }] = useMutation(
    adminApi.recalculateDerivedQuestionnaireData,
    {
      onError: () => toaster.error('Could not trigger recalculation of derived questionnaire data'),
      onSuccess: () => toaster.success('Recalculation of derived questionnaire data triggered successfully'),
    },
  );

  const [sendScheduledEmails, { isLoading: isSendScheduledEmailsLoading }] = useMutation(adminApi.sendScheduledEmails, {
    onError: () => toaster.error('Could not send scheduled emails'),
    onSuccess: () => toaster.success('Scheduled emails sent'),
  });

  const onClearCacheClick = useCallback(
    (cacheType: CacheType) => {
      setConfirmDialogText({
        heading: `Clear ${cacheType}?`,
        message: `This will clear every entry in the ${cacheType}`,
      });
      confirm(() => clearCache({ cacheType }));
    },
    [confirm, clearCache, setConfirmDialogText],
  );

  return (
    <>
      <Panel heading="Sessions" mb={4}>
        <PropertyList
          properties={sessionProperties}
          DefaultComponent={ValueOrEmpty}
        >
          <ActionButton
            {...sessionsConfig[actions.deleteSessions]}
            onClick={() => {
              setConfirmDialogText({
                heading: 'Delete all sessions?',
                message: 'This will log out all users except for app admins',
              });
              confirm(() => deleteAllSessions({ includeAppAdmins: false }));
            }}
          />
        </PropertyList>
      </Panel>
      <Panel heading="Caches" mb={4}>
        <PropertyList
          properties={cacheProperties}
          DefaultComponent={ValueOrEmpty}
        >
          {[
            actions.clearRfxTemplateActorCache,
            actions.clearRfxRequestActorCache,
            actions.clearContractActorCache,
            actions.clearQuestionnaireTemplateActorCache,
            actions.clearQuestionnaireActorCache,
          ].map((action) => (
            <ActionButton
              {...cachesConfig[action]}
              key={action}
              onClick={() => onClearCacheClick(cachesConfig[action].cacheType)}
            />
          ))}
        </PropertyList>
      </Panel>
      <Panel heading="Indexes" mb={4}>
        <PropertyList
          properties={indexesProperties}
          DefaultComponent={ValueOrEmpty}
        >
          <ActionButton
            {...indexesConfig[actions.reindexProducts]}
            // TODO test why disabled is not working
            disabled={isRefreshProductsLoading}
            onClick={refreshProductsIndex}
          />
          <ActionButton
            {...indexesConfig[actions.reindexCompanies]}
            disabled={isRefreshCompaniesLoading}
            onClick={refreshCompaniesIndex}
          />
          <ActionButton
            {...indexesConfig[actions.reindexRfqDashboardOverviews]}
            disabled={isRefreshRfqDashboardOverviewsLoading}
            onClick={refreshRfqDashboardOverviewsIndex}
          />
          <ActionButton
            {...indexesConfig[actions.reindexContractOverviews]}
            disabled={isRefreshContractOverviewsLoading}
            onClick={refreshContractOverviewsIndex}
          />
        </PropertyList>
      </Panel>
      <Panel heading="Derived data" mb={4}>
        <PropertyList
          properties={derivedDataProperties}
          DefaultComponent={ValueOrEmpty}
        >
          <ActionButton
            {...derivedDataConfig[actions.recalculateRequestData]}
            disabled={isRecalculateRequestsLoading}
            onClick={recalculateDerivedRequestData}
          />
          <ActionButton
            {...derivedDataConfig[actions.recalculateContractData]}
            disabled={isRecalculateContractsLoading}
            onClick={recalculateDerivedContractData}
          />
          <ActionButton
            {...derivedDataConfig[actions.recalculateQuestionnaireTemplateData]}
            disabled={isRecalculateQuestionnaireTemplatesLoading}
            onClick={recalculateDerivedQuestionnaireTemplateData}
          />
          <ActionButton
            {...derivedDataConfig[actions.recalculateQuestionnaireData]}
            disabled={isRecalculateQuestionnairesLoading}
            onClick={recalculateDerivedQuestionnaireData}
          />
        </PropertyList>
      </Panel>
      <Panel heading="Emails" mb={4}>
        <PropertyList
          properties={emailsProperties}
          DefaultComponent={ValueOrEmpty}
        >
          <ActionButton
            {...emailsConfig[actions.sendScheduledEmails]}
            disabled={isSendScheduledEmailsLoading}
            onClick={sendScheduledEmails}
          />
        </PropertyList>
      </Panel>

      <ConfirmDeleteDialog
        heading={confirmDialogText.heading}
        message={confirmDialogText.message}
        {...dialogProps}
      />
    </>
  );
};

const labelByResource = {
  [Resource.TEMPLATES]: 'Copy templates',
  [Resource.LISTS]: 'Copy lists',
};

const CopyTemplatesModal = ({
  defaultSourceCompany,
  onCancel,
  onSave,
  ...props
}: ModalProps & {
  defaultSourceCompany: CompanyMinimized;
  onCancel: () => void;
  onSave: () => void;
}) => {
  const adminApi = useAdminApi();
  const api = useApi();
  const toaster = useToaster();
  const waitForProcessingTask = useWaitForProcessingTask();

  const [copyCompanyResources] = useMutation(
    (payload) => waitForProcessingTask({
      command: () => adminApi.copyCompanyResources(payload),
    }),
    {
      onSuccess: callAll(onSave, () => toaster.success('Copied successfully')),
      onError: () => toaster.error('Could not be copied'),
    },
  );

  const searchCompanies = React.useCallback(
    async (text) => api.searchCompanies({ text, pageSize: 7, pageIndex: 0 }).then((result) => result.companies),
    [api],
  );

  const resourceOptions = React.useMemo(
    () =>
      map(values(Resource), (resource) => ({
        value: resource,
        label: labelByResource[resource],
      })),
    [],
  );

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{
          sourceCompany: defaultSourceCompany,
          targetCompany: null,
          resources: [Resource.TEMPLATES, Resource.LISTS],
        }}
        validationSchema={yup.object().shape({
          sourceCompany: yup.object().required(),
          targetCompany: yup.object().required(),
          resources: yup.array().min(1, 'You must select at least 1'),
        })}
        onSubmit={async ({ sourceCompany, targetCompany, resources }) => {
          await copyCompanyResources({
            sourceCompanyId: sourceCompany._id,
            targetCompanyId: targetCompany._id,
            resources,
          });
        }}
      >
        {({ isSubmitting, dirty, values, isValid }) => (
          <Form>
            <ModalHeader onClose={onCancel}>Copy templates and lists</ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                <CompanyFinderField
                  name="sourceCompany"
                  label="Source company"
                  placeholder="Select a company"
                  currentCompanyId={APP_ADMIN_COMPANY_ID}
                  disabledCompanies={[values.targetCompany]}
                  disabledCompanyLabel="Target company"
                  requiredErrorMessage="Required"
                  searchCompanies={searchCompanies}
                  withResultListSubheader={false}
                  required
                />
                <CompanyFinderField
                  name="targetCompany"
                  label="Target company"
                  placeholder="Select a company"
                  currentCompanyId={APP_ADMIN_COMPANY_ID}
                  disabledCompanies={[values.sourceCompany]}
                  disabledCompanyLabel="Source company"
                  requiredErrorMessage="Required"
                  searchCompanies={searchCompanies}
                  withResultListSubheader={false}
                  required
                />
                <CheckboxFieldArray name="resources" flexDirection="row" options={resourceOptions} />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} mr={2} />
              <Button disabled={isSubmitting || !dirty || !isValid}>Copy</Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const ToolsPanel = () => {
  const api = useApi();
  const copyTemplatesModal = useModalState();
  const config = useConfig();

  const { data: company } = useQuery(
    ['publicCompany', { companyId: config.defaultSourceCompanyId }],
    wrap(api.getPublicCompany),
    {
      enabled: Boolean(config.defaultSourceCompanyId),
    },
  );

  return (
    <>
      <Panel heading="Customer tools" mb={4}>
        <Flex height="52px" alignItems="center" px="20px">
          <Text flex={1} fontSize={2}>
            Copy templates and lists between companies
          </Text>
          <Button small variant="primary-outline" onClick={copyTemplatesModal.open}>
            Open
          </Button>
        </Flex>
      </Panel>
      {copyTemplatesModal.isOpen && (
        <CopyTemplatesModal
          defaultSourceCompany={company}
          isOpen={copyTemplatesModal.isOpen}
          onCancel={copyTemplatesModal.close}
          onSave={copyTemplatesModal.close}
          onRequestClose={copyTemplatesModal.close}
        />
      )}
    </>
  );
};

const configPageTabs = {
  customerTools: 'customer-tools',
  developerTools: 'developer-tools',
};

const tabsSidebarConfig: Record<
  string,
  {
    text: string;
    icon: IconValue;
  }
> = {
  [configPageTabs.customerTools]: {
    text: 'Customer Tools',
    icon: 'wrench',
  },
  [configPageTabs.developerTools]: {
    text: 'Developer tools',
    icon: 'code',
  },
};

const getTabIndex = (tab: string) => Object.values(configPageTabs).indexOf(tab);
const getTab = (index: number) => Object.values(configPageTabs)[index];

export const ToolsPage = () => {
  const adminApi = useAdminApi();
  const { tab } = toolsRoute.useSearch();
  const navigation = useAdminNavigation();
  const { data: config, status } = useQuery(['config'], adminApi.getConfig);

  const { isExtraSmall, isSmall } = useDeviceSize();

  useEffect(() => {
    if (!tab) {
      navigation.navigateToTools(configPageTabs.customerTools, true);
    }
  }, [tab, navigation]);

  return (
    <Page>
      <title.Container>
        <title.IconText icon="toolbox" size="large">
          Tools
        </title.IconText>
      </title.Container>
      <PanelLoadingWrapper status={status} data={config} errorMessage="An unexpected error occurred">
        <ConfigProvider config={config}>
          <Tabs index={getTabIndex(tab)} onChange={index => navigation.navigateToTools(getTab(index))}>
            <SidebarLayout
              sidebar={
                <TabListPanel>
                  {Object.entries(tabsSidebarConfig).map(([tabKey, config]) => (
                    <Tab key={tabKey}>
                      <Flex alignItems="center">
                        <Icon icon={config.icon} mr={2} fixedWidth />
                        <Text flex={1}>{config.text}</Text>
                      </Flex>
                    </Tab>
                  ))}
                </TabListPanel>
              }
              main={
                <TabPanels>
                  <TabPanel key={configPageTabs.customerTools}>
                    <ToolsPanel />
                  </TabPanel>
                  <TabPanel key={configPageTabs.developerTools}>
                    <DeveloperToolsPanel />
                  </TabPanel>
                </TabPanels>
              }
              sidebarStyle={!isExtraSmall && !isSmall ? { maxWidth: '232px', flex: '0 0 auto' } : undefined}
              mainStyle={!isExtraSmall && !isSmall ? { flex: '1 1 auto' } : undefined}
            />
          </Tabs>
        </ConfigProvider>
      </PanelLoadingWrapper>
    </Page>
  );
};
