import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { isNumber } from 'lodash';
import { Panel, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { CurrencyAmount, CurrencyCodeProvider } from '../../ui/Currency';
import { useRequestsReportingData } from './useRequestsReporting';
import * as dashboard from './dashboard';

export const NegotiatedAuctionsSavings = () => {
  const { t } = useTranslation('reporting');

  const reportingData = useRequestsReportingData();

  const {
    data: {
      currency,
      auctions: {
        winningBidPricesTotal,
        lowestStartingBidPricesTotal,
      },
    },
  } = reportingData;

  return (
    <CurrencyCodeProvider code={currency}>
      <Panel height="100%" p="20px">
        <Flex flexDirection="column" height="100%">
          <dashboard.PanelHeading mb={3} data-test="negotiated-auction-savings">
            {t('auctions.negotiatedAuctionSavings')}
          </dashboard.PanelHeading>
          <Flex flexDirection="column" justifyContent="space-between" flex={1}>
            <Stack gap={3}>
              <dashboard.LabeledValue
                small
                label={t('auctions.lowestStartingBid')}
                labelTestAttribute="lowest-starting-bid"
              >
                <CurrencyAmount value={lowestStartingBidPricesTotal} />
              </dashboard.LabeledValue>

              <dashboard.LabeledValue
                small
                label={t('auctions.winningBid')}
                labelTestAttribute="winning-bid"
              >
                <CurrencyAmount value={winningBidPricesTotal} />
              </dashboard.LabeledValue>
            </Stack>

            <Box py="20px">
              <PanelDivider />
            </Box>

            <dashboard.LabeledValue
              label={t('auctions.totalEstimatedSavings')}
              labelTestAttribute="total-estimated-savings"
            >
              <CurrencyAmount
                value={isNumber(winningBidPricesTotal) && isNumber(lowestStartingBidPricesTotal) && (
                  lowestStartingBidPricesTotal - winningBidPricesTotal
                )}
                placeholder={t('noData')}
              />
            </dashboard.LabeledValue>
          </Flex>
        </Flex>
      </Panel>
    </CurrencyCodeProvider>
  );
};
