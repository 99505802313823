import { isEmpty } from 'lodash';
import { FlexProps, Text } from 'rebass/styled-components';
import { useInView } from 'react-intersection-observer';
import { Panel, PanelDivider, PanelPadding, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { QuestionnaireTemplateAttachment } from '@deepstream/ui/modules/PreQualification/QuestionnaireTemplate/QuestionnaireTemplateAttachment';
import { QuestionnaireTemplateIdProvider, QuestionnaireTemplateProvider } from '@deepstream/ui/modules/PreQualification/QuestionnaireTemplate/questionnaireTemplateUtils';
import { Page } from '../../Page';
import * as Title from '../../title';
import { questionnaireTemplateRoute } from '../../AppRouting';
import { useAdminQuestionnaireTemplate } from './adminPreQual';
import { AdminQuestionnaireTemplatePropertyList } from './AdminQuestionnaireTemplatePropertyList';
import { AdminQuestionnaireTemplateQuestionsTable } from './AdminQuestionnaireTemplateQuestionsTable';
import { AdminQuestionnaireTemplateSuppliersTable } from './AdminQuestionnaireTemplateSuppliersTable';

export const PageTitle = ({
  flexDirection,
}: {
  flexDirection?: FlexProps['flexDirection'];
}) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.QuestionnaireTemplate
      size={flexDirection === 'column' ? 'large' : 'small'}
    />
  </Title.Container>
);

export const AdminQuestionnaireTemplatePage = () => {
  const { templateId } = questionnaireTemplateRoute.useParams();
  const [ref, inView, entry] = useInView();

  if (!templateId) {
    throw new Error('A templateId is required');
  }

  const { data, error, status } = useAdminQuestionnaireTemplate(templateId);

  const template = data?.template;

  return (
    <Page
      subHeading={template && entry && !inView && (
        <QuestionnaireTemplateProvider template={template}>
          <PageTitle />
        </QuestionnaireTemplateProvider>
      )}
    >
      {status === 'loading' ? (
        <Panel>
          <PanelText><Loading /></PanelText>
        </Panel>
      ) : status === 'error' ? (
        <Panel heading="Error">
          <PanelText color="danger">
            <Icon icon="exclamation-circle" mr={2} />{(error as any)?.toString()}
          </PanelText>
        </Panel>
      ) : data ? (
        <QuestionnaireTemplateProvider template={template}>
          <div ref={ref}>
            <PageTitle flexDirection="column" />
          </div>
          <Panel heading="General" mb={4}>
            <AdminQuestionnaireTemplatePropertyList companyName={data.companyName} numSuppliers={data.sentQuestionnaires.length} />
          </Panel>
          <Panel heading="Suppliers" mb={4}>
            <AdminQuestionnaireTemplateSuppliersTable suppliers={data.sentQuestionnaires} />
          </Panel>
          <Panel heading="Questions" mb={4}>
            <PanelPadding>
              <Text color="lightNavy" fontWeight={500} fontSize={2} mb={2}>
                Instructions
              </Text>
              <Stack gap={2}>
                {data.template.instructions ? (
                  <Text color="darkGray2" fontSize={1}>{data.template.instructions}</Text>
                ) : isEmpty(data.template.instructionsAttachments) ? (
                  <Text fontSize={1} color="subtext">No instructions</Text>
                ) : (
                  null
                )}
                <QuestionnaireTemplateIdProvider templateId={data.template._id}>
                  {data.template.instructionsAttachments.map((attachment) => (
                    <QuestionnaireTemplateAttachment
                      key={attachment._id}
                      attachment={attachment}
                    />
                  ))}
                </QuestionnaireTemplateIdProvider>
              </Stack>
            </PanelPadding>
            <PanelDivider />
            <AdminQuestionnaireTemplateQuestionsTable />
          </Panel>
        </QuestionnaireTemplateProvider>
      ) : null}
    </Page>
  );
};
