import * as React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { Header } from './Header';
import { ErrorBoundary } from './ErrorBoundary';

const contentMaxWidth = 1200;

const Main: React.FC = ({ children }) => (
  <Box as="main" role="main" padding={20} pb={60}>
    <Box maxWidth={contentMaxWidth} mx="auto">
      {children}
    </Box>
  </Box>
);

export const StickyOnScroll = styled(Flex)`
  position: fixed;
  z-index: 2;
  top: 65px;
  background-color: white;
  width: 100%;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.1), 0px 1px 2px rgba(0,0,0,0.2);
`;

export const Page: React.FC<any> = ({ children, subHeading }) => (
  <Flex flexDirection="column" height="100%" bg="#f7f9fb">
    <Header />
    {subHeading && (
      <StickyOnScroll>
        <Box maxWidth={contentMaxWidth} mx="auto">
          {subHeading}
        </Box>
      </StickyOnScroll>
    )}
    <Box flex="1" overflowY="auto">
      <ErrorBoundary>
        <Main>{children}</Main>
      </ErrorBoundary>
    </Box>
  </Flex>
);
