import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { CompanyMinimizedNameAndLogoCell } from '../../CompanyMinimizedNameAndLogoCell';

import { Table } from '../../Table';
import { StaticTableStyles } from '../../TableStyles';

import { ProgressBarCell } from './ProgressBarCell';
import { useRequestsReportingData } from './useRequestsReporting';

export const SupplierEngagementTable = () => {
  const { t } = useTranslation('reporting');
  const { data: { suppliers } } = useRequestsReportingData();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: t('supplierEngagement.supplierName'),
        accessor: 'name',
        Cell: withProps(CompanyMinimizedNameAndLogoCell, { minHeight: '47px', height: '47px', hasLink: false }),
      },
      {
        id: 'numRequestsReceived',
        Header: t('supplierEngagement.requestsReceived'),
        accessor: 'numRequestsReceived',
        width: 180,
      },
      {
        id: 'numRequestsAwarded',
        Header: t('supplierEngagement.requestsAwarded'),
        accessor: 'numRequestsAwarded',
        width: 180,
      },
      {
        id: 'requestResponseRate',
        Header: t('supplierEngagement.requestResponseRate'),
        accessor: 'requestResponseRate',
        Cell: ProgressBarCell,
      },
      {
        id: 'requestCompletionRate',
        Header: t('supplierEngagement.requestCompletionRate'),
        accessor: 'requestCompletionRate',
        Cell: ProgressBarCell,
      },
    ],
    [t],
  );

  return (
    <Panel>
      <StaticTableStyles>
        <Table
          isSortable
          isPaginated={suppliers.length > 5}
          smallPageControls
          columns={columns}
          data={suppliers}
        />
      </StaticTableStyles>
    </Panel>
  );
};
