import { useMemo } from 'react';
import { PropertyList } from '@deepstream/ui/PropertyList';
import { Datetime } from '@deepstream/ui/Datetime';
import { useContractData } from '@deepstream/ui/modules/Contracts/contract';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Id } from '../../Id';
import { RequestLink } from '../../RequestLink';

const RequestLinkValue = ({
  value: { requestId, requestSubject },
}: {
  value: { requestId: string; requestSubject: string };
}) => {
  return requestId ? (
    <RequestLink requestId={requestId}>
      {requestSubject}
    </RequestLink>
  ) : (
    <EmDash />
  );
};

export const AdminContractPropertyList = () => {
  const contract = useContractData();
  const { requestId, requestSubject } = contract;

  const generalProperties = useMemo(
    () => [
      { name: 'Status', value: contract.status },
      { name: 'ID', value: contract._id, Component: Id },
      { name: 'Reference', value: contract.autoReferenceNumber },
      { name: 'Created on', value: contract.meta.creationTimestamp, Component: Datetime },
      { name: 'Created by', value: contract.meta.creatorUsername },
      { name: 'Linked request', value: { requestId, requestSubject }, Component: RequestLinkValue },
    ],
    [contract, requestId, requestSubject],
  );

  return (
    <PropertyList properties={generalProperties} />
  );
};
