import { useMemo } from 'react';
import { isFunction, orderBy } from 'lodash';
import { EditableGridColumn } from './utils';
import { SortConfig } from './sortConfig';
import { getCellValue } from './getCellValue';

const getSortCellValue = <TRowOriginal = unknown>(row: TRowOriginal, column: EditableGridColumn<TRowOriginal>) => {
  if (isFunction(column.sortAccessorFn)) {
    return column.sortAccessorFn(row);
  } else {
    return getCellValue(row, column);
  }
};

export const useSortedGridData = <TRowOriginal = unknown>(
  sortConfig: SortConfig,
  data,
  columns: EditableGridColumn<TRowOriginal>[],
) => {
  return useMemo(() => {
    if (!sortConfig.columnId || !sortConfig.direction) {
      return data;
    }

    const column = columns.find(column => column._id === sortConfig.columnId);

    if (!column) {
      return data;
    }

    const getTransformedValue = column.sortType === 'caseInsensitive' ? (
      item => {
        const value = getSortCellValue(item, column);

        return value ? value.toLowerCase() : '';
      }
    ) : column.sortType === 'datetime' ? (
      item => {
        const value = getSortCellValue(item, column);

        const parsedDate = new Date(value);

        return value && isFinite(parsedDate.valueOf()) ? parsedDate : null;
      }
    ) : (
      item => getSortCellValue(item, column)
    );

    return orderBy(data, [getTransformedValue], [sortConfig.direction]);
  }, [sortConfig, columns, data]);
};
