import { useMemo } from 'react';
import { compact, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RequestsSavingsCardsData } from '@deepstream/common/reporting';
import { Box, Flex, Text } from 'rebass/styled-components';
import { TotalSavingsCalculationMethod } from '@deepstream/common/rfq-utils';
import { localeFormatFactorAsPercent, localeFormatPrice } from '@deepstream/utils';
import { DoughnutChart } from '@deepstream/ui-kit/charts/DoughnutChart';
import { inactiveSavingsMethodColorById, savingsMethodColorById } from './colors';
import { useCurrentUserLocale } from '../../useCurrentUser';

const TOOLTIP_WIDTH = 250;
const TOOLTIP_HEIGHT = 54;
const CARET_HEIGHT = 10;

export const SavingsByMethodPieChart = ({
  data,
}: {
  data: RequestsSavingsCardsData;
}) => {
  const { t } = useTranslation(['translation', 'reporting']);
  const locale = useCurrentUserLocale();

  const slices = useMemo(() => {
    const filteredRequests = data.requests.filter(request => request.areSavingsConfirmed);

    const counts = filteredRequests.reduce((accum, current) => {
      switch (current.savingsCalculationMethod) {
        case TotalSavingsCalculationMethod.MANUAL:
          accum.manualSavings += current.manualSavings || 0;
          break;
        case TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF:
          accum.budgetFinalValueSavings += current.budgetFinalValueSavings || 0;
          break;
        case TotalSavingsCalculationMethod.SUM_SPECIFIC_SAVINGS:
          accum.singleResponseLineItemSavings += current.singleResponseLineItemSavings || 0;
          accum.multiResponseLineItemSavings += current.multiResponseLineItemSavings || 0;
          accum.auctionLineItemSavings += current.auctionLineItemSavings || 0;
          break;
        default:
          break;
      }

      return accum;
    }, {
      singleResponseLineItemSavings: 0,
      multiResponseLineItemSavings: 0,
      manualSavings: 0,
      auctionLineItemSavings: 0,
      budgetFinalValueSavings: 0,
    });

    return compact(
      [
        // order is relevant
        'singleResponseLineItemSavings',
        'multiResponseLineItemSavings',
        'manualSavings',
        'auctionLineItemSavings',
        'budgetFinalValueSavings',
      ].map(id => {
        const count = counts[id];

        if (!count || count < 0) {
          return null;
        }

        return {
          id,
          value: count,
          label: id === 'singleResponseLineItemSavings' ? (
            `${t('request.lineItems.lineItem_other')}: ${t('request.multiStageBehavior.single.label')}`
          ) : id === 'multiResponseLineItemSavings' ? (
            `${t('request.lineItems.lineItem_other')}: ${t('request.multiStageBehavior.perStage.label')}`
          ) : id === 'manualSavings' ? (
            t('request.spendAndSavings.totalSavingsCalculationMethods.manual')
          ) : id === 'auctionLineItemSavings' ? (
            t('general.auction')
          ) : (
            t('request.spendAndSavings.totalSavingsCalculationMethods.budgetFinalValueDiff')
          ),
          activeColor: savingsMethodColorById[id],
          inactiveColor: inactiveSavingsMethodColorById[id],
        };
      }),
    );
  }, [data, t]);

  const TooltipOverlay = useMemo(() => {
    return ({
      activeSliceId,
      selectedValue,
      totalValue,
    }: {
      activeSliceId: string | null;
      selectedValue: number;
      totalValue: number;
    }) => {
      if (!activeSliceId) {
        return null;
      }

      const slice = slices.find(slice => slice.id === activeSliceId);

      if (!slice) {
        return null;
      }

      return (
        <Box
          backgroundColor="navy"
          color="white"
          fontSize={2}
          sx={{
            position: 'absolute',
            pointerEvents: 'none',
            left: '146px',
            top: '40px',
            width: TOOLTIP_WIDTH,
            height: TOOLTIP_HEIGHT,
            borderRadius: '4px',
            lineHeight: '20px',
            padding: '7px 12px',
            zIndex: 200,
          }}
        >
          <Text>
            {slice.label}
          </Text>
          <Text>
            {localeFormatPrice(slice.value, data.config.currency, { locale })}
            {' '}
            ({localeFormatFactorAsPercent(selectedValue / totalValue, { locale, decimalPlaces: 1 })})
          </Text>
          <Box
            backgroundColor="navy"
            sx={{
              position: 'absolute',
              top: `${TOOLTIP_HEIGHT / 2 - CARET_HEIGHT / 2}px`,
              left: `-${CARET_HEIGHT / 2}px;`,
              width: `${CARET_HEIGHT}px;`,
              height: `${CARET_HEIGHT}px;`,
              transform: 'rotate(-45deg);',
            }}
          />
        </Box>
      );
    };
  }, [data.config.currency, locale, slices]);

  return isEmpty(slices) ? (
    <Flex width="100%" height="100%" justifyContent="center">
      {t('noData', { ns: 'reporting' })}
    </Flex>
  ) : (
    <DoughnutChart
      description={t('request.spendAndSavings.savingsByMethod')}
      slices={slices}
      legendPosition="bottom"
      legendOrientation="vertical"
      cutout="0%"
      width="132px"
      height="132px"
      Overlay={TooltipOverlay}
    />
  );
};
