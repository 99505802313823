import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useAdminQuestionnaireQueryKey } from '@deepstream/ui/modules/PreQualification/Questionnaire/useQuestionnaire';

export const getAdminQuestionnaireTemplateQueryKey = (templateId: string) =>
  ['adminQuestionnaireTemplate', { templateId }];

export const useAdminQuestionnaireTemplateQueryKey = (templateId: string) => {
  return useMemo(() => getAdminQuestionnaireTemplateQueryKey(templateId), [templateId]);
};

export const useAdminQuestionnaireTemplate = (templateId: string) => {
  const adminApi = useAdminApi();
  const queryKey = useAdminQuestionnaireTemplateQueryKey(templateId);

  return useQuery(
    queryKey,
    wrap(adminApi.getAdminQuestionnaireTemplateOverview),
  );
};

export const useAdminQuestionnaire = () => {
  const adminApi = useAdminApi();
  const queryKey = useAdminQuestionnaireQueryKey();

  return useQuery(
    queryKey,
    wrap(adminApi.getAdminQuestionnaire),
  );
};
