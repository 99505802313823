import * as ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios';
import { initReactTranslations } from '@deepstream/ui/i18n';
import { ensureIntlListFormat } from '@deepstream/ui/polyfill/ensureIntlListFormat';
import { App } from './App';

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = (config) => Boolean(config.withCredentials);

Modal.setAppElement('#root');

const container = document.getElementById('root');

ensureIntlListFormat()
  .then(initReactTranslations)
  .then(() => {
    ReactDOM.render(<App />, container);
  })
  // eslint-disable-next-line no-console
  .catch(err => console.log(err));
