import * as React from 'react';
import { get, reject } from 'lodash';
import { Flex, Box, FlexProps, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { truncateStyle } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { PreWrap } from './PreWrapCell';

const mapProp = (children: React.ReactNode, propName: string, defaultValue: any) =>
  React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      const value = child.props[propName];
      return value === undefined ? defaultValue : value;
    } else {
      return defaultValue;
    }
  }) ?? undefined;

const toGroups = (array: any[], sizes: number[] = []) => {
  const groups: any[] = [];

  let index = 0;
  for (const size of sizes) {
    groups.push(array.slice(index, index + size));
    index += size;
  }

  if (index < array.length) {
    groups.push(array.slice(index));
  }

  return groups;
};

const ROW_HEIGHT = 50;

const rowStyle = {
  fontSize: 2,
  ':not(:last-child)': {
    borderBottom: 'lightGray',
  },
};

const Column: React.FC<FlexProps> = (props) => (
  <Flex flexDirection="column" {...props} />
);

export const DefaultRow: React.FC<FlexProps> = (props) => (
  <Flex
    alignItems="center"
    sx={rowStyle}
    {...props}
  />
);

type ValueOrEmDashProps = { value: any };

export const ValueOrEmpty: React.FC<{ value: any }> = ({ value }) => (
  <>
    {value || null}
  </>
);

export const ValueOrEmDash = ({ value }: ValueOrEmDashProps) => (
  <>
    {value || <EmDash />}
  </>
);

export const PreWrapValueOrEmDash = ({ value }: ValueOrEmDashProps) => (
  value ? <PreWrap>{value}</PreWrap> : <EmDash />
);

export type PropertyListActionProps = ButtonProps & {
  label: string;
  icon: IconProps['icon'];
  stretch?: boolean;
};

export const YesNoWithIcon = ({ value, placeholder }: { value?: boolean; placeholder?: React.ReactElement }) => {
  const { t } = useTranslation('translation');

  return value === true ? (
    <>
      <Icon color="success" icon="circle-check" regular mr={1} />
      {t('general.yes')}
    </>
  ) : value === false ? (
    <>
      <Icon color="danger" icon="circle-xmark" regular mr={1} />
      {t('general.no')}
    </>
  ) : (
    placeholder ?? <EmDash />
  );
};

export const PropertyListAction = ({
  label,
  icon,
  ...props
}: PropertyListActionProps) => (
  <Button variant="primary-outline" small iconLeft={icon} {...props}>
    {label}
  </Button>
);

export const Skip: React.FC<{ rows?: number }> = () => null;

export type PropertyListProps = {
  children?:
    (React.ReactElement<PropertyListActionProps> | null)[] |
    React.ReactElement<PropertyListActionProps>;
  properties: Array<{
    name: string;
    infoTooltip?: string;
    description?: string;
    value: any;
    Component?: (props: React.PropsWithChildren<any>) => React.ReactNode;
    heightAuto?: boolean;
    truncateLabel?: boolean;
  }>;
  DefaultComponent?: (props: React.PropsWithChildren<any>) => React.ReactNode;
  Row?: React.FC<any>;
};

export const PropertyList = ({
  properties,
  children,
  DefaultComponent = ValueOrEmDash,
  Row = DefaultRow,
}: PropertyListProps) => {
  const propertyGroups = React.useMemo(
    () => {
      const numRowsByGroup = mapProp(children, 'rows', 1);
      return toGroups(reject(properties, 'hidden'), numRowsByGroup);
    },
    [children, properties],
  );

  const childrenArray = React.Children.toArray(children);

  return (
    <Box as="dl">
      {propertyGroups.map((properties: any[], index) => (
        Boolean(properties.length) && (
          <DefaultRow key={index} alignItems="center">
            <Column flex={1}>
              {properties.map(({
                fieldName,
                name,
                description,
                infoTooltip,
                value,
                Component = DefaultComponent,
                heightAuto,
                labelWidth = 200,
                truncateLabel = false,
                ...props
              }, index) => (
                <Row
                  key={`${index}-${name}`}
                  px={20}
                  width="100%"
                  height={heightAuto ? 'auto' : ROW_HEIGHT}
                  py={heightAuto ? 16 : 0}
                  fieldName={fieldName}
                >
                  <Box
                    as="dt"
                    aria-label={name}
                    style={{
                      fontWeight: 500,
                      width: labelWidth,
                      ...(truncateLabel ? truncateStyle : {}),
                    }}
                    alignSelf={heightAuto ? 'flex-start' : 'center'}
                    mr={1}
                  >
                    {name}
                    {infoTooltip && (
                      <Tooltip content={infoTooltip}>
                        <Icon icon="question-circle" regular color="subtext" ml={1} fontWeight={400} />
                      </Tooltip>
                    )}
                    {description && (
                      <Text color="subtext" fontSize={1} fontWeight={400}>
                        {description}
                      </Text>
                    )}
                  </Box>
                  <Box as="dd" flex={1}>
                    <Component value={value} fieldName={fieldName} {...props} />
                  </Box>
                </Row>
              ))}
            </Column>
            {childrenArray[index] && get(childrenArray, [index, 'type']) !== Skip && (
              <Column
                alignSelf="stretch"
                mx={20}
                justifyContent={properties.length === 1 && properties[0].heightAuto ? 'flex-start' : 'center'}
              >
                {childrenArray[index]}
              </Column>
            )}
          </DefaultRow>
        )
      ))}
    </Box>
  );
};
