import { CellProps } from 'react-table';
import { QuestionnaireOverview } from '@deepstream/common/preQual';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { AdminTableLink } from '../../AdminTableLink';
import { questionnaireRoute } from '../../AppRouting';

const QuestionnaireLink = ({ questionnaireId, ...props }: { questionnaireId: string; children: React.ReactNode }) => (
  <AdminTableLink
    to={questionnaireRoute.to}
    params={{ questionnaireId }}
    onClick={stopPropagation}
    {...props}
  />
);

export const QuestionnaireLinkCell = ({ row, cell }: CellProps<QuestionnaireOverview>) => (
  <QuestionnaireLink questionnaireId={row.original._id}>
    {cell.value}
  </QuestionnaireLink>
);
