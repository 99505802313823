import { useAdminApi, wrap } from '@deepstream/ui/api';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useQuery } from 'react-query';
import { PropertyList } from '@deepstream/ui/PropertyList';
import { Datetime2 } from '@deepstream/ui/Datetime';
import { Disclosure } from '@deepstream/ui/ui/Disclosure';
import { DiscoverySearchResultsTable } from '@deepstream/ui/modules/AI/DiscoverySearchResultsTable';
import { downloadCsv } from '@deepstream/ui/modules/AI/DiscoverySearch';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { CompanyLink } from '@deepstream/ui/CompanyLink';
import { isFinite, sumBy } from 'lodash';
import { DateFormat } from '@deepstream/utils';
import * as title from '../title';
import { Page } from '../Page';
import { UserLink } from '../UserLink';
import { reportsDiscoveryTransactionRoute } from '../AppRouting';

function objectToArray(obj) {
  const result = [];

  for (const [key, value] of Object.entries(obj)) {
    result.push({ name: key, value });
  }

  return result;
}

const QueryParamsPanel = ({ queryParams }) => {
  return (
    <Panel>
      <PanelHeader heading="Query parameters" />
      <PanelDivider />
      <PropertyList
        properties={
          objectToArray({
            'I’m running a request for': queryParams.request,
            'My specifications are as follows': queryParams.scope,
            'Please show me suppliers based in': queryParams.includedLocations.length === 0
                ? null
                : queryParams.includedLocations.join(', '),
            'I would like suppliers similar to': queryParams.similar,
            'But don’t include these suppliers': queryParams.excludedSuppliers,
            'Something else you should know': queryParams.details,
          })
        }
      />
    </Panel>
  );
};

const QueryMetadataPanel = ({ queryData, numberOfPrompts }) => {
  const adminApi = useAdminApi();
  const { data: user } = useQuery(
    ['user', { userId: queryData.userId }],
    wrap(adminApi.getUser),
  );

  const { data: company } = useQuery(
    ['user', { companyId: queryData.companyId }],
    wrap(adminApi.getCompany),
  );

  return (
    <Panel>
      <PanelHeader heading="Query metadata" />
      <PanelDivider />
      <PropertyList properties={objectToArray({
        'Timestamp': <Datetime2 value={queryData.timestamp} format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ} />,
        'User': (
          <UserLink userId={queryData.userId}>
            {user ? `${user.name} (${user.email})` : queryData.userId}
          </UserLink>
        ),
        'Company': (
          <CompanyLink companyId={queryData.companyId}>
            {company ? company.name : queryData.companyId}
          </CompanyLink>
        ),
        'Prompt': queryData.promptStrategyVersion,
        'Duration (s)': isFinite(queryData?.totalDuration) ? (queryData.totalDuration / 1000).toFixed(3) : undefined,
        'Success': queryData.result.status,
        'Num. raw results': queryData.result.raw?.length,
        'Num. results': queryData.result.rows?.length,
        'Num. feedback': queryData.feedbackEntries.length,
        'Num. prompts': numberOfPrompts,
      })}
      />
    </Panel>
  );
};

const JsonTextPanel = ({
  title,
  json,
  initialIsOpen,
}: {
  title: string;
  json: string;
  initialIsOpen?: boolean;
}) => {
  if (!json || json?.length === 0) {
    return null;
  }

  return (
    <Panel>
      <PanelHeader heading={title} />
      <PanelDivider />
      <PanelPadding>
        <Disclosure summary="Show JSON data" width="100%" initialIsOpen={initialIsOpen}>
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(json, null, 4)}
          </pre>
        </Disclosure>
      </PanelPadding>
    </Panel>
  );
};

const AggregatePromptDataPanel = ({ promptData }) => {
  return (
    <Panel>
      <PanelHeader heading="Prompts aggregate metadata" />
      <PanelDivider />
      <PropertyList properties={objectToArray({
        'Total prompts duration (s)': sumBy(
          promptData,
          (prompt: { callDuration: number }) => prompt.callDuration / 1000,
        )?.toFixed(3),
        'Num. prompts': promptData?.length,
      })}
      />
    </Panel>
  );
};

const PromptPanels = ({ promptData }) => {
  return (
    promptData.map((prompt, index) => {
      return (
        <>
          <Panel>
            <PanelHeader heading={`Prompt ${index + 1}`} />
            <PanelDivider />
            <PropertyList
              properties={
                objectToArray({
                  'Context': prompt?.context,
                  'Model': prompt?.model,
                  'Temperature': prompt?.temperature?.toString(),
                  'Duration (s)': isFinite(prompt?.callDuration) ? (prompt.callDuration / 1000)?.toFixed(3) : undefined,
                  'Timestamp': prompt?.timestamp,
                  'Success': prompt.result.status,
                })
              }
            />
          </Panel>
          <PanelDivider />
          <JsonTextPanel title={`Prompt ${index + 1} prompt objects`} json={prompt.prompts} />
          <JsonTextPanel title={`Prompt ${index + 1} results`} json={prompt.result} />
        </>
      );
    })
  );
};

export const DiscoveryTransactionPage = () => {
  const adminApi = useAdminApi();
  const { transactionId } = reportsDiscoveryTransactionRoute.useParams();
  const { data, status } = useQuery(
    ['transaction', { transactionId }],
    wrap(adminApi.getDiscoveryTransaction),
  );

  return (
    <Page>
      <title.Container>
        <title.IconText icon="search" size="large">
          Discovery transaction
        </title.IconText>
      </title.Container>
      <Stack gap={4}>
        <Panel>
          <PanelHeader heading={`TransactionId: ${transactionId}`} />
        </Panel>

        {status === 'success' && data && (
          <>
            <QueryMetadataPanel
              queryData={data?.queryData}
              numberOfPrompts={data?.promptData?.length}
            />
            <QueryParamsPanel queryParams={data?.queryData?.params} />
            {data.queryData?.result?.status === 'success' && (
              <Panel>
                <PanelHeader heading="Search results" icon="list-ul">
                  <Button
                    small
                    onClick={() => downloadCsv('supplier-results.csv', data?.queryData?.result?.rows)}
                    iconLeft="download"
                    type="button"
                  >
                    Download your results
                  </Button>
                </PanelHeader>
                <PanelDivider />
                <DiscoverySearchResultsTable data={data?.queryData?.result?.rows} />
              </Panel>
            )}
            <JsonTextPanel
              title="Query result"
              json={data?.queryData?.result}
              initialIsOpen={false}
            />
            <JsonTextPanel
              title="Query feedback"
              json={data?.queryData?.feedbackEntries}
              initialIsOpen={false}
            />
            <AggregatePromptDataPanel promptData={data?.promptData} />
            <PromptPanels promptData={data?.promptData} />
          </>
        )}
      </Stack>
    </Page>
  );
};
