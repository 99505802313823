import * as React from 'react';
import { Flex, Image, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { getRegionName } from '@deepstream/common/region';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useEnv } from './env';
import { Company } from './ui/types';
import { useCurrentUserLocale } from './useCurrentUser';

const sizeToPx = {
  xxs: '14px',
  xs: '25px',
  sm: '34px',
  md: '40px',
};

const sizeToPadding = {
  xxs: '1px',
  xs: '1px',
  sm: '2px',
  md: '3px',
};

const Container = styled(Flex)<{
  size?: 'xxs' | 'xs' | 'sm' | 'md' ;
}>`
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => sizeToPx[size]};
  height: ${({ size }) => sizeToPx[size]};
  border-radius: 4px;
  border: 1px solid #E2E8EF;
  padding ${({ size }) => sizeToPadding[size]};
  overflow: hidden;
`;

type CompanyLogoProps = {
  companyId: string;
  size?: 'xxs' | 'xs' | 'sm' | 'md' ;
  backgroundColor?: string;
  mr?: number;
};

export const CompanyLogo = ({ companyId, size = 'sm', backgroundColor, mr = 2 } : CompanyLogoProps) => {
  const { API_URL } = useEnv();

  return companyId ? (
    <Container mr={mr} size={size} bg={backgroundColor}>
      <Image src={`${API_URL}/download/logo/${companyId}`} />
    </Container>
  ) : (
    null
  );
};

type CompanyLogoLayoutProps = Omit<CompanyLogoProps, 'companyId' | 'mr'> & {
  company: Company | CompanyMinimized;
  address?: {
    country?: string;
  };
  sx?: React.CSSProperties;
  textStyle?: React.CSSProperties;
};

const CompanyLogoLayout = ({
  company,
  address,
  sx = {},
  textStyle = {},
  ...props
}: CompanyLogoLayoutProps) => {
  const locale = useCurrentUserLocale();

  return (
    <Flex sx={{ columnGap: '4px', ...sx }} alignItems="center">
      <CompanyLogo companyId={company._id} mr={0} {...props} />
      <Stack gap={0} flex={1}>
        <Truncate sx={textStyle}>{company.name}</Truncate>
        {address?.country && (
          <Text fontSize={1} color="gray">
            {getRegionName(address?.country, locale)}
          </Text>
        )}
      </Stack>
    </Flex>
  );
};

export const CompanyLogoWithName = ({ company, ...props } : CompanyLogoLayoutProps) => {
  return (
    <CompanyLogoLayout company={company} {...props} />
  );
};

export const CompanyLogoWithCountry = ({ company, address, ...props } : CompanyLogoLayoutProps) => {
  return (
    <CompanyLogoLayout
      company={company}
      address={address}
      {...props}
    />
  );
};
