import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, MenuItemProps, MenuPopover, MenuItems, MenuButtonProps } from '@reach/menu-button';
import { useDropdownContext, useDropdownTrigger } from '@reach/dropdown';
import { PRect } from '@reach/rect';
import { isNumber, noop } from 'lodash';
import { lighten, transparentize } from 'polished';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import { Placement } from '@floating-ui/react';
import { KeyCode } from '@deepstream/ui-utils/KeyCode';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import { Icon, IconProps } from '../icon/Icon';
import { Tooltip } from '../popup/Tooltip';
import { Button, ButtonProps } from '../button/Button';

export const DropdownMenuHeader = styled(Text)`
  padding: 8px 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${props => props.theme.colors.subtext};
  font-size: ${props => props.theme.fontSizes[1]}px;
`;

export const DropdownMenuDivider = styled(Box)`
  width: 100%;
  height: 1px;
  margin: ${props => props.theme.space[1]}px 0px;
  background-color: ${props => props.theme.colors.lightGray2};
`;

type DropdownMenuItemProps = {
  icon?: IconProps['icon'];
  iconColor?: string;
  isIconRegular?: boolean;
  color?: string;
  onSelect: MenuItemProps['onSelect'];
  disabled?: boolean;
  tooltip?: React.ReactNode;
  tooltipPlacement?: Placement;
  style?: any;
};

export const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
  icon,
  iconColor,
  isIconRegular = false,
  color,
  onSelect,
  disabled,
  tooltip = '',
  tooltipPlacement = 'right',
  style = {},
  children,
  ...props
}) => (
  <MenuItem
    onSelect={disabled ? noop : onSelect}
    style={{ outline: 'none', ...style }}
    className={disabled ? 'disabled' : ''}
    {...props}
  >
    <Tooltip content={tooltip} placement={tooltipPlacement}>
      <Box color={color} sx={{ opacity: disabled ? 0.5 : undefined }}>
        {icon && (
          <Icon icon={icon} color={iconColor} mr={2} aria-hidden="true" fixedWidth regular={isIconRegular} />
        )}
        {children}
      </Box>
    </Tooltip>
  </MenuItem>
);

type ExtendedMenuButtonProps =
  & React.ButtonHTMLAttributes<HTMLButtonElement>
  & MenuButtonProps
  & {
    onExpandedStateChange?: (isExpanded: boolean) => void;
  };

/**
 * A variant of the `MenuButton` component from `@reach/menu-button` that calls
 * the optional `onExpandedStateChange` handler whenever `isExpanded` changes.
 */
const ExtendedMenuButton = React.forwardRef<any, ExtendedMenuButtonProps>(
  ({ onExpandedStateChange, ...rest }, forwardedRef) => {
    const {
      data: { isExpanded, controls },
      props,
    } = useDropdownTrigger({ ...rest, ref: forwardedRef });

    React.useEffect(() => {
      if (onExpandedStateChange) {
        onExpandedStateChange(isExpanded);
      }
    }, [isExpanded, onExpandedStateChange]);

    return (
      // eslint-disable-next-line react/button-has-type
      <button
        aria-expanded={isExpanded ? true : undefined}
        aria-haspopup
        aria-controls={controls}
        {...props}
        data-reach-menu-button=""
      />
    );
  },
);

const MenuButtonWrapper = styled(ExtendedMenuButton)`
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;

  &:disabled {
    color: ${props => lighten(0.25, props.theme.colors.primary)};
    cursor: default;
  }
`;

const MenuHeader = styled(Box)`
  flex: 0 0 auto;
`;

const MenuFooter = styled(Box)`
  flex: 0 0 auto;
`;

export const menuWrapperCss = css`
  flex-direction: column;
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.white};
  max-height: 404px;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  margin-top: 2px;
  overflow: hidden;
  border: ${props => props.theme.borders.lightGray};
`;

export const menuItemCss = css`
  > [data-reach-menu-item] {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    word-break: break-word;
  }

  > [data-reach-menu-item][data-selected] {
    color: ${props => props.theme.colors.text};
    background-color: ${props => transparentize(0.90, props.theme.colors.primary)};

    &.disabled {
      cursor: default;
      background-color: transparent;
    }
  }
`;

export const MenuWrapper = styled(Flex)`
  ${menuWrapperCss}
`;

export const DropdownMenuList = styled(MenuItems)`
  overflow-y: auto;
  outline: none !important;
  padding-top: 8px;
  padding-bottom: 8px;

  ${menuItemCss}
`;

const calculateRightAlignedPosition = (targetRect?: PRect | null): React.CSSProperties => {
  if (!targetRect) {
    return {};
  }

  return {
    position: 'fixed',
    right: document.body.clientWidth - targetRect.right,
    top: targetRect.bottom,
    zIndex: 201,
  };
};

const stopClickEventHandlers = {
  onMouseDown: stopEvent,
  onMouseUp: stopEvent,
  onClick: stopEvent,
};

const scrollIntoView = (element: Element, container: HTMLElement) => {
  const elementRect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const elementTop = elementRect.top;
  const elementBottom = elementRect.bottom;

  const containerTop = containerRect.top;
  const containerBottom = containerRect.bottom;

  if (elementTop < containerTop) {
    container.scrollTop -= containerTop - elementTop;
  } else if (elementBottom > containerBottom) {
    container.scrollTop += elementBottom - containerBottom;
  }
};

export type DropdownMenuProps =
  ButtonProps &
  {
    ariaLabel?: string;
    buttonText?: React.ReactNode;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    footerMenuItem?: React.ReactNode;
    menuStyle?: React.CSSProperties;
    menuListStyle?: React.CSSProperties;
    menuZIndex?: number;
    stopClickEvents?: boolean;
    wrapperStyle?: any;
    rightAligned?: boolean;
    popoverProps?: Omit<React.ComponentProps<typeof MenuPopover>, 'children'> & { 'data-test'?: string };
    position?: (targetRect?: PRect | null, popoverRect?: PRect | null) => React.CSSProperties;
    onExpandedStateChange?: (isExpanded: boolean) => void;
  };

const DropdownMenuContent: React.FC<DropdownMenuProps> = ({
  ariaLabel,
  buttonText,
  header,
  footer,
  footerMenuItem,
  children,
  wrapperStyle,
  menuStyle = {},
  menuListStyle = {},
  menuZIndex,
  position,
  stopClickEvents,
  rightAligned,
  popoverProps = {},
  onExpandedStateChange,
  ...props
}) => {
  const dropdownContext = useDropdownContext();

  const handleKeyDown = React.useCallback((event) => {
    let nextIndex;

    switch (event.code) {
      case KeyCode.ARROW_UP:
        nextIndex = dropdownContext.state.selectionIndex - 1;
        break;
      case KeyCode.ARROW_DOWN:
        nextIndex = dropdownContext.state.selectionIndex + 1;
        break;
      case KeyCode.PAGE_UP:
      case KeyCode.HOME:
        nextIndex = 0;
        break;
      case KeyCode.PAGE_DOWN:
      case KeyCode.END:
        nextIndex = dropdownContext.selectCallbacks.current
          ? dropdownContext.selectCallbacks.current.length - 1
          : null;
        break;
      default:
        break;
    }

    if (!isNumber(nextIndex)) return;

    const container = dropdownContext.dropdownRef.current;
    if (!container) return;

    const element = container.children[nextIndex];
    if (!element) return;

    scrollIntoView(element, container);
  }, [dropdownContext]);

  return (
    <>
      <MenuButtonWrapper
        disabled={props.disabled}
        style={wrapperStyle}
        aria-label={ariaLabel}
        onExpandedStateChange={onExpandedStateChange}
      >
        <Button as="div" display="flex" disabled={props.disabled} sx={{ alignItems: 'center' }} {...props}>
          {buttonText}
        </Button>
      </MenuButtonWrapper>

      <MenuPopover
        position={rightAligned ? calculateRightAlignedPosition : position}
        style={{ zIndex: menuZIndex }}
        onKeyDown={handleKeyDown}
        {...popoverProps}
      >
        <MenuWrapper style={menuStyle}>
          {header && <MenuHeader>{header}</MenuHeader>}
          <DropdownMenuList
            style={{
              paddingTop: header ? '0px' : '',
              paddingBottom: header ? '0px' : '',
              ...menuListStyle,
            }}
            {...(stopClickEvents ? stopClickEventHandlers : {})}
          >
            {children}
          </DropdownMenuList>
          {footerMenuItem && (
            <DropdownMenuList style={{ paddingTop: '0px', paddingBottom: '0px', overflow: 'visible' }}>
              <DropdownMenuDivider sx={{ margin: '0px !important' }} />
              {footerMenuItem}
            </DropdownMenuList>
          )}
          {footer && <MenuFooter>{footer}</MenuFooter>}
        </MenuWrapper>
      </MenuPopover>
    </>
  );
};

export const DropdownMenu: React.FC<DropdownMenuProps> = (props) => (
  <Menu>
    <DropdownMenuContent {...props} />
  </Menu>
);

export const EllipsisMenu = (props) => (
  <DropdownMenu
    variant="primary-outline"
    iconLeft="ellipsis-h"
    rightAligned
    wrapperStyle={{ height: 'fit-content' }}
    {...props}
  />
);

export const MoveUpMenuItem = (props) => {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem icon="arrow-up" {...props}>
      {t('general.moveUp')}
    </DropdownMenuItem>
  );
};

export const MoveDownMenuItem = (props) => {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem icon="arrow-down" {...props}>
      {t('general.moveDown')}
    </DropdownMenuItem>
  );
};

export const DeleteMenuItem = (props) => {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem
      icon="trash-o"
      color={!props.disabled ? 'danger' : undefined}
      {...props}
    >
      {t('general.delete')}
    </DropdownMenuItem>
  );
};

export const MakeObsoleteMenuItem = (props) => {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem icon="ban" {...props}>
      {t('general.makeObsolete')}
    </DropdownMenuItem>
  );
};

export const MakeNonObsoleteMenuItem = (props) => {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem icon="undo" {...props}>
      {t('general.makeNonObsolete')}
    </DropdownMenuItem>
  );
};

export const DiscardChangesMenuItem = (props) => {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem icon="times" {...props}>
      {t('general.discardChanges')}
    </DropdownMenuItem>
  );
};

export const BulkUploadMenuItem = (props) => {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem icon="upload" {...props}>
      {t('general.bulkUpload')}
    </DropdownMenuItem>
  );
};

export const DuplicateMenuItem = (props) => {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem icon="clone" {...props}>
      {t('general.duplicate')}
    </DropdownMenuItem>
  );
};

export const EditMenuItem = (props) => {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem icon="pencil" {...props}>
      {t('general.edit')}
    </DropdownMenuItem>
  );
};
