import * as React from 'react';
import { QueryFunctionContext } from 'react-query';
import { ApiClient } from './apiClient';
import { AdminApiClient } from './adminApiClient';
import { useAuth } from './auth/getAuthHook';

const ApiContext = React.createContext<ApiClient | null>(null);

interface ApiProviderProps {
  baseUrl: string;
  getAccessTokenSilently?: () => Promise<any>;
  children: React.ReactNode;
}

export const ApiProvider = ({
  baseUrl,
  getAccessTokenSilently: getAccessTokenSilentlyProp,
  ...props
}: ApiProviderProps) => {
  const { getAccessTokenSilently: getAccessTokenSilentlyAuth0 } = useAuth();

  const getAccessTokenSilently = getAccessTokenSilentlyProp || getAccessTokenSilentlyAuth0;

  if (!baseUrl) {
    throw new Error('`baseUrl` is not truthy');
  }

  const apiClient = React.useMemo(
    () => new ApiClient(baseUrl),
    [baseUrl],
  );

  React.useEffect(
    () => {
      apiClient.setTokenGenerator(getAccessTokenSilently);
    },
    [apiClient, getAccessTokenSilently, getAccessTokenSilentlyProp],
  );

  return (
    <ApiContext.Provider value={apiClient} {...props} />
  );
};

export const useApi = () => {
  const api = React.useContext(ApiContext);

  if (!api) {
    throw new Error('`api` is not truthy');
  }

  return api;
};

const AdminApiContext = React.createContext<AdminApiClient | null>(null);

export const AdminApiProvider: React.FC<{ baseUrl: string }> = ({ baseUrl, ...props }) => {
  const { getAccessTokenSilently } = useAuth();

  if (!baseUrl) {
    throw new Error('`baseUrl` is not truthy');
  }

  const apiClient = React.useMemo(
    () => new AdminApiClient(baseUrl),
    [baseUrl],
  );

  React.useEffect(
    () => {
      apiClient.setTokenGenerator(getAccessTokenSilently);
    },
    [apiClient, getAccessTokenSilently],
  );

  return (
    <AdminApiContext.Provider value={apiClient} {...props} />
  );
};

export const useAdminApi = () => {
  const adminApi = React.useContext(AdminApiContext);

  if (!adminApi) {
    // ToDo Revisit this
  }

  return adminApi as AdminApiClient; // fake
};

// Prepare an api function for use with react-query
export const wrap = <T extends (...args: any) => Promise<any>>
  (fn: T) => (context?: QueryFunctionContext): ReturnType<T> => (fn as any)(context?.queryKey[1]);
