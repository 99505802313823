import styled from 'styled-components';
import { ROW_HEIGHT, SMALL_ROW_HEIGHT } from './Table';

export const BasicTableStyles = styled.div<{
  hoverBackgroundColor?: string;
  fixedRowHeight?: boolean;
  hoverCursor?: string;
  bordered?: boolean;
  headerTextColor?: string;
  headerBackgroundColor?: string;
  customRowHeight?: string;
  cellPaddingX?: string;
}>`
  overflow: auto;

  .Table {
    width: 100%;
    border-spacing: 0;
    ${(props) =>
      props.bordered &&
        `border: ${props.theme.borders.lightGray2};
        border-radius: 4px;
        border-collapse: separate;`
    }
    table-layout: fixed;
    font-variant-numeric: tabular-nums;
    line-height: normal;

    tbody .Table__row:not(.unhoverable):hover {
      background-color: ${({ hoverBackgroundColor, theme }) => hoverBackgroundColor ? theme.colors[hoverBackgroundColor] : '#00000005'};
      cursor: ${({ hoverCursor }) => hoverCursor ?? 'pointer'};
    }

    .Table__row {
      th {
        white-space: nowrap;
        font-weight: 500;
        height: ${ROW_HEIGHT}px;
        color: ${({ headerTextColor }) => headerTextColor && `${headerTextColor}`};
        background-color: ${({ headerBackgroundColor }) => headerBackgroundColor && `${headerBackgroundColor}`};
      }

      td {
        padding-top: ${({ fixedRowHeight = true }) => !fixedRowHeight && '12px'};
        padding-bottom: ${({ fixedRowHeight = true }) => !fixedRowHeight && '12px'};
        height: ${({ customRowHeight, fixedRowHeight = true }) => {
          if (customRowHeight) {
            return `${customRowHeight}`;
          } else if (fixedRowHeight) {
            return `${ROW_HEIGHT}px`;
          } else {
            return '';
          }
        }};
      }

      th, td {
        margin: 0;
        padding-left: ${({ cellPaddingX }) => cellPaddingX ? `${cellPaddingX}` : '20px'};
        padding-right: ${({ cellPaddingX }) => cellPaddingX ? `${cellPaddingX}` : '20px'};
        text-align: left;
      }
    }

    ${(props) => props.bordered && `
      th:not(:last-child),
      td:not(:last-child) {
        border-right: ${props.theme.borders.lightGray2};
      }
    `}

    thead .Table__row,
    tbody .Table__row:not(:last-child),
    tbody .Table__row:last-child[data-paginated=true][data-page-end=false] {
      th, td {
        border-bottom: ${props => props.bordered ? props.theme.borders.lightGray2 : props.theme.borders.lightGray};
      }
    }
  }
`;

export const StaticTableStyles = styled.div<{
  fixedRowHeight?: boolean;
  px?: string;
  py?: string;
  headerColor?: string;
  smallHeader?: boolean;
  largeHeader?: boolean;
  stickyHeader?: boolean;
  selectedColor?: string;
}>`
  ${props => props.stickyHeader ? 'thead { position: sticky; top: 0; background-color: white; z-index: 1; }' : 'overflow: auto;'};

  .Table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
    font-variant-numeric: tabular-nums;
    line-height: normal;

    ${props => props.stickyHeader ? 'overflow-y: auto;' : ''}

    ${props => props.selectedColor ? `tr.Table__row.selected { background-color: ${props.theme.colors[props.selectedColor]}; }` : ''}

    .Table__row {
      th {
        white-space: nowrap;
        font-weight: 500;
        font-size: ${props => props.smallHeader ? props.theme.fontSizes[1] : props.theme.fontSizes[2]}px;
        height: ${props => props.smallHeader ? SMALL_ROW_HEIGHT : props.largeHeader ? 75 : ROW_HEIGHT}px;
        text-align: left;
        margin: 0;
        color: ${props => props.theme.colors[props.headerColor || 'lightNavy']};
      }

      td {
        ${({ fixedRowHeight = true, py = '12px' }) => fixedRowHeight ? `height: ${ROW_HEIGHT}px;` : `padding-top: ${py}; padding-bottom: ${py};`}
        text-align: left;
        margin: 0;
      }

      th:first-child, td:first-child {
        padding-left: ${props => props.px || '20px'};
      }

      th:last-child, td:last-child {
        padding-right: ${props => props.px || '20px'};
      }

      th + th, td + td {
        padding-left: ${props => props.px || '20px'};
      }
    }

    thead .Table__row,
    tbody .Table__row:not(:last-child),
    tbody .Table__row:last-child[data-paginated=true][data-page-end=false] {
      th, td {
        border-bottom: ${props => props.theme.borders.lightGray};
      }
    }
  }
`;

export const PagePermissionTableStyles = styled(StaticTableStyles as any)`
  .Table {
    .Table__row {
      th, td {
        padding: 0;
      }
      td {
        height: 40px;
      }
      th {
        background-color: ${props => props.theme.colors.disabledBackground};
        ${props => props.largeHeader ? 'vertical-align: top; padding-top: 10px;' : ''}
      }

      th + th, td + td {
        border-left: ${props => props.theme.borders.lightGray};
      }

      td + td {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`;

export const CompactTableStyles = styled.div<{
  hoverBackgroundColor?: string;
  fixedRowHeight?: boolean;
  hoverCursor?: string;
  px?: string;
  py?: string;
  headerTextColor?: string;
  headerBackgroundColor?: string;
  stickyHeader?: boolean;
  selectedColor?: string;
  bordered?: boolean;
}>`
  ${props => props.stickyHeader ? 'thead { position: sticky; top: 0; background-color: white; z-index: 1; }' : 'overflow: auto;'};

  .Table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
    font-variant-numeric: tabular-nums;
    line-height: normal;

    ${props => props.stickyHeader ? 'overflow-y: auto;' : ''}

    ${props => props.selectedColor ? `tr.Table__row.selected { background-color: ${props.theme.colors[props.selectedColor]}; }` : ''}

    .Table__row {
      th {
        white-space: nowrap;
        font-weight: 500;
        font-size: ${props => props.theme.fontSizes[1]}px;
        height: ${SMALL_ROW_HEIGHT}px;
        text-align: left;
        margin: 0;
        color: ${props => props.theme.colors[props.headerTextColor || 'lightNavy']};
        background-color: ${props => props.theme.colors[props.headerBackgroundColor || 'white']};
      }

      td {
        ${({ fixedRowHeight = true, py = '12px' }) => fixedRowHeight ? `height: ${SMALL_ROW_HEIGHT}px;` : `padding-top: ${py}; padding-bottom: ${py};`}
        text-align: left;
        margin: 0;
      }

      ${(props) => props.bordered && `
        th:not(:last-child),
        td:not(:last-child) {
          border-right: ${props.theme.borders.lightGray2};
        }
      `}

      th:first-child, td:first-child {
        padding-left: ${props => props.px || '20px'};
      }

      th:last-child, td:last-child {
        padding-right: ${props => props.px || '20px'};
      }

      th + th, td + td {
        padding-left: ${props => props.px || '20px'};
      }
    }

    tbody .Table__row:not(.unhoverable):hover {
      background-color: ${({ hoverBackgroundColor, theme }) => hoverBackgroundColor ? theme.colors[hoverBackgroundColor] : '#00000005'};
      cursor: ${({ hoverCursor }) => hoverCursor ?? 'pointer'};
    }

    thead .Table__row,
    tbody .Table__row:not(:last-child),
    tbody .Table__row:last-child[data-paginated=true][data-page-end=false] {
      th, td {
        border-bottom: ${props => props.theme.borders.lightGray2};
      }
    }
  }
`;

export const FormTableStyles = styled<any>('div')`
  overflow: auto;
  .Table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
    font-variant-numeric: tabular-nums;
    line-height: normal;

    .Table__row {
      th {
        margin: 0;
        text-align: left;
        white-space: nowrap;
        font-weight: 500;
        color: ${props => props.theme.colors.lightNavy};
        font-size: ${props => props.theme.fontSizes[1]}px;
        padding-bottom: ${props => props.noHeader ? 0 : '7px'};
      }

      td {
        margin: 0;
        text-align: left;
        vertical-align: top;
      }

      th + th, td + td {
        padding-left: 7px;
      }
    }

    .Table__row:not(:last-child) {
      td {
        padding-bottom: 7px;
      }
    }

    .Table__row:last-child {
      td {
        padding-bottom: ${props => props.noBottomPadding ? 0 : 7}px;
      }
    }
  }
`;

export const BulkImportTableStyle = styled(CompactTableStyles as any)`
  height: 320px;
  overflow: auto;

  .Table {
    table-layout: auto;
    border-collapse: separate;

    th, td {
      border: ${props => props.theme.borders.lightGray};
      white-space: nowrap;
    }

    // This is needed to have a sticky first column
    tr > td:first-child,
    tr > th:first-child {
      position: sticky;
      left: 0;
      background-color: white;
      z-index: 1;
      text-align: center;
      padding: ${props => props.px || '4px'}
    }
  }
`;

export const IntegrationTableStyle = styled(BasicTableStyles as any)`
  height: ${props => props.height};
  width: ${props => props.width};
  overflow: auto;
  ${props => props.stickyHeader ? 'thead { position: sticky; top: 0; background-color: white; z-index: 1; }' : 'overflow: auto;'};

  .Table {
    ${props => props.stickyHeader ? 'overflow-y: auto;' : ''}
  }
`;
