import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { AdminTableLink, AdminTableLinkProps } from './AdminTableLink';
import { requestRoute } from './AppRouting';

type RequestLinkProps =
  Omit<AdminTableLinkProps, 'to'> &
  { requestId: string };

export const RequestLink = ({ requestId, ...props }: RequestLinkProps) => (
  <AdminTableLink
    to={requestRoute.to}
    params={{ requestId }}
    onClick={stopPropagation}
    {...props}
  />
);
