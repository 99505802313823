import { useMemo, useState } from 'react';
import { compact, find, pick } from 'lodash';
import { IconValue, Sorting, SortingDirection } from '@deepstream/common';
import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useLocalStorageState } from './useLocalStorageState';

export type LabeledSorting = Sorting & {
  label: string;
};

export type LabeledSortCriteria = {
  accessor: string;
  label: string;
};

export type LabeledSortDirection = {
  direction: SortingDirection;
  icon: IconValue;
  label: string;
};

const getSortQueryParam = (selectedItems: LabeledSorting[]) =>
  selectedItems.map(selectedItem => pick(selectedItem, ['accessor', 'direction']));

const getSortQueryParam2 = (criteria: LabeledSortCriteria, direction: LabeledSortDirection) => ([{
  accessor: criteria?.accessor,
  direction: direction?.direction,
}]);

export const useSortDirectionItems = (): LabeledSortDirection[] => {
  const { t } = useTranslation('translation');

  return useMemo(() => [
    { label: t('requests.sorting.descending'), direction: SortingDirection.DESC, icon: 'arrow-up-wide-short' },
    { label: t('requests.sorting.ascending'), direction: SortingDirection.ASC, icon: 'arrow-down-short-wide' },
  ], [t]);
};

export const useLocalStorageSortProps2 = ({
  storageKey,
  criteriaItems,
  directionItems,
}: {
  storageKey: string;
  criteriaItems: LabeledSortCriteria[];
  directionItems: LabeledSortDirection[];
}) => {
  const [selectedSortCriteria, setSelectedSortCriteria] = useLocalStorageState<LabeledSortCriteria>({
    key: `${storageKey}-criteria`,
    defaultValue: () => criteriaItems[0],
    mapInitialValue: (initialValue) => find(criteriaItems, { accessor: initialValue?.accessor }) || criteriaItems[0],
  });

  const [selectedSortDirection, setSelectedSortDirection] = useLocalStorageState<LabeledSortDirection>({
    key: `${storageKey}-direction`,
    defaultValue: () => directionItems[0],
    mapInitialValue: (initialValue) => find(directionItems, { direction: initialValue?.direction }) || directionItems[0],
  });

  return {
    renderCriteriaItem: (item: LabeledSortCriteria | null) =>
      item ? item.label : '',
    renderDirectionItem: (item: LabeledSortDirection | null) =>
      item ? <IconText icon={item.icon} text={item.label} gap={1} /> : '',

    criteriaItems,
    directionItems,

    selectedSortCriteria,
    selectedSortDirection,

    onSortCriteriaChange: setSelectedSortCriteria,
    onSortDirectionChange: setSelectedSortDirection,

    getQueryParam: getSortQueryParam2,
  };
};

/**
 * Provides properties to control sort selection components.
 * The selection gets persisted in localStorage.
 */
export const useLocalStorageSortProps = ({
  storageKey,
  items,
}: {
  storageKey: string;
  items: LabeledSorting[];
}) => {
  const [selectedItems, setSelectedItems] = useLocalStorageState<LabeledSorting[]>({
    key: storageKey,
    defaultValue: () => items.slice(0, 1),
    mapInitialValue: (initialItems) => compact(
      initialItems.map(({ accessor, direction }) =>
        find(items, { accessor, direction }),
      ),
    ),
  });

  return {
    itemToString: (item: LabeledSorting | null) => item ? item.label : '',
    items,
    selectedItems,
    onChange: setSelectedItems,
    getQueryParam: getSortQueryParam,
  };
};

/**
 * Provides properties to control sort selection components.
 * The selection does not get persisted in localStorage.
 */
export const useSortProps = ({
  items,
}: {
  items: LabeledSorting[];
}) => {
  const [selectedItems, setSelectedItems] = useState(items.slice(0, 1));

  return {
    itemToString: (item: LabeledSorting | null) => item ? item.label : '',
    items,
    selectedItems,
    onChange: setSelectedItems,
    getQueryParam: getSortQueryParam,
  };
};

export const useSortProps2 = ({
  criteriaItems,
  directionItems,
}: {
  criteriaItems: LabeledSortCriteria[];
  directionItems: LabeledSortDirection[];
}) => {
  const [selectedSortCriteria, setSelectedSortCriteria] = useState<LabeledSortCriteria>(criteriaItems[0]);
  const [selectedSortDirection, setSelectedSortDirection] = useState<LabeledSortDirection>(directionItems[0]);

  return {
    renderCriteriaItem: (item: LabeledSortCriteria | null) =>
      item ? item.label : '',
    renderDirectionItem: (item: LabeledSortDirection | null) =>
      item ? <IconText icon={item.icon} text={item.label} gap={1} /> : '',

    criteriaItems,
    directionItems,

    selectedSortCriteria,
    selectedSortDirection,

    onSortCriteriaChange: setSelectedSortCriteria,
    onSortDirectionChange: setSelectedSortDirection,

    getQueryParam: getSortQueryParam2,
  };
};

export type SortProps = ReturnType<typeof useLocalStorageSortProps>;
export type SortProps2 = ReturnType<typeof useLocalStorageSortProps2> | ReturnType<typeof useSortProps2>;
