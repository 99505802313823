import { TeamUsers } from '@deepstream/ui/modules/Company/TeamManagement/TeamUsers';
import { useSession } from '@deepstream/ui/auth';
import { UserFlagsProvider } from '@deepstream/ui/UserFlagsContext';
import { Page } from './Page';
import * as title from './title';

export const TeamUsersPage = () => {
  const auth = useSession();

  return auth.user ? (
    <UserFlagsProvider>
      <Page>
        <title.Container>
          <title.IconText icon="users" size="large">
            Manage admin users
          </title.IconText>
        </title.Container>
        <TeamUsers currentUser={auth.user} />
      </Page>
    </UserFlagsProvider>
  ) : (
    null
  );
};
