import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import * as React from 'react';
import {
  Page,
  Section,
  ExchangeDefinition,
  Company,
  PageRole,
  SectionType,
  CollaboratorInviteStatus,
  AnyScope,
  Live,
  Draft,
  isHirePeriodExchangeDef,
  PageType,
  AuctionLineItemExchangeDefinition,
  isAuctionStage,
  ExchangeType,
  getAuctionLineItemExchangeDef,
  AuctionTermsExchangeDefinition,
  AuctionStatus,
  StageType,
  isLineItemExchangeDef,
  getPagePermissions,
  getEvaluationWeights,
  canRespond,
  RfxSection,
  RfxOtherSection,
  RfxAuctionLineItemsSection,
  isLinkedEvaluationPage,
  ScoringType,
  RfxVesselPricingSection,
  DocumentSection,
  BidIntentionStatus,
  getBidIntentionStatus,
  RfqStatus,
  RequestInteractivityStatus,
  CostAndSavingsByRecipientId,
  ExchangeRateDocument,
  BidStatus,
  getCurrentCompanyGroup,
  getBidOutcomeStatus,
} from '@deepstream/common/rfq-utils';
import { findIndex, find, keyBy, identity, values, flatMap, map, filter, mapValues, last, first, groupBy, pickBy, assign, isNil, compact, propertyOf, indexOf, some, every, isEqual } from 'lodash';
import { addMinutes, isFuture } from 'date-fns';
import { isCompanySuperUser } from '@deepstream/common/user-utils';
import { useInterval } from '@deepstream/ui-kit/hooks/useInterval';
import { BidProgress, emptyBidProgressWithPreviousStageResponses } from '@deepstream/common/bidProgress';
import { assignSums } from '@deepstream/utils';
import { isBiddingOnLot } from '@deepstream/common/rfq-utils/lot';
import {
  Auction,
  DashboardTemplate,
  ExchangeSnapshot,
  ExtendedSentRequestOverview,
  AuctionLot,
  RfxStructure,
  StructureStage,
  Team,
} from './types';
import { useCurrentUser, useCurrentUserLocale } from './useCurrentUser';
import { useCurrentCompanyId } from './currentCompanyId';
import { getNextItem, getPreviousItem } from './utils';
import { useRecipientId } from './useRfq';

type SummaryNavigationContextType = {
  navigateToSummary: () => void;
};

type CompanyTeamNavigationContextType = {
  navigateToCompanyTeam: () => void;
};

type PageNavigationContextType = {
  getPreviousVisiblePage: (pageId?: string | null) => Page | null;
  getNextVisiblePage: (pageId?: string | null) => Page | null;
};

/*
 * Contexts and hooks for easily accessing the request hierarchy (eg: page, section)
 */

const StructureContext = React.createContext<RfxStructure<AnyScope> | null>(null);
const TemplateContext = React.createContext<DashboardTemplate | null>(null);
const RecipientsContext = React.createContext<Company[] | null>(null);
const PageContext = React.createContext<Page | null>(null);
const SectionsContext = React.createContext<Section[] | RfxSection[] | null>(null);
const SectionContext = React.createContext<Section | RfxSection | null>(null);
const ExchangesContext = React.createContext<ExchangeSnapshot[] | null>(null);
const SummaryNavigationContext = React.createContext<SummaryNavigationContextType | null>(null);
const CompanyTeamNavigationContext = React.createContext<CompanyTeamNavigationContextType | null>(null);
const PageNavigationContext = React.createContext<PageNavigationContextType | null>(null);
const ExchangeRefetchContext = React.createContext<(() => Promise<any>) | null>(null);
const SaveChangesContext = React.createContext<((...args: any[]) => any) | null>(null);
const AuctionLotContext = React.createContext<AuctionLot | null>(null);
const StageIdContext = React.createContext<string | null>(null);
const RequirementGroupIdContext = React.createContext<string | null>(null);
const CostAndSavingsDataContext = React.createContext<{
  costAndSavingsByRecipientId: CostAndSavingsByRecipientId;
  exchangeRates: ExchangeRateDocument;
} | null>(null);

// TODO refine type
const EvaluationWeightsContext = React.createContext<any | null>(null);

export type SectionWithPosition<TSection> = TSection & {
  index: number;
  number: number;
  isFirst: boolean;
  isLast: boolean;
};

export const StructureProvider = React.memo<{ structure: RfxStructure<AnyScope>; children: React.ReactNode }>(({ structure, ...props }) => (
  <StructureContext.Provider value={structure} {...props} />
));

export const TemplateProvider = React.memo<{ template: DashboardTemplate; children: React.ReactNode }>(({ template, ...props }) => (
  <TemplateContext.Provider value={template} {...props} />
));

export const PageProvider = React.memo<{ page: Page; children: React.ReactNode }>(({ page, ...props }) => (
  <PageContext.Provider value={page} {...props} />
));

export const RecipientsProvider = React.memo<{ recipients: Company[]; children: React.ReactNode }>(({ recipients, ...props }) => (
  <RecipientsContext.Provider value={recipients} {...props} />
));

export const SectionsProvider = React.memo<{ sections: RfxSection[] | Section[]; children: React.ReactNode }>(({ sections, ...props }) => (
  <SectionsContext.Provider value={sections} {...props} />
));

export const SectionProvider = React.memo<{ section: RfxSection | Section | null; children: React.ReactNode }>(({ section, ...props }) => (
  <SectionContext.Provider value={section} {...props} />
));

export const StageIdProvider = React.memo<{ stageId: string; children: React.ReactNode }>(({ stageId, ...props }) => (
  <StageIdContext.Provider value={stageId} {...props} />
));

export const RequirementGroupIdProvider = React.memo<{ requirementGroupId: string; children: React.ReactNode }>(({
  requirementGroupId,
  ...props
}) => (
  <RequirementGroupIdContext.Provider value={requirementGroupId} {...props} />
));

export const CostAndSavingsDataProvider = React.memo<{
  data: {
    costAndSavingsByRecipientId: CostAndSavingsByRecipientId;
    exchangeRates: ExchangeRateDocument;
  };
  children: React.ReactNode;
}>(({ data, ...props }) => (
  <CostAndSavingsDataContext.Provider value={data} {...props} />
));

export const ExchangesProvider = React.memo<{
  exchanges: ExchangeSnapshot[] | null;
  children: React.ReactNode;
}>(({ exchanges, ...props }) => (
  <ExchangesContext.Provider value={exchanges} {...props} />
));

type ExchangeRefetchProviderProps = { refetch: () => Promise<any>; children: React.ReactNode };

export const ExchangeRefetchProvider = React.memo<ExchangeRefetchProviderProps>(({ refetch, ...props }) => (
  <ExchangeRefetchContext.Provider value={refetch} {...props} />
));

type SaveChangesProviderProps = { saveChanges: (...args: any[]) => any; children: React.ReactNode };

export const SaveChangesProvider = React.memo<SaveChangesProviderProps>(({ saveChanges, ...props }) => (
  <SaveChangesContext.Provider value={saveChanges} {...props} />
));

export const AuctionLotProvider = React.memo<{ lot: AuctionLot; children: React.ReactNode }>(({ lot, ...props }) => (
  <AuctionLotContext.Provider value={lot} {...props} />
));

export const useStructure = <Scope extends AnyScope = Live>() => {
  const structure = React.useContext<RfxStructure<Scope> | null>(StructureContext);
  if (!structure) throw new Error('No structure found');
  return structure;
};

export const useTemplate = () => {
  const template = React.useContext<DashboardTemplate>(TemplateContext);
  return template;
};

export const useAuction = () => {
  const structure = useStructure<Live>();

  return structure.auction;
};

export const useSummary = () => {
  const structure = useStructure<Live>();

  return structure.summary;
};

export const useIsAuctionEditable = () => {
  const auction = useAuction();

  return (!auction || auction.status === AuctionStatus.PENDING);
};

// Resets dirty forms if user is trying to edit the auction while it is starting
export const useFormResetOnAuctionStart = () => {
  const isAuctionEditable = useIsAuctionEditable();
  const { stopEditing } = useActions();
  const { dirty, resetForm } = useFormikContext();

  useEffect(() => {
    if (!isAuctionEditable && dirty) {
      stopEditing();
      resetForm();
    }
  }, [dirty, isAuctionEditable, resetForm, stopEditing]);
};

export const usePage = ({ required = true } = {}) => {
  const page = React.useContext<Page | null>(PageContext);
  if (!page && required) throw new Error('No page found');
  return page;
};

export const useRecipients = () => {
  const recipients = React.useContext(RecipientsContext);
  if (!recipients) throw new Error('No recipients found');
  return recipients;
};

export const useSections = () => {
  const sections = React.useContext(SectionsContext);
  if (!sections) throw new Error('No sections found');
  return sections;
};

export function useSection<TSection extends Section | RfxSection = Section | RfxSection>(): TSection {
  const section = React.useContext(SectionContext);
  if (!section) throw new Error('No section found');
  return section as TSection;
}

export const useSenders = () => {
  const { senders } = useStructure();

  return senders;
};

export const useRecipient = () => {
  const recipientId = useRecipientId();
  const structure = useStructure();
  const recipient = structure.recipients.find(recipient => recipient._id === recipientId);
  if (!recipient) throw new Error(`No recipient found with id ${recipientId}`);
  return recipient;
};

export const useBid = ({ required = true } = {}) => {
  const recipientId = useRecipientId({ required: false });
  if (required && !recipientId) throw new Error(`No bid found for id ${recipientId}`);

  const structure = useStructure();
  const bid = structure.bidById[recipientId];
  if (required && !bid) throw new Error(`No bid found for id ${recipientId}`);
  return bid;
};

export function useExchanges<TExchanges extends ExchangeSnapshot[] = ExchangeSnapshot[]>({ required = true } = {}): TExchanges {
  const exchanges = React.useContext(ExchangesContext);
  if (!exchanges && required) throw new Error('No exchanges found');
  return exchanges as TExchanges;
}

export const useSummaryNavigation = () => {
  const actions = React.useContext(SummaryNavigationContext);

  return actions;
};

export const useCompanyTeamNavigation = () => {
  const actions = React.useContext(CompanyTeamNavigationContext);

  return actions;
};

export const usePageNavigation = () => {
  const actions = React.useContext(PageNavigationContext);
  if (!actions) throw new Error('No page navigation actions found');
  return actions;
};

export const useExchangeRefetch = () => {
  const refetchExchange = React.useContext(ExchangeRefetchContext);

  return refetchExchange;
};

export const useSaveChanges = () => {
  const saveChanges = React.useContext(SaveChangesContext);
  if (!saveChanges) throw new Error('No SaveChangesContext found');
  return saveChanges;
};

export const useAuctionLot = () => {
  const lot = React.useContext(AuctionLotContext);
  if (!lot) throw new Error('No auction lot found');
  return lot;
};

/**
 * The ID of the current requirement group, which is either a lot
 * ID or 'general' (for general requirements).
 *
 * The underlying context is provided on the route level for
 * level-3 (request / my bid / stage) and level-4  (request / my bid
 * / stage / requirement) recipient bid pages.
 * On all other routes, this hook returns `undefined`.
 */
export const useStageId = () => {
  return React.useContext(StageIdContext);
};

/**
 * The ID of the current requirement group, which is either a lot
 * ID or 'general' (for general requirements).
 *
 * The underlying context is provided on the route level for
 * level-4 recipient bid pages (request / my bid / stage / requirement).
 * On all other routes, this hook returns `undefined`.
 */
export const useRequirementGroupId = () => {
  return React.useContext(RequirementGroupIdContext);
};

export const useCostAndSavingsData = () => {
  const costAndSavingsByRecipientId = React.useContext(CostAndSavingsDataContext);
  if (!costAndSavingsByRecipientId) throw new Error('No CostAndSavingsDataContext found');
  return costAndSavingsByRecipientId;
};

/**
 * Gets the current section with some additional data about its position
 */
export function useSectionWithPosition<TSection extends Section | RfxSection = Section | RfxSection>(): SectionWithPosition<TSection> {
  const page = usePage();
  const section = useSection<TSection>();

  return React.useMemo(
    () => {
      const sectionIndex = findIndex(
        page.sections,
        sectionId => sectionId === section._id,
      );

      return {
        index: sectionIndex,
        number: sectionIndex + 1,
        isFirst: sectionIndex === 0,
        isLast: sectionIndex === page.sections.length - 1,
        ...section,
      };
    },
    [page, section],
  );
}

export const usePageSections = () => {
  const { sectionById } = useStructure();
  const page = usePage();

  return React.useMemo(
    () => page.sections.map(sectionId => sectionById[sectionId]),
    [page.sections, sectionById],
  );
};

export const usePageExchangeDefs = () => {
  const { exchangeDefById } = useStructure();
  const sections = usePageSections();
  const exchangeDefIds = flatMap(sections, 'exchangeDefIds');

  return React.useMemo(
    () => map(exchangeDefIds, exchangeDefId => exchangeDefById[exchangeDefId]),
    [exchangeDefIds, exchangeDefById],
  );
};

export const getSectionExchangeDefs = (section: RfxSection, structure: RfxStructure<AnyScope>) =>
  section.exchangeDefIds.map(exchangeDefId => structure.exchangeDefById[exchangeDefId]);

export const useSectionExchangeDefs = () => {
  const structure = useStructure();
  const section = useSection<RfxSection>();

  return React.useMemo(
    () => getSectionExchangeDefs(section, structure),
    [section, structure],
  );
};

export const useOtherSectionLineItemFields = () => {
  const structure = useStructure();
  const section = useSection();

  return React.useMemo(() => {
    const sectionNamesByFieldId : Record<string, string[]> = {};
    const sectionFields = Object.values(structure.sectionById)
      .filter(({ _id, type }) => type === SectionType.LINE_ITEMS && _id !== section._id)
      .flatMap(section => {
        const sectionExchangeDefs = getSectionExchangeDefs(section, structure);
        const firstLineItemExchangeDef = sectionExchangeDefs.find(isLineItemExchangeDef);
        const fields = firstLineItemExchangeDef ? [firstLineItemExchangeDef.fields] : [];

        for (const field of fields) {
          Object.keys(field).forEach(key => {
            const fieldId = key;
            if (sectionNamesByFieldId[fieldId]) {
              sectionNamesByFieldId[fieldId].push(section.name);
            } else {
              sectionNamesByFieldId[fieldId] = [section.name];
            }
          });
        }

        return fields;
      });

    const sectionFieldsWithNames = sectionFields.map((field) => {
      return assign(
        {},
        ...Object.entries(field).map(([key, value]) => {
          return {
            [key]: {
              ...value,
              sectionNames: sectionNamesByFieldId[key],
            },
          };
        }),
      );
    });

    return assign({}, ...sectionFieldsWithNames);
  }, [structure, section._id]);
};

export const useIntervalsByHirePeriodId = () => {
  const { hirePeriodById } = useStructure();
  const exchangeDefs = useSectionExchangeDefs();

  return React.useMemo(
    () => {
      const hirePeriodExchangeDefs = filter(exchangeDefs, isHirePeriodExchangeDef);

      return {
        ...mapValues(hirePeriodById, () => ([])),
        ...groupBy(hirePeriodExchangeDefs, 'hirePeriodId'),
      };
    },
    [exchangeDefs, hirePeriodById],
  );
};

export const useStages = () => {
  const { stages } = useStructure();

  return stages;
};

/**
 * Gets the section's exchange defs which were added by the `group`. An optional
 * `filter` function can be used to further refine the returned exchange defs.
 */
export const useSectionExchangeDefsByCreator = <T extends ExchangeDefinition> ({
  filter = identity,
  group,
}: {
  filter?: (def: ExchangeDefinition) => boolean;
  group: 'sender' | 'recipient';
}) => {
  const { senders, recipients, exchangeDefById } = useStructure();
  const section = useSection<RfxOtherSection>();
  const companies = group === 'sender' ? senders : recipients;

  const exchangeDefs = React.useMemo(
    () => {
      const companiesById = keyBy(companies, '_id');

      return section
        .exchangeDefIds
        .map(exchangeDefId => exchangeDefById[exchangeDefId] as T)
        .filter(exchangeDef => companiesById[(exchangeDef as any).creatorId])
        .filter(filter);
    },
    [companies, exchangeDefById, filter, section.exchangeDefIds],
  );

  return exchangeDefs;
};

export type UserOverrides = Partial<{ isOwner: boolean; pageRole: PageRole }>;

const UserOverridesContext = React.createContext<UserOverrides>({});

export const useUserOverrides = () => React.useContext(UserOverridesContext);

export const OverridesProvider = React.memo<UserOverrides & { children: React.ReactNode }>(({
  children,
  ...permissions
}) => {
  const overrides = React.useMemo(() => permissions, values(permissions)); // eslint-disable-line

  return (
    <UserOverridesContext.Provider value={overrides}>
      {children}
    </UserOverridesContext.Provider>
  );
});

export const useTeam = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { teamById } = useStructure();

  return React.useMemo(
    () => teamById[currentCompanyId],
    [teamById, currentCompanyId],
  );
};

export const useRfxPermissions = () => {
  const overrides = useUserOverrides();
  const currentUser = useCurrentUser();
  const team = useTeam();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);

  return React.useMemo(
    () => {
      const isSuperUserOrOwner = (
        isSuperUser ||
        overrides.isOwner ||
        (currentUser && team && team.owners.includes(currentUser._id))
      );

      return {
        canRejectBids: isSuperUserOrOwner,
        canReinstateBids: isSuperUserOrOwner,
        canMoveSuppliers: isSuperUserOrOwner,
        canAwardOrCloseRfx: isSuperUserOrOwner,
        canDeclineOnBehalf: isSuperUserOrOwner,
        canDownloadReports: isSuperUserOrOwner,
        canEditApprovals: isSuperUserOrOwner,
        canViewApprovals: isSuperUserOrOwner,
        canEditPages: isSuperUserOrOwner,
        canEditTeam: isSuperUserOrOwner,
        canEditSummary: true,
        canAddSuppliers: true,
        canManageEvaluation: isSuperUserOrOwner,
        canManageRequestVisibility: isSuperUserOrOwner,
        canMoveSuppliersToStage: isSuperUserOrOwner,
      };
    },
    [currentUser, overrides.isOwner, team, isSuperUser],
  );
};

export const usePagesPermissions = () => {
  const overrides = useUserOverrides();
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { pageById } = useStructure();
  const team = useTeam();
  const user = team?.users[currentUser?._id];
  const scoringType = useEvaluationScoringType();

  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);

  return React.useMemo(
    () => mapValues(
      pageById,
      page => {
        if (overrides.pageRole) {
          return getPagePermissions(overrides.pageRole);
        }

        if (isSuperUser) {
          if (
            isLinkedEvaluationPage(page) &&
            scoringType === ScoringType.INDIVIDUAL_SCORES
          ) {
            if (user) {
              // super user that's a team member: allow submitting scores if roles allow it
              return getPagePermissions(user.rfqRoles![page._id]);
            } else {
              // super user that's not a team member: don't allow submitting scores
              return getPagePermissions(PageRole.COMMENTER);
            }
          }

          // on any other page, super users can act as editors
          return getPagePermissions(PageRole.EDITOR);
        }

        return user ? getPagePermissions(user.rfqRoles![page._id]) : {
          canEdit: false,
          canRead: false,
          canRespond: false,
          canComment: false,
          readOnly: false,
        };
      },
    ),
    [pageById, overrides.pageRole, user, isSuperUser, scoringType],
  );
};

export const usePagePermissions = ({ required = true } = {}) => {
  const overrides = useUserOverrides();
  const currentUser = useCurrentUser();
  const team = useTeam();
  const page = usePage({ required });
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isSuperUser = currentUser
    ? isCompanySuperUser(currentUser, currentCompanyId)
    : false;
  const scoringType = useEvaluationScoringType();

  return React.useMemo(
    () => {
      if (!page) {
        return null;
      }

      if (overrides.pageRole) {
        return getPagePermissions(overrides.pageRole);
      }

      const user = team.users[currentUser?._id];

      if (isSuperUser) {
        if (
          isLinkedEvaluationPage(page) &&
          scoringType === ScoringType.INDIVIDUAL_SCORES
        ) {
          if (user) {
            // super user that's a team member: allow submitting scores if roles allow it
            // if already an evaluator
            return getPagePermissions(user.rfqRoles![page._id]);
          } else {
            // super user that's not a team member: don't allow submitting scores
            return getPagePermissions(PageRole.COMMENTER);
          }
        }

        // on any other page, super users can act as editors
        return getPagePermissions(PageRole.EDITOR);
      }

      return getPagePermissions(user?.rfqRoles[page._id] || PageRole.NONE);
    },
    [team.users, currentUser?._id, isSuperUser, overrides.pageRole, page, scoringType],
  );
};

export const useHiddenPageIds = () => {
  const structure = useStructure();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);

  return isSuperUser
    ? []
    : Object.keys(
      pickBy(
        structure.teamById[currentCompanyId]?.users[currentUser._id]?.rfqRoles,
        value => value === PageRole.NONE,
      ),
    );
};

export const useIsSender = () => {
  const currentCompanyId = useCurrentCompanyId();
  const senders = useSenders();

  return Boolean(find(senders, { _id: currentCompanyId }));
};

export const useIsPendingCollaborator = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const senders = useSenders();
  const sender = find(senders, { _id: currentCompanyId });

  return sender?.inviteStatus === CollaboratorInviteStatus.PENDING;
};

export const useVisiblePages = () => {
  const { pages } = useStructure();
  const pageRoles = usePagesPermissions();
  const overrides = useUserOverrides();

  return React.useMemo(() => {
    return overrides.pageRole && overrides.pageRole !== PageRole.NONE
    ? pages
    : pages.filter(page => pageRoles[page._id].canRead);
  }, [pages, pageRoles, overrides]);
};

export const SummaryNavigationProvider = ({
  navigateToSummary,
  children,
}: {
  navigateToSummary: () => void;
  children: React.ReactNode;
}) => {
  const actions = React.useMemo(() => ({
    navigateToSummary,
  }), [navigateToSummary]);

  return (
    <SummaryNavigationContext.Provider value={actions}>
      {children}
    </SummaryNavigationContext.Provider>
  );
};

export const CompanyTeamNavigationProvider = ({
  navigateToCompanyTeam,
  children,
}: {
  navigateToCompanyTeam: () => void;
  children: React.ReactNode;
}) => {
  const actions = React.useMemo(() => ({
    navigateToCompanyTeam,
  }), [navigateToCompanyTeam]);

  return (
    <CompanyTeamNavigationContext.Provider value={actions}>
      {children}
    </CompanyTeamNavigationContext.Provider>
  );
};

export const isStageActive = (
  stage: Pick<StructureStage<AnyScope>, 'type' | 'completionDeadline'>,
  auction: Pick<Auction, 'status' | 'endDate'>,
) => {
  return stage.type === StageType.AUCTION
      // In the case of a cancelled auction, the following stage should
      // immediately become the active stage, without waiting for the
      // end of the auction.
      ? auction.status !== AuctionStatus.CANCELLED &&
        (auction.status === AuctionStatus.PAUSED ||
          isFuture(new Date(auction.endDate)))
    : Boolean(stage.completionDeadline) &&
        isFuture(new Date(stage.completionDeadline));
};

export const useIsAnyEnteredStageActive = (ignoreAuctionStage?: boolean) => {
  const { auction, stageById } = useStructure<Live>();
  const { enteredStageIds } = useBid();

  const [isAnyEnteredStageActive, setIsAnyEnteredStageActive] = React.useState<boolean>(
    ignoreAuctionStage
      ? enteredStageIds.map(propertyOf(stageById)).some(stage => !isAuctionStage(stage) && isStageActive(stage, auction))
      : enteredStageIds.map(propertyOf(stageById)).some(stage => isStageActive(stage, auction)),
  );

  const checkActiveState = React.useCallback(() => {
    const newActiveState = ignoreAuctionStage
      ? enteredStageIds.map(propertyOf(stageById)).some(stage => !isAuctionStage(stage) && isStageActive(stage, auction))
      : enteredStageIds.map(propertyOf(stageById)).some(stage => isStageActive(stage, auction));

    setIsAnyEnteredStageActive(newActiveState);
  }, [auction, enteredStageIds, stageById, setIsAnyEnteredStageActive, ignoreAuctionStage]);

  useInterval(checkActiveState, 250);

  return isAnyEnteredStageActive;
};

export const useIsEnteredStageActive = (stageId: string) => {
  const { auction, stageById } = useStructure<Live>();

  const [isEnteredStageActive, setIsEnteredStageActive] = React.useState<boolean>(
    isStageActive(stageById[stageId], auction),
  );

  const checkActiveState = React.useCallback(() => {
    const newActiveState = isStageActive(stageById[stageId], auction);

    setIsEnteredStageActive(newActiveState);
  }, [stageById, stageId, auction]);

  useInterval(checkActiveState, 250);

  return isEnteredStageActive;
};

export const getActiveStageId = (request: ExtendedSentRequestOverview) => {
  const { stageById, auction } = request;
  const activeStage = find(stageById, (stage) => request.sentDashboard.isLive && isStageActive(stage, auction));

  return activeStage
    ? {
        ...activeStage,
        index: indexOf(Object.keys(stageById), activeStage?._id) + 1,
      }
    : null;
};

/**
 * Returns the ID of the first stage that has a `completionDeadline` in the
 * future.
 * Returns null if no such stage exists.
 */
export const useActiveStageId = () => {
  const { stages, auction } = useStructure();

  const activeStage = find(stages, (stage) => isStageActive(stage, auction));

  return activeStage?._id ?? null;
};

export const useStageDeadline = (stageId: string): Date | null => {
  const { stageById, auction } = useStructure();

  const stage = stageById[stageId];
  const stageDeadline = !stage
    ? null
    : stage.type === StageType.AUCTION
      ? auction.endDate
      : stage.completionDeadline;

  // Memoizing to ensure stable reference. Triggers infinite loops in places otherwise.
  return React.useMemo(
    () => stageDeadline ? new Date(stageDeadline) : null,
    [stageDeadline],
  );
};

export const useAuctionPauseDate = (stageId?: string): Date | null => {
  const { stageById, auction } = useStructure();

  const stage = stageById[stageId];

  return React.useMemo(() => {
    if (stage?.type !== StageType.AUCTION || auction?.status !== AuctionStatus.PAUSED) {
      return null;
    }

    const auctionPauseDate = auction.pauseDate;

    return auctionPauseDate ? new Date(auctionPauseDate) : null;
  }, [stage, auction]);
};

export const PageNavigationProvider = ({
  children,
  predicate = () => true,
}: {
  children: React.ReactNode;
  predicate?: (page: Page) => boolean;
}) => {
  const { pages } = useStructure();
  const pageRoles = usePagesPermissions();

  const actions = React.useMemo(
    () => {
      const walkVisiblePages = (
        startingPageId: string | null,
        walker: (pageId: string | null) => Page | null,
      ) => {
        let page = walker(startingPageId);

        while (page) {
          if (
            !page.isHiddenWhileEditing &&
            page.type !== PageType.AUCTION &&
            predicate(page) &&
            pageRoles[page._id].canRead
          ) {
            break;
          }

          page = walker(page._id);
        }

        return page;
      };

      // Passing a falsy `pageId` will return the last visible page
      const getPreviousVisiblePage = (pageId: string | null = null) => walkVisiblePages(
        pageId,
        _id => _id
          ? getPreviousItem<Page>(pages, { _id })
          : last(pages) ?? null,
      );

      // Passing a falsy `pageId` will return the first visible page
      const getNextVisiblePage = (pageId: string | null = null) => walkVisiblePages(
        pageId,
        _id => _id
          ? getNextItem<Page>(pages, { _id })
          : first(pages) ?? null,
      );

      return {
        getPreviousVisiblePage,
        getNextVisiblePage,
      };
    },
    [pageRoles, pages, predicate],
  );

  return (
    <PageNavigationContext.Provider value={actions}>
      {children}
    </PageNavigationContext.Provider>
  );
};

/*
 * Contexts and hooks for managing a request's state.
 */

type StateContextType = {
  isLive: boolean;
  isDraft: boolean;
  isTemplate: boolean;
  isTemplatePreview: boolean;
  isRevising: boolean;
  isReview: boolean;
  isEditingSupplierExchangeDefs: boolean;
  isPublicRequestPreview: boolean;
  editingPanelId: string | null;
};

const StateContext = React.createContext<StateContextType | null>(null);

type ActionsContextType = {
  startEditing: (sectionId: string) => void;
  stopEditing: () => void;
};

const ActionsContext = React.createContext<ActionsContextType | null>(null);

export const StateProvider = ({
  isLive = false,
  isTemplate = false,
  isTemplatePreview = false,
  isRevising = false,
  isReview = false,
  isEditingSupplierExchangeDefs = false,
  isPublicRequestPreview = false,
  children,
}: {
  isLive?: boolean;
  isTemplate?: boolean;
  isTemplatePreview?: boolean;
  isRevising?: boolean;
  isReview?: boolean;
  isEditingSupplierExchangeDefs?: boolean;
  isPublicRequestPreview?: boolean;
  children: React.ReactNode;
}) => {
  const [editingPanelId, setEditingPanelId] = React.useState<string | null>(null);

  const state = React.useMemo(
    () => ({
      isLive,
      isDraft: !isLive,
      isTemplate,
      isTemplatePreview,
      isRevising,
      isReview,
      isEditingSupplierExchangeDefs,
      isPublicRequestPreview,
      editingPanelId,
    }),
    [
      isLive,
      isTemplate,
      isTemplatePreview,
      isRevising,
      isReview,
      isPublicRequestPreview,
      isEditingSupplierExchangeDefs,
      editingPanelId,
    ],
  );

  const actions = React.useMemo(
    () => ({
      startEditing: (sectionId: string) => {
        setEditingPanelId(sectionId);
      },
      stopEditing: () => {
        setEditingPanelId(null);
      },
    }),
    [],
  );

  return (
    <StateContext.Provider value={state}>
      <ActionsContext.Provider value={actions}>
        {children}
      </ActionsContext.Provider>
    </StateContext.Provider>
  );
};

/**
 * Define all possible variations so that we get static AND runtime guarantees
 */
export function useState(a: { required: true }): StateContextType;
export function useState(): StateContextType;
export function useState(a: { required: false }): StateContextType | null;
export function useState({ required = true } = {}) {
  const state = React.useContext(StateContext);

  if (!state) {
    if (required) {
      throw new Error('No state found');
    } else {
      return state;
    }
  }

  const { isLive, isDraft, isReview, isRevising, isTemplate, isTemplatePreview, editingPanelId } = state;

  if (isLive && (
    isDraft || isReview || isRevising || isTemplate || isTemplatePreview ||
    // The only panels that are editable while the request is live are the
    // suppliers panel (ie: when adding suppliers to a live request) and the
    // panels on the spend and savings page
    (editingPanelId && !['suppliers', 'budget', 'finalValue', 'totalSavings'].includes(editingPanelId))
  )) {
    throw new Error('Invalid state for a live request');
  }

  return state;
}

export const useActions = () => {
  const actions = React.useContext(ActionsContext);

  if (!actions) throw new Error('No actions found');

  return actions;
};

export const getAuctionLineItemsSection = (
  sectionById: RfxStructure<Draft>['sectionById'],
  stageId: string,
) => find(
  sectionById,
  section => (
    section.type === SectionType.AUCTION_LINE_ITEMS &&
    first(section.stages) === stageId
  ),
) as RfxAuctionLineItemsSection<Draft> | undefined;

export const useAuctionLineItemExchangeDef = (exchangeDef: AuctionLineItemExchangeDefinition) => {
  const { exchangeDefById } = useStructure();

  return getAuctionLineItemExchangeDef(exchangeDef, exchangeDefById);
};

// TODO Use real start date instead of the estimated one set on the stage
export const useAuctionDeadline = () => {
  const { stages } = useStructure();
  const section = useSection<RfxAuctionLineItemsSection>();

  const auctionStage = find(stages, isAuctionStage);

  return addMinutes(new Date(auctionStage.startDate), section.auctionRules.duration);
};

export const useAuctionTermsExchangeDef = (): AuctionTermsExchangeDefinition | null => {
  const structure = useStructure();
  const section = find(structure.sectionById, section => section.type === SectionType.AUCTION_TERMS);

  if (!section) {
    return null;
  }

  const exchangeDefs = getSectionExchangeDefs(section, structure);
  const exchangeDefsByType: any = groupBy(exchangeDefs, exchangeDef => exchangeDef.type);
  const auctionTermsExchangeDef = exchangeDefsByType[ExchangeType.AUCTION_TERMS][0];

  return auctionTermsExchangeDef;
};

export const useExchangeDefById = () => {
  const { exchangeDefById } = useStructure();

  return exchangeDefById;
};

export const useIsSuperUserOrOwner = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);
  const { teamById } = useStructure();

  return isSuperUser || teamById[currentCompanyId].owners.includes(currentUser._id);
};

export const EvaluationWeightsProvider: React.FC = (props) => {
  const structure = useStructure();
  const value = React.useMemo(() => getEvaluationWeights(structure), [structure]);

  return (
    <EvaluationWeightsContext.Provider value={value} {...props} />
  );
};

export const useEvaluationWeights = () => {
  const value = React.useContext(EvaluationWeightsContext);

  if (!value) throw new Error('No weighted score context found');

  return value;
};

export const useEvaluationScoringType = () => {
  const { settings: { scoringType } } = useStructure();

  return scoringType;
};

export const getPageEvaluators = (pageId: string, teamById: Record<string, Team>) => {
  return Object.entries(teamById).flatMap(([companyId, team]) => {
    return compact(
      Object.entries(team.users).map(([, user]) => {
        return canRespond(user.rfqRoles?.[pageId])
          ? { companyId, user }
          : null;
      }),
    );
  });
};

export const usePageEvaluators = () => {
  const { teamById } = useStructure();
  const page = usePage();

  return React.useMemo(() => {
    return getPageEvaluators(page._id, teamById);
  }, [page, teamById]);
};

export const useHirePeriods = () => {
  const { hirePeriodById, sectionById } = useStructure();

  return React.useMemo(() => {
    const vesselPricingSection = find(sectionById, { type: SectionType.VESSEL_PRICING }) as RfxVesselPricingSection;

    return (vesselPricingSection?.hirePeriodIds || []).map(propertyOf(hirePeriodById));
  }, [hirePeriodById, sectionById]);
};

export const useBidProgress = () => {
  const bid = useBid();
  const stageId = useStageId();
  const requirementGroupId = useRequirementGroupId();

  return React.useMemo(() => {
    return stageId
      ? assignSums([
        { ...emptyBidProgressWithPreviousStageResponses },
        ...values(bid.progressByPageIdByRequirementGroupIdByStageId[stageId]?.[requirementGroupId || 'general']),
      ]) as BidProgress
      : bid.progress;
  }, [bid, stageId, requirementGroupId]);
};

export const useHasLineItemsWithTotalCost = () => {
  const { exchangeDefById } = useStructure();

  return React.useMemo(() => {
    return some(
      exchangeDefById,
      exchangeDef => {
        if (exchangeDef.isObsolete) {
          return false;
        }

        if (exchangeDef.type === ExchangeType.AUCTION_LINE_ITEM) {
          return true;
        } else if (exchangeDef.type === ExchangeType.LINE_ITEM && exchangeDef.fields.totalCost) {
          return true;
        } else {
          return false;
        }
      },
    );
  }, [exchangeDefById]);
};

export const useStatus = () => {
  const { status } = useStructure<Live>();

  return status;
};

// For new document sections the stage visibility is set at the section level.
// For older ones we need to check if all document exchanges have consistent
// visibility. If they do, we can treat the stage visibility as if it was set
// at the section level. Otherwise we show the visibility at the exchange level.
export const useDocumentSectionVisibilitySettings = (exchangeDefs: ExchangeDefinition[]) => {
  const section = useSectionWithPosition<DocumentSection>();
  const stages = useStages();
  const isMultiStageRequest = stages.length > 1;

  const showSectionVisibility = React.useMemo(
    () => {
      if (section.stages?.length || !exchangeDefs.length) {
        return true;
      }

      return every(
        exchangeDefs,
        exchangeDef => isEqual(exchangeDef.stages, exchangeDefs[0].stages),
      );
    },
    [exchangeDefs, section],
  );

  const showExchangeDefVisibility = isMultiStageRequest && !showSectionVisibility;

  return {
    showSectionVisibility,
    showExchangeDefVisibility,
  };
};

export const useBidIntentionStatus = () => {
  const bid = useBid();

  return getBidIntentionStatus(bid);
};

export const useBidOutcomeStatus = () => {
  const bid = useBid();

  return getBidOutcomeStatus(bid);
};

export const useRequestInteractivityStatus = () => {
  const { extendedStatus } = useStructure<Live>();
  const isAnyEnteredStageActive = useIsAnyEnteredStageActive();

  if ([RfqStatus.AWARDED, RfqStatus.CLOSED].includes(extendedStatus)) {
    return RequestInteractivityStatus.ENDED;
  }

  return isAnyEnteredStageActive
    ? RequestInteractivityStatus.LIVE
    : RequestInteractivityStatus.PENDING;
};

export const useIsBidding = () => {
  const { lotById } = useStructure<Live>();
  const requirementGroupId = useRequirementGroupId();
  const bid = useBid();
  const bidIntentionStatus = getBidIntentionStatus(bid);
  const lot = lotById[requirementGroupId];

  return (
    bidIntentionStatus === BidIntentionStatus.BIDDING &&
    (
      !requirementGroupId ||
      requirementGroupId === 'general' ||
      isBiddingOnLot(lot, bid.intentionStatusByLotId[requirementGroupId])
    )
  );
};

export const useIsLotObsolete = () => {
  const { lotById } = useStructure<Live>();
  const requirementGroupId = useRequirementGroupId();

  return requirementGroupId && requirementGroupId !== 'general' && lotById[requirementGroupId]?.isObsolete;
};

export const useSortedBiddingRecipients = ({ excludeUnsuccessful }: { excludeUnsuccessful?: boolean } = {}) => {
  const { recipients, bidById } = useStructure<Live>();
  const locale = useCurrentUserLocale();

  return React.useMemo(() => {
    return recipients
      .filter(recipient => {
        const bid = bidById[recipient._id];

        if (excludeUnsuccessful && bid.status === BidStatus.UNSUCCESSFUL) {
          return false;
        }

        return getBidIntentionStatus(bid) === BidIntentionStatus.BIDDING;
      })
      .sort((a, b) => a.company.name.localeCompare(b.company.name, locale)) as Company[];
  }, [recipients, bidById, locale, excludeUnsuccessful]);
};

export const useSortedRecipients = (recipientIds: string[]) => {
  const { recipients } = useStructure<Live>();
  const locale = useCurrentUserLocale();

  return React.useMemo(() => {
    return recipients
      .filter(recipient => recipientIds.includes(recipient._id))
      .sort((a, b) => a.company.name.localeCompare(b.company.name, locale)) as Company[];
  }, [recipientIds, recipients, locale]);
};

export const useGetSortedRecipients = () => {
  const { recipients } = useStructure<Live>();
  const locale = useCurrentUserLocale();

  return React.useCallback((recipientIds: string[]) => {
    return recipients
      .filter(recipient => recipientIds.includes(recipient._id))
      .sort((a, b) => a.company.name.localeCompare(b.company.name, locale)) as Company[];
  }, [recipients, locale]);
};

export const useCurrentCompanyGroup = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const recipientId = useRecipientId({ required: false });

  const isRecipient = currentCompanyId === recipientId;

  return getCurrentCompanyGroup(isRecipient);
};

export const useCanReadPageOfType = (pageType: PageType) => {
  const { pages } = useStructure();
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const team = useTeam();
  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);

  const teamUser = team?.users[currentUser?._id];

  const chatPage = pages.find(page => page.type === pageType);

  return (
    chatPage &&
    (isSuperUser || getPagePermissions(teamUser.rfqRoles[chatPage._id]).canRead)
  );
};
