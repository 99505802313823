import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { AdminTableLink, AdminTableLinkProps } from './AdminTableLink';
import { templateRoute } from './AppRouting';

type TemplateLinkProps =
  Omit<AdminTableLinkProps, 'to' | 'params'> &
  { templateId: string };

export const TemplateLink = ({ templateId, ...props }: TemplateLinkProps) => (
  <AdminTableLink
    to={templateRoute.to}
    params={{ templateId }}
    onClick={stopPropagation}
    {...props}
  />
);
