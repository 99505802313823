import * as React from 'react';
import {
  useFloating,
  useHover,
  useInteractions,
  FloatingPortal,
  FloatingArrow,
  autoPlacement,
  offset,
  arrow,
  Placement,
  useClientPoint,
} from '@floating-ui/react';
import styled from 'styled-components';
import { compact } from 'lodash';
import { Box, BoxProps } from 'rebass/styled-components';
import { Clamp } from '../text/Clamp';

export const TooltipContent = styled.div`
  width: 250px;
  min-width: min-content;
  max-width: max-content;
  border-radius: 4px;
  padding: 11px 15px;
  line-height: 1.3;
  background-color: ${props => props.theme.colors.navy};
  color: ${props => props.theme.colors.white};
  transition: none;
  font-size: 14px;
`;

interface TooltipProps {
  content: React.ReactNode | string;
  placement?: Placement;
  children: React.ReactElement;
  followCursor?: boolean;
}

export const Tooltip = ({ content, placement, followCursor, children }: TooltipProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [childElt, setChildElement] = React.useState(null);
  const arrowRef = React.useRef(null);
  const child = React.Children.count(children) === 1 ? children : <>{children}</>;

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    middleware: [
      autoPlacement(),
      offset(10),
      arrow({ element: arrowRef }),
    ],
    elements: {
      reference: childElt,
    },
  });
  const hover = useHover(context, {
    delay: 250,
    mouseOnly: true,
  });
  const clientPoint = useClientPoint(context, {
    enabled: followCursor,
  });
  const { getFloatingProps } = useInteractions(compact([
    hover,
    followCursor && clientPoint,
  ]));

  return (
    <>
      {React.cloneElement(child, {
        ref: setChildElement,
      })}

      {isOpen && (
        <FloatingPortal>
          {content && (
            <TooltipContent ref={refs.setFloating} style={{ ...floatingStyles, zIndex: 99999 }} {...getFloatingProps()}>
              <Clamp lines={20}>{content}</Clamp>
              <FloatingArrow ref={arrowRef} context={context} />
            </TooltipContent>
          )}
        </FloatingPortal>
      )}
    </>
  );
};

export const TextTooltip = ({
  children,
  boxProps,
  ...props
}: Omit<TooltipProps, 'children'> & { children?: string; boxProps?: BoxProps }) => {
  return (
    <Tooltip {...props}>
      <Box as="span" {...boxProps}>
        {children}
      </Box>
    </Tooltip>
  );
};
