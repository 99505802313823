import * as React from 'react';
import { useQuery } from 'react-query';
import { useInView } from 'react-intersection-observer';
import { Panel, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { ErrorMessage } from '@deepstream/ui/ui/ErrorMessage';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Page } from './Page';
import { UserGeneralPropertyList } from './UserGeneralPropertyList';
import { UserProfilePropertyList } from './UserProfilePropertyList';
import { UserCompaniesTable } from './UserCompaniesTable';
import { UserRequestsTable } from './UserRequestsTable';
import { UserEmailsTable } from './UserEmailsTable';
import { UserAccessEventsTable } from './UserAccessEventsTable';
import * as Title from './title';
import { UserAuth0PropertyList } from './UserAuth0PropertyList';
import { AdminUserContractsTable } from './modules/Contracts/AdminUserContractsTable';
import { UserPreferencesList } from './UserPreferencesList';
import { userRoute } from './AppRouting';

export const PageTitle = ({
  flexDirection,
  user,
}: {
  flexDirection?: React.CSSProperties['flexDirection'];
  user: any;
}) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.User size={flexDirection === 'column' ? 'large' : 'small'} user={user} />
  </Title.Container>
);

export const AdminUserPage = () => {
  const adminApi = useAdminApi();
  const { userId } = userRoute.useParams();
  const [ref, inView, entry] = useInView();

  const { data: user, status: userStatus } = useQuery(
    ['user', { userId }],
    wrap(adminApi.getUser),
  );

  const { data: companies, status: companiesStatus } = useQuery(
    ['companies', { userId }],
    wrap(adminApi.getUserCompanies),
  );

  const { data: requests, status: requestsStatus } = useQuery(
    ['requests', { userId }],
    wrap(adminApi.getUserRequests),
  );

  const { data: contractOverviews, status: contractsStatus } = useQuery(
    ['userContracts', { userId }],
    wrap(adminApi.getUserContracts),
  );

  const { data: emails, status: emailsStatus } = useQuery(
    ['emails', { userId }],
    wrap(adminApi.getUserEmails),
  );

  const { data: accessEvents, status: accessEventsStatus } = useQuery(
    ['accessEvents', { userId }],
    wrap(adminApi.getUserAccessEvents),
  );

  return (
    <Page subHeading={user && !inView && entry && <PageTitle user={user} />}>
      {userStatus === 'loading' ? (
        <Panel>
          <PanelPadding><Loading /></PanelPadding>
        </Panel>
      ) : userStatus === 'error' ? (
        <Panel heading="Error">
          <PanelPadding>
            <ErrorMessage error="Oh no" />
          </PanelPadding>
        </Panel>
      ) : user ? (
        <>
          <div ref={ref}>
            <PageTitle flexDirection="column" user={user} />
          </div>

          <Stack gap={4}>
            <Panel heading="General">
              <UserGeneralPropertyList user={user} />
            </Panel>

            <Panel heading="Auth0">
              <UserAuth0PropertyList user={user} />
            </Panel>

            <Panel heading="Profile">
              <UserProfilePropertyList user={user} />
            </Panel>

            <Panel heading="Preferences">
              <UserPreferencesList user={user} />
            </Panel>

            <Panel heading="Companies">
              {companiesStatus === 'loading' ? (
                <PanelPadding><Loading /></PanelPadding>
              ) : companiesStatus === 'error' ? (
                <PanelPadding>
                  <ErrorMessage error="Oh no" />
                </PanelPadding>
              ) : companies && companies.length ? (
                <UserCompaniesTable user={user} companies={companies} />
              ) : (
                <PanelPadding color="gray">No companies yet</PanelPadding>
              )}
            </Panel>

            <Panel heading="Requests">
              {requestsStatus === 'loading' ? (
                <PanelPadding><Loading /></PanelPadding>
              ) : requestsStatus === 'error' ? (
                <PanelPadding>
                  <ErrorMessage error="Oh no" />
                </PanelPadding>
              ) : requests?.rfqDashboardOverviews?.length ? (
                <UserRequestsTable userId={userId} requests={requests} />
              ) : (
                <PanelPadding color="gray">No requests yet</PanelPadding>
              )}
            </Panel>

            <Panel heading="Contracts">
              {contractsStatus === 'loading' ? (
                <PanelPadding><Loading /></PanelPadding>
              ) : contractsStatus === 'error' ? (
                <PanelPadding>
                  <ErrorMessage error="Oh no" />
                </PanelPadding>
              ) : contractOverviews?.length ? (
                <AdminUserContractsTable
                  userId={userId}
                  contractOverviews={contractOverviews}
                />
              ) : (
                <PanelPadding color="gray">No contracts yet</PanelPadding>
              )}
            </Panel>

            <Panel heading="Sent emails">
              {emailsStatus === 'loading' ? (
                <PanelPadding><Loading /></PanelPadding>
              ) : emailsStatus === 'error' ? (
                <PanelPadding>
                  <ErrorMessage error="Oh no" />
                </PanelPadding>
              ) : emails.length ? (
                <UserEmailsTable emails={emails} />
              ) : (
                <PanelPadding color="gray">No emails yet</PanelPadding>
              )}
            </Panel>

            <Panel heading="Access events">
              {accessEventsStatus === 'loading' ? (
                <PanelPadding><Loading /></PanelPadding>
              ) : accessEventsStatus === 'error' ? (
                <PanelPadding>
                  <ErrorMessage error="Oh no" />
                </PanelPadding>
              ) : accessEvents.length ? (
                <UserAccessEventsTable accessEvents={accessEvents} />
              ) : (
                <PanelPadding color="gray">No events yet</PanelPadding>
              )}
            </Panel>
          </Stack>
        </>
      ) : null}
    </Page>
  );
};
