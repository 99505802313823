export const FilterComparisonOperator = {
  LT: 'lt',
  LTE: 'lte',
  EQ: 'eq',
  GTE: 'gte',
  GT: 'gt',
  NEQ: 'neq',
} as const;

export const ActiveStageFilterOperator = {
  ...FilterComparisonOperator,
  FINAL_STAGE: 'final_stage',
} as const;

export type ActiveStageFilter = {
  stageNumber?: number;
  operator: typeof ActiveStageFilterOperator[keyof typeof ActiveStageFilterOperator];
};

export const FinalDeadlineFilterOperator = {
  ...FilterComparisonOperator,
  HAS_PASSED: 'has_passed',
  HAS_NOT_PASSED: 'has_not_passed',
} as const;

export type FinalDeadlineFilter = {
  deadline?: Date;
  operator: typeof FinalDeadlineFilterOperator[keyof typeof FinalDeadlineFilterOperator];
};
