import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionnaireRenewalDateCell } from '@deepstream/ui/modules/PreQualification/Questionnaire/QuestionnaireRenewalDateCell';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { QuestionnaireOverview } from '@deepstream/common/preQual';
import { useCurrentUserLocale } from '@deepstream/ui/useCurrentUser';
import { localeFormatFactorAsPercent } from '@deepstream/utils';
import { QuestionnaireExpiryDateCell } from '@deepstream/ui/modules/PreQualification/Questionnaire/QuestionnaireExpiryDateCell';
import { QuestionnaireLinkCell } from '../PreQualification/QuestionnaireLinkCell';

type TableProps = {
  questionnaires: QuestionnaireOverview[];
};

export const CompanyQuestionnairesTable = ({ questionnaires }: TableProps) => {
  const { t } = useTranslation('preQualification');
  const locale = useCurrentUserLocale();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Questionnaire',
        accessor: 'name',
        Cell: nestCells(TruncateCell, QuestionnaireLinkCell),
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: ({ status }: QuestionnaireOverview) => t(`questionnaireStatus.${status}`),
      },
      {
        id: 'completion',
        Header: 'Completion',
        accessor: ({ completion }: QuestionnaireOverview) => localeFormatFactorAsPercent(completion, { locale, decimalPlaces: 0 }),
      },
      {
        id: 'version',
        Header: 'Version',
        accessor: 'meta.fromTemplateVersion',
      },
      {
        id: 'renewalDate',
        Header: 'Renewal date',
        accessor: 'renewalDate',
        Cell: QuestionnaireRenewalDateCell,
      },
      {
        id: 'expiryDate',
        Header: 'Expiry date',
        accessor: 'expiryDate',
        Cell: QuestionnaireExpiryDateCell,
      },
    ],
    [locale, t],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={questionnaires}
        isSortable
        smallPageControls
      />
    </BasicTableStyles>
  );
};
