import { useMemo, useCallback, useEffect } from 'react';
import { PageRole } from '@deepstream/common/rfq-utils';
import { pick, keys, includes } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Text } from 'rebass/styled-components';
import { QUESTIONS_PAGE_ID, QuestionnaireRoles, QuestionnaireTeamMember } from '@deepstream/common/preQual';
import { RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { useFormikContext } from 'formik';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ModalForm } from '../../../../ModalForm';
import { UserSelectField } from '../../../Request/Team/UserSelectField';
import { useQuestionnaireData } from '../questionnaireUtils';
import { useAddTeamMember, useRemoveTeamMember, useUpdateTeamMemberRoles } from '../questionnaireMutations';
import { RequestRole } from '../../../../types';
import { UserDetails } from '../../../../UserDetails';
import { SelectField, getItemLabelWithDescription } from '../../../../form/SelectField';
import { useCurrentUser } from '../../../../useCurrentUser';
import { TeamUserPagesPermissions } from './TeamUserPagesPermissions';
import { useQuestionnaireRoleSelectItems } from './useQuestionnaireRoleSelectItems';
import { useConfirmDialog, useModalState } from '../../../../ui/useModalState';
import { RemoveSelfDialog, RemoveTeamMemberDialog } from '../../../../RemoveTeamMemberDialog';
import { useIsAppAdmin } from '../../../../useIsAppAdmin';

const DEFAULT_USER_ROLE = RequestRole.TEAM_MEMBER;
const DEFAULT_PAGE_PERMISSION = PageRole.READER;

export type TeamUser = {
  _id: string;
  name: string;
  email: string;
  companyId: string;
  roleRestrictionProfiles: Record<string, RoleRestrictionProfile>;
};

type UserModalFields = {
  selectedUser: Pick<QuestionnaireTeamMember, '_id' | 'name' | 'email'> & { roleRestrictionProfile?: RoleRestrictionProfile } | undefined;
  role: RequestRole;
  pagesPermissions: QuestionnaireRoles | undefined;
};

const useTeamMembersIDs = ({
  companyId,
}: {
  companyId: string
}) => {
  const { teamById } = useQuestionnaireData();
  const companyUsers = teamById[companyId];

  const { owners: ownersIDs, users } = companyUsers || {};

  const usersIDs = keys(users);

  return { ownersIDs, usersIDs };
};

const useUserRole = ({
  companyId,
}: {
  companyId: string;
}) => {
  const { ownersIDs, usersIDs } = useTeamMembersIDs({ companyId });

  return useCallback((userId: string) => {
    if (includes(ownersIDs, userId)) return RequestRole.OWNER;
    if (includes(usersIDs, userId)) return RequestRole.TEAM_MEMBER;

    return null;
  }, [ownersIDs, usersIDs]);
};

const useUserPagesPermissions = ({
  companyId,
}: {
  companyId: string;
}) => {
  const { teamById } = useQuestionnaireData();
  const team = teamById?.[companyId];

  return useCallback((userId: string) => {
    return team.users[userId].roles;
  }, [team]);
};

const useFormInitialValues = ({
  companyId,
  user,
}: {
  companyId: string;
  user?: TeamUser;
}) => {
  const getUserRole = useUserRole({ companyId });
  const getPagesPermissions = useUserPagesPermissions({ companyId });

  return useMemo((): UserModalFields => {
    const userId = user?._id;
    const role = getUserRole(userId);

    // Edit mode
    if (userId) {
      return {
        // Map `TeamUser` to the format of `UserSelectField` return
        selectedUser: {
          ...pick(user, ['_id', 'name', 'email']),
          roleRestrictionProfile: user?.roleRestrictionProfiles?.[companyId] ?? null,
        },
        role,
        pagesPermissions: getPagesPermissions(userId),
      };
    }

    // New user mode
    return {
      selectedUser: undefined,
      role: DEFAULT_USER_ROLE,
      pagesPermissions: {
        [QUESTIONS_PAGE_ID]: DEFAULT_PAGE_PERMISSION,
      },
    };
  }, [
    companyId,
    getPagesPermissions,
    getUserRole,
    user,
  ]);
};

const TeamUserRole = () => {
  const { t } = useTranslation(['preQualification', 'translation']);
  const roles = useQuestionnaireRoleSelectItems();
  const currentUser = useCurrentUser();
  const { values } = useFormikContext<UserModalFields>();
  const isAuthorizedStakeholder = values?.selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER;
  const isSuperUser = values?.selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.SUPER_USER;
  const isEditSelf = currentUser._id === values?.selectedUser?._id;

  return (
    <>
      <SelectField
        items={roles}
        name="role"
        getButtonLabel={item => item?.label ?? ''}
        getItemLabel={getItemLabelWithDescription}
        disabled={isSuperUser || isAuthorizedStakeholder || isEditSelf}
      />
      {isAuthorizedStakeholder && (
        <MessageBlock variant="info" mt={0}>
          <Text>
            {t('questionnaire.team.pagePermissionsTable.stakeholdersLimitsInfo')}
          </Text>
        </MessageBlock>
      )}
      {isSuperUser && (
        <Text color="subtext" fontSize={0} mt={-2}>
          <Icon
            style={{ fontSize: '12px', marginRight: 4 }}
            icon="lock"
            light
          />
          {t('teamManagement.cannotBeEditedForSuperUser', { ns: 'translation' })}
        </Text>
      )}
    </>
  );
};

const TeamUserFields = ({
  companyId,
  user,
}: {
  companyId: string;
  user?: TeamUser;
}) => {
  const { usersIDs } = useTeamMembersIDs({ companyId });
  const { values, setFieldValue } = useFormikContext<UserModalFields>();

  useEffect(() => {
    const isAuthorizedStakeholder = values?.selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER;
    const isSuperUser = values?.selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.SUPER_USER;

    if (isAuthorizedStakeholder) {
      setFieldValue('role', RequestRole.TEAM_MEMBER);
    }
    if (isSuperUser) {
      setFieldValue('role', RequestRole.OWNER);
    }
  }, [values, setFieldValue]);

  return (
    <>
      {user ? (
        <UserDetails user={user} />
      ) : (
        <UserSelectField companyId={companyId} existingUserIds={usersIDs} />
      )}
      <TeamUserRole />
      <TeamUserPagesPermissions />
    </>
  );
};

export const TeamUserModal = ({
  companyId,
  user,
  close,
}: {
  companyId: string;
  user?: TeamUser;
  close: () => void;
}) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const currentUser = useCurrentUser();
  const initialValues = useFormInitialValues({
    companyId,
    user,
  });
  const isAppAdmin = useIsAppAdmin();

  const [addTeamMember] = useAddTeamMember({
    onSettled: close,
  });
  const [updateRoles] = useUpdateTeamMemberRoles({
    onSettled: close,
  });
  const [removeTeamMember] = useRemoveTeamMember();

  const { confirm, ...confirmDialogProps } = useConfirmDialog();
  const removeUserModalSelf = useModalState();

  const modalHeading = user ? t('questionnaire.team.editUser') : t('questionnaire.team.addUser');
  const submitLabel = user ? t('saveChanges', { ns: 'general' }) : t('questionnaire.team.addUser');

  return (
    <>
      <ModalForm
        style={{ content: { maxWidth: '400px', minWidth: '400px' } }}
        heading={modalHeading}
        initialValues={initialValues}
        validationSchema={
          yup.object().shape({
            selectedUser: yup.mixed().required(t('required', { ns: 'general' })),
            role: yup.string().oneOf(Object.values(RequestRole)),
            pagesPermissions: yup.object().required(),
          })
        }
        disableSubmitIfNotDirty={true}
        disableSubmitIfInvalid={true}
        isOpen
        onCancel={close}
        onSubmit={async (values) => {
          const isOwner = values.role === RequestRole.OWNER;

          const payload = {
            ...pick(values.selectedUser, ['_id', 'name', 'email']),
            isOwner,
            roles: isOwner
              ? { [QUESTIONS_PAGE_ID]: PageRole.RESPONDER }
              : values.pagesPermissions,
          };

          if (user) {
            await updateRoles({
              userId: payload._id,
              isOwner: payload.isOwner,
              roles: payload.roles,
            });
          } else {
            await addTeamMember(isAppAdmin ? { companyId, users: [payload] } : { users: [payload] });
          }

          close();
        }}
        submitLabel={submitLabel}
        footerLeftButton={user && (
          <Button
            variant="danger-outline"
            type="button"
            onClick={() => {
              const removeSelf = currentUser._id === user._id;
              if (removeSelf) {
                removeUserModalSelf.open();
              } else {
                confirm(async () => {
                  await removeTeamMember({ userId: user._id });
                  close();
                });
              }
            }}
          >
            {t('questionnaire.team.removeUser')}
          </Button>
        )}
      >
        <TeamUserFields companyId={companyId} user={user} />
      </ModalForm>
      {user && confirmDialogProps.isOpen && (
        <RemoveTeamMemberDialog
          user={user}
          {...confirmDialogProps}
        />
      )}
      {user && removeUserModalSelf.isOpen && (
        <RemoveSelfDialog
          user={user}
          isOpen={removeUserModalSelf.isOpen}
          close={removeUserModalSelf.close}
        />
      )}
    </>
  );
};
