import * as React from 'react';
import { LabelConfig, LabelConfigProvider } from './LabelConfigProvider';

/**
 * Provides the default label configuration for SwitchField components
 */
export const DefaultSwitchLabelConfigProvider: React.FC<{ name: string }> = ({ name, children }) => (
  <LabelConfigProvider
    variant={LabelConfig.LEFT}
    width="auto"
    style={{
      [name]: {
        fontSize: 2,
        lineHeight: '24px',
        mr: 1,
      },
    }}
  >
    {children}
  </LabelConfigProvider>
);
