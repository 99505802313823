import { useMemo, useRef, useEffect } from 'react';
import { mapValues, groupBy, first, cloneDeep, map, set, omitBy, isFinite, sortBy } from 'lodash';
import { intervalToDuration, differenceInMilliseconds } from 'date-fns';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useQuery } from 'react-query';
import { Flex, Text } from 'rebass/styled-components';
import { Tabs, TabListPanel, Tab, TabPanels, TabPanel } from '@deepstream/ui/ui/TabsVertical';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Table } from '@deepstream/ui/Table';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { LoadingPanel } from '@deepstream/ui/ui/Loading';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { ValueOrDashCell } from '@deepstream/ui/ValueOrDashCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { ErrorPanel } from '@deepstream/ui/ui/ErrorMessage';
import { useForceUpdateInterval } from '@deepstream/ui-kit/hooks/useForceUpdate';
import { DistanceToNow } from '@deepstream/ui/DistanceToNow';
import { SidebarLayout } from '@deepstream/ui/ui/ProfileLayout';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { Page } from '../Page';
import * as Title from '../title';
import { reportConfigByType } from './reportConfig';
import * as reports from './reports';
import { DiscoveryLogQuery, DiscoveryLogsQueryTable } from './DiscoveryLogsQueryTable';
import { ChatbotChatsTable } from './ChatbotChatsTable';
import { logsTabs, logsTabsConfig, reportsSidebarConfig, reportsTabs } from './tabsConfig';
import { reportsRoute, useAdminNavigation } from '../AppRouting';

const LastGeneratedCell = ({
  cell: { value: finishedAt },
  row: { original: { lastGeneratedReport } },
}) => {
  useForceUpdateInterval(5000);

  const [downloadReport, { isLoading }] = reports.useDownloadReport();

  if (!lastGeneratedReport) {
    return <EmDash />;
  } else if (finishedAt === -1) {
    return <ReportStatusCell cell={{ value: lastGeneratedReport.status }} />;
  } else {
    return (
      <Flex>
        <DistanceToNow date={new Date(finishedAt)} />
        {' '}
        <IconButton
          icon="download"
          onClick={() => downloadReport({ reportId: lastGeneratedReport._id })}
          disabled={isLoading}
          ml={2}
        />
      </Flex>
    );
  }
};

/**
 * Cell that contains a "Generate report" button. The button is disabled
 * for the duration of the generation.
 */
const GenerateReportCell = ({ row: { original: report } }) => {
  const { generateReport, isGenerating } = reports.useGenerateReport();

  if (report.disabled) {
    return null;
  }

  return (
    <Button
      small
      variant="primary-outline"
      iconLeft="file-alt"
      disabled={isGenerating}
      onClick={() => generateReport({ reportType: report.type })}
    >
      Generate report
    </Button>
  );
};

const DurationCell = ({ cell: { value: durationMs } }) => {
  if (Number.isNaN(durationMs)) {
    return <EmDash />;
  }

  const duration = intervalToDuration({ start: 0, end: durationMs });

  return (
    duration.minutes ? (
      <>{duration.minutes}m {duration.seconds}s</>
    ) : (
      <>{duration.seconds}s</>
    )
  );
};

const ReportStatusCell = ({ cell: { value: status } }) => (
  status === 'pending' ? (
    <IconText fixedWidth isIconRegular icon="circle" text="Pending" iconColor="gray" />
  ) : status === 'error' ? (
    <IconText fixedWidth icon="exclamation-circle" text="Failure" iconColor="danger" />
  ) : status === 'success' ? (
    <IconText fixedWidth icon="check-circle" text="Success" iconColor="success" />
  ) : status === 'inProgress' ? (
    <IconText fixedWidth icon="spinner" text="In progress" />
  ) : null
);

const DownloadReportCell = ({ row: { original: report } }) => {
  const [downloadReport, { isLoading }] = reports.useDownloadReport();
  const isDownloadable = report.status === 'success';

  return (
    isDownloadable ? (
      <Button
        small
        variant="primary-outline"
        iconLeft="download"
        disabled={isLoading}
        onClick={() => downloadReport({ reportId: report._id })}
      >
        Download
      </Button>
    ) : (
      null
    )
  );
};

/**
 * Panel with a table of generated reports by date requested
 */
const HistoryPanel = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: ({ type }) => reportConfigByType[type].name,
        width: 220,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Requested on',
        accessor: 'createdAt',
        Cell: DatetimeCell,
        width: 200,
      },
      {
        Header: 'Requested by',
        accessor: 'createdBy',
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Duration',
        accessor: ({ startedAt, finishedAt }) => (
          differenceInMilliseconds(new Date(finishedAt), new Date(startedAt))
        ),
        Cell: DurationCell,
        width: 80,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 100,
        Cell: ReportStatusCell,
      },
      {
        Header: 'Detail',
        sortType: 'caseInsensitive',
        accessor: ({ status, error = '' }) => {
          switch (status) {
            case 'pending': return 'Waiting for job to start';
            case 'inProgress': return 'Report is being generated';
            case 'success': return '';
            case 'error': return error;
            default: return '';
          }
        },
        Cell: nestCells(TruncateCell, ValueOrDashCell),
      },
      {
        id: 'actions',
        textAlign: 'right',
        width: 120,
        Cell: DownloadReportCell,
      },
    ],
    [],
  );

  const { data: generatedReports = [], isLoading, isError } = reports.useReportHistory();

  return isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error="Failed to load report history" />
  ) : (
    <Panel>
      <StaticTableStyles>
        <Table
          isPaginated
          isSortable
          columns={columns}
          data={generatedReports}
          initialPageSize={10}
        />
      </StaticTableStyles>
    </Panel>
  );
};

/**
 * Panel with table of each report type
 */
const ReportsPanel = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 230,
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Last generated',
        accessor: ({ lastGeneratedReport }) => {
          if (!lastGeneratedReport) {
            return -1;
          } else if (lastGeneratedReport?.status === 'success') {
            return lastGeneratedReport.finishedAt;
          } else {
            return -1;
          }
        },
        Cell: LastGeneratedCell,
        width: 150,
      },
      {
        id: 'actions',
        width: 200,
        textAlign: 'right',
        Cell: GenerateReportCell,
      },
    ],
    [],
  );

  const { data: generatedReports = [] } = reports.useReportHistory();

  const reportData = useMemo(
    () => {
      const lastGeneratedReportByType = mapValues(groupBy(generatedReports, 'type'), first);

      return sortBy(
        map(
          cloneDeep(omitBy(reportConfigByType, { disabled: true })),
          report => set(report, 'lastGeneratedReport', lastGeneratedReportByType[report.type]),
        ),
        'name',
      );
    },
    [generatedReports],
  );

  return (
    <Panel>
      <StaticTableStyles>
        <Table isSortable columns={columns} data={reportData} />
      </StaticTableStyles>
    </Panel>
  );
};

const formatQueriesForTable = (data: any): DiscoveryLogQuery[] => {
  return data.map((entry) => {
    return {
      transactionId: entry.transactionId,
      companyId: entry.companyId,
      userId: entry.userId,
      duration: isFinite(entry?.totalDuration) ? (entry.totalDuration / 1000)?.toFixed(3) : undefined,
      numberOfFeedbackEntries: entry.feedbackEntries.length,
      promptStrategyVersion: entry.promptStrategyVersion,
      numberOfResults: entry?.result?.rows?.length,
      status: entry?.result?.status,
      timestamp: Date.parse(entry.timestamp),
    };
  });
};

const DiscoveryTransactionsPanel = () => {
  const adminApi = useAdminApi();

  const { data, status } = useQuery(
    ['discoveryLogQueries'],
    wrap(adminApi.getDiscoveryQueries),
  );

  return (
    <Panel heading="Discovery transactions">
      {status === 'success' && (
        <DiscoveryLogsQueryTable queries={formatQueriesForTable(data)} />
      )}
    </Panel>
  );
};

const ChatbotChatsPanel = () => {
  const adminApi = useAdminApi();

  const { data, status } = useQuery(
    ['chatbotChats'],
    wrap(adminApi.getChatbotChats),
  );

  return (
    <Panel heading="Chats">
      {status === 'success' && (
        <ChatbotChatsTable chats={data} />
      )}
    </Panel>
  );
};

const tabs = Object.values({ ...reportsTabs, ...logsTabs });
const getTab = (index: number) => tabs[index];
const getTabIndex = (tab: string) => tabs.indexOf(tab);

/**
 * Top-level page for the reports
 */
export const ReportsPage = () => {
  const navigation = useAdminNavigation();
  const { tab, download: downloadReportIdParam } = reportsRoute.useSearch();

  const [downloadReport] = reports.useDownloadReport();

  const previousDownloadParam = useRef<any>();

  useEffect(() => {
    if (previousDownloadParam.current !== downloadReportIdParam) {
      previousDownloadParam.current = downloadReportIdParam;

      if (downloadReportIdParam) {
        navigation.navigateToReports({ tab }, true);

        downloadReport({ reportId: downloadReportIdParam });
      }
    }
  }, [navigation, downloadReportIdParam, downloadReport, tab]);

  useEffect(
    () => {
      if (!tab) {
        navigation.navigateToReports(
          { tab: reportsTabs.reports, download: downloadReportIdParam },
          true,
        );
      }
    },
    [tab, navigation, downloadReportIdParam],
  );

  const { isExtraSmall, isSmall } = useDeviceSize();

  const invalidateReports = reports.useInvalidateReports();

  return (
    <Page>
      <Title.Container>
        <Title.IconText solid icon="chart-line" size="large">
          Reporting
        </Title.IconText>
      </Title.Container>
      <Tabs
        index={getTabIndex(tab)}
        onChange={index => {
          invalidateReports();
          navigation.navigateToReports({ tab: getTab(index) });
        }}
      >
        <SidebarLayout
          sidebar={[reportsSidebarConfig, logsTabsConfig].map(
            (sidebarConfig) => (
              <TabListPanel
                key={sidebarConfig.key}
                heading={sidebarConfig.heading}
              >
                {Object.entries(sidebarConfig.tabs).map(([tabKey, config]) => (
                  <Tab key={tabKey}>
                    <Flex alignItems="center">
                      <Icon icon={config.icon} mr={2} fixedWidth regular={config.isIconRegular} />
                      <Text flex={1}>{config.text}</Text>
                    </Flex>
                  </Tab>
                ))}
              </TabListPanel>
            ),
          )}
          main={
            <TabPanels>
              <TabPanel key={reportsTabs.reports}>
                <ReportsPanel />
              </TabPanel>
              <TabPanel key={reportsTabs.history}>
                <HistoryPanel />
              </TabPanel>
              <TabPanel key={logsTabs.discovery}>
                <DiscoveryTransactionsPanel />
              </TabPanel>
              <TabPanel key={logsTabs.chatbotChats}>
                <ChatbotChatsPanel />
              </TabPanel>
            </TabPanels>
          }
          sidebarStyle={
            !isExtraSmall && !isSmall
              ? { maxWidth: '232px', flex: '0 0 auto' }
              : undefined
          }
          mainStyle={
            !isExtraSmall && !isSmall ? { flex: '1 1 auto' } : undefined
          }
        />
      </Tabs>
    </Page>
  );
};
