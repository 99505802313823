import * as React from 'react';
import { useKeyPress } from '@deepstream/ui-kit/hooks/useKeyPress';

export enum Direction {
  LTR = 'ltr',
  RTL = 'rtl',
}

const DirectionContext = React.createContext<Direction>(Direction.LTR);

export const useDirection = () => React.useContext(DirectionContext);

const flipDirection = (direction: Direction) =>
  direction === Direction.LTR
    ? Direction.RTL
    : Direction.LTR;

export const DirectionProvider: React.FC<{ initialDirection?: Direction }> = ({
  initialDirection = Direction.LTR,
  children,
}) => {
  const [direction, setDirection] = React.useState(initialDirection);
  const isFPressed = useKeyPress('f');
  const isControlPressed = useKeyPress('Control');

  React.useEffect(
    () => {
      if (isFPressed && isControlPressed) {
        setDirection(flipDirection);
      }
    },
    [isFPressed, isControlPressed],
  );

  return (
    <DirectionContext.Provider value={direction}>
      {children}
    </DirectionContext.Provider>
  );
};
