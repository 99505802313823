import { useCallback, useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { Box, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { values, includes } from 'lodash';
import { MembershipDecision, RoleRestrictionProfile, defaultRoles, getInviteMessage } from '@deepstream/common/user-utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { PanelText } from '@deepstream/ui-kit/elements/Panel';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { User, CompanyRoles } from '../../../types';
import { useAdminApi, useApi } from '../../../api';
import { useToaster } from '../../../toast';
import { UserDetails } from '../../../UserDetails';
import { useMutation } from '../../../useMutation';
import { ApiClient } from '../../../apiClient';
import { AdminApiClient } from '../../../adminApiClient';
import { useIsAppAdmin, isAppAdminCompany } from '../../../useIsAppAdmin';
import { Tabs, TabPanels, TabPanel, TabList, Tab } from '../../../ui/Tabs';
import { RoleField } from './RoleField';
import { EditPermissionList, EditRequestsPermissionList, EditContractsPermissionList, EditReportingPermissionList } from './EditPermissionList';
import {
  FULL_USER_EDITABLE_PERMISSIONS,
  SUPER_USER_EDITABLE_PERMISSIONS,
  STAKEHOLDER_EDITABLE_PERMISSIONS,
  globalRoleToRoleRestrictionProfile,
} from './utils';
import { GlobalRole } from './types';
import { useWaitForProcessingTask } from '../../../useWaitForProcessingTask';

const useApplyMembershipDecision = (companyId: string, apiClient: ApiClient | AdminApiClient) => {
  const queryClient = useQueryClient();
  const waitForProcessingTask = useWaitForProcessingTask();

  return useMutation(
    (payload) => waitForProcessingTask({
      command: () => apiClient.applyMembershipDecision(payload),
    }),
    {
      onSettled: callAll(
        () => queryClient.invalidateQueries(['membershipRequests', { companyId }]),
        () => queryClient.invalidateQueries(['users', { companyId }]),
      ),
    },
  );
};

const DecideMembershipModalContent = ({ apiClient, companyId, user, addToTeam, close }: {
  apiClient: ApiClient | AdminApiClient;
  companyId: string;
  user: User;
  addToTeam: () => void;
  close: () => void;
}) => {
  const { t } = useTranslation();
  const toaster = useToaster();

  const [applyMembershipDecision] = useApplyMembershipDecision(companyId, apiClient);

  const rejectMembership = async () => {
    await applyMembershipDecision({
      companyId,
      userId: user._id,
      decision: MembershipDecision.REJECT,
    }, {
      onSuccess: callAll(
        () => toaster.success(t('teamManagement.toaster.rejectMembershipRequestSuccess')),
        close,
      ),
      onError: () => toaster.error(t('teamManagement.toaster.rejectMembershipRequestError')),
    });
  };

  return (
    <>
      <ModalHeader onClose={close}>
        {t('teamManagement.dialog.membershipDecision.heading')}
      </ModalHeader>
      <ModalBody>
        <Box mb={3}>
          <UserDetails user={user} />
        </Box>
        <Text fontSize={1} fontWeight={500} mb={1}>
          {t('general.message')}
        </Text>
        <Text fontSize={2}>
          {user.requestedRoles?.[companyId] ? (
            getInviteMessage(user.requestedRoles?.[companyId], t)
          ) : (
            null
          )}
        </Text>
        <MessageBlock variant="info">
          {t('teamManagement.dialog.membershipDecision.info')}
        </MessageBlock>
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <CancelButton onClick={close} />
        <Box>
          <Button type="button" variant="danger" mr={2} onClick={rejectMembership}>
            {t('teamManagement.rejectRequest')}
          </Button>
          <Button type="button" variant="primary" onClick={addToTeam}>
            {t('teamManagement.addToTeam')}
          </Button>
        </Box>
      </ModalFooter>
    </>
  );
};

const EditPermissionsModalContent = ({ apiClient, companyId, user, close }: {
  apiClient: ApiClient | AdminApiClient;
  companyId: string;
  user: User;
  close: () => void;
}) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const isAppAdmin = useIsAppAdmin();

  const [applyMembershipDecision] = useApplyMembershipDecision(companyId, apiClient);

  const acceptMembership = async ({ globalRole, ...roles }: CompanyRoles & { globalRole: GlobalRole }) => {
    await applyMembershipDecision({
      companyId,
      userId: user._id,
      decision: MembershipDecision.ACCEPT,
      roleRestrictionProfile: globalRoleToRoleRestrictionProfile(globalRole),
      roles,
    }, {
      onSuccess: callAll(
        () => toaster.success(t('teamManagement.toaster.acceptMembershipRequestSuccess')),
        close,
      ),
      onError: () => toaster.error(t('teamManagement.toaster.acceptMembershipRequestError')),
    });
  };

  const isPermissionDisabled = useCallback((itemId, globalRole) => {
    if (globalRole === GlobalRole.AUTHORIZED_STAKEHOLDER) {
      return !includes(STAKEHOLDER_EDITABLE_PERMISSIONS, itemId);
    }

    if (globalRole === GlobalRole.FULL_USER) {
      return !includes(FULL_USER_EDITABLE_PERMISSIONS, itemId);
    }

    if (globalRole === GlobalRole.SUPER_USER) {
      return !includes(SUPER_USER_EDITABLE_PERMISSIONS, itemId);
    }
  }, []);

  return (
    <Formik
      initialValues={{
        globalRole: GlobalRole.FULL_USER,
        ...defaultRoles[RoleRestrictionProfile.FULL_USER],
      }}
      validationSchema={
        yup.object().shape({
          globalRole: yup.string().oneOf(values(GlobalRole)).required(),
          admin: yup.string().oneOf(['true', 'false']).required(),
          editor: yup.string().oneOf(['true', 'false']).required(),
          sendRFQ: yup.string().oneOf(['true', 'false']).required(),
          receiveRFQ: yup.string().oneOf(['true', 'false']).required(),
          sendContracts: yup.string().oneOf(['true', 'false']).required(),
          receiveContracts: yup.string().oneOf(['true', 'false']).required(),
          accessReportingForRequests: yup.string().oneOf(['true', 'false']).required(),
          accessReportingForContracts: yup.string().oneOf(['true', 'false']).required(),
        })
      }
      onSubmit={acceptMembership}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <ModalHeader onClose={close}>
            {t('teamManagement.dialog.addUser.heading')}
          </ModalHeader>
          <Tabs>
            <PanelText p={3}>
              <UserDetails user={user} />
            </PanelText>
            <PanelText p={3}>
              <RoleField />
            </PanelText>
            <TabList style={{ backgroundColor: 'white' }}>
              <Tab>
                {t('general', { ns: 'general' })}
              </Tab>
              <Tab disabled={isAppAdminCompany(companyId)}>
                <Icon
                  style={{ fontSize: '14px', marginRight: 4 }}
                  icon="file-text-o"
                />
                {t('general.request_other')}
              </Tab>
              <Tab disabled={isAppAdminCompany(companyId)}>
                <Icon
                  style={{ fontSize: '14px', marginRight: 4 }}
                  icon="file-contract"
                  regular
                />
                {t('general.contract_other')}
              </Tab>
              <Tab disabled={isAppAdminCompany(companyId)}>
                <Icon
                  style={{ fontSize: '14px', marginRight: 4 }}
                  icon="chart-bar"
                  regular
                />
                {t('general.reporting')}
              </Tab>
            </TabList>
            <ModalBody>
              <TabPanels>
                <TabPanel>
                  <EditPermissionList
                    isItemDisabled={(itemId) => isPermissionDisabled(itemId, values.globalRole)}
                    globalRole={values.globalRole}
                  />
                  {values.globalRole === GlobalRole.FULL_USER ? (
                    <MessageBlock variant="warn">
                      {isAppAdmin
                        ? t('teamManagement.dialog.addUser.fullUserWarningAdmin')
                        : t('teamManagement.dialog.addUser.fullUserWarning')
                      }
                    </MessageBlock>
                  ) : values.globalRole === GlobalRole.SUPER_USER ? (
                    <MessageBlock variant="warn">
                      {isAppAdmin
                        ? t('teamManagement.dialog.addUser.superUserWarningAdmin')
                        : t('teamManagement.dialog.addUser.superUserWarning')
                      }
                    </MessageBlock>
                  ) : (
                    <MessageBlock variant="info">
                      {t('teamManagement.dialog.addUser.accessInfo')}
                    </MessageBlock>
                  )}
                </TabPanel>
                <TabPanel>
                  <EditRequestsPermissionList isItemDisabled={(itemId) => isPermissionDisabled(itemId, values.globalRole)} />
                  <MessageBlock variant="info" mt={3} width="100%">
                    {t('teamManagement.dialog.addUser.accessInfo')}
                  </MessageBlock>
                </TabPanel>
                <TabPanel>
                  <EditContractsPermissionList isItemDisabled={(itemId) => isPermissionDisabled(itemId, values.globalRole)} />
                  <MessageBlock variant="info" mt={3} width="100%">
                    {t('teamManagement.dialog.addUser.contractsAccessInfo')}
                  </MessageBlock>
                </TabPanel>
                <TabPanel>
                  <EditReportingPermissionList
                    isItemDisabled={(itemId) => isPermissionDisabled(itemId, values.globalRole)}
                    globalRole={values.globalRole}
                  />
                </TabPanel>
              </TabPanels>
            </ModalBody>
          </Tabs>
          <ModalFooter>
            <CancelButton onClick={close} />
            <Button type="submit" variant="primary" disabled={isSubmitting}>
              {t('teamManagement.addUser')}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export const TeamMembershipDecisionModal = ({
  companyId,
  user,
  close,
  isOpen,
  ...props
}: {
  companyId: string;
  user: User | null;
  close: () => void;
} & ModalProps) => {
  const api = useApi();
  const adminApi = useAdminApi();
  const isAppAdmin = useIsAppAdmin();

  const [apiClient, setApiClient] = useState<ApiClient | AdminApiClient | null>();
  useEffect(() => {
    isAppAdmin ? setApiClient(adminApi) : setApiClient(api);
  }, [isAppAdmin, api, adminApi]);

  const [showEditPermissionsModalContent, setShowEditPermissionsModalContent] = useState(false);

  useEffect(() => {
    setShowEditPermissionsModalContent(false);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} style={{ content: { width: '500px' } }} {...props}>
      {!user || !apiClient ? (
        null
      ) : showEditPermissionsModalContent ? (
        <EditPermissionsModalContent
          apiClient={apiClient}
          companyId={companyId}
          user={user}
          close={close}
        />
      ) : (
        <DecideMembershipModalContent
          apiClient={apiClient}
          companyId={companyId}
          user={user}
          addToTeam={() => setShowEditPermissionsModalContent(true)}
          close={close}
        />
      )}
    </Modal>
  );
};
