import { useMemo, useCallback } from 'react';
import { useIsMutating } from 'react-query';
import {
  ExchangeType,
  FieldConfig,
  RfqEventChange,
  isLineItemExchangeDef,
  LineItemExchangeFields,
  SectionType,
  isDefinitionField,
} from '@deepstream/common/rfq-utils';
import {
  compact,
  difference,
  keyBy,
  keys,
  map,
  mapValues,
  pick,
  zipObject,
  find,
  filter,
  isEmpty,
  isFunction,
  omitBy,
  groupBy,
} from 'lodash';
import { ContractExchangeDefinition, ContractPage, ContractSection } from '@deepstream/common/contract/contract';
import { diffArrayBy } from '@deepstream/utils';
import { swap } from '@deepstream/utils/swap';
import { useCurrentCompanyId } from '../../currentCompanyId';
import {
  getSectionExchangeDefs,
  useContractData, useContractId, useContractPage, useContractSection, useContractState,
  useIsPendingCollaborator, useIsSender, useSectionExchangeDefsByCreator,
} from './contract';
import { useMutation } from '../../useMutation';
import { MutationArgs } from '../../types';
import { useApi } from '../../api';
import { useContractQueryKey } from './useContract';
import { contractMappings, contractTemplateMappings, mapDiffToCommands } from '../../ui/diff';
import { useContractMutationArgs } from './useContractMutationArgs';
import {
  checkAreFieldLabelsEqual,
  getLineItemFieldValueKeys,
  getUpdatedSectionFields,
  otherSectionFieldsetIdsToIgnore,
} from '../../draft/draft';
import { useCurrentUserLocale } from '../../useCurrentUser';

export const useIsEditing = () => {
  const section = useContractSection();
  const { editingPanelId } = useContractState();

  return section._id === editingPanelId;
};

export const useShowValidationErrors = () => {
  const { isReview, isTemplate } = useContractState();
  const isPendingCollaborator = useIsPendingCollaborator();

  return isReview && !isTemplate && !isPendingCollaborator;
};

export const useIsMutationLoading = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const queryKey = useContractQueryKey({
    contractId,
    currentCompanyId,
    scope: 'draft',
  });
  const mutationCount = useIsMutating({ mutationKey: queryKey });

  return mutationCount !== 0;
};

export const useUpdateContractSummary = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const locale = useCurrentUserLocale();
  const { isTemplate } = useContractState();
  const mutationArgs = useContractMutationArgs({ name: 'changesSaved', ...args });

  return useMutation(
    ({ summary }) => api.updateContractSummary({
      currentCompanyId,
      contractId,
      summary,
      locale,
      isTemplate,
    }),
    mutationArgs,
  );
};

export const useUpdateContractReminders = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const { isTemplate } = useContractState();
  const mutationArgs = useContractMutationArgs({ name: 'changesSaved', ...args });

  return useMutation(
    ({ reminders }) => api.updateContractReminders({
      currentCompanyId,
      contractId,
      reminders,
      isTemplate,
    }),
    mutationArgs,
  );
};

export const useUpdateCustomMilestones = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const { isTemplate } = useContractState();
  const mutationArgs = useContractMutationArgs({ name: 'changesSaved', ...args });

  return useMutation(
    ({ customMilestones }) => api.updateContractCustomMilestones({
      currentCompanyId,
      contractId,
      customMilestones,
      isTemplate,
    }),
    mutationArgs,
  );
};

export const useAddContractPage = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const { isTemplate } = useContractState();
  const mutationArgs = useContractMutationArgs({ name: 'pageAdded', ...args });

  return useMutation(
    ({ name }: { name: string }) => api.addContractPage({
      currentCompanyId,
      contractId,
      name,
      isTemplate,
    }),
    mutationArgs,
  );
};

export const useDuplicateContractPage = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const { isTemplate } = useContractState();
  const mutationArgs = useContractMutationArgs({ name: 'pageAdded', ...args });

  return useMutation(
    ({ name, sourcePageId }: { name: string; sourcePageId: string }) => api.duplicateContractPage({
      currentCompanyId,
      contractId,
      name,
      sourcePageId,
      isTemplate,
    }),
    mutationArgs,
  );
};

export const useRenameContractPage = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const { isTemplate } = useContractState();
  const mutationArgs = useContractMutationArgs({ name: 'pageRenamed', ...args });

  return useMutation(
    (page: Pick<ContractPage, '_id' | 'name'>) => api.updateContractPage({
      currentCompanyId,
      contractId,
      page,
      isTemplate,
    }),
    mutationArgs,
  );
};

export const useRemoveContractPage = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const { isTemplate } = useContractState();
  const mutationArgs = useContractMutationArgs({ name: 'pageRemoved', ...args });

  return useMutation(
    (page: Pick<ContractPage, '_id'>) => api.removeContractPage({
      currentCompanyId,
      contractId,
      page,
      isTemplate,
    }),
    mutationArgs,
  );
};

export const useReorderContractPages = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const { isTemplate } = useContractState();
  const mutationArgs = useContractMutationArgs({ name: 'pagesReordered', ...args });

  return useMutation(
    (pageIds: string[]) => api.reorderContractPages({
      currentCompanyId,
      contractId,
      pageIds,
      isTemplate,
    }),
    mutationArgs,
  );
};

export const useAddContractSection = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const { isTemplate } = useContractState();
  const mutationArgs = useContractMutationArgs({ name: 'sectionAdded', ...args });

  return useMutation(
    async (section: Pick<ContractSection, 'type' | 'name' | 'pageId'>) => {
      const response = await api.addContractSection({
        currentCompanyId,
        contractId,
        section,
        isTemplate,
      });

      return {
        ...section,
        ...response,
      };
    },
    mutationArgs,
  );
};

export const useDuplicateContractSection = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const locale = useCurrentUserLocale();
  const contractId = useContractId();
  const { isTemplate } = useContractState();
  const mutationArgs = useContractMutationArgs({ name: 'sectionDuplicated', ...args });

  return useMutation(
    (sourceSectionId: string) => api.duplicateContractSection({
      currentCompanyId,
      contractId,
      sourceSectionId,
      locale,
      isTemplate,
    }),
    mutationArgs,
  );
};

export const useMoveContractSection = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const mutationArgs = useContractMutationArgs({ name: 'sectionMoved', ...args });
  const page = useContractPage();
  const section = useContractSection();
  const { isTemplate } = useContractState();
  const previousIndex = page.sections.indexOf(section._id);

  return useMutation(
    (delta: number) => {
      const newSections = swap(page.sections, previousIndex, previousIndex + delta);

      return api.updateContractPage({
        currentCompanyId,
        contractId,
        page: {
          _id: page._id,
          sections: newSections,
        },
        isTemplate,
      });
    },
    mutationArgs,
  );
};

export const useRemoveContractSection = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const { isTemplate } = useContractState();
  const mutationArgs = useContractMutationArgs({ name: 'sectionRemoved', ...args });

  return useMutation(
    (section: Pick<ContractSection, '_id'>) => api.removeContractSection({
      currentCompanyId,
      contractId,
      section,
      isTemplate,
    }),
    mutationArgs,
  );
};

export const useUpdateContractCounterParty = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const contract = useContractData();
  const mutationArgs = useContractMutationArgs({ name: 'changesSaved', ...args });

  const { recipients, teamById } = contract;

  const previousRecipientIds = useMemo(
    () => map(recipients, recipient => recipient._id),
    [recipients],
  );

  const previousUserIdsByRecipientId = useMemo(
    () => mapValues(
      keyBy(recipients, recipient => recipient._id),
      recipient => keys(teamById[recipient._id].users),
    ),
    [recipients, teamById],
  );

  return useMutation(
    ({ recipient, users, requestId, requestSubject }) => {
      const nextRecipientIds = recipient ? compact([recipient._id]) : [];
      const nextUserIdsByRecipientId = recipient
        ? {
          [recipient._id]: map(users, user => user._id),
        }
        : {};
      const recipientIdsChanges = {
        add: difference(nextRecipientIds, previousRecipientIds),
        remove: difference(previousRecipientIds, nextRecipientIds),
      };

      const userIdsChanges = mapValues(
        zipObject(nextRecipientIds), // We only care about the user changes for the "next" recipients
        (_, recipientId) => {
          const previousUserIds = previousUserIdsByRecipientId[recipientId] ?? [];
          const nextUserIds = nextUserIdsByRecipientId[recipientId] ?? [];

          return {
            add: difference(nextUserIds, previousUserIds),
            remove: difference(previousUserIds, nextUserIds),
          };
        },
      );

      const requestIdChanged = requestId !== contract.requestId;

      return api.updateContract({
        currentCompanyId,
        contractId,
        commands: compact([
          {
            name: 'updateRecipients',
            payload: {
              recipientIds: recipientIdsChanges,
              userIdsByRecipientId: userIdsChanges,
            },
          },
          requestIdChanged && {
            name: 'updateRequestData',
            payload: {
              requestId,
              requestSubject,
            },
          },
        ]),
      });
    },
    mutationArgs,
  );
};

export const useSaveSection = ({
  getContractCommands,
  ...args
}: {
  getContractCommands: any;
} & MutationArgs) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const { isTemplate } = useContractState();
  const mutationArgs = useContractMutationArgs({ name: 'changesSaved', ...args });

  return useMutation(
    (params) => api.updateContract({
      currentCompanyId,
      contractId,
      commands: getContractCommands(params),
      isTemplate,
    }),
    mutationArgs,
  );
};

const sanitize = <T,> (items: T[], diffKeys: string[]) =>
  map(items, def => pick(def, diffKeys));

export const getCommands = ({ next, previous, keys, mapping, isTemplate }) =>
  mapDiffToCommands(
    diffArrayBy(sanitize(next, keys), sanitize(previous, keys), '_id'),
    isTemplate ? contractTemplateMappings[mapping] : contractMappings[mapping],
  ) as any;

type SaveSectionPayload = {
  section: ContractSection;
  exchangeDefs: ContractExchangeDefinition[];
  recipientExchangeDefs?: ContractExchangeDefinition[];
  extraCommands?: RfqEventChange[];
};

type SaveSectionConfig = {
  sectionKeys: string[];
  exchangeDefKeys: string[] | ((
    next: SaveSectionPayload,
    previous: SaveSectionPayload
  ) => string[]);
};

const createUseSaveSection = ({
  sectionKeys,
  exchangeDefKeys,
}: SaveSectionConfig) => ({ shouldBePublished } = { shouldBePublished: false }) => {
  const { isTemplate } = useContractState();
  const section = useContractSection();
  const isSender = useIsSender();
  const senderExchangeDefs = useSectionExchangeDefsByCreator({
    group: 'sender',
  });
  const recipientExchangeDefs = useSectionExchangeDefsByCreator({
    group: 'recipient',
  });
  const exchangeDefs = isSender ? senderExchangeDefs : recipientExchangeDefs;
  const canRemoveRecipientExchangeDefs = isSender && section.type === SectionType.DOCUMENT &&
    !section.isLive && !isEmpty(recipientExchangeDefs);

  const previous = {
    section,
    exchangeDefs,
    recipientExchangeDefs: canRemoveRecipientExchangeDefs ? recipientExchangeDefs : undefined,
  } as SaveSectionPayload;

  return useSaveSection({
    getContractCommands: (next: SaveSectionPayload) => [
      // Only senders can make changes to the section
      ...(isSender
        ? getCommands({
          mapping: 'section',
          next: [next.section],
          previous: [previous.section],
          keys: sectionKeys,
          isTemplate,
        })
        : []
      ),
      ...getCommands({
        mapping: 'exchangeDef',
        next: next.exchangeDefs,
        previous: previous.exchangeDefs,
        keys: isFunction(exchangeDefKeys) ? exchangeDefKeys(next, previous) : exchangeDefKeys,
        isTemplate,
      }).map(command => ['updateSection', 'updateContractTemplateSection'].includes(command.name) // `updateSection` command needs a section id `_id`
        ? {
          ...command,
          payload: {
            ...command.payload,
            _id: next.section._id,
            shouldBePublished,
          },
        }
        : ['addExchangeDefinition', 'addContractTemplateExchangeDefinition'].includes(command.name)
          ? {
            ...command,
            payload: {
              ...command.payload,
              sectionId: next.section._id, // contract exchange definitions need a `sectionId` field
              shouldBePublished,
            },
          }
          : {
            ...command,
            payload: {
              ...command.payload,
              shouldBePublished,
            },
          },
      ),
      // Senders can remove recipient exchanges that were imported from a request
      ...(canRemoveRecipientExchangeDefs
        ? getCommands({
          mapping: 'exchangeDef',
          next: next.recipientExchangeDefs,
          previous: previous.recipientExchangeDefs,
          keys: exchangeDefKeys,
          isTemplate,
        }).filter(command => ['removeExchangeDefinition', 'removeContractTemplateExchangeDefinition'].includes(command.name))
        : []
      ),
      ...(next.extraCommands || []),
    ],
  });
};

const lineItemExchangeBaseDefKeys = ['_id', 'type', 'isObsolete', 'currencies', 'sectionId', 'fields', 'isFixed'];

const getLineItemCustomFieldsKeys = (exchangeDefs) => {
  const lineItemFields: FieldConfig[] = find(
    exchangeDefs,
    (exchangeDef) => exchangeDef.type === ExchangeType.LINE_ITEM,
  )?.fields || {};
  const definitionLineItemFields = filter(
    lineItemFields,
    isDefinitionField,
  );

  return map(
    definitionLineItemFields,
    (field) => field.source.key,
  );
};

const saveSectionConfigByType: Record<string, SaveSectionConfig> = {
  [SectionType.LINE_ITEMS]: {
    sectionKeys: ['_id', 'type', 'name', 'description', 'providedBy', 'isObsolete', 'pageId'],
    exchangeDefKeys: (next, previous) => [
      ...lineItemExchangeBaseDefKeys,
      ...getLineItemCustomFieldsKeys(next.exchangeDefs),
      ...getLineItemCustomFieldsKeys(previous.exchangeDefs),
    ],
  },
  [SectionType.DOCUMENT]: {
    sectionKeys: ['_id', 'type', 'name', 'description', 'providedBy', 'isObsolete', 'pageId'],
    exchangeDefKeys: ['_id', 'type', 'category', 'attachments', 'isObsolete', 'sectionId'],
  },
  [SectionType.CONTRACT]: {
    sectionKeys: [], // Users can't update the section
    exchangeDefKeys: [
      '_id', 'type', 'description', 'attachments', 'isObsolete', 'sectionId', 'isAddendum', 'signatureType',
      'requireApprovalBeforeCountersigning', 'senderSigners', 'providedBy',
    ],
  },
};

export const useSaveLineItemsSection = createUseSaveSection(saveSectionConfigByType[SectionType.LINE_ITEMS]);

export const useSaveDocumentsSection = createUseSaveSection(saveSectionConfigByType[SectionType.DOCUMENT]);

export const useSaveContractsSection = createUseSaveSection(saveSectionConfigByType[SectionType.CONTRACT]);

export const useAddContractTeamMember = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const mutationArgs = useContractMutationArgs({ name: 'userAdded', ...args });

  return useMutation(
    ({ user }) => api.addContractTeamMember({
      currentCompanyId,
      contractId,
      user,
    }),
    mutationArgs,
  );
};

export const useUpdateContractTeamMemberRoles = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const mutationArgs = useContractMutationArgs({ name: 'userRolesUpdated', ...args });

  return useMutation(
    ({ userId, contractRoles, isOwner }) => api.updateContractTeamMemberRoles({
      currentCompanyId,
      contractId,
      userId,
      isOwner,
      contractRoles,
    }),
    mutationArgs,
  );
};

export const useRemoveContractTeamMember = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const mutationArgs = useContractMutationArgs({ name: 'userRemoved', ...args });

  return useMutation(
    ({ userId }) => api.removeContractTeamMember({
      currentCompanyId,
      contractId,
      userId,
    }),
    mutationArgs,
  );
};

export const useGetOtherSectionFieldLabelChangeCommands = () => {
  const contract = useContractData();
  const { isTemplate } = useContractState();

  return useCallback((sectionId: string, fields?: LineItemExchangeFields) => {
    if (!fields) {
      return [];
    }

    const fieldLabelByFieldsetId = omitBy(
      mapValues(
        groupBy(fields, field => field._id.split(':')[0]),
        (fields) => (fields[0] as unknown as { label: string }).label,
      ),
      (field, fieldsetId) => otherSectionFieldsetIdsToIgnore.includes(fieldsetId),
    );

    const otherLineItemSections = filter(
      contract.sectionById,
      section => section.type === SectionType.LINE_ITEMS && section._id !== sectionId,
    );

    const commands = [];

    for (const section of otherLineItemSections) {
      const sectionExchangeDefs = getSectionExchangeDefs(section, contract);
      const firstLineItemExchangeDef = sectionExchangeDefs.find(isLineItemExchangeDef as any);

      if (!firstLineItemExchangeDef) {
        continue;
      }

      const areFieldLabelsEqual = checkAreFieldLabelsEqual(
        firstLineItemExchangeDef as any,
        fieldLabelByFieldsetId,
      );

      if (!areFieldLabelsEqual) {
        const updatedSectionFields = getUpdatedSectionFields(
          firstLineItemExchangeDef as any,
          fieldLabelByFieldsetId,
        );

        const exchangeDefs = sectionExchangeDefs.filter(isLineItemExchangeDef as any);

        const updatedExchangeDefs = exchangeDefs.map(exchangeDef => ({
          ...exchangeDef,
          fields: updatedSectionFields,
        }));

        const exchangeDefKeys = compact([
          ...lineItemExchangeBaseDefKeys,
          ...getLineItemFieldValueKeys(exchangeDefs),
        ]);

        const sectionCommands = getCommands({
          mapping: 'exchangeDef',
          next: updatedExchangeDefs,
          previous: exchangeDefs,
          keys: exchangeDefKeys,
          isTemplate,
        });

        commands.push(...sectionCommands);
      }
    }

    return commands;
  }, [contract, isTemplate]);
};
