import { upperFirst } from 'lodash';
import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { nestCells } from '@deepstream/ui/nestCells';
import { Table } from '@deepstream/ui/Table';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { withProps } from '@deepstream/ui-utils/withProps';
import { ContractOverview } from '@deepstream/common/contract';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { CapitalizeCell } from '../../CapitalizeCell';
import { ContractLinkCell } from './ContractLinkCell';
import { CompanyLinkCell } from '../../CompanyLinkCell';
import { RequestLink } from '../../RequestLink';

type ContractsTableProps = {
  userId: string;
  contractOverviews: ContractOverview[];
};

export const LinkedRequestCell = ({ row, cell }: CellProps<ContractOverview>) => {
  const contractOverview = row.original;
  const { requestId } = contractOverview;

  return requestId ? (
    <RequestLink requestId={requestId}>
      {cell.value}
    </RequestLink>
  ) : (
    <EmDash />
  );
};

const getCreatorCompanyId = (contractOverview: ContractOverview) => contractOverview.senders[0]._id;

export const AdminUserContractsTable = ({ userId, contractOverviews }: ContractsTableProps) => {
  const { isExtraLarge, isLarge } = useDeviceSize();

  const columns = useMemo(
    () => [
      {
        Header: 'Contract name',
        accessor: 'name',
        Cell: nestCells(TruncateCell, ContractLinkCell),
        sortType: 'caseInsensitive',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        Header: 'Sender counterparty',
        accessor: 'senders[0].name',
        Cell: nestCells(TruncateCell, withProps(CompanyLinkCell, { getCompanyId: getCreatorCompanyId })),
        sortType: 'caseInsensitive',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        Header: 'Company role',
        accessor: contractOverview => {
          const isRecipient = contractOverview.recipient?.userIds.includes(userId);
          return isRecipient ? 'Recipient' : 'Sender';
        },
        Cell: TruncateCell,
        width: 120,
        sortType: 'caseInsensitive',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: 'status',
        Cell: CapitalizeCell,
        width: 100,
        sortType: 'caseInsensitive',
        Filter: withProps(FilterSelect, { itemToString: upperFirst }),
        filter: filterMultipleValues,
      },
      {
        id: 'linkedRequest',
        Header: 'Linked request',
        accessor: 'requestSubject',
        Cell: LinkedRequestCell,
        sortType: 'caseInsensitive',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [userId],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={contractOverviews}
        isSortable
        noFilteredDataPlaceholder="No contracts match chosen filters"
        hiddenColumns={!isLarge && !isExtraLarge ? ['status', 'linkedRequest'] : []}
      />
    </BasicTableStyles>
  );
};
