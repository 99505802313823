import * as React from 'react';
import { Flex, Text } from 'rebass/styled-components';

import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { APP_ADMIN_LOCALE } from '@deepstream/common/constants';

import { getPrimarySubtag } from '@deepstream/utils';
import { EditLanguageModal } from './EditLanguageModal';

type LanguagesTableProps = {
  languages: any[];
};

export const LanguagesTable: React.FC<LanguagesTableProps> = ({ languages }) => {
  const editLanguageModal = useModalState();
  const [selectedRow, setSelectedRow] = React.useState(null);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: TruncateCell,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <Flex alignItems="center">
            <Icon icon={value.split(' ')[0] === 'Enabled' ? 'check' : 'close'} mr={2} fixedWidth />
            <Text flex={1}>{value}</Text>
          </Flex>
        ),
      },
      {
        id: 'actions',
        accessor: '_id',
        Cell: ({ cell }) => {
          return !cell.row.original.isDefault ? (
            <Button
              small
              iconLeft="pencil"
              onClick={(event) => {
                event.stopPropagation();
                setSelectedRow(cell.row.original);
                editLanguageModal.open();
              }}
              variant="primary-outline"
            >
              Edit
            </Button>
          ) : null;
        },
        textAlign: 'right',
        width: 200,
        disableSortBy: true,
      },
    ],
    [editLanguageModal],
  );

  const languageNames = new Intl.DisplayNames([APP_ADMIN_LOCALE], { type: 'language' });

  return (
    <BasicTableStyles hoverCursor="default" hoverBackgroundColor="default">
      <Table
        columns={columns}
        data={languages.map(language => ({
          ...language,
          status: language.isEnabled
            ? language.isDefault ? 'Enabled (Default)' : 'Enabled'
            : 'Disabled',
          name: `${languageNames.of(getPrimarySubtag(language.code))} [${language.code}]`,
        }))}
        isSortable
      />
      {selectedRow && (
        <EditLanguageModal
          language={selectedRow}
          isOpen={editLanguageModal.isOpen}
          onCancel={editLanguageModal.close}
          onSave={editLanguageModal.close}
          onRequestClose={editLanguageModal.close}
        />
      )}
    </BasicTableStyles>
  );
};
