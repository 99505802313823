import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { contractRoute } from '../../AppRouting';
import { AdminTableLink, AdminTableLinkProps } from '../../AdminTableLink';

type ContractLinkProps =
  Omit<AdminTableLinkProps, 'to' | 'params'> &
  { contractId: string };

export const ContractLink = ({ contractId, ...props }: ContractLinkProps) => (
  <AdminTableLink
    to={contractRoute.to}
    params={{ contractId }}
    onClick={stopPropagation}
    {...props}
  />
);
