import * as React from 'react';
import { get, isFinite } from 'lodash';
import { Box, BoxProps } from 'rebass/styled-components';
import { DateFormat, localeFormatDate } from '@deepstream/utils';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { useSession } from './auth';
import { useCurrentUserLocale } from './useCurrentUser';
import { User } from './types';

export type DatetimeValue = string | number | Date;

export const momentToDateFnsDateFormat: Record<string, DateFormat> = {
  'DD MMM YYYY': DateFormat.DD_MMM_YYYY,
  'DD-MM-YYYY': DateFormat.DD_MM_YYYY_DASH,
  'MM/DD/YYYY': DateFormat.MM_DD_YYYY_SLASH,
  'YYYY-MM-DD': DateFormat.YYYY_MM_DD_DASH,
};

export const momentToDateFnsDateTimeFormat: Record<string, DateFormat> = {
  'DD MMM YYYY': DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ,
  'DD-MM-YYYY': DateFormat.DD_MM_YYYY_HH_MM_A_ZZZ_DASH,
  'MM/DD/YYYY': DateFormat.MM_DD_YYYY_HH_MM_A_ZZZ_SLASH,
  'YYYY-MM-DD': DateFormat.YYYY_MM_DD_HH_MM_A_ZZZ_DASH,
};

export const getFormattedDate = (value: DatetimeValue, format: DateFormat, locale: string) => {
  const parsedDate = new Date(value);

  return value && isFinite(parsedDate.valueOf())
    ? localeFormatDate(parsedDate, format, { locale })
    : null;
};

// TODO merge Datetime and Datetime2
export const Datetime2 = ({
  value,
  format,
  EmptyCell = EmDash,
  showTooltip,
}: {
  value?: DatetimeValue;
  format: DateFormat;
  EmptyCell?: React.FC<any>;
  showTooltip?: boolean;
}) => {
  const locale = useCurrentUserLocale();
  const formattedValue = getFormattedDate(value, format, locale);

  return (
    <Tooltip content={showTooltip && formattedValue}>
      <>{formattedValue ?? <EmptyCell />}</>
    </Tooltip>
  );
};

export const getDateFormat = (isCondensed: boolean, onlyDate: boolean, onlyTime: boolean, user: User) => {
  if (onlyDate) {
    return isCondensed
      ? DateFormat.DD_MM_YY_SLASH
      : (momentToDateFnsDateFormat[get(user, 'preferences.dateFormat')] || DateFormat.DD_MM_YYYY_SLASH);
  } else if (onlyTime) {
    return isCondensed
      ? DateFormat.HH_MM_A
      : DateFormat.HH_MM_A_ZZZ;
  } else {
    return isCondensed
      ? DateFormat.DD_MM_YYYY_HH_MM_A_SLASH
      : (momentToDateFnsDateTimeFormat[get(user, 'preferences.dateFormat')] || DateFormat.DD_MM_YYYY_SLASH);
  }
};

export const Datetime = ({
  value,
  onlyDate = false,
  onlyTime = false,
  isCondensed = false,
  ...props
}: Omit<BoxProps, 'value'> & {
  value?: DatetimeValue;
  onlyDate?: boolean;
  onlyTime?: boolean;
  isCondensed?: boolean;
}) => {
  const { user } = useSession();

  const format = getDateFormat(isCondensed, onlyDate, onlyTime, user);

  return (
    <Box {...props}>
      <Datetime2 value={value} format={format} />
    </Box>
  );
};
