import * as React from 'react';
import { useQueryClient } from 'react-query';
import { EntityTeamMemberOverview, isOnlyQuestionnaireOwner, isOnlyRequestOwner } from '@deepstream/common/user-utils';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useToaster } from '@deepstream/ui/toast';
import { useApi, useAdminApi } from '@deepstream/ui/api';
import { useIsOnlyAdmin } from '@deepstream/ui/modules/Company/TeamManagement/useIsOnlyAdmin';
import { useCompanyUsers } from '@deepstream/ui/modules/Company/TeamManagement/useCompanyUsers';
import { useMutation } from '@deepstream/ui/useMutation';
import { User } from '@deepstream/ui/types';
import { callAll } from '@deepstream/utils/callAll';
import { RemoveSoleOwnerModal } from '@deepstream/ui/modules/Company/TeamManagement/RemoveSoleOwnerModal';
import { useWaitForProcessingTask } from '@deepstream/ui/useWaitForProcessingTask';
import { isEmpty } from 'lodash';
import { useAdminNavigation } from './AppRouting';

type RemoveUserFromCompanyButtonProps = {
  user: User;
  companyId: string;
};

export const RemoveUserFromCompanyButton: React.FC<RemoveUserFromCompanyButtonProps> = ({
  user,
  companyId,
}) => {
  const userId = user._id;
  const adminApi = useAdminApi();
  const api = useApi();
  const navigation = useAdminNavigation();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const deleteConfirmationModal = useModalState();
  const warningModal = useModalState();
  const [soleOwnerQuestionnaires, setSoleOwnerQuestionnaires] = React.useState<EntityTeamMemberOverview[]>([]);
  const [soleOwnerRequests, setSoleOwnerRequests] = React.useState<EntityTeamMemberOverview[]>([]);
  const [soleOwnerContracts, setSoleOwnerContracts] = React.useState<EntityTeamMemberOverview[]>([]);
  const isOnlyAdmin = useIsOnlyAdmin({ userId, companyId });
  const { isSuccess } = useCompanyUsers(companyId);
  const waitForProcessingTask = useWaitForProcessingTask();

  const [getEntityTeamMemberOverviews] = useMutation(
    api.getEntityTeamMemberOverviews,
    {
      onError: () => toaster.error('Could not get entity team member overviews'),
      onSettled: () => queryClient.invalidateQueries(['company', { companyId }]),
    },
  );

  const handleRemoveUserClick = async () => {
    const entityTeamMemberOverviews = await getEntityTeamMemberOverviews({
      companyId,
      userId,
    });

    const soleOwnerQuestionnaires = entityTeamMemberOverviews.filter(isOnlyQuestionnaireOwner);
    const soleOwnerRequests = entityTeamMemberOverviews.filter(isOnlyRequestOwner);
    const soleOwnerContracts = entityTeamMemberOverviews.filter(isOnlyRequestOwner);

    if (
      isEmpty(soleOwnerQuestionnaires) &&
      isEmpty(soleOwnerRequests) &&
      isEmpty(soleOwnerContracts)
    ) {
      deleteConfirmationModal.open();
    } else {
      if (soleOwnerQuestionnaires?.length) {
        setSoleOwnerQuestionnaires(soleOwnerQuestionnaires);
      }
      if (soleOwnerRequests?.length) {
        setSoleOwnerRequests(soleOwnerRequests);
      }
      if (soleOwnerContracts?.length) {
        setSoleOwnerContracts(soleOwnerContracts);
      }
      warningModal.open();
    }
  };

  const [removeUserFromCompany] = useMutation(
    (payload) => waitForProcessingTask({
      command: () => adminApi.removeUserFromCompany(payload),
    }),
    {
      onSuccess: callAll(
        () => toaster.success('User successfully removed from company'),
        () => navigation.navigateToCompany(companyId),
      ),
      onError: () => toaster.error('An error occurred when trying to remove the user'),
    },
  );

  const removeUser = React.useCallback(
    async ({
      user,
      questionnaireReplacementUserId,
      rfxReplacementUserId,
      contractReplacementUserId,
    }: {
      user: User;
      questionnaireReplacementUserId?: string;
      rfxReplacementUserId?: string;
      contractReplacementUserId?: string;
    }) => {
      await removeUserFromCompany({
        userId: user._id,
        companyId,
        questionnaireReplacementUserId,
        rfxReplacementUserId,
        contractReplacementUserId,
      });
    },
    [companyId, removeUserFromCompany],
  );

  return (
    <>
      <Button
        variant="danger"
        onClick={handleRemoveUserClick}
        disabled={!isSuccess}
      >
        Remove
      </Button>
      <Dialog
        heading="Remove user from company?"
        style={{ content: { maxWidth: '500px' } }}
        body={isOnlyAdmin ? (
          <MessageBlock variant="warn" my={2}>
            No email or notification will be sent. This user is the only admin and will also be removed from all questionnaires, requests and contracts belonging to this company.
          </MessageBlock>
        ) : (
          <MessageBlock variant="info" my={2}>
            No email or notification will be sent. This user will also be removed from all questionnaires, requests and contracts belonging to this company.
          </MessageBlock>
        )}
        okButtonText="Remove"
        okButtonVariant="danger"
        isOpen={deleteConfirmationModal.isOpen}
        onOk={() => removeUserFromCompany({
          userId,
          companyId,
        }, {
          onSuccess: deleteConfirmationModal.close,
        })}
        onCancel={deleteConfirmationModal.close}
      />
      <RemoveSoleOwnerModal
        isOpen={warningModal.isOpen}
        soleOwnerQuestionnaires={soleOwnerQuestionnaires}
        soleOwnerRequests={soleOwnerRequests}
        soleOwnerContracts={soleOwnerContracts}
        user={user}
        companyId={companyId}
        close={warningModal.close}
        removeUser={removeUser}
      />
    </>
  );
};
