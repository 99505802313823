import { constant, isEmpty, noop, uniq, without } from 'lodash';
import * as React from 'react';

export enum GridSelectionState {
  NONE = 'none',
  SOME = 'some',
  ALL = 'all',
}

export type GridSelectionContextValue = {
  isSelected: (rowId: string) => boolean;
  // select: (rowId: string) => void;
  // deselect: (rowId: string) => void;
  toggle: (rowId: string) => void;
  // setSelection: (rowIds: string[]) => void;
  getGridSelectionState: () => GridSelectionState;
  setGridSelectionState: (state: GridSelectionState.ALL | GridSelectionState.NONE) => void;
  selectedRowIds: string[];
};

const GridSelectionContext = React.createContext<GridSelectionContextValue>({
  isSelected: constant(false),
  // select: noop,
  // deselect: noop,
  toggle: noop,
  // setSelection: noop,
  getGridSelectionState: () => GridSelectionState.NONE,
  setGridSelectionState: noop,
  selectedRowIds: [],
});

// TODO make generic; support range selection and rowId selection

export const GridSelectionProvider = ({ children, allRowIds }: { children: React.ReactChild; allRowIds: string[] }) => {
  const [selectedRowIds, setSelectedRowIds] =
    React.useState<string[]>([]);

  const value = React.useMemo(() => {
    return {
      isSelected: (rowId: string) => selectedRowIds?.includes(rowId),
      // select: (rowId: string) => setSelectedRowIds(rowIds => uniq([...rowIds, rowId])),
      // deselect: (rowId: string) => setSelectedRowIds(rowIds => without(rowIds, rowId)),
      selectedRowIds,
      toggle: (rowId: string) => setSelectedRowIds(rowIds => {
        return rowIds.includes(rowId)
          ? without(rowIds, rowId)
          : uniq([...rowIds, rowId]);
      }),
      getGridSelectionState: () => {
        if (isEmpty(selectedRowIds)) {
          return GridSelectionState.NONE;
        } else if (selectedRowIds.length === allRowIds.length) {
          return GridSelectionState.ALL;
        } else {
          return GridSelectionState.SOME;
        }
      },
      setGridSelectionState: (state: GridSelectionState.ALL | GridSelectionState.NONE) => {
        if (state === GridSelectionState.ALL) {
          setSelectedRowIds(allRowIds);
        } else {
          setSelectedRowIds([]);
        }
      },
    };
  }, [allRowIds, selectedRowIds, setSelectedRowIds]);

  return (
    <GridSelectionContext.Provider value={value}>
      {children}
    </GridSelectionContext.Provider>
  );
};

export const useGridSelection = () => {
  const state = React.useContext(GridSelectionContext);
  if (!state) throw new Error('No grid selection context found');
  return state;
};
