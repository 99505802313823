import { localeFormatFactorAsPercent } from '@deepstream/utils';
import { APP_ADMIN_LOCALE } from '@deepstream/common/constants';
import { FeatureAdoption } from './ClientAdoptionGrid';

export const renderPercentage = ({ count, total }) => {
  if (total === 0) {
    return 'N/A';
  }

  return localeFormatFactorAsPercent(count / total, { locale: APP_ADMIN_LOCALE, decimalPlaces: 1 });
};

export const getFeatureAdoptionInfo = (featureAdoption: FeatureAdoption[]) => {
  const count = featureAdoption.filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length;
  const total = featureAdoption.length;

  return `${count} of ${total} clients`;
};
