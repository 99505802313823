import { CellProps } from 'react-table';
import { QuestionnaireTemplateOverview } from '@deepstream/common/preQual';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { AdminTableLink, AdminTableLinkProps } from '../../AdminTableLink';
import { questionnaireTemplateRoute } from '../../AppRouting';

type QuestionnaireTemplateLinkProps =
  Omit<AdminTableLinkProps, 'to' | 'params'> &
  { templateId: string };

const QuestionnaireTemplateLink = ({ templateId, ...props }: QuestionnaireTemplateLinkProps) => (
  <AdminTableLink
    to={questionnaireTemplateRoute.to}
    params={{ templateId }}
    onClick={stopPropagation}
    {...props}
  />
);

export const QuestionnaireTemplateLinkCell = ({ row, cell }: CellProps<QuestionnaireTemplateOverview>) => (
  <QuestionnaireTemplateLink templateId={row.original._id}>
    {cell.value || '(Not set)'}
  </QuestionnaireTemplateLink>
);
