import * as React from 'react';
import { Box, Flex, SxStyleProp } from 'rebass/styled-components';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { IconValue } from '@deepstream/common/icons';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { renderPercentage } from '../reports/utils';

export const ProductDashboardSectionHeader: React.FC<{
  heading: string;
  icon: IconValue;
  tooltip?: React.ReactNode;
  isIconRegular?: boolean;
  right?: React.ReactNode;
  sx?: SxStyleProp;
}> = ({ heading, icon, tooltip, isIconRegular, right, sx = {} }) => {
  return (
    <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', ...sx }}>
      <Flex as="h3" fontSize={4} fontWeight={500} mb={3} alignItems="baseline">
        <Icon icon={icon} mr={2} fixedWidth regular={isIconRegular} />
        {heading}

        {tooltip && (
          <Tooltip content={tooltip}>
            <Icon icon="question-circle-o" ml={2} fixedWidth fontSize={1} />
          </Tooltip>
        )}
      </Flex>

      {right && (
        <Box>
          {right}
        </Box>
      )}
    </Flex>
  );
};

export const ProductDashboardSectionContainer: React.FC = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'inline-grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        columnGap: theme.space[3],
        width: '100%',
      }}
    >
      {children}
    </Box>
  );
};

export const ProductDashboardSection: React.FC<{
  heading: string;
  icon: IconValue;
  tooltip?: React.ReactNode;
  isIconRegular?: boolean;
  right?: React.ReactNode;
  headerSx?: SxStyleProp;
}> = ({ icon, heading, tooltip, children, isIconRegular, right, headerSx }) => {
  return (
    <PanelPadding>
      <ProductDashboardSectionHeader
        heading={heading}
        icon={icon}
        tooltip={tooltip}
        isIconRegular={isIconRegular}
        right={right}
        sx={headerSx}
      />
      <ProductDashboardSectionContainer>
        {children}
      </ProductDashboardSectionContainer>
    </PanelPadding>
  );
};

export const ProductDashboardMetric = ({ label, value, subValue, onViewMoreClick }: {
  label: string;
  value: string | number;
  subValue?: string;
  onViewMoreClick?: () => void;
}) => {
  return (
    <Flex
      sx={{
        border: 'lightGray2',
        borderRadius: '4px',
        height: '135px',
        gap: '4px',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      p="20px"
    >
      <Box
        as="label"
        color="subtext"
        fontSize={2}
        sx={{ letterSpacing: '1px', textTransform: 'uppercase' }}
      >
        {label}
      </Box>
      <Box fontSize="28px" fontWeight={500}>
        {value}
      </Box>
      {onViewMoreClick ? (
        <Box
          fontSize="14px"
          fontWeight={500}
          onClick={onViewMoreClick}
          color="primary"
          sx={{ cursor: 'pointer' }}
        >
          {subValue}
          <Icon
            icon="chevron-right"
            ml={1}
          />
        </Box>
        ) : (
          <Box fontSize="14px" fontWeight={500}>
            {subValue}
          </Box>
        )}
    </Flex>
  );
};

export const ProductDashboardFunnelMetric = ({ label, mainValue, proceededValues, totalValue, onViewMoreClick, tooltip }: {
  label: string;
  mainValue: number;
  proceededValues?: {
    proceeded: number;
    notProceeded: number;
  };
  totalValue?: number;
  tooltip?: string;
  onViewMoreClick: () => void;
}) => {
  return (
    <Flex
      sx={{
        border: 'lightGray2',
        borderRadius: '4px',
        height: '135px',
        gap: '4px',
        justifyContent: 'left',
        flexDirection: 'column',
      }}
      p="20px"
    >
      <Box
        color="primary"
        onClick={onViewMoreClick}
        fontSize={2}
        fontWeight={500}
        sx={{ letterSpacing: '1px', textTransform: 'uppercase', cursor: 'pointer' }}
        mb="16px"
      >
        {label}
        {tooltip && (
          <Tooltip content={tooltip} placement="top">
            <Icon icon="question-circle-o" ml={1} fixedWidth fontSize={2} />
          </Tooltip>
        )}
        <Icon
          icon="chevron-right"
          ml={1}
        />
      </Box>
      <Flex
        justifyContent="space-between"
      >
        <Box fontSize="28px" fontWeight={500} pt="5px">
          {mainValue}
        </Box>
        {proceededValues ? (
          <Flex
            justifyContent="center"
            flexDirection="column"
          >
            <Flex justifyContent="space-between" flexDirection="row">
              <Box fontSize="18px" fontWeight={500} pr={3}>
                <Icon color="success" icon="arrow-right" regular mr={2} />
                {proceededValues.proceeded}
              </Box>
              <Box fontSize="14px" fontWeight={400} color="subtext" pt={1}>
                {renderPercentage({
                  count: proceededValues.proceeded,
                  total: mainValue,
                })}
              </Box>
            </Flex>
            <Flex justifyContent="space-between" flexDirection="row">
              <Box fontSize="18px" fontWeight={500} pr={3} >
                <Icon color="danger" icon="times" regular mr={2} />
                {proceededValues.notProceeded}
              </Box>
              <Box fontSize="14px" fontWeight={400} color="subtext" pt={1} >
                {renderPercentage({
                  count: proceededValues.notProceeded,
                  total: mainValue,
                })}
              </Box>
            </Flex>
          </Flex>
        ) : (
          <Flex
            justifyContent="center"
            flexDirection="column"
          >
            <Box fontSize="18px" fontWeight={500} textAlign="right">
              {renderPercentage({
                count: mainValue,
                total: totalValue,
              })}
            </Box>
            <Box fontSize="14px" fontWeight={400} color="subtext">
              funnel conversion rate
            </Box>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
