import * as React from 'react';

export type RequestsNavigation = {
  navigateToSenderRequest: (arg: { requestId: string, isDraft: boolean }) => void;
  navigateToReceivedRequest: (requestId: string) => void;
  navigateToTemplatePreview: (templateId: string) => void;
  navigateToTemplateEdit: (templateId: string) => void;
  navigateToRequestsTab: (tab?: string, replace?: boolean) => void;
};

const RequestsNavigationContext = React.createContext<RequestsNavigation | null>(null);

export const RequestsNavigationProvider: React.FC<RequestsNavigation> = ({
  navigateToSenderRequest,
  navigateToReceivedRequest,
  navigateToTemplatePreview,
  navigateToTemplateEdit,
  navigateToRequestsTab,
  ...props
}) => {
  const contextValue = React.useMemo(
    () => ({
      navigateToSenderRequest,
      navigateToReceivedRequest,
      navigateToTemplatePreview,
      navigateToTemplateEdit,
      navigateToRequestsTab,
    }),
    [
      navigateToReceivedRequest,
      navigateToSenderRequest,
      navigateToTemplateEdit,
      navigateToTemplatePreview,
      navigateToRequestsTab,
    ],
  );

  return (
    <RequestsNavigationContext.Provider value={contextValue} {...props} />
  );
};

export const useRequestsNavigation = () => {
  const requestsNavigation = React.useContext(RequestsNavigationContext);
  if (!requestsNavigation) throw new Error('no requestsNavigation found');
  return requestsNavigation;
};
