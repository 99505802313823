import * as React from 'react';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { AdminTableLink, AdminTableLinkProps } from './AdminTableLink';
import { userRoute } from './AppRouting';

type UserLinkProps =
  Omit<AdminTableLinkProps, 'to'> &
  { userId: string };

export const UserLink: React.FC<UserLinkProps> = ({ userId, ...props }) => (
  <AdminTableLink
    to={userRoute.to}
    params={{ userId }}
    onClick={stopPropagation}
    {...props}
  />
);
