import { omit, sumBy } from 'lodash';

export type BidProgress = {
  noAction: number;
  complete: number;
  awaitingRecipient: number;
  awaitingSender: number;
  awaitingTeam: number;
  /**
   * The number of complete multi-stage line items in a no longer
   * active stage.
   * Counted in a dedicated property because we need to exclude those
   * counts when calculating the overall request progress (which should
   * only account for the most recent stage of multi-stage line items).
   */
  previousStageComplete?: number;
  /**
   * The number of incomplete multi-stage line items in a no longer
   * active stage.
   * Counted in a dedicated property because we need to exclude those
   * counts when calculating the overall request progress (which should
   * only account for the most recent stage of multi-stage line items).
   */
  previousStageNoResponse?: number;
  obsolete?: number;
};

export const emptyBidProgress = {
  noAction: 0,
  complete: 0,
  awaitingTeam: 0,
  awaitingRecipient: 0,
  awaitingSender: 0,
  obsolete: 0,
} as const satisfies BidProgress;

export const emptyBidProgressWithPreviousStageResponses = {
  noAction: 0,
  complete: 0,
  awaitingTeam: 0,
  awaitingRecipient: 0,
  awaitingSender: 0,
  previousStageComplete: 0,
  previousStageNoResponse: 0,
  obsolete: 0,
} as const satisfies BidProgress;

export const omitPreviousStageProgress = (progress: BidProgress) => omit(progress, ['previousStageComplete', 'previousStageNoResponse']);

export const getTotalResponseItemCount = ({
  awaitingRecipient = 0,
  awaitingSender = 0,
  complete = 0,
  awaitingTeam = 0,
  previousStageComplete = 0,
  previousStageNoResponse = 0,
}: BidProgress) => {
  return awaitingRecipient + awaitingSender + complete + awaitingTeam + previousStageComplete + previousStageNoResponse;
};

export const getCompleteResponseItemCount = ({
  complete = 0,
  previousStageComplete = 0,
}: BidProgress) => {
  return complete + previousStageComplete;
};

export const getExchangesProgressItems = (progress: BidProgress, isRecipient: boolean) => {
  const orderedProgressItems = [
    {
      id: 'actionRequired',
      value: isRecipient
        ? progress.awaitingRecipient
        : progress.awaitingSender,
    },
    {
      id: 'waitingForTeam',
      value: progress.awaitingTeam,
    },
    isRecipient
      ? {
        id: 'waitingForBuyer',
        value: progress.awaitingSender,
      }
      : {
        id: 'waitingForSupplier',
        value: progress.awaitingRecipient,
      },
    {
      id: 'complete',
      value: (progress.complete || 0) + (progress.previousStageComplete || 0),
    },
    {
      id: 'informationOnly',
      value: progress.noAction,
    },
    {
      id: 'noResponse',
      value: progress.previousStageNoResponse,
    },
    {
      id: 'obsolete',
      value: progress.obsolete,
    },
  ];

  const filteredProgressItems = orderedProgressItems.filter(item => item.value) as {
      id: string;
      value: number;
  }[];

  return [
    {
      id: 'total',
      value: sumBy(filteredProgressItems, item => item.value),
    },
    ...filteredProgressItems,
  ];
};
