import { BidOutcomeStatus } from '@deepstream/common/rfq-utils';

export const iconPropsByBidOutcomeStatus = {
  [BidOutcomeStatus.PENDING]: {
    icon: 'circle-dashed',
    color: 'subtext',
  },
  [BidOutcomeStatus.AWARDED]: {
    icon: 'trophy',
    color: 'warning',
  },
  [BidOutcomeStatus.PARTIALLY_AWARDED]: {
    icon: 'trophy',
    color: 'warning',
  },
  [BidOutcomeStatus.UNSUCCESSFUL]: {
    icon: 'circle-xmark',
    regular: true,
    color: 'subtext',
  },
} as const;
