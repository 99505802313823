import { isBoolean, isEmpty, isFinite, isNumber, isString, without } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from './ui/MultiSelect';

const sorter = (values) => {
  if (isEmpty(values)) {
    return [];
  }
  if (values.every(value => {
    const parsedDate = new Date(value);

    return value && isFinite(parsedDate.valueOf());
  })) {
    return values.sort((a: any, b: any) => new Date(b).getTime() - new Date(a).getTime());
  }
  if (values.every(isNumber)) {
    return values.sort((a, b) => a - b);
  }
  if (values.every(isString)) {
    return values.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);
  }
  return values.sort();
};

export const FilterSelect: React.FC<any> = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  itemToString,
  transformSelectedValues,
}) => {
  const { t } = useTranslation('general');
  // Calculate the options for filtering
  // using the preFilteredRows
  const availableOptions = React.useMemo(() => {
    const options = new Set();

    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id] ?? '-');
    });

    return options.has('-')
      ? ['-', ...sorter(without(Array.from(options.values()), '-'))]
      : sorter([...options.values()]);
  }, [preFilteredRows, id]);

  const castValues = (selectedItem) => {
    if (transformSelectedValues) {
      return transformSelectedValues(selectedItem);
    }
    return selectedItem || '-';
  };

  const itemToStringFunc = (param) => {
    if (itemToString) {
      if ((isBoolean(param) || param) && itemToString(param)) {
        return itemToString(param);
      }
      return param || '-';
    }
    return '-';
  };

  return (
    <MultiSelect
      variant="secondary-outline"
      onChange={setFilter}
      items={availableOptions}
      itemToString={itemToStringFunc}
      buttonWidth={140}
      selectedItems={filterValue || []}
      getButtonIcon={(selectedItems) =>
        selectedItems?.length ? 'filter' : 'plus'
      }
      placeholder={t('selectFilters')}
      getButtonText={(selectedItems) =>
        selectedItems?.length >= 2
          ? `${selectedItems?.length} filters`
          : selectedItems?.length === 1
            ? `${castValues(selectedItems[0])}`
            : null
      }
    />
  );
};

export const filterMultipleValues = (
  rows: any[],
  columnId: any,
  filterValues: string[],
) =>
  rows.filter((row) => {
    const cellValue: string = row.values[columnId];

    // Filtering doesn't work for null values, so we use a dash character instead
    const shouldFilterByMissingValue = filterValues.includes('-') && !cellValue;

    return (
      filterValues.length === 0 ||
      filterValues.includes(cellValue) ||
      shouldFilterByMissingValue
    );
  });
