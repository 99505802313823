import { useMemo, useState, useCallback } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { size, isEmpty, map, first, get, orderBy, some, uniq, intersection, last, reject, every, matches, overEvery, flatten, isNull, compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { isCompanySuperUser, EntityTeamMemberOverview, RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { SortingDirection } from '@deepstream/common';

import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useModalState } from '../../../ui/useModalState';
import { Loading } from '../../../ui/Loading';
import { renderName } from '../../../RequestsTable';
import { LabeledSorting, useLocalStorageSortProps } from '../../../sorting';
import { ClearFiltersButton, useLocalStorageFilterProps } from '../../../filtering';
import { SelectDropdownMenu } from '../../../ui/MultiSelect';
import { MIN_CELL_HEIGHT } from '../../../FieldsCell';
import { User } from '../../../types';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { EmptyTableMessage } from '../../../ui/EmptyTableMessage';
import { useApi, wrap } from '../../../api';
import { useToaster } from '../../../toast';
import { useMutation } from '../../../useMutation';
import { Notification } from '../../Notifications/types';
import { useNotificationSubject } from '../../Notifications/useNotificationSubject';
import { useUserFlags } from '../../../UserFlagsContext';
import { TeamUsersTable } from './TeamUsersTable';
import { AddUserToCompanyModal } from './AddUserToCompanyModal';
import { useCompanyUsers } from './useCompanyUsers';
import { UserCount } from './UserCount';
import { EditPermissionsModal } from './EditPermissionsModal';
import { orderedPermissions } from './utils';
import { GlobalRole } from './types';

const NO_PERMISSIONS = 'none';

const useRoleFilterItems = (users: User[] | undefined, currentCompanyId: string) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!users) {
      return;
    }

    const hasSuperUser = users.some(user => (
      isCompanySuperUser(user, currentCompanyId)
    ));
    const hasFullUser = users.some(user => (
      user.roleRestrictionProfiles?.[currentCompanyId] === RoleRestrictionProfile.FULL_USER
    ));
    const hasStakeholder = users.some(user => (
      user.roleRestrictionProfiles?.[currentCompanyId] === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER
    ));

    return compact([
      hasSuperUser
        ? {
          globalRole: GlobalRole.SUPER_USER,
          name: t('user.globalRole.superUser'),
        }
        : null,
      hasFullUser
        ? {
          globalRole: GlobalRole.FULL_USER,
          name: t('user.globalRole.fullUser'),
        }
        : null,
      hasStakeholder
        ? {
          globalRole: GlobalRole.AUTHORIZED_STAKEHOLDER,
          name: t('user.globalRole.authorizedStakeholder'),
        } : null,
    ]);
  }, [t, currentCompanyId, users]);
};

type PermissionFilterItem = { permission: string; name: string };

const usePermissionFilterItems = (users: User[] | undefined, currentCompanyId: string) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!users) {
      return;
    }

    const allUserPermissions = map(users, user => {
      const roles = user.roles[currentCompanyId] ?? {};
      return map(roles, (hasPermission, role) =>
        hasPermission ? role : null,
      );
    });

    const uniqueUserPermissions = uniq(flatten(allUserPermissions));

    const permissionFilterItems = map(
      intersection(orderedPermissions, uniqueUserPermissions) as string[],
      (permission) => ({
        permission,
        name: t(`teamManagement.permissions.${permission}`),
      }),
    );

    const hasUserWithNoPermissions = some(
      allUserPermissions,
      userPermissions => every(userPermissions, isNull),
    );

    if (hasUserWithNoPermissions) {
      return [
        ...permissionFilterItems,
        {
          permission: NO_PERMISSIONS,
          name: t('general.none'),
        },
      ];
    } else {
      return permissionFilterItems;
    }
  }, [t, currentCompanyId, users]);
};

const useSortItems = (): LabeledSorting[] => {
  const { t } = useTranslation();

  return useMemo(() => [
    { label: t('general.sorting.nameAscending'), accessor: 'name', direction: SortingDirection.ASC },
    { label: t('general.sorting.nameDescending'), accessor: 'name', direction: SortingDirection.DESC },
  ], [t]);
};

type TeamUsersProps = {
  currentUser: any;
};

export const TeamUsers = ({ currentUser }: TeamUsersProps) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const addUserModal = useModalState();
  const { data: users, status } = useCompanyUsers(currentCompanyId);
  const api = useApi();
  const toaster = useToaster();
  const editPermissionsModal = useModalState();
  const [entityTeamMemberOverviews, setEntityTeamMemberOverviews] = useState<EntityTeamMemberOverview[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { belongsToPayingCompany } = useUserFlags();

  const { data: entitiesCount, status: entitiesCountStatus } = useQuery(
    ['companyMemberEntitiesCounts', { companyId: currentCompanyId }],
    wrap(api.getCompanyMemberEntitiesCounts),
  );

  const [getEntityTeamMemberOverviews] = useMutation(
    api.getEntityTeamMemberOverviews,
    {
      onError: () =>
        toaster.error(t('teamManagement.errors.getCompanyUserRequests')),
    },
  );

  const openEditPermissionsModal = useCallback(
    async (user: User) => {
      const entityTeamMemberOverviews = await getEntityTeamMemberOverviews({
        companyId: currentCompanyId,
        userId: user._id,
      });

      setEntityTeamMemberOverviews(entityTeamMemberOverviews);
      setSelectedUser(user);
      editPermissionsModal.open();
    },
    [currentCompanyId, getEntityTeamMemberOverviews, editPermissionsModal],
  );

  const notificationFilter = useMemo(
    () => overEvery([
      matches({
        domain: 'teamManagement',
        to: {
          companyId: currentCompanyId,
          userId: currentUser._id,
        },
      }),
      (notification: Notification) => [
        'requestDecision',
        'userPermissionChanged',
        'userRemovedFromTeam',
        'userAddedToTeam',
      ].includes(notification.action),
    ]) as (notification: Notification) => boolean,
    [currentCompanyId, currentUser],
  );

  const teamUsersNotificationsRef = useNotificationSubject({
    filter: notificationFilter,
  });

  const isAdmin = currentUser.roles[currentCompanyId].admin;

  const roleFilterItems = useRoleFilterItems(users, currentCompanyId);
  const roleFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${currentUser._id}.teamUsers.globalRoleFilter`,
    items: roleFilterItems,
    idProp: 'globalRole',
    renderItem: renderName,
  });

  const permissionFilterItems = usePermissionFilterItems(users, currentCompanyId);
  const permissionFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${currentUser._id}.teamUsers.permissionFilter`,
    items: permissionFilterItems,
    idProp: 'permission',
    renderItem: renderName,
  });

  const sortItems = useSortItems();
  const sortProps = useLocalStorageSortProps({
    storageKey: `${currentCompanyId}.${currentUser._id}.teamUsers.sort`,
    items: sortItems,
  });

  const selectedUsers = useMemo(() => {
    if (!users) {
      return null;
    }

    let usersClone = [...users];

    if (!isEmpty(roleFilterProps.selectedItems)) {
      const selectedRoles = map(roleFilterProps.selectedItems, 'globalRole');
      usersClone = usersClone.filter(user =>
        selectedRoles.includes(user.roleRestrictionProfiles[currentCompanyId] as unknown as GlobalRole),
      );
    }

    if (permissionFilterProps.selectedItems?.some(({ permission }) => permission === NO_PERMISSIONS)) {
      usersClone = usersClone.filter(user =>
        every(
          orderedPermissions,
          permission => !user.roles[currentCompanyId]?.[permission],
        ),
      );
    } else if (!isEmpty(permissionFilterProps.selectedItems)) {
      const selectedPermissions = map(permissionFilterProps.selectedItems, 'permission');
      usersClone = usersClone.filter(user =>
        every(
          selectedPermissions,
          permission => user.roles[currentCompanyId]?.[permission],
        ),
      );
    }

    if (!isEmpty(sortProps.selectedItems)) {
      const sorting = first(sortProps.selectedItems) as LabeledSorting;

      usersClone = orderBy(
        usersClone,
        [(user: User) =>
          get(user, sorting.accessor, '').toLowerCase()],
        [sorting.direction],
      );
    }

    return usersClone;
  }, [
    users,
    roleFilterProps.selectedItems,
    permissionFilterProps.selectedItems,
    sortProps.selectedItems,
    currentCompanyId,
  ]);

  const hasFilters = (
    !isEmpty(roleFilterProps.selectedItems) ||
    !isEmpty(permissionFilterProps.selectedItems)
  );

  // makes sure that NO_PERMISSIONS is never selected at the same time as any of the
  // other filter items
  const interceptFilterChange = (filterItems: PermissionFilterItem[]) => {
    const lastFilterItem = last(filterItems);

    if (!lastFilterItem) {
      permissionFilterProps.onChange([]);
    } else if (lastFilterItem.permission === NO_PERMISSIONS) {
      permissionFilterProps.onChange([lastFilterItem]);
    } else {
      permissionFilterProps.onChange(reject(filterItems, { permission: NO_PERMISSIONS }));
    }
  };

  return (
    <>
      <Panel ref={teamUsersNotificationsRef}>
        <PanelHeader
          height="60px"
          pt="1px"
          pb="2px"
          heading={selectedUsers ? (
            <UserCount count={size(selectedUsers)} />
          ) : null}
          collapse={false}
        >
          <Flex>
            {status === 'success' && hasFilters && (
              <Box mr={2}>
                <ClearFiltersButton
                  onClick={callAll(
                    () => roleFilterProps.onChange([]),
                    () => permissionFilterProps.onChange([]),
                  )}
                />
              </Box>
            )}
            <Box mr={2}>
              <SelectDropdownMenu
                multi
                buttonText={t('general.role')}
                buttonIcon="filter"
                menuWidth={215}
                menuZIndex={10}
                disabled={status !== 'success'}
                {...roleFilterProps}
              />
            </Box>
            <Box mr={2}>
              <SelectDropdownMenu
                multi
                buttonText={t('teamManagement.permission_other')}
                buttonIcon="filter"
                menuWidth={180}
                menuZIndex={10}
                disabled={status !== 'success'}
                {...permissionFilterProps}
                onChange={interceptFilterChange}
              />
            </Box>
            <Box mr={isAdmin ? '24px' : undefined}>
              <SelectDropdownMenu
                rightAligned={!isAdmin}
                buttonText={t('general.sort')}
                buttonIcon="sort"
                menuWidth={160}
                menuZIndex={10}
                disabled={status !== 'success' || (!!selectedUsers && isEmpty(selectedUsers))}
                {...sortProps}
              />
            </Box>
            {isAdmin && (
              <Button
                variant="primary"
                small
                iconLeft="plus"
                onClick={addUserModal.open}
              >
                {t('teamManagement.addUser')}
              </Button>
            )}
          </Flex>
        </PanelHeader>
        <PanelDivider />
        {status === 'success' && entitiesCount && selectedUsers && !isEmpty(selectedUsers) ? (
          <TeamUsersTable
            users={selectedUsers}
            entitiesCount={entitiesCount}
            canEditPermissions={isAdmin}
            currentUser={currentUser}
            openEditPermissionsModal={openEditPermissionsModal}
          />
        ) : (
          <Flex
            height={MIN_CELL_HEIGHT * 5}
            flexDirection="column"
            justifyContent="center"
          >
            <PanelPadding>
              {status === 'loading' || entitiesCountStatus === 'loading' ? (
                <Loading fontSize={4} fontWeight={400} />
              ) : status === 'error' ? (
                <ErrorMessage fontSize={3} error={t('teamManagement.errors.getUsers')} />
              ) : entitiesCountStatus === 'error' ? (
                <ErrorMessage fontSize={3} error={t('teamManagement.errors.getRequestCounts')} />
              ) : hasFilters ? (
                <EmptyTableMessage
                  header={t('general.noResultsMatchYourFilters')}
                  body={(
                    <ClearFiltersButton
                      onClick={() => permissionFilterProps.onChange([])}
                    />
                  )}
                />
              ) : (
                <EmptyTableMessage
                  header={t('teamManagement.noUsers')}
                  body={t('teamManagement.noUsersInCompany')}
                />
              )}
            </PanelPadding>
          </Flex>
        )}
      </Panel>
      <AddUserToCompanyModal
        companyId={currentCompanyId}
        isPayingCompany={belongsToPayingCompany}
        isOpen={addUserModal.isOpen}
        onCancel={addUserModal.close}
        onSave={addUserModal.close}
      />
      {selectedUser && editPermissionsModal.isOpen && (
        <EditPermissionsModal
          user={selectedUser}
          users={users}
          companyId={currentCompanyId}
          isPayingCompany={belongsToPayingCompany}
          entityTeamMemberOverviews={entityTeamMemberOverviews}
          isOpen={editPermissionsModal.isOpen}
          onCancel={editPermissionsModal.close}
          onSave={editPermissionsModal.close}
          onRequestClose={editPermissionsModal.close}
        />
      )}
    </>
  );
};
