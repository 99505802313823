import { TFunction } from 'i18next';

import { ActionType, ExchangeType } from './types';
import { ActionSubtype, ExchangeDefinition, LockType, ExtensionExpiry } from '.';
import { Color } from '../actionsConfig';
import { ContractDocumentExchangeDefinition, ContractProvidedBy } from '../contract';

const {
  NONE,
  OBSOLETE_ACTION,
  SUBMIT,
  UPLOAD_DOCUMENT,
  APPROVE_DOCUMENT,
  INITIATE,
  COUNTERSIGN,
  ACCEPT: ACCEPT_ACTION,
  REJECT,
  DEVIATE,
  RESOLVE,
  REVISE,
  OBSOLETE_EXCHANGE,
  REFER_TO_BULLETIN,
  CLOSE,
  UNLOCK,
  UNOBSOLETE_EXCHANGE,
  REENABLE_RESPONSES,
  DISABLE_RESPONSES,
  MARK_AS_AGREED,
  REPLACE,
  AGREE,
  VERIFIED_RECIPIENT_SIGNED,
  VERIFIED_RECIPIENT_REJECTED,
  VERIFIED_ENVELOPE_COMPLETE,
  VERIFIED_ENVELOPE_SETUP_FAILED,
  APPROVE,
  REQUIRE_MORE_INFO,
} = ActionType;

const {
  ACCEPT_CLOSED,
  ACCEPT,
  COMPLETE_OR_SIGN,
  COMPLETE_OR_SIGN_CLOSED,
  COMPLETE_OR_SIGN_LOCKED,
  DOCUMENT_REQUEST,
  DOCUMENT_REQUEST_CLOSED,
  DOCUMENT_REQUEST_LOCKED,
  INFORMATION,
} = ExchangeType;

const getExchangeTypeToLockSubject = (t) => ({
  [ExchangeType.LINE_ITEM]: t('common.lineItem'),
  [ExchangeType.DOCUMENT_REQUEST_LOCKED]: t('common.documentRequest'),
  [ExchangeType.COMPLETE_OR_SIGN_LOCKED]: t('common.documentExchange'),
  [ExchangeType.INCLUSIONS]: t('common.inclusion'),
  [ExchangeType.TERMS]: t('common.term'),
  [ExchangeType.QUESTION]: t('common.question'),
});

export const getClarificationActionDisplayProps = (action, t: TFunction) => {
  const { type, subtype } = action;

  switch (type) {
    case INITIATE:
      return { description: t('clarification.openedClarification') };
    case NONE:
      return { description: '' };
    case RESOLVE:
      return { description: t('clarification.resolvedClarification') };
    case REFER_TO_BULLETIN:
      return { description: t('clarification.referredBulletin') };
    case OBSOLETE_ACTION:
      switch (subtype) {
        case ActionSubtype.RESOLVE_EXCHANGE:
          return { description: t('clarification.reopenedClarification') };
        case ActionSubtype.REFER_TO_BULLETIN:
          return { description: t('clarification.removedBulletinReference') };
        default:
          return null;
      }
    default:
      return null;
  }
};

export const getChatActionDisplayProps = (action, t: TFunction) => {
  const { type } = action;

  switch (type) {
    case INITIATE:
      return { description: t('chat.openedChat') };
    case NONE:
      return { description: '' };
    case CLOSE:
      return { description: t('chat.closedChat') };
    case OBSOLETE_ACTION:
      return { description: t('chat.reopenedChat') };
    default:
      return null;
  }
};

export const getDocumentActionDisplayProps = (
  action,
  t: TFunction,
  exchangeType: ExchangeType,
  senderActionOverrides,
) => {
  const { type, subtype } = action;

  switch (type) {
    case INITIATE:
      switch (subtype) {
        case ActionSubtype.SPECIFY_DOCUMENT_EXCHANGE:
          return senderActionOverrides
            ? {
                ...senderActionOverrides,
                description: t('document.documentSpecified'),
                icon: 'file-o',
              }
            : {
                description: t('document.specifiedDocumentExchange'),
              };
        case ActionSubtype.ADD_DOCUMENT:
          return senderActionOverrides
            ? {
                ...senderActionOverrides,
                description: t('document.documentUploaded'),
                icon: 'file-o',
              }
            : {
                description: t('document.addedDocument'),
              };
        default:
          return null;
      }
    case NONE:
      return { description: '' };
    case ACCEPT_ACTION:
      return {
        description: t('auction.accepted'),
      };
    case RESOLVE:
      switch (exchangeType) {
        case ACCEPT:
        case ACCEPT_CLOSED:
        case COMPLETE_OR_SIGN:
        case COMPLETE_OR_SIGN_CLOSED:
        case COMPLETE_OR_SIGN_LOCKED:
          return {
            description: t('common.resolvedExchange'),
          };
        case DOCUMENT_REQUEST:
        case DOCUMENT_REQUEST_CLOSED:
        case DOCUMENT_REQUEST_LOCKED:
          return {
            description: t('document.resolvedDocumentExchange'),
          };
        default:
          return null;
      }
    case UNOBSOLETE_EXCHANGE:
      switch (exchangeType) {
        case ACCEPT:
        case ACCEPT_CLOSED:
        case COMPLETE_OR_SIGN:
        case INFORMATION:
          return {
            description: t('document.unmarkedDocumentObsolete'),
          };
        case COMPLETE_OR_SIGN_CLOSED:
        case COMPLETE_OR_SIGN_LOCKED:
          return {
            description: t('common.unmarkedExchangeObsolete'),
          };
        case DOCUMENT_REQUEST:
        case DOCUMENT_REQUEST_CLOSED:
        case DOCUMENT_REQUEST_LOCKED:
          return {
            description: t('document.unmarkedDocumentRequestObsolete'),
          };
        default:
          return null;
      }
    case OBSOLETE_EXCHANGE:
      return senderActionOverrides
        ? {
            ...senderActionOverrides,
            description: t('document.documentExchangeObsolete'),
            icon: 'ban',
          }
        : {
            description: t('document.markedDocumentObsolete'),
          };
    case OBSOLETE_ACTION:
      switch (subtype) {
        case ActionSubtype.PREVIOUS_ACTION:
          return {
            description: t('common.clearedResponse'),
          };
        case ActionSubtype.RESOLVE_EXCHANGE:
          return {
            description: t('common.markExchangeUnresolved'),
          };
        default:
          return null;
      }
    case REVISE:
      switch (exchangeType) {
        case ACCEPT:
        case ACCEPT_CLOSED:
        case COMPLETE_OR_SIGN_CLOSED:
        case COMPLETE_OR_SIGN_LOCKED:
        case COMPLETE_OR_SIGN:
        case INFORMATION:
          return senderActionOverrides
            ? {
                ...senderActionOverrides,
                description: t('document.detailsChanged'),
                icon: 'pencil',
              }
            : {
                description: t('document.changedTheDetails'),
              };
        case DOCUMENT_REQUEST:
        case DOCUMENT_REQUEST_CLOSED:
        case DOCUMENT_REQUEST_LOCKED:
          return senderActionOverrides
            ? {
                ...senderActionOverrides,
                description: t('document.documentExchangeRevised'),
                icon: 'file-o',
              }
            : {
                description: t('document.updatedDocumentRequest'),
              };
        default:
          return null;
      }
    case 'exchange-imported':
      return {
        description: t('document.importedDocument'),
      };
    case REENABLE_RESPONSES: {
      const extensionExpiry = action.payload.extensionExpiry || ExtensionExpiry.NEXT_DEADLINE;

      return {
        description:
          extensionExpiry === ExtensionExpiry.NEXT_DEADLINE
            ? t('common.reenabledResponsesUntilNextDeadline')
            : t('common.reenabledResponsesUntilCompleted'),
      };
    }
    case DISABLE_RESPONSES: {
      return {
        description: t('common.disabledResponses'),
      };
    }
    case SUBMIT:
      switch (exchangeType) {
        case DOCUMENT_REQUEST:
        case DOCUMENT_REQUEST_CLOSED:
        case DOCUMENT_REQUEST_LOCKED:
          return {
            description: t('document.submittedDocument'),
          };
        case COMPLETE_OR_SIGN_CLOSED:
        case COMPLETE_OR_SIGN_LOCKED:
        case COMPLETE_OR_SIGN:
          switch (subtype) {
            case ActionSubtype.SUBMIT:
              return {
                description: t('document.submittedDocument'),
              };
            case ActionSubtype.UPDATE:
              return senderActionOverrides
                ? {
                    ...senderActionOverrides,
                    description: t('document.documentRevised'),
                    icon: 'file-o',
                  }
                : {
                    description: t('document.updatedDocument'),
                  };
            default:
              return null;
          }
        case ACCEPT:
        case ACCEPT_CLOSED:
        case INFORMATION:
          return senderActionOverrides
            ? {
                ...senderActionOverrides,
                description: t('document.documentRevised'),
                icon: 'file-o',
              }
            : {
                description: t('document.updatedDocument'),
              };
        default:
          return null;
      }
    case COUNTERSIGN:
      return {
        description: t('document.submittedCountersignedDocument'),
      };
    case REJECT:
      switch (exchangeType) {
        case ACCEPT:
          return {
            description: t('document.rejectedDeviation'),
          };
        case COMPLETE_OR_SIGN:
          return {
            description: t('document.rejected'),
          };
        case DOCUMENT_REQUEST:
        case DOCUMENT_REQUEST_CLOSED:
        case DOCUMENT_REQUEST_LOCKED:
          return {
            description: t('document.cannotProvideDocument'),
          };
        default:
          return null;
      }
    case DEVIATE:
      return {
        description: t('common.submittedResponse'),
      };
    case 'locked':
      return {
        icon: 'lock',
        iconBackgroundColor: Color.LIGHT_GRAY,
        description: t('common.eventUnavailable', {
          subject: getExchangeTypeToLockSubject(t)[exchangeType],
        }),
      };
    case UNLOCK:
      switch (action.lock.type) {
        case LockType.BID_DEADLINE:
          return {
            description: t('common.unlockedByFinalDeadline'),
          };
        case LockType.STAGE_DEADLINE:
          return {
            description: t('common.unlockedByStageDeadline'),
          };
        case LockType.TEAM_MEMBER:
        case LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE:
        case LockType.TEAM_MEMBER_AFTER_BID_DEADLINE:
          return {
            description: t('common.unlockedExchange'),
          };
        default:
          return null;
      }
    default:
      return null;
  }
};

export const getLineItemActionDisplayProps = (
  action,
  t: TFunction,
  exchangeType: ExchangeType,
  senderActionOverrides,
) => {
  const { type, subtype, submissionMethod } = action;

  switch (type) {
    case INITIATE:
      return senderActionOverrides
        ? {
            ...senderActionOverrides,
            description: t('lineItem.lineItemSpecified'),
            icon: 'list-ul',
          }
        : {
            description: t('lineItem.addedLineItem'),
          };
    case NONE:
      return { description: '' };
    case OBSOLETE_ACTION:
      return {
        description: t('common.unmarkedResponseAgreed'),
      };
    case SUBMIT:
      switch (subtype) {
        case ActionSubtype.CLEAR:
          return {
            description: t('common.clearedResponse'),
          };
        case ActionSubtype.SUBMIT:
          return {
            description: submissionMethod === 'excel' ? t('common.submittedResponseExcel') : t('common.submittedResponse'),
          };
        case ActionSubtype.UPDATE:
          return {
            description: submissionMethod === 'excel' ? t('common.updatedResponseExcel') : t('common.updatedResponse'),
          };
        default:
          return null;
      }
    case REVISE:
      return senderActionOverrides
        ? {
            ...senderActionOverrides,
            description: t('lineItem.lineItemRevised'),
            icon: 'pencil',
          }
        : {
            description: t('lineItem.updatedLineItem'),
          };
    case OBSOLETE_EXCHANGE:
      return senderActionOverrides
        ? {
            ...senderActionOverrides,
            description: t('lineItem.lineItemObsolete'),
            icon: 'ban',
          }
        : {
            description: t('lineItem.markedLineItemObsolete'),
          };
    case UNOBSOLETE_EXCHANGE:
      return {
        description: t('lineItem.unmarkedLineItemObsolete'),
      };
    case 'currency-changed':
      switch (subtype) {
        case ActionSubtype.CLEAR_CURRENCY:
          return {
            description: t('lineItem.currencyCleared'),
          };
        case ActionSubtype.SET_CURRENCY:
          return {
            description: t('lineItem.currencySetTo', {
              currencyCode: action.currency,
            }),
          };
        case ActionSubtype.UPDATE_CURRENCY:
          return {
            description: t('lineItem.currencyChangedFrom', {
              previousCurrency: action.previousCurrency,
              currency: action.currency,
            }),
          };
        default:
          return null;
      }
    case 'locked':
      return {
        icon: 'lock',
        iconBackgroundColor: Color.LIGHT_GRAY,
        description: t('common.eventUnavailable', {
          subject: getExchangeTypeToLockSubject(t)[exchangeType],
        }),
      };
    case UNLOCK:
      switch (action.lock.type) {
        case LockType.BID_DEADLINE:
          return {
            description: t('common.unlockedByFinalDeadline'),
          };
        case LockType.STAGE_DEADLINE:
          return {
            description: t('common.unlockedByStageDeadline'),
          };
        case LockType.TEAM_MEMBER:
        case LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE:
        case LockType.TEAM_MEMBER_AFTER_BID_DEADLINE:
          return {
            description: t('common.unlockedExchange'),
          };
        default:
          return null;
      }
    case REENABLE_RESPONSES: {
      const extensionExpiry = action.payload.extensionExpiry || ExtensionExpiry.NEXT_DEADLINE;

      return {
        description:
          extensionExpiry === ExtensionExpiry.NEXT_DEADLINE
            ? t('common.reenabledResponsesUntilNextDeadline')
            : t('common.reenabledResponsesUntilCompleted'),
      };
    }
    case DISABLE_RESPONSES: {
      return {
        description: t('common.disabledResponses'),
      };
    }
    case 'exchange-imported':
      return {
        description: t('lineItem.importedLineItem'),
      };
    case MARK_AS_AGREED:
      return {
        description: t('common.markAgreed'),
      };
    case 'auction-closed':
      return {
        description: t('lineItem.auctionLineItemClosingPrice'),
      };
    case 'response-tag-updated':
      return {
        description: t('lineItem.newResponseRequiredForStage', { stageNum: action.stageIndex + 1 }),
        icon: 'list-ul',
      };
    default:
      return null;
  }
};

export const getInclusionsActionDisplayProps = (
  action,
  t: TFunction,
  exchangeType: ExchangeType,
  senderActionOverrides,
) => {
  const { type } = action;

  switch (type) {
    case INITIATE:
      return senderActionOverrides
        ? {
            ...senderActionOverrides,
            description: t('inclusion.inclusionSpecified'),
            icon: 'ship',
          }
        : {
            description: t('inclusion.specifiedInclusion'),
          };
    case OBSOLETE_ACTION:
      switch (action.subtype) {
        case ActionSubtype.SUBMIT:
        case ActionSubtype.UPDATE:
          return {
            description: t('common.clearedResponse'),
          };
        default:
          return {
            description: t('common.markPreviousActionObsolete'),
          };
      }
    case UNOBSOLETE_EXCHANGE:
      return {
        description: t('inclusion.unmarkedInclusionObsolete'),
      };
    case NONE:
      return { description: '' };
    case SUBMIT:
      return action.option === 'included'
        ? {
            description: t('inclusion.markedIncluded'),
          }
        : {
            description: t('inclusion.markedNotIncluded'),
          };
    case REVISE:
      return senderActionOverrides
        ? {
            ...senderActionOverrides,
            description: t('inclusion.inclusionRevised'),
            icon: 'pencil',
          }
        : {
            description: t('inclusion.updatedInclusion'),
          };
    case OBSOLETE_EXCHANGE:
      return senderActionOverrides
        ? {
            ...senderActionOverrides,
            description: t('inclusion.inclusionObsolete'),
            icon: 'ban',
          }
        : {
            description: t('inclusion.markedInclusionObsolete'),
          };
    case 'locked':
      return {
        icon: 'lock',
        iconBackgroundColor: Color.LIGHT_GRAY,
        description: t('common.eventUnavailable', {
          subject: getExchangeTypeToLockSubject(t)[exchangeType],
        }),
      };
    case UNLOCK:
      switch (action.lock.type) {
        case LockType.BID_DEADLINE:
          return {
            description: t('common.unlockedByFinalDeadline'),
          };
        case LockType.STAGE_DEADLINE:
          return {
            description: t('common.unlockedByStageDeadline'),
          };
        case LockType.TEAM_MEMBER:
        case LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE:
        case LockType.TEAM_MEMBER_AFTER_BID_DEADLINE:
          return {
            description: t('common.unlockedExchange'),
          };
        default:
          return null;
      }
    case REENABLE_RESPONSES: {
      const extensionExpiry = action.payload.extensionExpiry || ExtensionExpiry.NEXT_DEADLINE;

      return {
        description:
          extensionExpiry === ExtensionExpiry.NEXT_DEADLINE
            ? t('common.reenabledResponsesUntilNextDeadline')
            : t('common.reenabledResponsesUntilCompleted'),
      };
    }
    case DISABLE_RESPONSES: {
      return {
        description: t('common.disabledResponses'),
      };
    }
    default:
      return null;
  }
};

export const getQuestionActionDisplayProps = (
  action,
  t: TFunction,
  exchangeType: ExchangeType,
  senderActionOverrides,
) => {
  const { type, subtype } = action;

  switch (type) {
    case INITIATE:
      return senderActionOverrides
        ? {
            ...senderActionOverrides,
            description: t('question.questionSpecified'),
            icon: 'question',
          }
        : {
            description: t('question.specifiedQuestion'),
          };
    case NONE:
      return { description: '' };
    case SUBMIT:
      switch (subtype) {
        case ActionSubtype.CLEAR:
          return {
            description: t('common.clearedResponse'),
          };
        case ActionSubtype.SUBMIT:
          return {
            description: t('common.submittedResponse'),
          };
        case ActionSubtype.UPDATE:
          return {
            description: t('common.updatedResponse'),
          };
        default:
          return null;
      }
    case REVISE:
      switch (subtype) {
        case ActionSubtype.REVISE_QUESTION:
          return senderActionOverrides
            ? {
                ...senderActionOverrides,
                description: t('question.questionRevised'),
                icon: 'pencil',
              }
            : {
                description: t('question.revisedQuestion'),
              };
        case ActionSubtype.REVISE_QUESTION_CONFIGURATION:
          return senderActionOverrides
            ? {
                ...senderActionOverrides,
                description: t(
                  'request.question.questionConfigurationRevised',
                  { ns: 'translation' },
                ),
                icon: 'pencil',
              }
            : {
                description: t(
                  'request.question.questionConfigurationRevised',
                  { ns: 'translation' },
                ),
              };
        default:
          return null;
      }
    case OBSOLETE_EXCHANGE:
      return senderActionOverrides
        ? {
            ...senderActionOverrides,
            description: t('question.questionObsolete'),
            icon: 'ban',
          }
        : {
            description: t('question.markedQuestionObsolete'),
          };
    case UNOBSOLETE_EXCHANGE:
      return {
        description: t('question.unmarkedQuestionObsolete'),
      };
    case 'locked':
      return {
        icon: 'lock',
        iconBackgroundColor: Color.LIGHT_GRAY,
        description: t('common.eventUnavailable', {
          subject: getExchangeTypeToLockSubject(t)[exchangeType],
        }),
      };
    case UNLOCK:
      switch (action.lock.type) {
        case LockType.BID_DEADLINE:
          return {
            description: t('common.unlockedByFinalDeadline'),
          };
        case LockType.STAGE_DEADLINE:
          return {
            description: t('common.unlockedByStageDeadline'),
          };
        case LockType.TEAM_MEMBER:
        case LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE:
        case LockType.TEAM_MEMBER_AFTER_BID_DEADLINE:
          return {
            description: t('common.unlockedExchange'),
          };
        default:
          return null;
      }
    case REENABLE_RESPONSES: {
      const extensionExpiry = action.payload.extensionExpiry || ExtensionExpiry.NEXT_DEADLINE;

      return {
        description:
          extensionExpiry === ExtensionExpiry.NEXT_DEADLINE
            ? t('common.reenabledResponsesUntilNextDeadline')
            : t('common.reenabledResponsesUntilCompleted'),
      };
    }
    case DISABLE_RESPONSES: {
      return {
        description: t('common.disabledResponses'),
      };
    }
    case 'document-expired':
      return {
        description: t('question.documentExpired'),
      };
    case REQUIRE_MORE_INFO:
      return {
        description: t('question.requiredMoreInformation'),
      };
    default:
      return null;
  }
};

export const getCurrencyActionDisplayProps = (action, t: TFunction) => {
  const { type, subtype, previousCurrency, currentCurrency } = action;

  switch (type) {
    case INITIATE:
      switch (subtype) {
        case ActionSubtype.SET_CURRENCY:
          return {
            description: t('currency.setCurrency'),
          };
        case ActionSubtype.REQUEST_CURRENCY:
          return {
            description: t('currency.requestCurrency'),
          };
        default:
          return null;
      }
    case SUBMIT:
      return {
        description: t('currency.submitCurrency', {
          currentCurrency,
          previousCurrency,
        }),
      };
    case REVISE:
      return {
        description: t('currency.updatedCurrency'),
      };
    default:
      return null;
  }
};

export const getEvaluationCriterionActionDisplayProps = (
  action,
  t: TFunction,
  exchangeType: ExchangeType,
  senderActionOverrides,
) => {
  const { type, subtype, points } = action;

  switch (type) {
    case INITIATE:
      return {
        ...senderActionOverrides,
        description: t('evaluation.evaluationCriterionSpecified'),
        icon: 'plus',
      };
    case SUBMIT:
      switch (subtype) {
        case ActionSubtype.CLEAR:
          return {
            description: t('evaluation.clearedScore'),
          };
        case ActionSubtype.SUBMIT:
          return {
            description: t('evaluation.submittedScoreCount', { count: points }),
          };
        case ActionSubtype.UPDATE:
          return {
            description: t('evaluation.updatedScoreCount', { count: points }),
          };
        default:
          return null;
      }
    case OBSOLETE_ACTION:
      return {
        description: t('common.markPreviousActionObsolete'),
      };
    case REVISE:
      return {
        ...senderActionOverrides,
        description: t('evaluation.evaluationCriterionRevised'),
        icon: 'plus',
      };
    case NONE:
      return { description: '' };
    case OBSOLETE_EXCHANGE:
      return {
        ...senderActionOverrides,
        description: t('evaluation.evaluationCriterionObsolete'),
        icon: 'ban',
      };
    default:
      return null;
  }
};

export const getContractActionDisplayProps = (
  action,
  t: TFunction,
  exchangeDef: ExchangeDefinition,
  senderActionOverrides,
) => {
  const { type, subtype } = action;
  const { isAddendum, providedBy } = exchangeDef as ContractDocumentExchangeDefinition;

  const isProvidedBySupplier = providedBy === ContractProvidedBy.SUPPLIER;

  switch (type) {
    case INITIATE: {
      return senderActionOverrides
        ? {
          ...senderActionOverrides,
          description: isProvidedBySupplier
            ? isAddendum
              ? t('contract.addendumRequested')
              : t('contract.contractRequested')
            : isAddendum
              ? t('contract.addendumUploaded')
              : t('contract.contractUploaded'),
          icon: 'file-o',
        }
        : {
          description: isProvidedBySupplier
            ? isAddendum
              ? t('contract.addendumRequested')
              : t('contract.contractRequested')
            : isAddendum
              ? t('contract.addedAddendum')
              : t('contract.addedContract'),
        };
    }
    case NONE:
      return { description: '' };
    case UPLOAD_DOCUMENT:
      return {
        description: t('contract.uploadedContract'),
      };
    case APPROVE_DOCUMENT:
      return {
        description: t('contract.approvedContract'),
      };
    case SUBMIT:
      switch (subtype) {
        case ActionSubtype.SUBMIT:
          return {
            description: isAddendum
              ? t('contract.signedAndSubmittedAddendum')
              : t('contract.signedAndSubmittedContract'),
          };
        case ActionSubtype.UPDATE:
          return {
            description: isAddendum
              ? t('contract.updatedAddendum')
              : t('contract.updatedContract'),
          };
        default:
          return null;
      }
    case DEVIATE:
      return {
        description: t('contract.suggestedEdits'),
      };
    case REPLACE:
      return {
        description: isAddendum
          ? t('contract.uploadedNewAddendum')
          : t('contract.uploadedNewContract'),
      };
    case COUNTERSIGN:
      return {
        description: isAddendum
          ? t('contract.submittedAddendum')
          : t('contract.submittedContract'),
      };
    case ACCEPT_ACTION:
      return {
        description: isAddendum
          ? t('contract.acceptedEditsAndUploadedNewAddendum')
          : t('contract.acceptedEditsAndUploadedNewContract'),
      };
    case REJECT:
      return {
        description: t('contract.rejectedEdits'),
      };
    case OBSOLETE_ACTION:
      return {
        description: t('common.markPreviousActionObsolete'),
      };
    case REVISE:
      return senderActionOverrides
        ? {
          ...senderActionOverrides,
          description: t('contract.detailsChanged'),
          icon: 'pencil',
        }
        : {
          description: t('contract.changedTheDetails'),
        };
    case OBSOLETE_EXCHANGE:
      return senderActionOverrides
        ? {
          ...senderActionOverrides,
          description: isAddendum
            ? t('contract.addendumObsolete')
            : t('contract.contractObsolete'),
          icon: 'ban',
        }
        : {
          description: isAddendum
            ? t('contract.markedAddendumObsolete')
            : t('contract.markedContractObsolete'),
        };
    case AGREE:
      return {
        description: t('contract.acceptedAndAssignedSigners'),
      };
    case VERIFIED_RECIPIENT_SIGNED:
      return {
        description: t('contract.completedSigning'),
      };
    case VERIFIED_RECIPIENT_REJECTED:
      return {
        description: t('contract.rejectedSigning'),
      };
    case VERIFIED_ENVELOPE_COMPLETE:
      return {
        description: t('contract.contractSigned'),
        icon: 'file-signature',
      };
    case VERIFIED_ENVELOPE_SETUP_FAILED:
      return {
        description: t('contract.eSignatureSetupFailed'),
        icon: 'exclamation',
      };
    case APPROVE:
      return {
        description: t('contract.approved'),
      };
    default:
      return null;
  }
};

export const getLegacyContractActionDisplayProps = (
  action,
  t: TFunction,
  senderActionOverrides,
) => {
  const { type } = action;

  switch (type) {
    case INITIATE:
      return senderActionOverrides
        ? {
          ...senderActionOverrides,
          description: t('legacyContract.contractUploaded'),
          icon: 'file-o',
        }
        : {
          description: t('legacyContract.addedContract'),
        };
    case REVISE:
      return senderActionOverrides
        ? {
          ...senderActionOverrides,
          description: t('legacyContract.detailsChanged'),
          icon: 'pencil',
        }
        : {
          description: t('legacyContract.changedTheDetails'),
        };
    case OBSOLETE_EXCHANGE:
      return senderActionOverrides
        ? {
          ...senderActionOverrides,
          description: t('legacyContract.contractObsolete'),
          icon: 'ban',
        }
        : {
          description: t('legacyContract.markedContractObsolete'),
        };
    case SUBMIT:
      return senderActionOverrides
        ? {
          ...senderActionOverrides,
          description: t('legacyContract.contractUpdated'),
          icon: 'file-o',
        }
        : {
          description: t('legacyContract.updatedContract'),
        };
    default:
      return null;
  }
};

export const getTermsActionDisplayProps = (
  action,
  t: TFunction,
  exchangeType: ExchangeType,
  senderActionOverrides,
) => {
  const { type } = action;

  switch (type) {
    case INITIATE:
      return senderActionOverrides
        ? {
            ...senderActionOverrides,
            description: t('term.termSpecified'),
            icon: 'ship',
          }
        : {
            description: t('term.specifiedTerm'),
          };
    case NONE:
      return { description: '' };
    case REVISE:
      return senderActionOverrides
        ? {
            ...senderActionOverrides,
            description: t('term.termRevised'),
            icon: 'pencil',
          }
        : {
            description: t('term.updatedTerm'),
          };
    case OBSOLETE_EXCHANGE:
      return senderActionOverrides
        ? {
            ...senderActionOverrides,
            description: t('term.termObsolete'),
            icon: 'ban',
          }
        : {
            description: t('term.markedTermObsolete'),
          };
    case UNOBSOLETE_EXCHANGE:
      return {
        description: t('term.unmarkedTermObsolete'),
      };
    case 'locked':
      return {
        icon: 'lock',
        iconBackgroundColor: Color.LIGHT_GRAY,
        description: t('common.eventUnavailable', {
          subject: getExchangeTypeToLockSubject(t)[exchangeType],
        }),
      };
    case UNLOCK:
      switch (action.lock.type) {
        case LockType.BID_DEADLINE:
          return {
            description: t('common.unlockedByFinalDeadline'),
          };
        case LockType.STAGE_DEADLINE:
          return {
            description: t('common.unlockedByStageDeadline'),
          };
        case LockType.TEAM_MEMBER:
        case LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE:
        case LockType.TEAM_MEMBER_AFTER_BID_DEADLINE:
          return {
            description: t('common.unlockedExchange'),
          };
        default:
          return null;
      }
    case REENABLE_RESPONSES: {
      const extensionExpiry = action.payload.extensionExpiry || ExtensionExpiry.NEXT_DEADLINE;

      return {
        description:
          extensionExpiry === ExtensionExpiry.NEXT_DEADLINE
            ? t('common.reenabledResponsesUntilNextDeadline')
            : t('common.reenabledResponsesUntilCompleted'),
      };
    }
    case DISABLE_RESPONSES: {
      return {
        description: t('common.disabledResponses'),
      };
    }
    default:
      return null;
  }
};

export const getInternalDocumentActionDisplayProps = (
  action,
  t: TFunction,
) => {
  const { type } = action;

  switch (type) {
    case INITIATE:
      return {
        description: t('document.addedDocument'),
      };
    case SUBMIT:
      return {
        description: t('document.updatedDocument'),
      };
    case NONE:
      return { description: '' };
    default:
      return null;
  }
};

export const getAuctionTermsActionDisplayProps = (
  action,
  t: TFunction,
) => {
  const { type } = action;

  switch (type) {
    case ACCEPT_ACTION:
      return {
        description: t('auction.accepted'),
      };
    default:
      return null;
  }
};
