import * as React from 'react';

type InputFunction = (props: Record<string, any>) => Record<string, any>;

type Input = Record<string, any> | InputFunction;

// Utility function that creates a component with the values provided by `input`
export const withProps = (BaseComponent: React.FC<any>, input: Input): React.FC<any> =>
  (props) => React.createElement(
    BaseComponent,
    {
      ...props,
      ...(typeof input === 'function' ? input(props) : input),
    },
  );
