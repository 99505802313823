import { isFinite } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RequestsValueCardsData, RequestsReportingConfig } from '@deepstream/common/reporting';
import { Box } from 'rebass/styled-components';
import { localeFormatPrice } from '@deepstream/utils';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Grid } from '@deepstream/ui-kit/elements/Grid';
import * as dashboard from './dashboard';
import { useDeviceSize } from '../../ui/useDeviceSize';
import { useRequestsReportingCardsData } from './useRequestsReporting';
import { ValueScatterChart } from './ValueScatterChart';
import { ValueDoughnutChart } from './ValueDoughnutChart';
import { useCurrentUserLocale } from '../../useCurrentUser';

const UnconfirmedValueSubtext = ({ amount, currencyCode }: { amount: number; currencyCode: string }) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();

  return (
    <Box color="subtext">
      {isFinite(amount) ? (
        t('request.spendAndSavings.andValueUnconfirmed', {
          value: localeFormatPrice(amount, currencyCode, { locale, notation: 'compact' }),
        })
      ) : (
        t('general.notAvailable')
      )}
    </Box>
  );
};

export const ValueCards = ({
  config,
  useData = useRequestsReportingCardsData,
}: { config: RequestsReportingConfig; useData?: typeof useRequestsReportingCardsData }) => {
  const { t } = useTranslation('translation');
  const { isLarge, isExtraLarge } = useDeviceSize();
  const { data, isLoading, isError, isSuccess } = useData<RequestsValueCardsData>(config, 'value');

  const interval = data?.config.interval;

  return (
    <>
      <Grid
        sx={{
          gridTemplateColumns: isLarge || isExtraLarge
            ? '1fr 1fr 1fr'
            : '1fr 1fr',
          gridTemplateRows: isLarge || isExtraLarge
            ? '134px'
            : '134px 134px',
        }}
      >
        <dashboard.Card
          isLoading={isLoading}
          isError={isError}
          heading={t('request.spendAndSavings.allTime')}
        >
          <PanelPadding pt={0}>
            {isSuccess && data && (
              <>
                <dashboard.Price
                  amount={data.totals.allTime.amount}
                  currencyCode={data.config.currency}
                />
                <UnconfirmedValueSubtext
                  amount={data.unconfirmedTotals.allTime.amount}
                  currencyCode={data.config.currency}
                />
              </>
            )}
          </PanelPadding>
        </dashboard.Card>
        <dashboard.Card
          isLoading={isLoading}
          isError={isError}
          heading={interval ? t(`request.spendAndSavings.this.${interval}`) : undefined}
        >
          <PanelPadding pt={0}>
            {isSuccess && data && (
              <>
                <dashboard.Price
                  amount={data.totals.currentInterval.amount}
                  comparisonAmount={data.totals.previousInterval.amount}
                  currencyCode={data.config.currency}
                />
                <UnconfirmedValueSubtext
                  amount={data.unconfirmedTotals.currentInterval.amount}
                  currencyCode={data.config.currency}
                />
              </>
            )}
          </PanelPadding>
        </dashboard.Card>
        <dashboard.Card
          isLoading={isLoading}
          isError={isError}
          heading={interval ? t(`request.spendAndSavings.last.${interval}`) : undefined}
        >
          <PanelPadding pt={0}>
            {isSuccess && data && (
              <>
                <dashboard.Price
                  amount={data.totals.previousInterval.amount}
                  currencyCode={data.config.currency}
                />
                <UnconfirmedValueSubtext
                  amount={data.unconfirmedTotals.previousInterval.amount}
                  currencyCode={data.config.currency}
                />
              </>
            )}
          </PanelPadding>
        </dashboard.Card>
      </Grid>
      <Grid
        sx={{
          gridTemplateColumns: isLarge || isExtraLarge
            ? '1fr 1fr'
            : '1fr',
          gridTemplateRows: isLarge || isExtraLarge
            ? '305px'
            : '305px 305px',
        }}
      >
        <dashboard.Card
          isLoading={isLoading}
          isError={isError}
          heading={t('request.spendAndSavings.percentOfRequestsWithValue')}
          infoTooltip={t('request.spendAndSavings.percentOfRequestsWithValueInfo')}
        >
          <PanelPadding>
            {isSuccess && data && (
              <ValueDoughnutChart data={data} />
            )}
          </PanelPadding>
        </dashboard.Card>
        <dashboard.Card
          isLoading={isLoading}
          isError={isError}
          heading={interval ? t(`request.spendAndSavings.valuePer.${interval}`) : undefined}
        >
          <PanelPadding pt="10px" sx={{ height: '276px' }}>
            {isSuccess && data && (
              <ValueScatterChart data={data} />
            )}
          </PanelPadding>
        </dashboard.Card>
      </Grid>
    </>
  );
};
