import { useMemo } from 'react';
import { values } from 'lodash';
import { Text } from 'rebass/styled-components';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useContractData } from '@deepstream/ui/modules/Contracts/contract';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { UserLinkCell } from '../../UserLinkCell';

export const AdminContractUsersTable = ({ companyId }: { companyId: string }) => {
  const contract = useContractData();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        // TODO Change to `name` after merging with the Reminders feature and populating user names
        accessor: 'email',
        Cell: UserLinkCell,
      },
      {
        Header: 'Date added',
        accessor: 'dateAdded',
        Cell: DatetimeCell,
      },
    ],
    [],
  );

  const data = useMemo(
    () => values(contract.teamById[companyId].users),
    [contract, companyId],
  );

  return data.length ? (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={data}
      />
    </StaticTableStyles>
  ) : (
    <PanelPadding>
      <Text color="subtext">No users</Text>
    </PanelPadding>
  );
};
