import { useState, useMemo } from 'react';
import { last, upperFirst } from 'lodash';
import * as yup from 'yup';
import { PropertyList, PropertyListAction } from '@deepstream/ui/PropertyList';
import { Datetime } from '@deepstream/ui/Datetime';
import { AdminRequestMeta } from '@deepstream/ui/types';
import * as rfx from '@deepstream/ui/rfx';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { Modal, ModalProps, ModalBody, ModalFooter, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { useAdminApi } from '@deepstream/ui/api';
import { useToaster } from '@deepstream/ui/toast';
import { useQueryClient } from 'react-query';
import { callAll } from '@deepstream/utils/callAll';
import { Form, Formik } from 'formik';
import { TextField } from '@deepstream/ui/form/TextField';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Flex } from 'rebass/styled-components';
import { Button, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { useMutation } from '@deepstream/ui/useMutation';
import { localeFormatNumber } from '@deepstream/utils';
import { APP_ADMIN_LOCALE } from '@deepstream/common/constants';
import { useRfqId } from '@deepstream/ui/useRfq';
import { useTranslation } from 'react-i18next';
import { Id } from './Id';
import { TemplateLink } from './TemplateLink';
import { useAdminRequestQueryKey } from './adminRequest';

const TemplateLinkOrDash = ({ value: template }) => {
  return template ? (
    <TemplateLink templateId={template?._id}>
      {template?.meta.name}
    </TemplateLink>
  ) : (
    <EmDash />
  );
};

const EditRequestSizeLimitOverrideModal = ({
  defaultRequestSizeLimits,
  requestSizeLimitOverrides,
  onCancel,
  onSave,
  propName,
  ...props
}: ModalProps & {
  defaultRequestSizeLimits: {
    maxComplexity: number;
    maxExchangeDefCount: number;
  };
  requestSizeLimitOverrides: {
    maxComplexity?: number;
    maxExchangeDefCount?: number;
  };
  propName: 'maxComplexity' | 'maxExchangeDefCount',
  onCancel: () => void;
  onSave: () => void;
}) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const rfqId = useRfqId();
  const adminRequestQueryKey = useAdminRequestQueryKey(rfqId);

  const label = {
    maxComplexity: 'request size limit',
    maxExchangeDefCount: 'maximum exchange definition count',
  }[propName];

  const [updateRequestSizeLimits] = useMutation(
    adminApi.updateRequestSizeLimits,
    {
      onSuccess: callAll(
        onSave,
        (_, params) => {
          if (params[propName]) {
            toaster.success(`${upperFirst(label)} override updated successfully`);
          } else {
            toaster.success(`${upperFirst(label)} override removed successfully`);
          }
        },
      ),
      onError: (_, params) => {
        if (params[propName]) {
          toaster.error(`${upperFirst(label)} override could not be updated`);
        } else {
          toaster.error(`${upperFirst(label)} override could not be removed`);
        }
      },
      onSettled: () => queryClient.invalidateQueries(adminRequestQueryKey),
    },
  );

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{
          override: requestSizeLimitOverrides[propName],
        }}
        validationSchema={
          yup.object().shape({
            override: yup
              .number()
              .min(250, 'Minimum of 250')
              .required('Required'),
          })
        }
        onSubmit={async ({ override }, { setSubmitting }) => {
          await updateRequestSizeLimits({
            rfqId,
            [propName]: override,
          }, {
            onSettled: () => setSubmitting(false),
          });
        }}
      >
        {({ isSubmitting, dirty, isValid, setSubmitting }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              Edit {label} override
            </ModalHeader>
            <ModalBody>
              <TextField
                required
                name="override"
                label={`${upperFirst(label)} override`}
                format="integer.positive"
              />
              <MessageBlock variant="info">
                This value will override the general {label} for
                this request only. The current general limit is {defaultRequestSizeLimits[propName]}.
              </MessageBlock>
            </ModalBody>
            <ModalFooter>
              {requestSizeLimitOverrides[propName] && (
                <Button
                  variant="danger-outline"
                  onClick={async () => {
                    setSubmitting(true);
                    await updateRequestSizeLimits({
                      rfqId,
                      [propName]: null,
                    }, {
                      onSettled: () => setSubmitting(false),
                    });
                  }}
                  disabled={isSubmitting}
                >
                  Remove override
                </Button>
              )}
              <Flex flex={1} justifyContent="flex-end">
                <CancelButton onClick={onCancel} mr={2} />
                <SaveButton disabled={isSubmitting || !dirty || !isValid} />
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const RequestPropertyList = ({
  requestId,
  meta,
}: {
  requestId: string;
  meta: AdminRequestMeta;
}) => {
  const { t } = useTranslation();
  const structure = rfx.useStructure();
  const template = rfx.useTemplate();
  const editRequestSizeLimitOverrideModal = useModalState();
  const [requestSizeLimitOverridePropName, setRequestSizeLimitOverridePropName] =
    useState<'maxComplexity' | 'maxExchangeDefCount' | null>(null);

  const generalProperties = useMemo(
    () => {
      const { autoReferenceNumber, summary, stages } = structure;

      return [
        { name: 'Status', value: t(`request.status.${structure.extendedStatus}`) },
        { name: 'ID', value: requestId, Component: Id },
        { name: 'Reference', value: autoReferenceNumber },
        { name: 'Created on', value: meta.creationTimestamp, Component: Datetime },
        { name: 'Created by', value: meta.creatorUsername },
        { name: 'Origin template', value: template, Component: TemplateLinkOrDash },
        { name: 'Deadline', value: last(stages)?.completionDeadline, Component: Datetime },
        { name: 'Enhanced?', value: summary.isEnhancedListing ? 'Yes' : 'No' },
        {
          requestSizeLimitPropName: 'maxComplexity',
          name: 'Request size limit override',
          value: meta.requestSizeLimitOverrides.maxComplexity
            ? localeFormatNumber(meta.requestSizeLimitOverrides.maxComplexity, { locale: APP_ADMIN_LOCALE })
            : undefined,
        },
        {
          requestSizeLimitPropName: 'maxExchangeDefCount',
          name: 'Maximum exchange definition count override',
          value: meta.requestSizeLimitOverrides.maxExchangeDefCount
            ? localeFormatNumber(meta.requestSizeLimitOverrides.maxExchangeDefCount, { locale: APP_ADMIN_LOCALE })
            : undefined,
        },
      ];
    },
    [meta, requestId, structure, template, t],
  );

  return (
    <>
      <PropertyList properties={generalProperties}>
        {generalProperties.map(({ requestSizeLimitPropName }, index) => {
          return requestSizeLimitPropName ? (
            <PropertyListAction
              key={index}
              label="Edit"
              icon="pencil-alt"
              onClick={() => {
                setRequestSizeLimitOverridePropName(requestSizeLimitPropName as any);
                editRequestSizeLimitOverrideModal.open();
              }}
            />
          ) : (
            <br key={index} />
          );
        }) as any}
      </PropertyList>
      {requestSizeLimitOverridePropName && (
        <EditRequestSizeLimitOverrideModal
          isOpen={editRequestSizeLimitOverrideModal.isOpen}
          onCancel={editRequestSizeLimitOverrideModal.close}
          onSave={editRequestSizeLimitOverrideModal.close}
          onRequestClose={editRequestSizeLimitOverrideModal.close}
          propName={requestSizeLimitOverridePropName}
          defaultRequestSizeLimits={meta.defaultRequestSizeLimits}
          requestSizeLimitOverrides={meta.requestSizeLimitOverrides}
        />
      )}
    </>
  );
};
