import * as React from 'react';
import {
  FrozenHeaderCellProps,
  DataCellProps,
} from '../core/utils';
import { ComparisonGrid, ComparisonGridProps } from '../core/ComparisonGrid';
import { RenderGridDataCell } from './RenderGridDataCell';
import { useGridIdPrefix } from './gridIdPrefix';
import { DEFAULT_EDITABLE_DATA_CELL_WIDTH, DEFAULT_ROW_HEIGHT, EditableGridColumn } from './utils';

export const navigableRange = { startRowIndex: 1, startColumnIndex: 0 };

export const FrozenHeaderCell = React.memo(({ column }: FrozenHeaderCellProps<EditableGridColumn>) => {
  const { Header } = column.original;

  return (
    <Header column={column} />
  );
});

export const ReadOnlyDataCell = React.memo((props: DataCellProps<EditableGridColumn, any, any>) => {
  const { column } = props;
  const ValueCell = column?.original.ValueCell;

  return ValueCell ? (
    <ValueCell {...props} />
  ) : (
    null
  );
});

type ReadOnlyGridProps<TRowOriginal = unknown> = Omit<
  React.ComponentProps<typeof ComparisonGrid>,
  | 'columnData'
  | 'subRowHeight'
  | 'FirstColumnCell'
  | 'RenderDataCell'
  | 'FrozenHeaderCell'
  | 'DataCell'
  | 'staticRowHeights'
  | 'bodyPaddingLeft'
  | 'bodyPaddingRight'
  | 'bodyPaddingBottom'
  | 'navigableRange'
  | 'onDataCellKeyboardAction'
  | 'onActiveCellChange'
  | 'idPrefix'
  | 'onScroll'
  | 'editedCell'
  | 'contentAreaSubcolumnData'
  | 'frozenLeftSubcolumnData'
  | 'SecondRowFrozenHeaderCell'
  | 'FrozenFooterCell'
> & {
  columns: EditableGridColumn<TRowOriginal>[];
  GridComponent?: (props: ComparisonGridProps<any, any, any>) => React.ReactElement;
  RenderDataCell?: any;
};

export const ReadOnlyGrid = <TRowOriginal = unknown>({
  columns,
  GridComponent = ComparisonGrid,
  RenderDataCell = RenderGridDataCell,
  defaultColumnWidth = DEFAULT_EDITABLE_DATA_CELL_WIDTH,
  ...props
}: ReadOnlyGridProps<TRowOriginal>) => {
  const idPrefix = useGridIdPrefix();

  return (
    <GridComponent
      columnData={columns}
      subRowHeight={DEFAULT_ROW_HEIGHT}
      defaultColumnWidth={defaultColumnWidth}
      FirstColumnCell={ReadOnlyDataCell}
      RenderDataCell={RenderDataCell}
      FrozenHeaderCell={FrozenHeaderCell}
      DataCell={ReadOnlyDataCell}
      staticRowHeights
      bodyPaddingLeft={0}
      bodyPaddingRight={0}
      bodyPaddingBottom={0}
      navigableRange={navigableRange}
      idPrefix={idPrefix}
      {...props}
    />
  );
};
