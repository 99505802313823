import * as React from 'react';
import { Box, Flex, SxStyleProp } from 'rebass/styled-components';
import { PieChart, PieChartRef, PieChartSlice, updateActiveSlice } from '@deepstream/ui-kit/charts/PieChart';
import { PieChartSliceList } from '@deepstream/ui-kit/charts/PieChartSliceList';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import * as dashboard from './dashboard';

/**
 * Component to render a panel containing a pie chart on the left and a
 * legend on the right.
 * Children of the component are rendered below the legend.
 */
export const PieChartPanel: React.FC<{
  heading: string;
  headingTestAttribute?: string;
  slices: PieChartSlice[];
  chartDescription: string;
  onActiveSliceChange?: (sliceId: string | null) => void;
  sx?: SxStyleProp,
}> = ({
  heading,
  headingTestAttribute,
  slices,
  chartDescription,
  onActiveSliceChange,
  sx,
  children,
}) => {
  const chartRef = React.useRef<PieChartRef>(null);
  const [activeSliceId, setActiveSliceId] = React.useState<string | null>(null);

  React.useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.data.datasets.forEach((dataset) => {
        updateActiveSlice(dataset, activeSliceId);
      });

      chart.update();

      onActiveSliceChange?.(activeSliceId);
    }
  }, [activeSliceId, onActiveSliceChange]);

  return (
    <Panel sx={{ maxWidth: 492, height: '100%', ...sx }} p="20px 0 20px 20px">
      <Flex flexDirection="column" height="100%">
        <dashboard.PanelHeading data-test={headingTestAttribute}>
          {heading}
        </dashboard.PanelHeading>
        <Flex flex={1}>
          <Flex mt={3} mr={20} flex={1} flexDirection="column" justifyContent="space-between">
            <PieChartSliceList
              slices={slices}
              activeSliceId={activeSliceId}
              setActiveSliceId={setActiveSliceId}
            />
            {children}
          </Flex>
          <Box flex="0 0 200px" mt={2}>
            <PieChart
              ref={chartRef}
              slices={slices}
              onActiveSliceChange={setActiveSliceId}
              description={chartDescription}
            />
          </Box>
        </Flex>
      </Flex>
    </Panel>
  );
};
