import { useState } from 'react';

import { Flex, Text } from 'rebass/styled-components';
import { Panel, PanelDivider, PanelHeader, PanelPadding, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { ControlledRadioButtonGroup } from '@deepstream/ui/ui/RadioButton';

import { ProductDashboardMetric, ProductDashboardSectionContainer, ProductDashboardSectionHeader } from './ProductDashboardUtils';

const ProductDashboardGeneralUserSessions = ({ status, data }) => {
  const [value, setValue] = useState('all');

  return (
    <PanelPadding>
      <Flex flexDirection="column" sx={{ gap: 3 }}>
        <ProductDashboardSectionHeader
          heading="User sessions"
          icon="timer"
          tooltip="A user session begins when a user starts actively engaging with DeepStream and ends after 30 minutes of inactivity. It is measured from the user’s first interaction to their last interaction."
          right={(
            <ControlledRadioButtonGroup
              name="User sessions filter"
              onChange={setValue}
              value={value}
              inputs={[
                { label: 'All users', value: 'all' },
                { label: 'Paying', value: 'paying' },
                { label: 'Non-paying', value: 'nonPaying' },
              ]}
            />
          )}
        />

        <Flex flexDirection="column" sx={{ gap: 1 }}>
          <Text fontSize="14px" fontWeight={500}>Session length</Text>
          <ProductDashboardSectionContainer>
            <ProductDashboardMetric
              label="Today"
              value={renderSessionLength(data.data.activeSessions.today.sessionLength[value].total)}
              subValue={renderAverageSessionLength(data.data.activeSessions.today.sessionLength[value].userAverage)}
            />
            <ProductDashboardMetric
              label="This month"
              value={renderSessionLength(data.data.activeSessions.thisMonth.sessionLength[value].total)}
              subValue={renderAverageSessionLength(data.data.activeSessions.thisMonth.sessionLength[value].userAverage)}
            />
            <ProductDashboardMetric
              label="Last month"
              value={renderSessionLength(data.data.activeSessions.lastMonth.sessionLength[value].total)}
              subValue={renderAverageSessionLength(data.data.activeSessions.lastMonth.sessionLength[value].userAverage)}
            />
          </ProductDashboardSectionContainer>
        </Flex>

        <Flex flexDirection="column" sx={{ gap: 1 }}>
          <Text fontSize="14px" fontWeight={500}>Number of sessions</Text>
          <ProductDashboardSectionContainer>
            <ProductDashboardMetric
              label="Today"
              value={data.data.activeSessions.today.numberOfSessions[value].total}
              subValue={renderAverageNumberOfSessions(data.data.activeSessions.today.numberOfSessions[value].userAverage)}
            />
            <ProductDashboardMetric
              label="This month"
              value={data.data.activeSessions.thisMonth.numberOfSessions[value].total}
              subValue={renderAverageNumberOfSessions(data.data.activeSessions.thisMonth.numberOfSessions[value].userAverage)}
            />
            <ProductDashboardMetric
              label="Last month"
              value={data.data.activeSessions.lastMonth.numberOfSessions[value].total}
              subValue={renderAverageNumberOfSessions(data.data.activeSessions.lastMonth.numberOfSessions[value].userAverage)}
            />
          </ProductDashboardSectionContainer>
        </Flex>
      </Flex>
    </PanelPadding>
  );
};

const UniqueActiveUsers = ({ data }) => {
  const [value, setValue] = useState('all');

  return (
    <PanelPadding>
      <Flex flexDirection="column" sx={{ gap: 3 }}>
        <ProductDashboardSectionHeader
          heading="Unique active users"
          icon="users"
          tooltip={
            <Flex flexDirection="column" sx={{ gap: 2 }}>
              <Text>Number users who successfully log in at least once in the given time period</Text>
              <Text>Note: Paying company status and team membership are not currently tracked historically</Text>
            </Flex>
          }
          right={(
            <ControlledRadioButtonGroup
              name="Unique users filter"
              onChange={setValue}
              value={value}
              inputs={[
                { label: 'All users', value: 'all' },
                { label: 'Paying', value: 'paying' },
                { label: 'Non-paying', value: 'nonPaying' },
              ]}
            />
          )}
        />

        <ProductDashboardSectionContainer>
          <ProductDashboardMetric
            label="Today"
            value={data.data.activeUsers?.today[value]}
          />
          <ProductDashboardMetric
            label="This month"
            value={data.data.activeUsers?.thisMonth[value]}
          />
          <ProductDashboardMetric
            label="Last month"
            value={data.data.activeUsers?.lastMonth[value]}
          />
        </ProductDashboardSectionContainer>
      </Flex>
    </PanelPadding>
  );
};

const UniqueActiveCompanies = ({ data }) => {
  const [value, setValue] = useState('all');

  return (
    <PanelPadding>
      <Flex flexDirection="column" sx={{ gap: 3 }}>
        <ProductDashboardSectionHeader
          heading="Unique active companies"
          icon="building"
          tooltip={
            <Flex flexDirection="column" sx={{ gap: 2 }}>
              <Text>Number of companies where 1 or more company users successfully logs in at least once in the given time period</Text>
              <Text>Note: Paying company status and team membership are not currently tracked historically</Text>
            </Flex>
          }
          right={(
            <ControlledRadioButtonGroup
              name="Active companies filter"
              onChange={setValue}
              value={value}
              inputs={[
                { label: 'All companies', value: 'all' },
                { label: 'Paying', value: 'paying' },
                { label: 'Non-paying', value: 'nonPaying' },
              ]}
            />
          )}
        />

        <ProductDashboardSectionContainer>
          <ProductDashboardMetric
            label="Today"
            value={data.data.activeCompanies.today[value]}
          />
          <ProductDashboardMetric
            label="This month"
            value={data.data.activeCompanies.thisMonth[value]}
          />
          <ProductDashboardMetric
            label="Last month"
            value={data.data.activeCompanies.lastMonth[value]}
          />
        </ProductDashboardSectionContainer>
      </Flex>
    </PanelPadding>
  );
};

const renderSessionLength = (sessionLengthMs: number) => {
  const totalMinutes = Math.floor(sessionLengthMs / 60 / 1000);
  const days = Math.floor(totalMinutes / 60 / 24);
  const hours = Math.floor((totalMinutes % (60 * 24)) / 60);
  const minutes = totalMinutes % 60;
  let result = '';

  if (days > 0) {
    result += `${days}d `;
  }

  if (hours > 0) {
    result += `${hours}h `;
  }

  if (minutes > 0) {
    result += `${minutes}m`;
  }

  return result || '0m';
};

const renderAverageSessionLength = (sessionLength: number) => {
  if (!sessionLength) {
    return '—';
  }

  return `${renderSessionLength(sessionLength)} avg per user`;
};

const renderAverageNumberOfSessions = (numberOfSessions: number) => {
  if (!numberOfSessions) {
      return '—';
  }

  return `${numberOfSessions.toFixed(1)} avg per user`;
};

export const ProductDashboardGeneralPanel = ({ status, data }) => {
  return (
    <Panel>
      <PanelHeader heading="General" />
      <PanelDivider />
      {status === 'loading' ? (
        <PanelText>
          <Loading />
        </PanelText>
      ) : status === 'error' ? (
        <PanelText>Oh no</PanelText>
      ) : data.data ? (
        <>
          {isFinite(data.data.activeUsers?.today?.all) ? (
            <UniqueActiveUsers data={data} />
          ) : null}
          {data.data.activeCompanies ? (
            <UniqueActiveCompanies data={data} />
          ) : null}
          {data.data.activeSessions ? (
            <ProductDashboardGeneralUserSessions
              data={data}
              status={status}
            />
          ) : null}
        </>
      ) : data ? (
        <PanelText>No data</PanelText>
      ) : (
        null
      )}
    </Panel>
  );
};
