import * as React from 'react';
import { compact, flatten, zip } from 'lodash';
import { Text, Box, FlexProps, Flex, TextProps } from 'rebass/styled-components';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import * as rfx from '@deepstream/ui/rfx';
import { useContractData } from '@deepstream/ui/modules/Contracts/contract';
import { useQuestionnaireTemplateData } from '@deepstream/ui/modules/PreQualification/QuestionnaireTemplate/questionnaireTemplateUtils';
import { useQuestionnaireData } from '@deepstream/ui/modules/PreQualification/Questionnaire/questionnaireUtils';
import { Placement } from '@floating-ui/react';
import { CopyIdAndNameMenu } from './CopyIdAndNameMenu';
import { CopyUserMenu } from './CopyUserMenu';

// intersperse([1, 2, 3], 'x')
// => [1, 'x', 2, 'x', 3]
const intersperse = (array: any[], separator: any) => {
  const separators = Array.from(
    { length: array.length - 1 },
    () => separator,
  );

  return compact(flatten(zip(array, separators)));
};

const TextSize = {
  small: 14,
  medium: 18,
  large: 35,
};

type IconTextProps = TextProps & {
  size: 'small' | 'medium' | 'large';
  icon: IconProps['icon'];
  solid?: boolean;
  regular?: boolean;
};

export const IconText: React.FC<IconTextProps> = React.forwardRef(({ size, icon, solid, regular, children, ...props }, ref) => (
  <Text ref={ref} as="span" color="text" fontSize={TextSize[size]} lineHeight={1.3} fontWeight={500} {...props}>
    <Flex style={{ display: 'inline-flex' }}>
      <Box minWidth={size === 'small' ? 20 : 30} mr={size === 'small' ? 2 : 3} style={{ display: 'inline-block', textAlign: 'center' }}>
        <Icon
          icon={icon}
          solid={solid}
          regular={regular}
          aria-hidden="true"
          fontSize={size === 'small' ? 20 : TextSize[size]}
        />
      </Box>
      <Box>
        {children}
      </Box>
    </Flex>
  </Text>
));

IconText.displayName = 'IconText';

export const QuestionnaireTemplate = ({
  placement = 'left',
  ...props
}: {
  placement?: Placement;
} & Omit<IconTextProps, 'icon'>) => {
  const { _id: templateId, summary } = useQuestionnaireTemplateData();

  return (
    <CopyIdAndNameMenu
      data={{ ...summary, _id: templateId }}
      placement={placement}
      nameKey="name"
    >
      <IconText icon="file-alt" regular {...props}>
        {summary.name}
      </IconText>
    </CopyIdAndNameMenu>
  );
};

export const Questionnaire = ({
  placement = 'left',
  ...props
}: {
  placement?: Placement;
} & Omit<IconTextProps, 'icon'>) => {
  const { _id: questionnaireId, summary } = useQuestionnaireData();

  return (
    <CopyIdAndNameMenu
      data={{ ...summary, _id: questionnaireId }}
      placement={placement}
      nameKey="name"
    >
      <IconText icon="file-alt" regular {...props}>
        {summary.name}
      </IconText>
    </CopyIdAndNameMenu>
  );
};

export const Request = ({
  placement = 'left',
  requestId,
  ...props
}: {
  placement?: Placement;
  requestId: string;
} & Omit<IconTextProps, 'icon'>) => {
  const { summary } = rfx.useStructure();

  return (
    <CopyIdAndNameMenu
      data={{ ...summary, _id: requestId }}
      placement={placement}
      nameKey="subject"
    >
      <IconText icon="file-alt" regular {...props}>
        {summary.subject}
      </IconText>
    </CopyIdAndNameMenu>
  );
};

export const Template = ({
  placement = 'left',
  ...props
}: {
  placement?: Placement;
} & Omit<IconTextProps, 'icon'>) => {
  const template = rfx.useTemplate();
  return (
    <CopyIdAndNameMenu
      data={{ _id: template._id, ...template.meta }}
      placement={placement}
      nameKey="name"
    >
      <IconText icon="files-o" {...props}>
        {template.meta.name}
      </IconText>
    </CopyIdAndNameMenu>
  );
};

export const Contract = ({
  placement = 'left',
  ...props
}: {
  placement?: Placement;
} & Omit<IconTextProps, 'icon'>) => {
  const { _id: contractId, summary } = useContractData();

  return (
    <CopyIdAndNameMenu
      data={{ ...summary, _id: contractId }}
      placement={placement}
      nameKey="name"
    >
      <IconText icon="file-alt" regular {...props}>
        {summary.name}
      </IconText>
    </CopyIdAndNameMenu>
  );
};

export const Company = ({
  placement = 'left',
  company,
  ...props
}: {
  placement?: Placement;
  company: CompanyMinimized;
} & Omit<IconTextProps, 'icon'>) => (
  <CopyIdAndNameMenu data={company} placement={placement}>
    <IconText icon="building" regular {...props}>
      {company.name}
    </IconText>
  </CopyIdAndNameMenu>
);

export const User: React.FC<any> = ({ placement = 'left', user, ...props }) => (
  <CopyUserMenu user={user} placement={placement}>
    <IconText icon="user" regular {...props}>
      {user.name || user.email}
    </IconText>
  </CopyUserMenu>
);

export const RecentActivity: React.FC<any> = (props) => (
  <IconText icon="tachometer-alt" solid {...props}>
    Recent activity
  </IconText>
);

export const Dashboard: React.FC<any> = (props) => (
  <IconText icon="rocket" regular {...props}>
    Dashboard
  </IconText>
);

export const Auction: React.FC<any> = (props) => (
  <IconText icon="gavel" {...props}>
    Auction
  </IconText>
);

export const Container: React.FC<FlexProps> = ({ flexDirection, children, ...props }) => {
  const childrenArray = React.Children.toArray(children);

  return (
    flexDirection === 'column' ? (
      <Box mt={3} mb={4} {...props} >
        {/*  simulate newlines between child elements */}
        {intersperse(childrenArray, <Box mb="5px" />).map((child, key) => ({ ...child, key }))}
      </Box>
    ) : (
      <Flex mt={3} mb={4} {...props} flexDirection={flexDirection}>
        {children}
      </Flex>
    )
  );
};
