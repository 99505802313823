import * as React from 'react';
import Downshift, { DownshiftState } from 'downshift';
import { without } from 'lodash';

const stateReducer = (state: DownshiftState<any>, changes: any) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return {
        ...changes,
        highlightedIndex: state.highlightedIndex,
        isOpen: true,
        inputValue: '',
      };
    default:
      return changes;
  }
};

/*
 * Inspired by multi-select example provided by the downshift docs:
 * https://codesandbox.io/s/github/kentcdodds/downshift-examples/tree/master
 */

// FIXME: tighter types

type State = {
  selectedItems: any[];
};

type MultiDownshiftProps = any;

export class MultiDownshift extends React.Component<MultiDownshiftProps, State> {
  override state: State = {
    selectedItems: [],
  };

  constructor(props: MultiDownshiftProps) {
    super(props);

    if (props.selectedItems) {
      this.state.selectedItems = props.selectedItems;
    }
  }

  handleSelection = (selectedItem: any, downshift: DownshiftState<any>) => {
    if (this.state.selectedItems.includes(selectedItem)) {
      this.removeItem(selectedItem, () => {
        const { onDeselect, onChange } = this.props;
        const { selectedItems } = this.state;

        if (onDeselect) {
          onDeselect(selectedItem, this.getStateAndHelpers(downshift));
        }

        if (onChange) {
          onChange(selectedItems, this.getStateAndHelpers(downshift));
        }
      });
    } else {
      this.addSelectedItem(selectedItem, () => {
        const { onSelect, onChange } = this.props;
        const { selectedItems } = this.state;

        if (onSelect) {
          onSelect(selectedItem, this.getStateAndHelpers(downshift));
        }

        if (onChange) {
          onChange(selectedItems, this.getStateAndHelpers(downshift));
        }
      });
    }
  };

  override componentDidUpdate(prevProps: MultiDownshiftProps) {
    if (this.props.selectedItems !== prevProps.selectedItems) {
      this.setState({ selectedItems: this.props.selectedItems });
    }
  }

  removeItem = (item: any, cb?: any) => {
    this.setState(
      ({ selectedItems }) => ({ selectedItems: without(selectedItems, item) }),
      cb,
    );
  };

  addSelectedItem(item: any, cb?: any) {
    this.setState(
      ({ selectedItems }) => ({ selectedItems: [...selectedItems, item] }),
      cb,
    );
  }

  getRemoveButtonProps: any = ({ onClick, onKeyDown, item, ...props }: any = {}) => ({
    onClick: (event: React.MouseEvent) => {
      // TODO: use something like downshift's composeEventHandlers utility instead
      if (onClick) onClick(event);
      event.stopPropagation();
      this.removeItem(item);
    },
    onKeyDown: (event: React.KeyboardEvent) => {
      if (onKeyDown) onKeyDown(event);

      if (event.key === 'Enter') {
        event.stopPropagation();
        this.removeItem(item);
      }
    },
    ...props,
  });

  getStateAndHelpers(downshift: any) {
    const { selectedItems } = this.state;
    const { getRemoveButtonProps, removeItem } = this;

    return {
      getRemoveButtonProps,
      removeItem,
      selectedItems,
      ...downshift,
    };
  }

  override render() {
    // We don't pass `onSelect` into `Downshift` to avoid calling it twice
    const { children, onSelect, ...props } = this.props;

    if (typeof children !== 'function') {
      throw new Error('prop `children` must be a function');
    }

    // TODO: compose together props (rather than overwriting them) like downshift does
    return (
      <Downshift
        {...props}
        stateReducer={stateReducer}
        onChange={this.handleSelection}
        selectedItem={null}
      >
        {downshift => children(this.getStateAndHelpers(downshift))}
      </Downshift>
    );
  }
}
