import { createMachine, assign, DoneInvokeEvent, send } from 'xstate';
import { groupBy, pick, map, mapValues } from 'lodash';
import { Invite, InvitedPerson, Invitation, InviteModalResponse } from './types';

type InviteModalEvent = DoneInvokeEvent<any>;

export enum InviteModalActions {
  INIT = 'init',
  RESET = 'reset',
  BACK = 'back',
  NEXT = 'next',
  ADD_INVITED_PERSONS = 'addInvitedPersons',
  DISMISS = 'dismiss',
  HANDLE_STATUS_REPORT = 'handleStatusReport',
  POST_DATA = 'postData',
  RESET_MACHINE = 'resetMachine',
  SAVE_BULK_VALIDATION_ERROR = 'setBulkValidationError',
  VALIDATE_BULK_VALIDATION_RESPONSE = 'validateBulkValidationResponse',
}

interface InviteModalContext {
  companyId: string;
  errorBulkValidationEndpoint: string;
  errorFailedInvites: string;
  errorInvalidInvites: string;
  failedAddNewCompany: any;
  failedInvites: any;
  invalidInvites: Invite[];
  invitedPersons: InvitedPerson[];
  invitation: Invitation;
  payload: any[];
  successfulInvites: InviteModalResponse[];
}

export const inviteModalInitialContext = {
  companyId: '',
  errorBulkValidationEndpoint: '',
  errorFailedInvites: '',
  errorInvalidInvites: '',
  failedAddNewCompany: [],
  failedInvites: [], // Invites that were attempted to be sent to '/ajax/company/:companyId/invites' endpoint, but that have failed for different reasons
  invalidInvites: [], // These users have already been invited or have joined in another form. Response is from '/ajax/company/:companyId/invites/bulkValidation'
  invitedPersons: [],
  invitation: { message: '', locale: '' },
  payload: [],
  successfulInvites: [],
};

function preparePayloadFunction({ invitedPersons, invitation }) {
  const invitedPersonsByCompanyId = groupBy(invitedPersons, person => person.company._id);
  const companyById = mapValues(invitedPersonsByCompanyId, person => {
    if (!person[0].company._id.includes('temp-id-')) {
      return person[0].company;
    } else {
      return pick(person[0].company, ['name']);
    }
  });

  return map(
    invitedPersonsByCompanyId,
    (person, companyId) => ({
      company: companyById[companyId],
      users: map(person, person => pick(person, ['email', 'firstName', 'lastName'])),
      message: invitation.message,
      locale: invitation.locale,
    }),
  );
}

export const inviteModalMachine = createMachine<InviteModalContext, InviteModalEvent>({
  id: 'inviteModalMachine',
  initial: 'choosePersons',
  on: {
    [InviteModalActions.RESET_MACHINE]: {
      target: 'choosePersons',
      actions: ['resetContext'],
    },
  },
  states: {

    choosePersons: {
      id: 'choosePersons',
      initial: 'start',
      states: {
        start: {
          on: {
            [InviteModalActions.ADD_INVITED_PERSONS]:
              {
                target: '#validatingInvites',
                actions: ['addInvitedPersonsToContext', 'clearFailedInvites'],
              },
          },
        },

        validatingInvites: {
          id: 'validatingInvites',
          invoke: {
            src: 'postToInviteBulkValidation',
            onDone: {
              target: '#processingBulkValidation',
              actions: ['setBulkValidationResponse'],
            },
            onError: {
              target: '#choosePersons',
            },
          },
        },

        processingBulkValidation: {
          id: 'processingBulkValidation',
          always: [
            { cond: 'isEveryInviteValid', target: '#customizeMessage' },
            { target: '#choosePersons' },
          ],
        },
      },
    },

    customizeMessage: {
      id: 'customizeMessage',
      exit: ['setMessageInContext'],
      on: {
        [InviteModalActions.BACK]: 'choosePersons',
        [InviteModalActions.NEXT]: 'review',
      },
    },

    review: {
      id: 'review',
      initial: 'preparePayload',
      exit: ['clearFailedAddNewCompany', 'clearFailedInvites'],
      states: {

        preparePayload: {
          id: 'preparePayload',
          entry: ['preparePayloadAction'],
          on: {
            [InviteModalActions.POST_DATA]: 'postPayload',
          },
        },

        postPayload: {
          id: 'postPayload',
          entry: ['clearFailedAddNewCompany', 'clearFailedInvites'],
          invoke: {
            src: 'sendAllInvites',
            onDone: {
              target: 'postingFinished',
            },
          },
          on: {
            [InviteModalActions.HANDLE_STATUS_REPORT]: {
              actions: ['setSuccessfulInvites', 'setFailedInvites', 'setFailedAddNewCompany'],
            },
          },
        },

        postingFinished: {
          id: 'postingFinished',
          always: [
            { cond: 'failedInvitesExist', target: '#postingFinishedWithErrors' },
            { cond: 'failedAddNewCompanyExist', target: '#postingFinishedWithErrors' },
            { target: '#postingFinishedNoErrors' },
          ],
        },

        postingFinishedWithErrors: {
          id: 'postingFinishedWithErrors',
        },

        postingFinishedNoErrors: {
          id: 'postingFinishedNoErrors',
        },

      },
      on: {
        [InviteModalActions.BACK]: 'customizeMessage',
      },
    },

  },
},
{
  actions: {
    addInvitedPersonsToContext: assign({ invitedPersons: (_, event) => event.data }),

    clearFailedAddNewCompany: assign({ failedAddNewCompany: () => [] }) as any,
    clearFailedInvites: assign({ failedInvites: () => [] }) as any,

    preparePayloadAction: assign({ payload: (context) => preparePayloadFunction(context) }),
    resetContext: assign(() => inviteModalInitialContext),

    setBulkValidationResponse: assign({
      invalidInvites: (_, event) => event.data.invalidInvites,
    }),

    setFailedAddNewCompany: assign({ failedAddNewCompany: (context, event) => {
      if (event.data.statusReport.companyCreate === 'failed') {
        return [...context.failedAddNewCompany, event.data.invitedCompany.name];
      } else {
        return [...context.failedAddNewCompany];
      }
    } }),
    setFailedInvites: assign({ failedInvites: (context, event) => {
      if (event.data.statusReport.failedInvites.length > 0) {
        return context.failedInvites.concat(event.data.statusReport.failedInvites);
      } else {
        return [...context.failedInvites];
      }
    } }),

    setSuccessfulInvites: assign({ successfulInvites: (context, event) => {
      if (event.data.statusReport.companyCreate !== 'failed') {
        return [...context.successfulInvites, { company: event.data.invitedCompany, users: event.data.users }];
      } else {
        return [...context.successfulInvites];
      }
    } }),

    setMessageInContext: assign({ invitation: (_, event) => event.data }),
    transitionToValidate: send(InviteModalActions.VALIDATE_BULK_VALIDATION_RESPONSE),
  },
  guards: {
    isEveryInviteValid: (context) => context.invalidInvites?.length === 0,
    failedInvitesExist: (context) => context.failedInvites?.length > 0,
    failedAddNewCompanyExist: (context) => context.failedAddNewCompany?.length > 0,
  },
},
);
