import { useQuery } from 'react-query';
import { useApi, wrap } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { SupplierList } from './types';

export const useSupplierLists = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: supplierLists = [], status: supplierListsStatus, refetch: refetchSupplierLists } = useQuery<SupplierList[]>(
    ['supplierLists', { companyId: currentCompanyId }],
    wrap(api.getSupplierLists),
    {
      staleTime: 100,
    },
  );

  return {
    supplierLists,
    supplierListsStatus,
    refetchSupplierLists,
  };
};
