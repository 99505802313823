import * as React from 'react';
import { useQueries, useQuery } from 'react-query';
import { map, zipWith } from 'lodash';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { PanelLoadingWrapper } from '@deepstream/ui/ui/Loading';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { AddUserToCompanyModal } from '@deepstream/ui/modules/Company/TeamManagement/AddUserToCompanyModal';
import { useCompanyUsers } from '@deepstream/ui/modules/Company/TeamManagement/useCompanyUsers';
import { User, IntegrationDataType } from '@deepstream/ui/types';
import { Company } from '@deepstream/ui/ui/types';
import { ErrorPanel } from '@deepstream/ui/ui/ErrorMessage';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { CompanyRequestsTable } from './CompanyRequestsTable';
import { CompanyMembershipRequestsTable } from './CompanyMembershipRequestsTable';
import { CompanyClaimsTable } from './CompanyClaimsTable';
import * as Title from '../../title';
import { CompanyUsersTable } from './CompanyUsersTable';
import { CompanyGeneralPropertyList } from './CompanyGeneralPropertyList';
import { CompanyProfilePropertyList } from './CompanyProfilePropertyList';
import { CompanyReportsPropertyList } from './CompanyReportsPropertyList';
import { CompanyFeatureList } from './CompanyFeatureList';
import { ExternalCompaniesTable } from './ExternalCompaniesTable';
import { useCombinedQueryStatus } from '../../useCombinedQueryStatus';
import { CompanyTemplatesTable } from './CompanyTemplatesTable';
import { CompanySentContractsTable } from './CompanyContractsTable';
import { ExternalSystemsPanel } from './AdminExternalSystemsPanel';
import { CompanyQuestionnaireTemplatesTable } from './CompanyQuestionnaireTemplatesTable';
import { CompanyQuestionnairesTable } from './CompanyQuestionnairesTable';

const useClaimUsers = (company?: Company) => {
  const adminApi = useAdminApi();

  const companyClaims = company?.claims || [];

  const queryResult = useQueries(
    companyClaims.map(claim => ({
      queryKey: ['user', { userId: claim.userId }],
      queryFn: wrap(adminApi.getUser),
    })),
  );

  return {
    data: map(queryResult, 'data') as Array<User | undefined>,
    statuses: map(queryResult, 'status'),
  };
};

const useClaimsWithUsers = (company?: Company, users?: Array<User | undefined>) => React.useMemo(() => {
  if (!company || !company.claims || !users) {
    return [];
  }

  return zipWith(company.claims, users, (claim, user) => ({
    claim,
    user: user ?? {
      _id: claim.userId,
      name: '',
    } as User,
  })).map((claimWithUser, index) => ({
    ...claimWithUser,
    // add ID to provide a table key
    _id: String(index),
  }));
}, [company, users]);

const OpenCompanyProfileButton: React.FC<{ companyId: string }> = ({ companyId }) => {
  const openCompanyProfile = React.useCallback(
    () => {
      window.open(`${process.env.NX_APP_URL}/network/99/company-profile/${companyId}`);
    },
    [companyId],
  );

  return (
    <Button
      small
      iconLeft="external-link-alt"
      onClick={openCompanyProfile}
    >
      Open full profile
    </Button>
  );
};

export const PageTitle: React.FC<any> = ({ flexDirection, company }) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.Company size={flexDirection === 'column' ? 'large' : 'small'} company={company} />
  </Title.Container>
);

export const CompanyDetails = ({ company }: { company: Company }) => {
  const adminApi = useAdminApi();

  const companyId = company._id;

  const addUserToCompanyModal = useModalState();

  const { data: users = [], status: usersStatus } = useCompanyUsers(companyId);

  const { data: requests, status: requestsStatus } = useQuery(
    ['requests', { companyId }],
    wrap(adminApi.getCompanyRequests),
  );

  const { data: questionnaireTemplates, status: questionnaireTemplatesStatus } = useQuery(
    ['questionnaireTemplates', { companyId }],
    wrap(adminApi.getCompanyQuestionnaireTemplates),
  );

  const { data: questionnaires, status: questionnairesStatus } = useQuery(
    ['receivedQuestionnaires', { companyId }],
    wrap(adminApi.getCompanyReceivedQuestionnaires),
  );

  const { data: sentContracts, status: sentContractsStatus } = useQuery(
    ['sentContracts', { companyId }],
    wrap(adminApi.getCompanySentContracts),
  );

  const { data: membershipRequests, status: membershipRequestsStatus } =
    useQuery(
      ['membershipRequests', { companyId }],
      wrap(adminApi.getCompanyMembershipRequests),
    );

  const { data: templates, status: templatesStatus } = useQuery(
    ['templates', { companyId }],
    wrap(adminApi.getCompanyTemplates),
  );

  const { data: claimUsers, statuses: claimUserStatuses } = useClaimUsers(company);
  const claimsWithUsersStatus = useCombinedQueryStatus(...claimUserStatuses);
  const claimsWithUsers = useClaimsWithUsers(company, claimUsers);

  const { data: integrationData, isError: errorLoadingIntegrationData } = useQuery(
    ['integrationData', { companyId, type: IntegrationDataType.EXTERNAL_COMPANY_TO_INTERNAL_COMPANY }],
    wrap(adminApi.getIntegrationDataByCompany),
  );

  return (
    <>
      <Stack gap={4}>
        <Panel heading="General">
          <CompanyGeneralPropertyList company={company} />
        </Panel>

        <Panel heading="Features">
          <CompanyFeatureList company={company} />
        </Panel>

        <Panel>
          <PanelHeader heading="Users">
            <Button small iconLeft="plus" onClick={addUserToCompanyModal.open} disabled={usersStatus !== 'success'}>
              Add user
            </Button>
          </PanelHeader>
          <PanelDivider />
          <PanelLoadingWrapper status={usersStatus} data={users} emptyDataMessage="No team members yet">
            <CompanyUsersTable companyId={company._id} users={users} />
          </PanelLoadingWrapper>
        </Panel>

        <Panel heading="Requests to join">
          <PanelLoadingWrapper status={membershipRequestsStatus} data={membershipRequests} emptyDataMessage="No requests">
            <CompanyMembershipRequestsTable
              companyId={company._id}
              membershipRequests={membershipRequests}
            />
          </PanelLoadingWrapper>
        </Panel>

        <Panel heading="Claims">
          <PanelLoadingWrapper status={claimsWithUsersStatus} data={claimsWithUsers} emptyDataMessage="No claims yet">
            <CompanyClaimsTable
              company={company}
              claimsWithUsers={claimsWithUsers}
            />
          </PanelLoadingWrapper>
        </Panel>

        <Panel heading="Templates">
          <PanelLoadingWrapper status={templatesStatus} data={templates} emptyDataMessage="No templates">
            <CompanyTemplatesTable templates={templates} />
          </PanelLoadingWrapper>
        </Panel>

        <Panel heading="Active questionnaires">
          <PanelLoadingWrapper status={questionnaireTemplatesStatus} data={questionnaireTemplates} emptyDataMessage="No questionnaires yet">
            <CompanyQuestionnaireTemplatesTable templates={questionnaireTemplates} />
          </PanelLoadingWrapper>
        </Panel>

        <Panel heading="Received questionnaires">
          <PanelLoadingWrapper status={questionnairesStatus} data={questionnaires} emptyDataMessage="No questionnaires received yet">
            <CompanyQuestionnairesTable questionnaires={questionnaires} />
          </PanelLoadingWrapper>
        </Panel>

        <Panel heading="Requests">
          <PanelLoadingWrapper status={requestsStatus} data={requests} emptyDataMessage="No requests yet">
            <CompanyRequestsTable companyId={company._id} requests={requests} />
          </PanelLoadingWrapper>
        </Panel>

        <Panel heading="Sent Contracts">
          <PanelLoadingWrapper status={sentContractsStatus} data={sentContracts} emptyDataMessage="No sent contracts yet">
            <CompanySentContractsTable sentContracts={sentContracts} />
          </PanelLoadingWrapper>
        </Panel>

        <ExternalSystemsPanel company={company} />

        {errorLoadingIntegrationData ? (
          <ErrorPanel error="Could not load external companies data" />
        ) : integrationData?.length ? (
          <Panel heading="External Companies Data">
            <ExternalCompaniesTable integrationData={integrationData} />
          </Panel>
        ) : (
          null
        )}

        <Panel>
          <PanelHeader heading="Profile">
            <OpenCompanyProfileButton companyId={company._id} />
          </PanelHeader>
          <PanelDivider />
          <CompanyProfilePropertyList company={company} />
        </Panel>

        <Panel heading="Reports">
          <CompanyReportsPropertyList companyId={companyId} />
        </Panel>
      </Stack>
      <AddUserToCompanyModal
        companyId={companyId}
        isPayingCompany={Boolean(company?.subscription)}
        isOpen={addUserToCompanyModal.isOpen}
        onCancel={addUserToCompanyModal.close}
        onSave={addUserToCompanyModal.close}
        onRequestClose={addUserToCompanyModal.close}
      />
    </>
  );
};
