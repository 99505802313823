import * as React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { isEmpty, isNil, omitBy } from 'lodash';
import { useApi, wrap } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { Operator } from './types';

export type SearchState = {
  text: string;
  pageIndex: number;
  pageSize: number;
  supplierListIds: string[];
  deliveryCountries: string[];
  products: string[];
  productsOperator?: Operator;
  /** @deprecated legacy product tags */
  productTags: string[];
};

const SearchStateContext = React.createContext<SearchState | null>(null);

export const useSearchState = () => {
  const searchState = React.useContext(SearchStateContext);
  if (!searchState) throw new Error('No search state found');
  return searchState;
};

export const SearchStateProvider: React.FC<{ searchState: SearchState }> = ({
  searchState,
  children,
}) => (
  <SearchStateContext.Provider value={searchState}>
    {children}
  </SearchStateContext.Provider>
);

export const useCompanySearch = ({ isInternalNetwork }: { isInternalNetwork: boolean }) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const search = useSearch({ strict: false }) as SearchState;
  const navigate = useNavigate();

  const updateSearch = React.useCallback(
    (newSearch: Partial<SearchState>) => {
      navigate({
        // Drop empty arrays/empty strings/undefined/null from the search params so that the URL is cleaner
        search: omitBy(
          { ...search, ...newSearch },
          value => Array.isArray(value) ? isEmpty(value) : (isNil(value) || value === ''),
        ),
      });
    },
    [navigate, search],
  );

  const request = useQuery(
    [isInternalNetwork ? 'searchInternalCompanies' : 'searchExternalCompanies', { currentCompanyId, searchState: search }],
    wrap(isInternalNetwork ? api.searchInternalCompaniesWithAggregations : api.searchExternalCompaniesWithAggregations),
  );

  return React.useMemo(
    () => ({
      // Request state
      request,

      // Search state
      ...search,

      // Actions
      setPageIndex: (pageIndex: number) => {
        updateSearch({ pageIndex });
      },
      nextPage: () => {
        updateSearch({ pageIndex: search.pageIndex + 1 });
      },
      previousPage: () => {
        updateSearch({ pageIndex: search.pageIndex - 1 });
      },
      updateSupplierListIds: (supplierListIds: SearchState['supplierListIds']) => {
        updateSearch({ supplierListIds, pageIndex: 0 });
      },
      updateDeliveryCountries: (deliveryCountries: SearchState['deliveryCountries']) => {
        updateSearch({ deliveryCountries, pageIndex: 0 });
      },
      /** @deprecated */
      updateProductTags: (productTags: SearchState['productTags']) => {
        updateSearch({ productTags, pageIndex: 0 });
      },
      updateProducts: (products: SearchState['products'], productsOperator: SearchState['productsOperator']) => {
        updateSearch({ products, productsOperator, pageIndex: 0 });
      },
      updateText: (text: SearchState['text']) => {
        updateSearch({
          text,
          supplierListIds: [],
          deliveryCountries: [],
          productTags: [],
          products: [],
          productsOperator: undefined,
          pageIndex: 0,
        });
      },
      updatePageSize: (pageSize: SearchState['pageSize']) => {
        updateSearch({ pageSize });
      },
    }),
    [request, search, updateSearch],
  );
};
