import { useCallback } from 'react';
import { ONE_MINUTE, ONE_SECOND } from '@deepstream/common/constants';
import { ProcessingTaskStatus } from '@deepstream/common/processingTask';
import { useApi } from './api';
import { useGlobalProcessing, ProcessingContext } from './GlobalProcessingProvider';

const DEFAULT_POLLING_INTERVAL = 2 * ONE_SECOND;
const DEFAULT_TIMEOUT = 20 * ONE_MINUTE;

export const useWaitForProcessingTask = () => {
  const api = useApi();
  const { setProcessingContext: setIsProcessingModalVisible } = useGlobalProcessing();

  return useCallback(async ({
    command,
    pollingInterval,
    timeout,
  }: {
    command: () => Promise<{ processingTaskId: string }>,
    pollingInterval?: number,
    timeout?: number,
  }) => {
    let showModalTimeoutId;

    try {
      showModalTimeoutId = setTimeout(() => {
        setIsProcessingModalVisible?.(ProcessingContext.PROCESSING_TASK);
      }, 3000);

      const { processingTaskId } = await command();

      if (!processingTaskId) {
        throw new Error('Command did not return a processingTaskId');
      }

      const effectivePollingInterval = pollingInterval || DEFAULT_POLLING_INTERVAL;
      const effectiveTimeout = timeout || DEFAULT_TIMEOUT;

      await new Promise((resolve, reject) => {
        const startTime = Date.now();

        const intervalId = setInterval(async () => {
          if (Date.now() > startTime + effectiveTimeout) {
            clearTimeout(showModalTimeoutId);
            setIsProcessingModalVisible?.(null);
            clearInterval(intervalId);
            reject(new Error('Timeout exceeded waiting for processing task'));
          } else {
            try {
              const { status } = await api.getProcessingTaskStatus({
                processingTaskId,
              });

              if (status === ProcessingTaskStatus.SUCCEEDED) {
                clearTimeout(showModalTimeoutId);
                setIsProcessingModalVisible?.(null);
                clearInterval(intervalId);
                resolve(true);
              } else if (status !== ProcessingTaskStatus.PROCESSING) {
                clearTimeout(showModalTimeoutId);
                setIsProcessingModalVisible?.(null);
                clearInterval(intervalId);
                reject(new Error('Task failed'));
              }
            } catch (err) {
              // When we couldn't get a lock state from the
              // server, we assume a network issue and try again
              // in the next intervallic invocation of this function.
            }
          }
        }, effectivePollingInterval);
      });
    } catch (err) {
      clearTimeout(showModalTimeoutId);
      setIsProcessingModalVisible?.(null);
      throw err;
    }
  }, [api, setIsProcessingModalVisible]);
};
