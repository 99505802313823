import { useState, useMemo } from 'react';
import { FeatureFlagConfig } from '@deepstream/common/rfq-utils';

import { useModalState } from '@deepstream/ui/ui/useModalState';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { PropertyList, PropertyListAction } from '@deepstream/ui/PropertyList';
import { EditCompanyFeatureFlagModal } from './EditCompanyFeatureFlagModal';

const featureFlags: FeatureFlagConfig[] = [
  {
    accessor: 'auctionsEnabled',
    label: 'Auction stage',
    modalHeading: 'Edit auctions stage',
  },
  {
    accessor: 'managementReportingEnabled',
    label: 'Reporting',
    modalHeading: 'Edit management reporting',
  },
  {
    accessor: 'contractManagementEnabled',
    label: 'Contracts',
    modalHeading: 'Edit contracts',
  },
  {
    accessor: 'bulkLegacyContractsImport',
    label: 'Contracts Bulk Import (Legacy contracts)',
    modalHeading: 'Edit contracts bulk import',
  },
  {
    accessor: 'publicRequestsEnabled',
    label: 'Public Requests',
    modalHeading: 'Edit public requests',
  },
  {
    accessor: 'contractChatbotEnabled',
    label: 'Quest chat (DeepAI) - Contract',
    modalHeading: 'Edit Quest (Contract) chat module',
  },
  {
    accessor: 'driveChatbotEnabled',
    label: 'Quest chat (DeepAI) - Drive',
    modalHeading: 'Edit Quest (Drive) chat module',
  },
  {
    accessor: 'preQualEnabled',
    label: 'Pre-qualification',
    modalHeading: 'Edit pre-qualification',
  },
  {
    accessor: 'contractESignatureEnabled',
    label: 'Contract e-signature',
    modalHeading: 'Edit contract e-signature',
  },
  {
    accessor: 'forceLotsEnabled',
    label: 'Force lots enabled',
    modalHeading: 'Edit force lots enabled',
  },
  {
    accessor: 'forceLotsDisabled',
    label: 'Force lots disabled (overrides “Force lots enabled”)',
    modalHeading: 'Edit force lots disabled',
  },
  {
    accessor: 'notificationsIntegration',
    label: 'Notifications integration',
    modalHeading: 'Edit Notifications integration',
  },
];

export const CompanyFeatureList = ({ company }: { company: any }) => {
  const editFeatureFlagModal = useModalState();
  const [selectedFeatureFlag, setSelectedFeatureFlag] = useState(null);

  const properties = useMemo(
    () => featureFlags.map(featureFlag => ({
      name: featureFlag.label,
      value: company.featureFlags?.[featureFlag.accessor]
        ? <IconText icon="check" text="Enabled" />
        : <IconText icon="ban" text="Disabled" />,
      labelWidth: 300,
    })),
    [company],
  );

  return (
    <>
      <PropertyList properties={properties}>
        {featureFlags.map(featureFlag => (
          <PropertyListAction
            key={featureFlag.accessor}
            label="Edit"
            icon="pencil-alt"
            onClick={() => {
              setSelectedFeatureFlag(featureFlag.accessor);
              editFeatureFlagModal.open();
            }}
          />
        ))}
      </PropertyList>
      <EditCompanyFeatureFlagModal
        companyId={company._id}
        isOpen={editFeatureFlagModal.isOpen}
        onCancel={editFeatureFlagModal.close}
        onSave={editFeatureFlagModal.close}
        onRequestClose={editFeatureFlagModal.close}
        featureFlag={featureFlags.find(featureFlag => featureFlag.accessor === selectedFeatureFlag)}
        value={!!company.featureFlags?.[selectedFeatureFlag]}
      />
    </>
  );
};
