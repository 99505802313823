import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useQuery } from 'react-query';
import { Stack } from '@deepstream/ui-kit/elements/Stack';

import { productDashboardTabs } from './tabsConfig';
import { ProductDashboardGeneralPanel } from './ProductDashboardGeneralPanel';
import { ProductDashboardRequestsPanel } from './ProductDashboardRequestsPanel';
import { ProductDashboardIntegrationsPanel } from './ProductDashboardIntegrationsPanel';
import { ProductDashboardContractsPanel } from './ProductDashboardContractsPanel';
import { ProductDashboardPreQualificationPanel } from './ProductDashboardPreQualificationPanel';

export const ProductDashboard = ({ tab }) => {
  const adminApi = useAdminApi();

  const { data, status } = useQuery(
    ['productDashboard'],
    wrap(adminApi.getProductDashboard),
  );

  return (
    <Stack gap={3}>
      {tab === productDashboardTabs.general ? (
        <ProductDashboardGeneralPanel status={status} data={data} />
      ) : tab === productDashboardTabs.preQualification ? (
        <ProductDashboardPreQualificationPanel status={status} data={data} />
      ) : tab === productDashboardTabs.requests ? (
        <ProductDashboardRequestsPanel status={status} data={data} />
      ) : tab === productDashboardTabs.contracts ? (
        <ProductDashboardContractsPanel status={status} data={data} />
      ) : tab === productDashboardTabs.integrations ? (
        <ProductDashboardIntegrationsPanel status={status} data={data} />
      ) : null}
    </Stack>
  );
};
