import * as React from 'react';
import ReactDOM from 'react-dom';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Flex, Text, Box } from 'rebass/styled-components';
import { v4 as uuid } from 'uuid';
import { assignWith, fromPairs, get, isString, set, size, sumBy } from 'lodash';
import { GridQuestionColumn, getEmptyFieldValue, isFieldValueDefined } from '@deepstream/common/rfq-utils';
import { getScrollbarSize } from '@deepstream/ui-utils/getScrollbarSize';
import { BORDER_ADJUSTMENT, DEFAULT_FROZEN_HEADER_FIRST_LINE_HEIGHT } from '@deepstream/ui-kit/grid/core/constants';
import { NonVirtualGrid } from '@deepstream/ui-kit/grid/core/NonVirtualGrid';
import { ColumnData, RowData, getGridContainerId } from '@deepstream/ui-kit/grid/core/utils';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { EditableGrid } from '@deepstream/ui-kit/grid/EditableGrid/EditableGrid';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { RenderTableDataCell } from '@deepstream/ui-kit/grid/EditableGrid/RenderTableDataCell';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { ACTION_COLUMN_WIDTH, DEFAULT_ROW_HEIGHT, EditableGridColumn, GridPasteMethod } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { useEditableGridActions } from '@deepstream/ui-kit/grid/EditableGrid/useEditableGridActions';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { useGridIdPrefix } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useField } from 'formik';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { DateFormat, immutableSet } from '@deepstream/utils';
import {
  DateInputCell,
  NumberInputCell,
  TextAreaInputCell,
} from './inputCell';
import {
  DateValueCell,
  NumberValueCell,
  TextValueCell,
  ValidationAwareRowNumberCell,
} from './validationAwareValueCell';
import { useConfirmDialogWithConfig } from '../useModalState';
import { QuestionResponseGridMenu } from './QuestionResponseGridMenu';
import { SimpleHeader } from './header';
import { useAddGridRowsState } from './useAddGridRowsState';
import { AddMoreItemsModal } from './AddMoreItemsModal';
import { useHandleGridClipboardEvent } from './useHandleGridClipboardEvent';
import { parseCellValue } from './utils';
import { Validation, useErrors } from '../../draft/validation';
import { ContextType, HooksContext } from '../../useHooks';
import { ErrorMessage } from '../../form/Field';

const navigableRange = { startRowIndex: 1, startColumnIndex: 0 };
const selectableRange = { startColumnIndex: 1 };

const frozenLeftColumnIds = ['rowNumber'];

export const DEFAULT_MAX_GRID_ROWS = 25;

export const setGridResponseValueInRow = (row, columnId, value) => immutableSet(row, `values.${columnId}`, value);

export const AddRowButton = (props: ButtonProps) => {
  const { t } = useTranslation('translation');

  return (
    <Button small variant="secondary-transparent-outline" iconLeft="plus" {...props}>
      {t('request.question.grid.addRow')}
    </Button>
  );
};

const ErrorMessages = () => {
  const { t } = useTranslation('translation');
  const { errors } = useErrors();
  const [,, isGridValidHelper] = useField<boolean>('response.isGridValid');

  const rowCountError = errors.rowCount;
  const fieldErrorCount = isString(errors.rows) ? 0 : sumBy(errors.rows, row => size((row as any)?.values));

  const isGridValid = !rowCountError && fieldErrorCount === 0;

  useWatchValue(
    isGridValid,
    isValid => {
      isGridValidHelper.setValue(isValid);
    },
  );

  return (
    <Flex sx={{ gap: 3 }}>
      {rowCountError && (
        <ErrorMessage error={rowCountError} />
      )}
      {fieldErrorCount > 0 && (
        <ErrorMessage
          error={t('request.question.validation.fieldErrorCount', { count: fieldErrorCount })}
        />
      )}
    </Flex>
  );
};

const generateCsvCellValue = (columnConfig, row) => get(row, columnConfig.accessorKey);

const addCsvDataToRows = async ({ rows, data, affectedColumns }: {
  rows: any;
  data: any;
  affectedColumns: any;
}) => {
  let validCellCount = 0;

  const updatedRows = rows.map((row, rowIndex) => {
    if (row.isObsolete) {
      return row;
    }

    const rowData = data[rowIndex] ?? [];
    const updatedRow = { _id: row._id, values: { ...row.values } };

    affectedColumns.forEach((column, columnIndex) => {
      const rawValue = rowData[columnIndex];

      const {
        canInsertValue,
        cellValue,
        isInputValid,
      } = parseCellValue(rawValue, { _id: column.original._id, type: column.original.fieldType });

      if (canInsertValue) {
        set(updatedRow, column.original.accessorKey, cellValue);
      }

      if (isInputValid) {
        validCellCount += 1;
      }
    });

    return updatedRow;
  });

  return {
    updatedRows,
    validCellCount,
  };
};

const createClearFieldsUpdater = ({
  startRowIndex,
  endRowIndex,
  affectedColumns,
}: { startRowIndex: number, endRowIndex: number, affectedColumns: { original: EditableGridColumn }[] }) =>
  (row: Record<string, unknown>, rowIndex: number) => {
    if (rowIndex >= startRowIndex && rowIndex < endRowIndex) {
      const updatedRow = { ...row };

      for (const column of affectedColumns) {
        const { accessorKey, fieldType } = column.original;

        const fieldValue = get(row, accessorKey);

        if (isFieldValueDefined(fieldValue, fieldType)) {
          const newFieldValue = getEmptyFieldValue(fieldType);

          set(updatedRow, accessorKey, newFieldValue);
        }
      }

      return updatedRow;
    } else {
      return row;
    }
  };

export const createEmptyGridQuestionResponseRow = (columns: GridQuestionColumn[]) => {
  return {
    _id: uuid(),
    values: fromPairs(
      columns.map(column => [
        column.id,
        getEmptyFieldValue(column.type),
      ]),
    ),
  };
};

export const QuestionResponseGrid = ({
  viewportHeightDelta,
  isReadOnly,
  columns,
  currencyCode,
  isExpandedView,
}: {
  /**
   * Used to determine the maximum height of the grid.
   *
   * `maxGridHeight = 100vh - viewportHeightDelta`
   */
  viewportHeightDelta: number;
  isReadOnly?: boolean;
  columns: GridQuestionColumn[];
  currencyCode: string;
  isExpandedView?: boolean;
}) => {
  const { t } = useTranslation('translation');
  const {
    configureDialog: openConfirmDialog,
    ...confirmDialogProps
  } = useConfirmDialogWithConfig();
  const handleGridClipboardEvent = useHandleGridClipboardEvent();
  const {
    pendingKeyboardEvent,
    rowData: rows,
    maxRows,
    setEditedCell,
    appendRows,
  } = useEditableGridData();
  const { menuReferenceId } = useGridMenuState();
  const idPrefix = useGridIdPrefix();

  const {
    addGridRowsModal,
    additionalRowCount,
    selectPasteMethod,
    resolveWithPasteMethod,
  } = useAddGridRowsState();

  const createRow = React.useCallback(() => {
    return createEmptyGridQuestionResponseRow(columns);
  }, [columns]);

  const gridActions = useEditableGridActions({
    selectPasteMethod,
    createRow: createRow as any,
    generateCsvCellValue,
    addCsvDataToRows,
    createClearFieldsUpdater,
  });

  const { toggleMenu } = useGridMenuState();

  const startEditingInputCell = React.useCallback((
    row: RowData<any, any>,
    column: ColumnData<EditableGridColumn>,
    event?: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (row.original.isObsolete) return;

    pendingKeyboardEvent.current = event ?? null;

    setEditedCell({
      rowId: row.original._id,
      columnId: column.original._id,
    });
  }, [setEditedCell, pendingKeyboardEvent]);

  const editableGridColumns: EditableGridColumn[] = React.useMemo(() => {
    return [
      {
        _id: 'rowNumber',
        accessorKey: 'rowNumber',
        Header: SimpleHeader,
        label: '',
        ValueCell: withProps(ValidationAwareRowNumberCell, { fieldName: 'rows', showErrorIcon: true, highlightRowOnError: false }),
        toggleMenu: isReadOnly ? undefined : toggleMenu,
        width: isReadOnly ? ACTION_COLUMN_WIDTH : 2 * ACTION_COLUMN_WIDTH,
      },
      ...columns.map(field => {
        const commonProps = {
          _id: field.id,
          fieldType: field.type,
          accessorKey: `values.${field.id}`,
          Header: SimpleHeader,
          label: field.name,
          required: !isReadOnly,
        };

        switch (field.type) {
          case 'string':
            return {
              ...commonProps,
              startEditingCell: startEditingInputCell,
              InputCell: TextAreaInputCell,
              ValueCell: withProps(TextValueCell, { truncate: false, fieldName: 'rows', highlightRowOnError: false }),
              width: 350,
            };
          case 'number':
            return {
              ...commonProps,
              startEditingCell: startEditingInputCell,
              InputCell: NumberInputCell,
              ValueCell: withProps(NumberValueCell, { fieldName: 'rows', highlightRowOnError: false }),
              width: 150,
            };
          case 'percentage':
            return {
              ...commonProps,
              startEditingCell: startEditingInputCell,
              InputCell: NumberInputCell,
              ValueCell: withProps(NumberValueCell, { fieldName: 'rows', highlightRowOnError: false }),
              width: 190,
              suffix: (
                <Icon
                  sx={{
                    top: '2px',
                  }}
                  icon="percent"
                  color="subtext50"
                  fixedWidth
                  fontSize={2}
                />
              ),
              decimalPlaces: 2,
            };
          case 'price':
            return {
              ...commonProps,
              startEditingCell: startEditingInputCell,
              InputCell: NumberInputCell,
              ValueCell: withProps(NumberValueCell, { fieldName: 'rows', highlightRowOnError: false }),
              prefix: currencyCode,
              width: 190,
              format: 'money.positive',
              decimalPlaces: 2,
            };
          case 'date':
            return {
              ...commonProps,
              startEditingCell: startEditingInputCell,
              InputCell: DateInputCell,
              ValueCell: withProps(DateValueCell, { fieldName: 'rows', highlightRowOnError: false }),
              format: DateFormat.DD_MMM_YYYY,
              width: 180,
            };
          default:
            throw new Error(`unknown field type ${field.type}`);
        }
      }),
    ];
  }, [isReadOnly, startEditingInputCell, toggleMenu, columns, currencyCode]);

  const { errors } = useErrors();

  const columnsWithErrors = React.useMemo(() => {
    const errorsByFieldKey = assignWith(
      {},
      ...Object.values(errors.rows || {}),
      (a = 0) => a + 1,
    );

    return editableGridColumns.map(column => {
      const errors = errorsByFieldKey[column.accessorKey];

      return errors
        ? { ...column, errors }
        : column;
    });
  }, [editableGridColumns, errors]);

  const bodyPaddingBottom = 0;

  const maxGridHeight = (
    DEFAULT_FROZEN_HEADER_FIRST_LINE_HEIGHT +
    BORDER_ADJUSTMENT +
    bodyPaddingBottom +
    DEFAULT_ROW_HEIGHT * 6 +
    getScrollbarSize()
  );

  return (
    <>
      {addGridRowsModal.isOpen && (
        <AddMoreItemsModal
          {...addGridRowsModal}
          heading={t('request.question.grid.dialog.addMoreRowsModal.heading')}
          info={(
            <Trans
              i18nKey="request.question.grid.dialog.addMoreRowsModal.info"
              values={{ count: additionalRowCount }}
              components={{ b: <b /> }}
            />
          )}
          onSubmit={(pasteMethod: GridPasteMethod) => {
            if (resolveWithPasteMethod.current) {
              resolveWithPasteMethod.current(pasteMethod);
            }
            resolveWithPasteMethod.current = null;
            addGridRowsModal.close();
          }}
          onCancel={() => {
            if (resolveWithPasteMethod.current) {
              resolveWithPasteMethod.current(null);
            }
            resolveWithPasteMethod.current = null;
            addGridRowsModal.close();
          }}
        />
      )}

      {rows.length > 0 ? (
        <DefaultEditableGridStyles
          style={{
            width: '100%',
            height: isExpandedView ? `calc(100vh - ${viewportHeightDelta}px)` : `min(100vh - ${viewportHeightDelta}px, ${maxGridHeight}px)`,
          }}
          isReadOnly={isReadOnly}
          cellContentCss="align-items: flex-start; padding: 10px"
          activeCellContentCss="padding: 8px"
          cellContentWithPrefixCss="padding-top: 0;padding-bottom: 0;padding-left:0;"
          activeCellContentWithPrefixCss="padding-top: 0;padding-bottom: 0;padding-left: 10px;padding-right: 0;"
          cellContentWithSuffixCss="padding-top: 0;padding-bottom: 0;padding-right:0;padding-left: 0"
          activeCellContentWithSuffixCss="padding-top: 0;padding-bottom: 0;padding-right: 0;padding-left: 0;"
          suffixCss="align-items: flex-start;padding-top: 11px; padding-bottom: 11px; flex: 0 0 auto;"
          activeSuffixCss="align-items: flex-start;padding-top: 9px; padding-bottom: 9px; flex: 0 0 auto;"
        >
          {isReadOnly ? (
            <ReadOnlyGrid
              columns={columnsWithErrors}
              rowData={rows as any}
              GridComponent={NonVirtualGrid}
              RenderDataCell={RenderTableDataCell}
              canScrollHorizontally
              frozenLeftColumnIds={frozenLeftColumnIds}
            />
          ) : (
            <EditableGrid
              columns={columnsWithErrors}
              rowData={rows as any}
              bodyPaddingBottom={bodyPaddingBottom}
              gridActions={gridActions}
              onGridClipboardEvent={handleGridClipboardEvent}
              GridComponent={NonVirtualGrid}
              RenderDataCell={RenderTableDataCell}
              frozenFooterHeight={46}
              submitEditedCellOnScroll={false}
              navigableRange={navigableRange}
              selectableRange={selectableRange}
              canScrollHorizontally
              frozenLeftColumnIds={frozenLeftColumnIds}
            />
          )}
        </DefaultEditableGridStyles>
      ) : (
        <Text color="subtext" fontSize={2} mb={2}>
          {t('request.question.grid.noRowsAdded')}
        </Text>
      )}
      {!isReadOnly && (
        <ErrorMessages />
      )}
      {!isReadOnly && (
        <AddRowButton
          mt={2}
          type="button"
          disabled={rows.length >= maxRows}
          onClick={() => {
            const newRow = createRow();

            appendRows([newRow] as any);

            // using setTimeout to make sure that the scroll action
            // happens after the addition of the new row got created
            setTimeout(() => {
              const gridContainerId = getGridContainerId(idPrefix);
              const gridContainer = document.getElementById(gridContainerId);

              gridContainer.scrollTop = gridContainer.scrollHeight;
              gridContainer.scrollLeft = 0;
            }, 1);
          }}
        />
      )}
      {menuReferenceId ? (
        <QuestionResponseGridMenu
          columns={columnsWithErrors}
          gridActions={gridActions}
        />
      ) : (
        null
      )}
      <Dialog style={{ content: { width: '500px' } }} {...confirmDialogProps} />
    </>
  );
};

export const useRowsValidationSchema = (columns: GridQuestionColumn[]) => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    if (!columns) {
      return yup.mixed();
    }

    const columnsShape = fromPairs(
      Object.values(columns)
        .map((field) => {
          let fieldSchema;

          switch (field.type) {
            case 'date':
              fieldSchema = yup.date().typeError(t('general.required')).required(t('general.required'));
              break;
            case 'string':
              fieldSchema = yup.string().typeError(t('general.required')).required(t('general.required'));
              break;
            case 'price':
            case 'percentage':
            case 'number':
              fieldSchema = yup.number().typeError(t('general.required')).required(t('general.required'));
              break;
            default:
              throw new Error(`Unsupported field type: ${field.type}`);
          }

          return [
            field.id,
            fieldSchema,
          ];
        }),
      );

      return yup.array(yup.object().shape({ values: yup.object().shape(columnsShape) })).required();
  }, [columns, t]);
};

const GridResponseHooksProvider = ({ children, showValidationErrors }) => {
  const contextValue = React.useMemo(
    () => ({
      contextType: ContextType.QUESTIONNAIRE,
      useStatus: () => null,
      useContextId: () => null,
      useSummary: () => null,
      useExchangeDefById: () => null,
      useExchanges: () => null,
      useShowValidationErrors: () => showValidationErrors,
      useSection: () => null,
      useAuction: () => null,
      usePagePermissions: () => null,
      useBidProgress: () => null,
      useCommentNotifications: () => [],
      useRecipients: () => [],
      useAvailableBulletins: () => [],
      useActions: () => null,
      useESign: () => null,
      useIsSuperUserOrOwner: () => null,
    }),
    [showValidationErrors],
  );

  return (
    <HooksContext.Provider value={contextValue}>
      {children}
    </HooksContext.Provider>
  );
};

export const GridQuestionResponseValidation = ({ showValidationErrors = false, columns, rowsConfig, children }) => {
  const { t } = useTranslation('translation');
  const {
    rowData: rows,
  } = useEditableGridData();
  const rowsValidationSchema = useRowsValidationSchema(columns);

  const minRowCount = rowsConfig.isCustom ? rowsConfig.min : 1;
  const maxRowCount = rowsConfig.isCustom ? rowsConfig.max : 25;

  return (
    <Validation
      values={{
        rowCount: rows.length,
        rows,
      }}
      schema={showValidationErrors ? (
        yup.object().shape({
          rowCount: yup.number()
            .min(minRowCount, t('request.question.validation.minimumRowCount', { count: minRowCount }))
            .max(maxRowCount, t('request.question.validation.maximumRowCount', { count: maxRowCount })),
          rows: rowsValidationSchema,
        })
      ) : (
        yup.mixed()
      )}
    >
      <GridResponseHooksProvider showValidationErrors={showValidationErrors}>
        {children}
      </GridResponseHooksProvider>
    </Validation>
  );
};

export const EditableQuestionResponseGrid = ({
  columns,
  currencyCode,
  isExpandedView,
}: {
  columns: GridQuestionColumn[];
  currencyCode?: string;
  isExpandedView?: boolean;
}) => {
  const [,, rowsHelper] = useField<unknown[]>('response.value.rows');
  const { rowData } = useEditableGridData();

  useWatchValue(
    rowData,
    newRowData => {
      rowsHelper.setValue(newRowData);
    },
  );

  return (
    <QuestionResponseGrid
      viewportHeightDelta={230}
      columns={columns}
      currencyCode={currencyCode}
      isExpandedView={isExpandedView}
    />
  );
};

export const ReadOnlyQuestionResponseGrid = ({
  columns,
  viewportHeightDelta = 200,
  currencyCode,
  isExpandedView,
}: {
  columns: GridQuestionColumn[];
  viewportHeightDelta?: number;
  currencyCode?: string;
  isExpandedView?: boolean;
}) => {
  return (
    <QuestionResponseGrid
      isReadOnly
      viewportHeightDelta={viewportHeightDelta}
      columns={columns}
      currencyCode={currencyCode}
      isExpandedView={isExpandedView}
    />
  );
};

export const GridQuestionExpandedViewContainer: React.FC = ({ children }) => {
  return ReactDOM.createPortal(
    <Box
      sx={{
        position: 'fixed',
        inset: 0,
        padding: '20px',
        zIndex: 202,
        backgroundColor: 'lightGray3',
      }}
    >
      {children}
    </Box>,
    document.body,
    '',
  );
};
