import * as React from 'react';
import { useQuery } from 'react-query';
import { keyBy } from 'lodash';
import { getUserLocale } from '@deepstream/common/user-utils';
import { Currencies, Currency } from '../types';
import { useApi } from '../api';
import { useSession } from '../auth';

export const DEFAULT_CURRENCY = 'USD';

export const CurrenciesContext = React.createContext<Currencies>({});

export const CurrenciesProvider = React.memo((props: { children: React.ReactNode }) => {
  const api = useApi();
  const { user, isAuthenticated } = useSession();

  const { data: currencyCodes = [] } = useQuery({
    queryKey: ['currencyCodes'],
    queryFn: api.getCurrencyCodes,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: isAuthenticated,
  });

  const currencies = React.useMemo(
    () => {
      const locale = getUserLocale(user);
      const currencyNames = new Intl.DisplayNames([locale], { type: 'currency' });

      const currenciesWithNames = currencyCodes.map(code => ({
        code,
        name: currencyNames.of(code),
      }));

      return keyBy(currenciesWithNames, currency => currency.code);
    },
    [currencyCodes, user],
  );

  return (
    <CurrenciesContext.Provider value={currencies} {...props} />
  );
});

export const useCurrencies = () => React.useContext(CurrenciesContext);

export const useCurrencySelectItems = ({
  short,
  filterPredicate,
}: { short?: boolean; filterPredicate?: (currency: Currency) => boolean } = {}) => {
  const currencies = useCurrencies();

  return React.useMemo(() => {
    const allCurrencies = Object.values(currencies);
    const filteredCurrencies = filterPredicate
      ? allCurrencies.filter(filterPredicate)
      : allCurrencies;

    return short
      ? filteredCurrencies.map(({ code }) => ({
        value: code,
        label: code,
      }))
      : filteredCurrencies.map(({ code, name }) => ({
        value: code,
        label: `${code} – ${name}`,
      }));
  }, [currencies, short, filterPredicate]);
};
