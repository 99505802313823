import {
  Panel,
  PanelDivider,
  PanelHeader,
  PanelText,
} from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { localeFormatPrice } from '@deepstream/utils';
import { APP_ADMIN_LOCALE } from '@deepstream/common/constants';
import { useModalState } from '@deepstream/ui/ui/useModalState';

import { getFeatureAdoptionInfo, renderPercentage } from '../reports/utils';
import { ClientAdoptionRateModal } from '../reports/ClientAdoptionRateModal';
import {
  ProductDashboardMetric,
  ProductDashboardSection,
} from './ProductDashboardUtils';
import { MultiStageLineItemsFunnel } from './MultiStageLineItemsFunnel';

const renderAccuracyPercentage = ({ accurate, total }) =>
  renderPercentage({ count: accurate, total });

const renderAccurateOfTotal = ({ accurate, total }) => {
  if (!total) {
    return 'N/A';
  }

  return `${accurate} of ${total}`;
};

export const ProductDashboardRequestsPanel = ({ status, data }) => {
  const clientAdoptionRateModal = useModalState();

  return (
    <>
      <Panel>
        <PanelHeader heading="Requests" />
        <PanelDivider />
        {status === 'loading' ? (
          <PanelText>
            <Loading />
          </PanelText>
        ) : status === 'error' ? (
          <PanelText>Oh no</PanelText>
        ) : data.data ? (
          <>
            <ProductDashboardSection heading="Requests issued" icon="share">
              <ProductDashboardMetric label="Today" value={data.data.issuedRequests.today} />
              <ProductDashboardMetric label="This month" value={data.data.issuedRequests.thisMonth} />
              <ProductDashboardMetric label="Last month" value={data.data.issuedRequests.lastMonth} />
            </ProductDashboardSection>
            {data.data.requestValueAccuracy && (
              <ProductDashboardSection
                heading="Request value accuracy rate"
                tooltip="% of awarded requests where the client indicates that the calculated request value is accurate"
                icon="check-circle"
                isIconRegular
              >
                <ProductDashboardMetric
                  label="Today"
                  value={renderAccuracyPercentage(data.data.requestValueAccuracy.today)}
                  subValue={renderAccurateOfTotal(data.data.requestValueAccuracy.today)}
                />
                <ProductDashboardMetric
                  label="This week"
                  value={renderAccuracyPercentage(data.data.requestValueAccuracy.thisWeek)}
                  subValue={renderAccurateOfTotal(data.data.requestValueAccuracy.thisWeek)}
                />
                <ProductDashboardMetric
                  label="Last week"
                  value={renderAccuracyPercentage(data.data.requestValueAccuracy.lastWeek)}
                  subValue={renderAccurateOfTotal(data.data.requestValueAccuracy.lastWeek)}
                />
              </ProductDashboardSection>
            )}
            {data.data.confirmedRequestValue && (
              <ProductDashboardSection
                heading="Confirmed request value"
                tooltip={(
                  <>
                    Combined total of <br />
                    Calculated request value (where confirmed as accurate on award)<br />
                    Adjusted request value (where provided manually on award)<br />
                    <br />
                    Values are converted to USD using FX rate at the time of award<br />
                  </>
                )}
                icon="circle-dollar"
                isIconRegular
              >
                <ProductDashboardMetric
                  label="All time"
                  value={(
                    localeFormatPrice(data.data.confirmedRequestValue.allTime, 'USD', { locale: APP_ADMIN_LOCALE, decimalPlaces: 2 })
                  )}
                />
                <ProductDashboardMetric
                  label="This week"
                  value={(
                    localeFormatPrice(data.data.confirmedRequestValue.thisWeek, 'USD', { locale: APP_ADMIN_LOCALE, decimalPlaces: 2 })
                  )}
                />
                <ProductDashboardMetric
                  label="Last week"
                  value={(
                    localeFormatPrice(data.data.confirmedRequestValue.lastWeek, 'USD', { locale: APP_ADMIN_LOCALE, decimalPlaces: 2 })
                  )}
                />
              </ProductDashboardSection>
            )}
          </>
        ) : data ? (
          <PanelText>No data</PanelText>
        ) : null}
        {data?.data?.multiStageResponsesFeatureAdoption &&
          clientAdoptionRateModal.isOpen && (
            <ClientAdoptionRateModal
              isOpen={clientAdoptionRateModal.isOpen}
              onOk={clientAdoptionRateModal.close}
              onCancel={clientAdoptionRateModal.close}
              heading="Multi-stage line items – Client adoption rate"
              featureAdoption={data.data.multiStageResponsesFeatureAdoption}
            />
          )}
      </Panel>

      <Panel>
        <PanelHeader heading="Multi-stage line items" />
        <PanelDivider />
        {status === 'loading' ? (
          <PanelText>
            <Loading />
          </PanelText>
        ) : status === 'error' ? (
          <PanelText>Oh no</PanelText>
        ) : data.data ? (
          <>
            {data.data.multiStageResponsesFeatureAdoption &&
              data.data.sentOrRevisedRequests && (
                <ProductDashboardSection
                  heading="Adoption and usage"
                  tooltip={
                    <>
                      <b>Client adoption rate</b>
                      <br />
                      % of “paying companies” that have issued 1 or more
                      requests (including revisions) with This feature enabled.
                      <br />
                      <br />
                      <b>Request usage rate</b>
                      <br />% of requests with this feature enabled either on
                      issue or when first enabled on revision.
                    </>
                  }
                  icon="users"
                  isIconRegular
                >
                  <ProductDashboardMetric
                    label="Client adoption rate (now)"
                    value={renderPercentage({
                      count: data.data.multiStageResponsesFeatureAdoption.filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length,
                      total: data.data.multiStageResponsesFeatureAdoption.length,
                    })}
                    subValue={getFeatureAdoptionInfo(data.data.multiStageResponsesFeatureAdoption)}
                    onViewMoreClick={data.data.multiStageResponsesFeatureAdoption.length ? clientAdoptionRateModal.open : undefined}
                  />
                  <ProductDashboardMetric
                    label="Request usage rate (this week)"
                    value={renderPercentage({
                  count: data.data.sentOrRevisedRequests.thisWeek.withMultiStageResponses,
                      total: data.data.sentOrRevisedRequests.thisWeek.total,
                    })}
                    subValue={`${data.data.sentOrRevisedRequests.thisWeek.withMultiStageResponses} of ${data.data.sentOrRevisedRequests.thisWeek.total} requests`}
                  />
                  <ProductDashboardMetric
                    label="Request usage rate (last week)"
                    value={renderPercentage({
                      count:
                        data.data.sentOrRevisedRequests.lastWeek
                          .withMultiStageResponses,
                      total: data.data.sentOrRevisedRequests.lastWeek.total,
                    })}
                    subValue={`${data.data.sentOrRevisedRequests.lastWeek.withMultiStageResponses} of ${data.data.sentOrRevisedRequests.lastWeek.total} requests`}
                  />
                </ProductDashboardSection>
              )}
            {data.data.multiStageLineItemsFunnel && (
              <MultiStageLineItemsFunnel
                funnelData={data.data.multiStageLineItemsFunnel}
              />
            )}
          </>
        ) : data ? (
          <PanelText>No data</PanelText>
        ) : null}
        {data?.data?.multiStageResponsesFeatureAdoption && clientAdoptionRateModal.isOpen && (
          <ClientAdoptionRateModal
            isOpen={clientAdoptionRateModal.isOpen}
            onOk={clientAdoptionRateModal.close}
            onCancel={clientAdoptionRateModal.close}
            heading="Multi-stage line items – Client adoption rate"
            featureAdoption={data.data.multiStageResponsesFeatureAdoption}
          />
        )}
      </Panel>
    </>
  );
};
