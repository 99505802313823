import { Panel, PanelDivider, PanelHeader, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { useModalState } from '@deepstream/ui/ui/useModalState';

import { getFeatureAdoptionInfo, renderPercentage } from '../reports/utils';
import { ClientAdoptionRateModal } from '../reports/ClientAdoptionRateModal';
import { ProductDashboardMetric, ProductDashboardSection } from './ProductDashboardUtils';

const renderFixedDecimalNumber = (value: number) => {
  return value.toFixed(1);
};

export const ProductDashboardPreQualificationPanel = ({ status, data }) => {
  const preQualificationAdoptionRateModal = useModalState();
  const questionnaireCompletedAdoptionRateModal = useModalState();
  const questionnaireCompletedWithMinFiveSuppliersAdoptionRateModal = useModalState();
  const requestsWithSuppliersFromPreQualAdoptionThisMonth = useModalState();
  const requestsWithSuppliersFromPreQualAdoptionLastMonth = useModalState();
  const requestsWithSuppliersFromPreQualAdoptionAllTime = useModalState();

  return (
    <Panel>
      <PanelHeader heading="Pre-qualification" />
      <PanelDivider />
      {status === 'loading' ? (
        <PanelText>
          <Loading />
        </PanelText>
      ) : status === 'error' ? (
        <PanelText>Oh no</PanelText>
      ) : data.data ? (
        <>
          {data.data.preQualificationFeatureAdoption && (
            <ProductDashboardSection heading="Enabled clients" icon="person">
              <ProductDashboardMetric
                label="Whole number (now)"
                value={data.data.preQualificationFeatureAdoption.filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length}
              />
              <ProductDashboardMetric
                label="Client adoption rate (now)"
                value={renderPercentage({
                  count: data.data.preQualificationFeatureAdoption.filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length,
                  total: data.data.preQualificationFeatureAdoption.length,
                })}
                subValue={getFeatureAdoptionInfo(data.data.preQualificationFeatureAdoption)}
                onViewMoreClick={data.data.preQualificationFeatureAdoption.length ? preQualificationAdoptionRateModal.open : undefined}
              />
            </ProductDashboardSection>
          )}
          {data.data.questionnaireCompletedFeatureAdoption && (
            <ProductDashboardSection heading="Clients with 1+ approved / rejected questionnaire" icon="person">
              <ProductDashboardMetric
                label="Whole number (now)"
                value={data.data.questionnaireCompletedFeatureAdoption.filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length}
              />
              <ProductDashboardMetric
                label="Client adoption rate (now)"
                value={renderPercentage({
                  count: data.data.questionnaireCompletedFeatureAdoption.filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length,
                  total: data.data.questionnaireCompletedFeatureAdoption.length,
                })}
                subValue={getFeatureAdoptionInfo(data.data.questionnaireCompletedFeatureAdoption)}
                onViewMoreClick={
                  data.data.questionnaireCompletedFeatureAdoption.length ? questionnaireCompletedAdoptionRateModal.open : undefined
                }
              />
            </ProductDashboardSection>
          )}
          {data.data.questionnaireCompletedWithMinFiveSuppliersFeatureAdoption && (
            <ProductDashboardSection heading="Clients with 1+ approved / rejected questionnaire with 5+ suppliers" icon="person">
              <ProductDashboardMetric
                label="Whole number (now)"
                value={data.data.questionnaireCompletedWithMinFiveSuppliersFeatureAdoption
                  .filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length
                }
              />
              <ProductDashboardMetric
                label="Client adoption rate (now)"
                value={renderPercentage({
                  count: data.data.questionnaireCompletedWithMinFiveSuppliersFeatureAdoption
                    .filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length,
                  total: data.data.questionnaireCompletedWithMinFiveSuppliersFeatureAdoption.length,
                })}
                subValue={getFeatureAdoptionInfo(data.data.questionnaireCompletedWithMinFiveSuppliersFeatureAdoption)}
                onViewMoreClick={data.data.questionnaireCompletedWithMinFiveSuppliersFeatureAdoption.length
                  ? questionnaireCompletedWithMinFiveSuppliersAdoptionRateModal.open
                  : undefined
                }
              />
            </ProductDashboardSection>
          )}
          {data.data.questionnairesIssued && (
            <ProductDashboardSection heading="Questionnaires issued" icon="share">
              <ProductDashboardMetric label="This month" value={data.data.questionnairesIssued.thisMonth} />
              <ProductDashboardMetric label="Last month" value={data.data.questionnairesIssued.lastMonth} />
              <ProductDashboardMetric label="All time" value={data.data.questionnairesIssued.allTime} />
            </ProductDashboardSection>
          )}
          {data.data.averageQuestionnairesIssued && (
            <ProductDashboardSection heading="Average questionnaires issued" icon="share" tooltip="Average number of questionnaires issued by clients with pre-qual feature flag enabled">
              <ProductDashboardMetric label="This month" value={renderFixedDecimalNumber(data.data.averageQuestionnairesIssued.thisMonth)} />
              <ProductDashboardMetric label="Last month" value={renderFixedDecimalNumber(data.data.averageQuestionnairesIssued.lastMonth)} />
              <ProductDashboardMetric label="All time" value={renderFixedDecimalNumber(data.data.averageQuestionnairesIssued.allTime)} />
            </ProductDashboardSection>
          )}
          {data.data.medianQuestionnairesIssued && (
            <ProductDashboardSection heading="Median questionnaires issued" icon="share" tooltip="Median number of questionnaires issued by clients with pre-qual feature flag enabled">
              <ProductDashboardMetric label="This month" value={renderFixedDecimalNumber(data.data.medianQuestionnairesIssued.thisMonth)} />
              <ProductDashboardMetric label="Last month" value={renderFixedDecimalNumber(data.data.medianQuestionnairesIssued.lastMonth)} />
              <ProductDashboardMetric label="All time" value={renderFixedDecimalNumber(data.data.medianQuestionnairesIssued.allTime)} />
            </ProductDashboardSection>
          )}
          {data.data.requestsWithSuppliersFromPreQual && data.data.requestsWithSuppliersFromPreQualFeatureAdoption && (
            <ProductDashboardSection
              heading="Requests with suppliers added through pre-qualification list" icon="person-circle-check"
              tooltip={(
                <>
                  Number of requests with at least 1 supplier added using the pre-qual pop-up modal.
                </>
              )}
            >
              <ProductDashboardMetric
                label="This month"
                value={data.data.requestsWithSuppliersFromPreQual.thisMonth}
                subValue={getFeatureAdoptionInfo(data.data.requestsWithSuppliersFromPreQualFeatureAdoption.thisMonth)}
                onViewMoreClick={data.data.requestsWithSuppliersFromPreQualFeatureAdoption.thisMonth.length
                  ? requestsWithSuppliersFromPreQualAdoptionThisMonth.open
                  : undefined
                }
              />
              <ProductDashboardMetric
                label="Last month"
                value={data.data.requestsWithSuppliersFromPreQual.lastMonth}
                subValue={getFeatureAdoptionInfo(data.data.requestsWithSuppliersFromPreQualFeatureAdoption.lastMonth)}
                onViewMoreClick={data.data.requestsWithSuppliersFromPreQualFeatureAdoption.lastMonth.length
                  ? requestsWithSuppliersFromPreQualAdoptionLastMonth.open
                  : undefined
                }
              />
              <ProductDashboardMetric
                label="All time"
                value={data.data.requestsWithSuppliersFromPreQual.allTime}
                subValue={getFeatureAdoptionInfo(data.data.requestsWithSuppliersFromPreQualFeatureAdoption.allTime)}
                onViewMoreClick={data.data.requestsWithSuppliersFromPreQualFeatureAdoption.allTime.length
                  ? requestsWithSuppliersFromPreQualAdoptionAllTime.open
                  : undefined
                }
              />
            </ProductDashboardSection>
          )}
        </>
      ) : data ? (
        <PanelText>No data</PanelText>
      ) : (
        null
      )}
      {preQualificationAdoptionRateModal.isOpen && (
        <ClientAdoptionRateModal
          isOpen={preQualificationAdoptionRateModal.isOpen}
          onOk={preQualificationAdoptionRateModal.close}
          onCancel={preQualificationAdoptionRateModal.close}
          heading="Pre-qualification Hub – Client adoption rate"
          featureAdoption={data.data.preQualificationFeatureAdoption}
        />
      )}
      {questionnaireCompletedAdoptionRateModal.isOpen && (
        <ClientAdoptionRateModal
          isOpen={questionnaireCompletedAdoptionRateModal.isOpen}
          onOk={questionnaireCompletedAdoptionRateModal.close}
          onCancel={questionnaireCompletedAdoptionRateModal.close}
          heading="Enabled clients with 1+ completed questionnaire"
          featureAdoption={data.data.questionnaireCompletedFeatureAdoption}
          adoptedColumnLabel="Completed?"
        />
      )}
      {questionnaireCompletedWithMinFiveSuppliersAdoptionRateModal.isOpen && (
        <ClientAdoptionRateModal
          isOpen={questionnaireCompletedWithMinFiveSuppliersAdoptionRateModal.isOpen}
          onOk={questionnaireCompletedWithMinFiveSuppliersAdoptionRateModal.close}
          onCancel={questionnaireCompletedWithMinFiveSuppliersAdoptionRateModal.close}
          heading="Enabled clients with 5+ completed questionnaire"
          featureAdoption={data.data.questionnaireCompletedWithMinFiveSuppliersFeatureAdoption}
          adoptedColumnLabel="Completed?"
        />
      )}
      {requestsWithSuppliersFromPreQualAdoptionThisMonth.isOpen && (
        <ClientAdoptionRateModal
          isOpen={requestsWithSuppliersFromPreQualAdoptionThisMonth.isOpen}
          onOk={requestsWithSuppliersFromPreQualAdoptionThisMonth.close}
          onCancel={requestsWithSuppliersFromPreQualAdoptionThisMonth.close}
          heading="Requests with pre-qual suppliers (this month)"
          featureAdoption={data.data.requestsWithSuppliersFromPreQualFeatureAdoption.thisMonth}
          detailsColumnLabel="Requests"
          hideNonAdopted
        />
      )}
      {requestsWithSuppliersFromPreQualAdoptionLastMonth.isOpen && (
        <ClientAdoptionRateModal
          isOpen={requestsWithSuppliersFromPreQualAdoptionLastMonth.isOpen}
          onOk={requestsWithSuppliersFromPreQualAdoptionLastMonth.close}
          onCancel={requestsWithSuppliersFromPreQualAdoptionLastMonth.close}
          heading="Requests with pre-qual suppliers (last month)"
          featureAdoption={data.data.requestsWithSuppliersFromPreQualFeatureAdoption.lastMonth}
          detailsColumnLabel="Requests"
          hideNonAdopted
        />
      )}
      {requestsWithSuppliersFromPreQualAdoptionAllTime.isOpen && (
        <ClientAdoptionRateModal
          isOpen={requestsWithSuppliersFromPreQualAdoptionAllTime.isOpen}
          onOk={requestsWithSuppliersFromPreQualAdoptionAllTime.close}
          onCancel={requestsWithSuppliersFromPreQualAdoptionAllTime.close}
          heading="Requests with pre-qual suppliers (all time)"
          featureAdoption={data.data.requestsWithSuppliersFromPreQualFeatureAdoption.allTime}
          detailsColumnLabel="Requests"
          hideNonAdopted
        />
      )}
    </Panel>
  );
};
