import * as React from 'react';
import { Link, LinkProps, RegisteredRouter } from '@tanstack/react-router';
import styled from 'styled-components';

// FIXME: this is a temporary fix for the type issue with `preload` and
// missing `style` and `title` props (as well as other `a` attributes).
// These issues were introduced in a recent version so hopefully this gets
// fixed in @tanstack/react-router in the near future
export type AdminTableLinkProps =
  Omit<LinkProps<RegisteredRouter['routeTree']>, 'preload'> &
  { style?: React.CSSProperties; title?: string };

export const AdminTableLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;
