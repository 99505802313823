import { useMemo } from 'react';
import { ExchangeStatus, ExchangeType, InclusionOption, SectionType, getCurrentCompanyGroup } from '@deepstream/common/rfq-utils';
import { capitalize, compact, filter, first, map, range, values } from 'lodash';
import { exchangesConfig, exchangeStatusConfig } from '@deepstream/common';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { ValueOrDashCell } from '@deepstream/ui/ValueOrDashCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { ExchangeSnapshot } from '@deepstream/ui/types';
import { MultiSelect } from '@deepstream/ui/ui/MultiSelect';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import * as rfx from '@deepstream/ui/rfx';
import { useTranslation } from 'react-i18next';

// Some labels are different than the ones in exchangesConfig
const labelByExchangeType = {
  [ExchangeType.HIRE_PERIOD]: 'Hire period list item',
  [ExchangeType.FEES]: 'Fee',
  [ExchangeType.TERMS]: 'Term',
  [ExchangeType.INCLUSIONS]: 'Inclusion',
  [ExchangeType.CHAT]: 'Docs allowed',
  [ExchangeType.CHAT_NO_RECEIVER_UPLOAD]: 'Docs not allowed',
  [ExchangeType.CHAT_ONLY_RECEIVER_UPLOAD]: 'Docs not allowed',
  [ExchangeType.EVALUATION_CRITERION]: 'Criterion',
  [ExchangeType.AUCTION_LINE_ITEM]: 'Auction line item',
  [ExchangeType.AUCTION_INFORMATION]: 'Auction info',
  [ExchangeType.AUCTION_TERMS]: 'Auction terms',
};

const Filter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  // Calculate the options for filtering using the preFilteredRows
  const availableOptions = useMemo(
    () => {
      const options = new Set();

      preFilteredRows.forEach((row: any) => {
        options.add(row.values[id] ?? '-');
      });

      return [...options.values()];
    },
    [preFilteredRows, id],
  );

  return (
    <MultiSelect
      variant="secondary-outline"
      onChange={setFilter}
      items={availableOptions}
      itemToString={item => item ?? <EmDash />}
      buttonWidth={140}
      selectedItems={filterValue || []}
      getButtonIcon={selectedItems =>
        selectedItems?.length ? 'filter' : 'plus'
      }
      placeholder="Select filters"
      getButtonText={selectedItems => (
        selectedItems?.length >= 2 ? (
          `${selectedItems?.length} filters`
        ) : selectedItems?.length === 1 ? (
          selectedItems[0] ?? <EmDash />
        ) : (
          null
        )
      )}
    />
  );
};

// Define a custom filter function
const filterMultipleValues = (rows: any[], columnId: any, filterValues: string[]) =>
  rows.filter(row => {
    const cellValue: string = row.values[columnId];

    // Filtering doesn't work for null values, so we use a dash character instead
    const shouldFilterByMissingValue = filterValues.includes('-') && !cellValue;

    return filterValues.length === 0 || filterValues.includes(cellValue) || shouldFilterByMissingValue;
  });

const sectionTypeToName = {
  [SectionType.LINE_ITEMS]: 'Line items',
  [SectionType.DELIVERY]: 'Delivery details',
  [SectionType.DOCUMENT]: 'Documents',
  [SectionType.PAYMENT]: 'Payment milestones',
  [SectionType.VESSEL_PRICING]: 'Vessel Pricing',
  [SectionType.EVALUATION]: 'Evaluation',
  [SectionType.BULLETINS]: 'Bulletins',
  [SectionType.CHAT]: 'Chat',
  [SectionType.CLARIFICATIONS]: 'Clarifications',
  [SectionType.INTERNAL_DOCUMENT]: 'Internal documents',
  [SectionType.AUCTION_LINE_ITEMS]: 'Auction line items',
  [SectionType.AUCTION_TERMS]: 'Auction terms',
};

const exchangeTypesWithNoStatus = [
  ExchangeType.CURRENCY,
  ExchangeType.BULLETIN,
  ExchangeType.TERMS,
  ExchangeType.INTERNAL_DOCUMENT,
];

const getExchangeStatus = (snapshot: ExchangeSnapshot) => {
  const { companies, turn, status } = snapshot;

  if (status === ExchangeStatus.INCOMPLETE) {
    const sender = values(companies).find(company => company.group === 'sender')!;
    const isBuyerTurn = turn && turn.includes(sender._id);

    if (isBuyerTurn) {
      return ExchangeStatus.WAITING_FOR_BUYER;
    } else {
      return ExchangeStatus.ACTION_REQUIRED;
    }
  } else {
    return status;
  }
};

export const RequestExchangesTable = ({
  recipientId,
}: {
  recipientId?: string;
}) => {
  const { t } = useTranslation();
  const structure = rfx.useStructure();
  const exchanges = rfx.useExchanges();

  const columns = useMemo(
    () => compact([
      !recipientId
        ? {
          id: 'supplierName',
          Header: 'Supplier',
          accessor: (snapshot: ExchangeSnapshot) => snapshot.recipientId ? structure.recipients.find(recipient => recipient._id === snapshot.recipientId)?.company.name : null,
          sortType: 'caseInsensitive',
          Filter,
          filter: filterMultipleValues,
          Cell: nestCells(TruncateCell, ValueOrDashCell),
        }
        : null,
      {
        id: 'pageName',
        Header: 'Page',
        accessor: (snapshot: ExchangeSnapshot) => snapshot.def.type !== ExchangeType.INTERNAL_DOCUMENT
          ? structure.pages.find(page => page.sections.includes(snapshot.def.sectionId as string))?.name
          : null,
        sortType: 'caseInsensitive',
        Filter,
        filter: filterMultipleValues,
        Cell: nestCells(TruncateCell, ValueOrDashCell),
      },
      {
        Header: 'Section',
        accessor: (snapshot: ExchangeSnapshot) => capitalize(sectionTypeToName[structure.sectionById[snapshot.def.sectionId as string]?.type]),
        Filter,
        filter: filterMultipleValues,
        Cell: TruncateCell,
      },
      {
        id: 'exchangeType',
        Header: 'Type',
        accessor: (snapshot: ExchangeSnapshot) => {
          const { type } = snapshot.def;

          if (type === ExchangeType.INCLUSIONS) {
            return snapshot.def.option === InclusionOption.FLEXIBLE
              ? 'Inclusion (supplier-specified)'
              : 'Inclusion (fixed)';
          }

          return labelByExchangeType[type] || exchangesConfig[type]?.label || type;
        },
        Filter,
        filter: filterMultipleValues,
        Cell: TruncateCell,
      },
      {
        Header: 'Stage',
        accessor: (snapshot: ExchangeSnapshot) => {
          const { sectionId } = snapshot.def;

          const firstStageId = first(structure.sectionById[sectionId]?.stages) || first(snapshot.def.stages);

          if (!firstStageId) {
            return null;
          }

          // TODO [lots] later adjust for lots
          const firstStageIndex = structure.stages.findIndex(stage => stage._id === firstStageId);

          return range(firstStageIndex, structure.stages.length).map(index => index + 1).join(', ');
        },
        Filter,
        filter: filterMultipleValues,
        Cell: ValueOrDashCell,
      },
      {
        Header: 'Origin',
        accessor: (snapshot: ExchangeSnapshot) => {
          const exchangeDef = structure.exchangeDefById[snapshot._id];

          return structure.senders.some(sender => sender._id === exchangeDef.creatorId)
            ? 'Buyer'
            : 'Supplier';
        },
        Filter,
        filter: filterMultipleValues,
        Cell: TruncateCell,
      },
      {
        Header: 'Status',
        accessor: (snapshot: ExchangeSnapshot) => {
          if (exchangeTypesWithNoStatus.includes(snapshot.def.type)) {
            return null;
          }

          const status = getExchangeStatus(snapshot);
          const currentCompanyGroup = getCurrentCompanyGroup(snapshot.currentCompanyId === snapshot.recipientId);
          const statusConfig = exchangeStatusConfig[status]?.[currentCompanyGroup];

          return statusConfig
            ? t(`request.exchange.exchangeStatus.${status}`)
            : undefined;
        },
        Filter,
        filter: filterMultipleValues,
        Cell: nestCells(TruncateCell, ValueOrDashCell),
      },
      // We need to render an empty cell in the last column that corresponds to the table header cell created for the filter icon.
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ]),
    [recipientId, structure, t],
  );

  const data = useMemo(
    () => recipientId
      ? filter(exchanges, { recipientId })
      : map(
        exchanges,
        snapshot => ({
          ...snapshot,
          _rowId: snapshot.recipientId
            ? `${snapshot.def._id}-${snapshot.recipientId}`
            : snapshot.def._id,
        }),
      ),
    [recipientId, exchanges],
  );

  const initialSortBy = useMemo(
    () => compact([
      !recipientId ? { id: 'supplierName' } : null,
      { id: 'pageName' },
      { id: 'exchangeType' },
    ]),
    [recipientId],
  );

  return (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={data}
        isPaginated
        isSortable
        initialSortBy={initialSortBy}
      />
    </StaticTableStyles>
  );
};
