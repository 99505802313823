import { useMemo } from 'react';
import { upperFirst } from 'lodash';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { withProps } from '@deepstream/ui-utils/withProps';
import { ContractOverview } from '@deepstream/common/contract';
import { RequestLink } from '../../RequestLink';
import { CapitalizeCell } from '../../CapitalizeCell';
import { formatAdminDate } from '../../formatDate';
import { YesNoCell } from '../../YesNoCell';
import { ContractLinkCell } from '../Contracts/ContractLinkCell';

type TableProps = {
  sentContracts: ContractOverview[];
};

export const CompanySentContractsTable = ({ sentContracts }: TableProps) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Ref.',
        accessor: 'autoReferenceNumber',
        width: 60,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        Header: 'Contract Name',
        accessor: 'name',
        width: 320,
        Cell: nestCells(TruncateCell, ContractLinkCell),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Supplier Counterparty',
        accessor: 'recipient.name',
        cell: TruncateCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 220,
      },
      {
        Header: 'Created by',
        accessor: 'meta.createdBy.name',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 160,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: 'status',
        Cell: CapitalizeCell,
        Filter: withProps(FilterSelect, { itemToString: upperFirst }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 100,
      },
      {
        Header: 'Created on',
        accessor: 'meta.createdAt',
        Cell: nestCells(TruncateCell, DatetimeCell),
        filter: filterMultipleValues,
        Filter: withProps(FilterSelect, { itemToString: formatAdminDate, transformSelectedValues: formatAdminDate }),
        width: 220,
        sort: 'datetime',
      },
      {
        Header: 'Linked',
        accessor: (contract) => Boolean(contract.requestId),
        width: 110,
        sortType: 'boolean',
        Cell: ({ cell, value }) => {
          if (!value) return 'No';

          return (
            <RequestLink requestId={cell.row.original.requestId} title={cell.row.original.requestSubject}>
              Yes
            </RequestLink>
          );
        },
        Filter: withProps(FilterSelect, {
          itemToString: value => value ? 'Yes' : 'No',
          transformSelectedValues: value => value ? 'Yes' : 'No',
        }),
        filter: filterMultipleValues,
      },
      {
        Header: 'Legacy',
        accessor: (contract) => contract.isLegacy,
        sortType: 'boolean',
        Cell: YesNoCell,
        Filter: withProps(FilterSelect, {
          itemToString: value => value ? 'Yes' : 'No',
          transformSelectedValues: value => value ? 'Yes' : 'No',
        }),
        filter: filterMultipleValues,
        width: 110,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={sentContracts}
        isSortable
        noFilteredDataPlaceholder="No contracts match chosen filters"
        smallPageControls
      />
    </BasicTableStyles>
  );
};
