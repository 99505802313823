import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PropertyList } from '@deepstream/ui/PropertyList';
import { Datetime } from '@deepstream/ui/Datetime';
import { useQuestionnaireData } from '@deepstream/ui/modules/PreQualification/Questionnaire/questionnaireUtils';
import { localeFormatFactorAsPercent } from '@deepstream/utils';
import { useCurrentUserLocale } from '@deepstream/ui/useCurrentUser';
import { Questionnaire, QuestionnaireStatus } from '@deepstream/common/preQual';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';

const QuestionnaireRenewalDate = ({ value: questionnaire }: { value: Questionnaire }) => {
  const { t } = useTranslation('preQualification');

  return questionnaire.status !== QuestionnaireStatus.APPROVED ? (
    <EmDash />
  ) : questionnaire.renewalDate ? (
    <Datetime
      value={questionnaire.renewalDate}
    />
  ) : (
    t('questionnaire.noExpiry')
  );
};

export const AdminQuestionnairePropertyList = () => {
  const { t } = useTranslation('preQualification');
  const locale = useCurrentUserLocale();
  const questionnaire = useQuestionnaireData();

  const generalProperties = useMemo(
    () => [
      { name: 'Sender', value: questionnaire.senders[0].name },
      { name: 'Status', value: t(`questionnaireStatus.${questionnaire.status}`) },
      { name: 'Completion', value: localeFormatFactorAsPercent(questionnaire.numResolvedRequirements / questionnaire.numRequirements, { locale, decimalPlaces: 0 }) },
      { name: 'Version', value: questionnaire.meta.questionnaireTemplateVersion },
      { name: 'Renewal date', value: questionnaire, Component: QuestionnaireRenewalDate },
    ],
    [locale, questionnaire, t],
  );

  return (
    <PropertyList properties={generalProperties} />
  );
};
