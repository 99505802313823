import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useToaster } from '../../toast';
import { useContractId, useContractState } from './contract';
import { MutationArgs } from '../../types';
import { useContractQueryKey } from './useContract';

export const useContractMutationArgs = ({ name, onSuccess, onError, onSettled }: { name: string } & MutationArgs) => {
  const { t } = useTranslation('contracts');
  const toaster = useToaster();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const { isDraft, isTemplate } = useContractState();
  const contractQueryKey = useContractQueryKey({
    contractId,
    currentCompanyId,
    scope: isDraft ? 'draft' : 'current',
    isTemplate,
  });
  const queryClient = useQueryClient();

  return {
    mutationKey: contractQueryKey,
    onSuccess: callAll(
      onSuccess,
      () => toaster.success(t(`toaster.${name}.success`)),
    ),
    onError: callAll(
      onError,
      () => toaster.error(t(`toaster.${name}.failed`)),
    ),
    onSettled: callAll(
      onSettled,
      () => queryClient.invalidateQueries(contractQueryKey),
    ),
  };
};
