import * as React from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { useInView } from 'react-intersection-observer';
import { Panel, PanelText, PanelHeading, PanelDivider, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { useApi, useAdminApi, wrap } from '@deepstream/ui/api';
import { Bold } from '@deepstream/ui/Bold';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { SendInviteButton, InviteProxyProvider } from '@deepstream/ui/InviteModal';
import { EditPermissionsModal } from '@deepstream/ui/modules/Company/TeamManagement/EditPermissionsModal';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { useMutation } from '@deepstream/ui/useMutation';
import { useToaster } from '@deepstream/ui/toast';
import { EntityTeamMemberOverview } from '@deepstream/common/user-utils';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { CompanyLink } from './CompanyLink';
import { Page } from './Page';
import { CompanyUserRequestsTable } from './CompanyUserRequestsTable';
import { CompanyUserProfilePropertyList } from './CompanyUserProfilePropertyList';
import { CompanyUserInvitesTable } from './CompanyUserInvitesTable';
import { PermissionsPropertyList } from './PermissionsPropertyList';
import * as Title from './title';
import { UserLink } from './UserLink';
import { RemoveUserFromCompanyButton } from './RemoveUserFromCompanyButton';
import { companyUserRoute } from './AppRouting';

export const BorderWrap = styled(Flex)`
  border: #E2E8EF 1px solid;
`;

export const PageTitle: React.FC<any> = ({ flexDirection, company, user }) => {
  const { isExtraSmall } = useDeviceSize();

  return (
    <Title.Container flexDirection={flexDirection} px={isExtraSmall ? 3 : 0}>
      <UserLink
        userId={user._id}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '50%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              marginRight: isExtraSmall ? 10 : 40,
            }
        }
      >
        <Title.User
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'large' : 'small'}
          user={user}
        />
      </UserLink>

      <CompanyLink
        companyId={company._id}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '50%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }
        }
      >
        <Title.Company
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'medium' : 'small'}
          company={company}
        />
      </CompanyLink>
    </Title.Container>
  );
};

export const CompanyUserPage = () => {
  const api = useApi();
  const adminApi = useAdminApi();
  const { companyId, userId } = companyUserRoute.useParams();
  const [ref, inView, entry] = useInView();

  const toaster = useToaster();
  const editPermissionsModal = useModalState();
  const [entityTeamMemberOverviews, setEntityTeamMemberOverviews] = React.useState<EntityTeamMemberOverview[]>([]);

  const [getEntityTeamMemberOverviews] = useMutation(
    api.getEntityTeamMemberOverviews,
    {
      onError: () =>
        toaster.error('Could not get entity team member overviews'),
    },
  );

  const { data: company, status: companyStatus } = useQuery(
    ['company', { companyId }],
    wrap(adminApi.getCompany),
  );

  const { data: user, status: userStatus } = useQuery(
    ['user', { userId }],
    wrap(adminApi.getUser),
  );

  const { data: requests, status: requestsStatus } = useQuery(
    ['requests', { companyId, userId }],
    wrap(adminApi.getCompanyUserRequests),
  );

  const { data: invitesData, status: invitesDataStatus, refetch } = useQuery(
    ['invitesData', { companyId, userId }],
    wrap(api.getCompanyUserInvites),
  );

  const openEditPermissionsModal = React.useCallback(async () => {
    const entityTeamMemberOverviews = await getEntityTeamMemberOverviews({
      companyId,
      userId,
    });

    setEntityTeamMemberOverviews(entityTeamMemberOverviews);

    editPermissionsModal.open();
  }, [companyId, userId, getEntityTeamMemberOverviews, editPermissionsModal]);

  return (
    <>
      <Page
        subHeading={
          company &&
          user &&
          entry &&
          !inView && (
            <PageTitle company={company} user={user} />
          )
        }
      >
        {companyStatus === 'loading' || userStatus === 'loading' ? (
          <Panel>
            <PanelText>
              <Loading />
            </PanelText>
          </Panel>
        ) : companyStatus === 'error' || userStatus === 'error' ? (
          <Panel heading="Error">
            <PanelText>Oh no</PanelText>
          </Panel>
        ) : company && user ? (
          <>
            <div ref={ref}>
              <PageTitle flexDirection="column" company={company} user={user} />
            </div>

            <Panel heading="Profile" mb={4}>
              <CompanyUserProfilePropertyList user={user} company={company} />
            </Panel>

            <Panel mb={4}>
              <PanelHeader heading="Permissions">
                <EditButton small onClick={openEditPermissionsModal} />
              </PanelHeader>
              <PanelDivider />
              <PermissionsPropertyList user={user} company={company} />
            </Panel>

            <Panel heading="Requests" mb={4}>
              {requestsStatus === 'loading' ? (
                <PanelText>
                  <Loading />
                </PanelText>
              ) : requestsStatus === 'error' ? (
                <PanelText>Oh no</PanelText>
              ) : requests.length ? (
                <CompanyUserRequestsTable companyId={company._id} userId={user._id} requests={requests} />
              ) : (
                <PanelText color="gray">No requests yet</PanelText>
              )}
            </Panel>

            <Panel mb={4}>
              <Flex justifyContent="space-between">
                <PanelHeading>
                  Invites
                </PanelHeading>
                <Box my={3} mr={2}>
                  <InviteProxyProvider user={user} company={company}>
                    <SendInviteButton
                      label="New invite by proxy"
                      isNetwork
                      onInvitesSent={() => refetch()}
                      small
                    />
                  </InviteProxyProvider>
                </Box>
              </Flex>

              <PanelDivider />

              {invitesDataStatus === 'loading' ? (
                <PanelText>
                  <Loading />
                </PanelText>
              ) : invitesDataStatus === 'error' ? (
                <PanelText>Oh no</PanelText>
              ) : invitesData.length ? (
                <CompanyUserInvitesTable invitesData={invitesData} />
              ) : (
                <PanelText color="gray">No invites yet</PanelText>
              )}

            </Panel>

            {user && (
              <Panel heading="Remove from company">
                <PanelText>
                  <Box mb={3}>
                    Remove <Bold>{user.name}</Bold> from <Bold>{company.name}</Bold>
                  </Box>
                  <Box>
                    <RemoveUserFromCompanyButton
                      user={user}
                      companyId={companyId as string}
                    />
                  </Box>
                </PanelText>
              </Panel>
            )}
          </>
        ) : null}
      </Page>
      {user && company && (
        <EditPermissionsModal
          user={user}
          users={[]}
          companyId={company._id}
          isPayingCompany={Boolean(company.subscription)}
          entityTeamMemberOverviews={entityTeamMemberOverviews}
          isOpen={editPermissionsModal.isOpen}
          onCancel={editPermissionsModal.close}
          onSave={editPermissionsModal.close}
          onRequestClose={editPermissionsModal.close}
        />
      )}
    </>
  );
};
