import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Action, ActionType, Attachment } from '@deepstream/common/rfq-utils';
import { Box, Flex } from 'rebass/styled-components';
import * as React from 'react';
import { isEmpty } from 'lodash';
import { isMac } from '@deepstream/ui-utils/isMac';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import { UseMutateAsyncFunction } from 'react-query';
import { TextField } from './form/TextField';
import { ActionFilesField } from './ExchangeModal/exchangeReplyFormConfig';

interface SendMessageFormProps {
  onSubmit: UseMutateAsyncFunction<void, any, Omit<Action, 'companyId' | 'user' | 'date'>, any>;
  placeholder?: string;
  buttonProps?: ButtonProps;
  showButtonOnFocus?: boolean;
  canHaveAttachments?: boolean;
  maxTextFieldHeight?: number;
  actionFieldAttachmentsLimit?: number;
}

const commentWithAttachmentSchema = yup.object().shape({
    attachments: yup.array(yup.object()).when('comment', {
      is: isEmpty,
      then: yup.array(yup.object()).min(1).required(),
    }),
    comment: yup.string().when('attachments', {
      is: isEmpty,
      then: yup.string().required(),
    }),
  },
  [['attachments', 'comment']],
);

export const SendMessageForm = ({
  onSubmit,
  placeholder,
  maxTextFieldHeight = 122,
  buttonProps,
  showButtonOnFocus,
  canHaveAttachments,
  actionFieldAttachmentsLimit,
}: SendMessageFormProps) => {
  const [numResets, setNumResets] = React.useState(0);
  const [textFieldFocused, setTextFieldFocused] = React.useState(false);
  const { t } = useTranslation();

  const handleFormKeyDown = (submitForm) => (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter' && ((isMac && event.metaKey) || (!isMac && event.ctrlKey))) {
      stopEvent(event);

      submitForm();
    }
  };

  const [showAttachmentsField, setShowAttachmentsField] = React.useState(false);

  const [maxFiles, setMaxFiles] = React.useState<number>(0);
  const [currentFilesCount, setCurrentFilesCount] = React.useState<number>(0);

  const handleAttachmentsButtonClick = React.useCallback(() => {
    if (!showAttachmentsField) {
      // First click - show the attachments field with a limit of one file
      setShowAttachmentsField(true);
      setMaxFiles(1);
    } else if (
      // Subsequent clicks - increase the limit by one, only if the current attachments count is equal to the limit
      currentFilesCount === maxFiles &&
      maxFiles < actionFieldAttachmentsLimit
    ) {
      setMaxFiles(maxFiles + 1);
    }
  }, [maxFiles, showAttachmentsField, actionFieldAttachmentsLimit, currentFilesCount]);

  const onAttachmentsChange = (attachments: Attachment[]) => {
    // Keep track of the current attachments count
    setCurrentFilesCount(attachments.length);
    if (attachments.length === 0) {
      // When the last attachment is removed, hide the attachments field
      setShowAttachmentsField(false);
    }
  };

  return (
    <Formik<{ comment: string; attachments?: Attachment[] }>
      validateOnBlur
      validateOnMount
      initialValues={{ comment: '' }}
      validationSchema={
        canHaveAttachments ? commentWithAttachmentSchema : yup.object()
          .shape({
            comment: yup.string()
              .required(),
            })
      }
      onSubmit={async ({ comment, attachments }, { resetForm }) => {
        if (canHaveAttachments && attachments?.length) {
          await onSubmit({ value: ActionType.NONE, comment, attachments });
        } else {
          await onSubmit({ value: ActionType.NONE, comment });
        }
        resetForm();
        setNumResets(numResets => numResets + 1);
      }}
    >
      {({ isSubmitting, isValid, values, submitForm }) => (
        <Form onKeyDown={handleFormKeyDown(submitForm)}>
          <Flex flexDirection="column" sx={{ rowGap: 1 }}>
            <Flex alignItems="flex-end" sx={{ columnGap: 2 }}>
              {canHaveAttachments && (
                <Button
                  type="button"
                  variant="secondary-outline"
                  iconLeft="paperclip"
                  onClick={handleAttachmentsButtonClick}
                  flex="0 0 40px"
                />
              )}
              <TextField
                hideLabel
                hideError
                isMultiLine
                hasDynamicHeight
                maxHeight={maxTextFieldHeight}
                minHeight={textFieldFocused ? 60 : undefined}
                name="comment"
                label={t('general.message')}
                placeholder={placeholder}
                disabled={isSubmitting}
                onFocus={() => setTextFieldFocused(true)}
                onBlur={() => setTextFieldFocused(false)}
              />
              {!showButtonOnFocus ||
              textFieldFocused ||
              showAttachmentsField ||
              values.comment.length ||
              values.attachments?.length ? (
                <Button
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  iconLeft="paper-plane"
                  flex="0 0 40px"
                  {...buttonProps}
                />
              ) : null}
            </Flex>
            {canHaveAttachments && showAttachmentsField && (
              <Box px="48px">
                <ActionFilesField
                  key={`attachments-${numResets}`}
                  name="attachments"
                  max={maxFiles}
                  onChange={onAttachmentsChange}
                />
              </Box>
            )}
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
