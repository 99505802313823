import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { filter, findIndex } from 'lodash';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tabs, TabList, Tab } from '../ui/Tabs';
import { getFilteredTasksCount, TaskCounter } from '../TaskCounter';
import { NotificationCounter } from '../modules/Notifications/NotificationCounter';
import { useTasks } from '../TasksProvider';
import { useCollapsibleHeader } from '../CollapsibleHeaderContext';

const Divider = styled(Box)`
  position: absolute;
  left: -1px;
  top: 9px;
  height: 20px;
  width: 1px;
  background-color: ${props => props.theme.colors.lightGray2};
  cursor: default;
`;

const dividerTabStyle = {
  position: 'relative' as const,
  marginLeft: '1px',
};

export type HeaderTab = {
  id: string;
  name: string;
  pageId?: string;
  navigate?: () => void;
  iconLeft?: IconProps['icon'];
  iconRight?: IconProps['icon'];
  iconProps?: Partial<IconProps>;
  hide?: boolean;
  disabled?: boolean;
  className?: string;
  tooltip?: string;
  taskFilter?: any;
  notificationFilter?: any;
  withDivider?: boolean;
  isRightAligned?: boolean;
};

type HeaderTabsProps = {
  tabs: HeaderTab[];
  selectedTabId?: string;
  tabListStyle?: React.CSSProperties;
};

export const PageHeaderTabs = ({ tabs, selectedTabId, tabListStyle }: HeaderTabsProps) => {
  const theme = React.useContext(ThemeContext);
  const { isCollapsed } = useCollapsibleHeader();

  const visibleTabs = React.useMemo(
    () => filter(tabs, tab => !tab.hide),
    [tabs],
  );

  const selectedTabIndex = React.useMemo(
    () => findIndex(visibleTabs, { id: selectedTabId }),
    [visibleTabs, selectedTabId],
  );

  const rightTabs = React.useMemo(
    () => visibleTabs.filter(tab => tab.isRightAligned),
    [visibleTabs],
  );
  const leftTabs = React.useMemo(
    () => visibleTabs.filter(tab => !tab.isRightAligned),
    [visibleTabs],
  );

  const { tasks } = useTasks();
  const allTasksCount = visibleTabs.reduce((acc, tab) => {
    if (tab.taskFilter) {
      return acc + getFilteredTasksCount(tasks, tab.taskFilter);
    }
    return acc;
  }, 0);

  const renderTabGroup = (tabs: HeaderTab[]) => tabs.map(({
    id,
    pageId,
    name,
    iconLeft,
    iconRight,
    iconProps = {},
    disabled,
    className,
    tooltip = '',
    taskFilter,
    notificationFilter,
    withDivider,
  }) => (
    <Tab
      key={pageId ?? id}
      style={{
        padding: '8px 12px',
        ...(withDivider ? dividerTabStyle : {}),
      }}
      disabled={disabled}
      tooltip={tooltip}
      // Avoid using className as much as possible - currently only used for mobile styles defined in Angular
      className={className}
    >
      {/* Render the Divider as part of a Tab and position it absolutely, otherwise it messes up the tab HighlightBar */}
      {withDivider && <Divider />}
      <Flex alignItems="center">
        {iconLeft && <Icon icon={iconLeft} height="13px" mr={1} {...iconProps} />}
        {name}
        {iconRight && <Icon icon={iconRight} ml={1} {...iconProps} />}
        {taskFilter && <TaskCounter filter={taskFilter} ml={2} />}
        {notificationFilter && <NotificationCounter filter={notificationFilter} ml={2} />}
      </Flex>
    </Tab>
  ));

  // key is needed because reach/tabs has an issue with dynamic tabs
  // https://github.com/reach/reach-ui/issues/828
  return (
    <Tabs
      key={`${visibleTabs.length}-${allTasksCount}`}
      index={selectedTabIndex}
      canOverflow
      onChange={index => visibleTabs[index].navigate()}
      style={{
        opacity: isCollapsed ? 0 : 1,
        transition: 'all 0.3s ease-in-out',
      }}
    >
      <TabList
        style={{
          display: 'flex',
          backgroundColor: theme.colors.white,
          fontSize: theme.fontSizes[2],
          border: 'none',
          ...tabListStyle,
        }}
      >
        {renderTabGroup(leftTabs)}
        {rightTabs && (
          <Box sx={{ marginLeft: 'auto' }}>
            {renderTabGroup(rightTabs)}
          </Box>
        )}
      </TabList>
    </Tabs>
  );
};
