import { createContext, useEffect, useReducer, useContext, useMemo } from 'react';
import * as i18next from 'i18next';
import { get, identity, noop } from 'lodash';
import { useIntercom } from 'react-use-intercom';
import * as Sentry from '@sentry/react';
import { useQuery } from 'react-query';
import { getUserLocale } from '@deepstream/common/user-utils';
import { CurrentUser, User } from './types';
import { useApi } from './api';
import { useEnv } from './env';
import { useAuth } from './auth/getAuthHook';

export type SessionState = {
  status: 'pending' | 'accepted' | 'rejected';
  hasCheckedAuth: boolean;
  isAuthenticated: boolean;
  isAppAdmin: boolean;
  user: CurrentUser | null;
};

enum ActionType {
  SESSION_ACCEPTED = 'session-accepted',
  SESSION_REJECTED = 'session-rejected',
  SESSION_DESTROYED = 'session-destroyed',
}

type SessionAction =
  { type: ActionType.SESSION_ACCEPTED; user: CurrentUser } |
  { type: ActionType.SESSION_REJECTED } |
  { type: ActionType.SESSION_DESTROYED };

const initialSessionState: SessionState = {
  status: 'pending',
  hasCheckedAuth: false,
  isAuthenticated: false,
  isAppAdmin: false,
  user: null,
};

const isAppAdmin = (user: User) =>
  get(user, 'roles._app.admin');

const sessionReducer = (state: SessionState, action: SessionAction): SessionState => {
  switch (action.type) {
    case ActionType.SESSION_ACCEPTED: {
      return {
        status: 'accepted',
        hasCheckedAuth: true,
        isAuthenticated: true,
        isAppAdmin: isAppAdmin(action.user),
        user: action.user,
      };
    }

    case ActionType.SESSION_DESTROYED:
    case ActionType.SESSION_REJECTED: {
      return {
        status: 'rejected',
        hasCheckedAuth: true,
        isAuthenticated: false,
        isAppAdmin: false,
        user: null,
      };
    }

    default: {
      return state;
    }
  }
};

type Session =
  SessionState &
  { logout: () => void };

const useSessionState = ({
  predicate,
}: {
  predicate: any;
}): Session => {
  const api = useApi();
  const auth = useAuth();
  const [sessionState, dispatch] = useReducer(sessionReducer, initialSessionState);
  const { ONBOARDING_URL } = useEnv();
  const intercom = useIntercom();

  const { data: user, status } = useQuery({
    queryKey: ['me'],
    queryFn: api.getMe,
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: true,
    enabled: auth.isAuthenticated && !auth.isLoading,
  });

  useEffect(() => {
    if (auth.isLoading) {
      return;
    }

    if (!auth.isAuthenticated) {
      dispatch({ type: ActionType.SESSION_REJECTED });
      return;
    }

    switch (status) {
      case 'loading': {
        return;
      }

      case 'error': {
        return dispatch({ type: ActionType.SESSION_REJECTED });
      }

      case 'success': {
        if (predicate(user)) {
          return dispatch({ type: ActionType.SESSION_ACCEPTED, user });
        } else {
          return dispatch({ type: ActionType.SESSION_REJECTED });
        }
      }
    }
  }, [predicate, dispatch, api, auth, status, user]);

  useEffect(
    () => {
      const { user } = sessionState;

      if (user) {
        const locale = getUserLocale(user);

        i18next.changeLanguage(locale);

        // Update Sentry with the current user
        Sentry.setUser({
          id: user._id,
          email: user.email,
        });

        // Initialize/update the Intercom user
        intercom.update({
          userId: user._id,
          email: user.email,
          name: user.name,
          phone: user.phoneNumber,
          userHash: user.hashedUserId,
          customAttributes: {
            'DeepStream locale': locale,
          },
          companies: user
            .companyRoles
            .filter(role => role._id !== '_app' && !role.hasSentRequest)
            .map(companyRole => ({
              companyId: companyRole._id,
              name: companyRole.name,
            })),
        });
      }
    },
    [intercom, sessionState],
  );

  return useMemo(
    () => ({
      ...sessionState,
      logout: () => {
        intercom.shutdown();

        auth.logout({
          logoutParams: {
            returnTo: `${ONBOARDING_URL}/logout`,
          },
        });
      },
    }),
    [ONBOARDING_URL, auth, intercom, sessionState],
  );
};

export const SessionContext = createContext<Session>({} as Session);

export const AdminSessionProvider = (props: { children: React.ReactNode }) => {
  const sessionState = useSessionState({ predicate: isAppAdmin });

  return (
    <SessionContext.Provider value={sessionState} {...props} />
  );
};

export const SessionProvider = (props: { children: React.ReactNode }) => {
  const sessionState = useSessionState({ predicate: identity });

  return (
    <SessionContext.Provider value={sessionState} {...props} />
  );
};

export const SsrSessionProvider = (props: { children: React.ReactNode }) => {
  const dummySession = useMemo(
    () => ({
      status: 'accepted',
      hasCheckedAuth: true,
      isAuthenticated: false,
      user: { featureFlags: {} } as CurrentUser,
      isAppAdmin: false,
      logout: () => {},
    }) as const,
    [],
  );

  return (
    <SessionContext.Provider value={dummySession} {...props} />
  );
};

export const useSession = () => useContext(SessionContext);

export const useSessionStatusHandler = ({
  status,
  handler,
}: {
  status: SessionState['status'];
  handler: (session: SessionState) => void;
}) => {
  const session = useSession();

  useEffect(
    () => {
      if (session.status === status) {
        handler(session);
      }
    },
    [handler, session, status],
  );
};
