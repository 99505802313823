import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { QuestionnaireTemplateOverview } from '@deepstream/common/preQual';
import { QuestionnaireTemplateLinkCell } from '../PreQualification/QuestionnaireTemplateLinkCell';

type TableProps = {
  templates: QuestionnaireTemplateOverview[];
};

export const CompanyQuestionnaireTemplatesTable = ({ templates }: TableProps) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Questionnaire',
        accessor: 'name',
        Cell: nestCells(TruncateCell, QuestionnaireTemplateLinkCell),
      },
      {
        Header: 'No. of suppliers',
        accessor: (row: QuestionnaireTemplateOverview) => row.supplierIds.length,
      },
      {
        Header: 'No. of questions',
        accessor: 'numQuestions',
      },
      {
        Header: 'Version',
        accessor: 'version',
      },
    ],
    [],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={templates}
        isSortable
        smallPageControls
      />
    </BasicTableStyles>
  );
};
