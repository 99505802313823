import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { StepProgress } from './ui/StepProgress';

type FooterProps = {
    activeState: string;
    steps: any;
    onContinue: () => any;
    onBack?: () => any;
    isBackVisible?: boolean;
    areStepsVisible?: boolean;
    primaryButtonText?: string;
    backButtonText?: string;
    primaryButtonVariant?: ButtonProps['variant'];
    isBackDisabled?: boolean;
    isNextDisabled?: boolean;
    currentStepName?: string;
  };

export const StepFooter: React.FC<FooterProps> = ({
  activeState,
  steps,
  onContinue,
  onBack,
  isBackVisible = true,
  areStepsVisible = true,
  primaryButtonText,
  backButtonText,
  primaryButtonVariant = 'primary',
  isBackDisabled = false,
  isNextDisabled = false,
  currentStepName: propsCurrentStepName,
}) => {
  const { t } = useTranslation('general');
  const currentStepName = propsCurrentStepName || t(`modalStep.${steps[activeState]}`, { ns: 'translation' });
  const currentStepIndex = Object.keys(steps).indexOf(activeState);
  const stepsCount = Object.keys(steps).length;
  return (
    <ModalFooter justifyContent="space-between">
      <Box>
        {areStepsVisible && (
          <StepProgress
            currentStepIndex={currentStepIndex}
            totalStepCount={stepsCount}
            currentStepName={currentStepName}
          />
        )}
      </Box>
      <Flex>
        {isBackVisible && (
          <Button variant="secondary" onClick={onBack} mr={2} disabled={isBackDisabled}>
            {backButtonText || t('back')}
          </Button>
        )}
        <Button variant={primaryButtonVariant} onClick={onContinue} disabled={isNextDisabled}>
          {primaryButtonText || t('continue')}
        </Button>
      </Flex>
    </ModalFooter>
  );
};
