/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/no-autofocus */
import { useMemo } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { isFunction, isNil, isNumber } from 'lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { QuestionExchangeDefinition, isDocumentRequestExchange, lockableExchangeTypes } from '@deepstream/common/rfq-utils';

import { ProductTag } from '@deepstream/common/products';
import { TFunction } from 'i18next';
import {
  localeFormatNumber,
  localeFormatPrice,
  localeFormatFactorAsPercent,
  DateFormat,
  returnTrue,
} from '@deepstream/utils';
import { Icon, ObsoleteIcon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import {
  DataCellProps,
} from '@deepstream/ui-kit/grid/core/utils';
import { isMultiClickEvent } from '@deepstream/ui-utils/domEvent';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { getCellValue } from '@deepstream/ui-kit/grid/EditableGrid/getCellValue';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { getFormattedDate } from '../../Datetime';
import { useCellValidationError } from '../../draft/useCellValidationError';
import { ErrorMessage } from '../ErrorMessage';
import { useHooks } from '../../useHooks';
import * as validation from '../../draft/validation';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { useGetSupplierResponseCellValue } from '../../draft/cell';
import * as rfx from '../../rfx';
import { InformationModalProvider } from '../../InformationModal';
import { RfqAttachment } from '../../ExchangeModal/AttachmentLink';
import { LockedTooltip } from '../../LockedTooltip';
import { useExchangeRates } from '../../useExchangeRates';
import { computeFormulaValue } from '../../modules/Request/computeFormula';

export const ValidationAwareRowNumberCell = ({
  rowNumberPrefix,
  row,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
  showErrorIcon,
}: DataCellProps<EditableGridColumn, any, any> & {
  rowNumberPrefix?: string;
  fieldName?: string;
  highlightRowOnError?: boolean;
  showErrorIcon?: boolean;
}) => {
  const { useShowValidationErrors } = useHooks();
  const showValidationErrors = useShowValidationErrors();
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);

  return (
    <div
      className={clsx({
        'invalid': showValidationErrors && highlightRowOnError && !isRowValid,
        'number-cell': true,
      })}
    >
      {showValidationErrors && !isRowValid && showErrorIcon ? (
        <Icon icon="exclamation-circle" color="danger" mt="2px" />
      ) : (
        <>{rowNumberPrefix}{(row.original.index ?? row.index) + 1}</>
      )}
    </div>
  );
};

export const RowNumberWarningCell = ({
  rowNumberPrefix,
  row,
  fieldName = 'exchangeDefs',
}: DataCellProps<EditableGridColumn, any, any> & {
  rowNumberPrefix?: string;
  fieldName?: string;
}) => {
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);

  return isRowValid ? (
    <div className="number-cell">
      {rowNumberPrefix}{(row.original.index ?? row.index) + 1}
    </div>
  ) : (
    <div
      className={clsx({
        'warning': true,
        'number-cell': true,
      })}
    >
      <Icon icon="warning" color="warning" />
    </div>
  );
};

export const DisabledCell = ({
  row,
  column,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
}: DataCellProps<EditableGridColumn, any, any> & { fieldName?: string; highlightRowOnError?: boolean }) => {
  const { staticValue } = column.original;

  const { useShowValidationErrors } = useHooks();
  const showValidationErrors = useShowValidationErrors();
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);

  return (
    <div
      className={clsx({
        'invalid': showValidationErrors && highlightRowOnError && !isRowValid,
        'cell-content': true,
        disabled: 'true',
      })}
    >
      <Tooltip content={staticValue ?? undefined}>
        <>{staticValue}</>
      </Tooltip>
    </div>
  );
};

export const getUnspscCodeTooltipContent = (product: ProductTag, t: TFunction) => [
  product.title,
  `${t('general.type')}: ${t(`productsAndServices.type.${product.type}`)}`,
  `${t('productsAndServices.code')}: ${product._id}`,
].join('\n');

export const UnspscCodeValueCell = ({
  row,
  column,
  isActive,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
}: DataCellProps<EditableGridColumn, any, any> & { fieldName?: string; highlightRowOnError?: boolean }) => {
  const { t } = useTranslation();
  const {
    accessorKey,
    startEditingCell,
  } = column.original;

  const { useShowValidationErrors } = useHooks();
  const showValidationErrors = useShowValidationErrors();
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);
  const error = useCellValidationError({
    fieldName,
    customFieldKey: accessorKey,
    row,
    column: {},
    useShowValidationErrors,
  });

  const value = getCellValue(row.original, column.original);

  const product = value ? row.original.productOrService : null;
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <div
      aria-haspopup="dialog"
      className={clsx({
        'invalid': showValidationErrors && (error || (highlightRowOnError && !isRowValid)),
        'cell-content': true,
        disabled: isDisabled,
      })}
      onClick={(event) => {
        if (isActive || isMultiClickEvent(event)) {
          startEditingCell?.(row, column);
        }
      }}
    >
      <Tooltip content={product ? getUnspscCodeTooltipContent(product, t) : undefined}>
        {showValidationErrors && error ? (
          <ErrorMessage fontSize={2} error={error} />
        ) : (
          <Truncate>
            {product ? `[${product._id}] ${product.title}` : <EmDash />}
          </Truncate>
        )}
      </Tooltip>
    </div>
  );
};

export const BulkUploadUnspscCodeValueCell = ({
  row,
  column,
  isActive,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
}: DataCellProps<EditableGridColumn, any, any> & { fieldName?: string; highlightRowOnError?: boolean }) => {
  const { t } = useTranslation();
  const {
    accessorKey,
    startEditingCell,
  } = column.original;

  const error = useCellValidationError({
    fieldName,
    customFieldKey: accessorKey,
    row,
    column: {},
    useShowValidationErrors: returnTrue,
  });

  const value = getCellValue(row.original, column.original);

  const product = value ? row.original.productOrService : null;
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <div
      aria-haspopup="dialog"
      className={clsx({
        'warning': Boolean(error),
        'cell-content': true,
        disabled: isDisabled,
      })}
      onClick={(event) => {
        if (isActive || isMultiClickEvent(event)) {
          startEditingCell?.(row, column);
        }
      }}
    >
      <Tooltip content={product ? getUnspscCodeTooltipContent(product, t) : undefined}>
        <Truncate>
          {error ? (
            <>
              <Icon icon="warning" color="warning" mr={2} />
              {error || <EmDash />}
            </>
          ) : (
            product ? `[${product._id}] ${product.title}` : <EmDash />
          )}
        </Truncate>
      </Tooltip>
    </div>
  );
};

export const DateValueCell = ({
  row,
  column,
  isActive,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
}: DataCellProps<EditableGridColumn, any, any> & { fieldName?: string; highlightRowOnError?: boolean }) => {
  const {
    accessorKey,
    format,
    startEditingCell,
  } = column.original;
  const locale = useCurrentUserLocale();

  const { useShowValidationErrors } = useHooks();
  const showValidationErrors = useShowValidationErrors();
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);
  const error = useCellValidationError({
    fieldName,
    customFieldKey: accessorKey,
    row,
    column: {},
    useShowValidationErrors,
  });

  const value = getCellValue(row.original, column.original);
  const formattedDate = getFormattedDate(value, format as DateFormat, locale);
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <div
      aria-haspopup="dialog"
      className={clsx({
        'invalid': showValidationErrors && (error || (highlightRowOnError && !isRowValid)),
        'cell-content': true,
        'with-suffix': true,
        disabled: isDisabled,
      })}
      style={{ paddingLeft: 0, paddingRight: 0 }}
      onClick={(event) => {
        if (isActive || isMultiClickEvent(event)) {
          startEditingCell?.(row, column);
        }
      }}
    >
      <Flex height="100%" width="100%">
        <Tooltip content={formattedDate ?? undefined}>
          <Truncate className="pre-suffix">
            {showValidationErrors && error ? (
              <ErrorMessage truncate fontSize={2} error={error} />
            ) : (
              formattedDate ?? <EmDash />
            )}
            { }
          </Truncate>
        </Tooltip>
        <div className="suffix">
          <Icon
            icon="calendar-alt"
            color="subtext50"
            fixedWidth
            fontSize={2}
          />
        </div>
      </Flex>
    </div>
  );
};

export const BulkUploadDateValueCell = ({
  row,
  column,
  isActive,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
}: DataCellProps<EditableGridColumn, any, any> & { fieldName?: string; highlightRowOnError?: boolean }) => {
  const {
    accessorKey,
    format,
    startEditingCell,
  } = column.original;
  const locale = useCurrentUserLocale();

  const error = useCellValidationError({
    fieldName,
    customFieldKey: accessorKey,
    row,
    column: {},
    useShowValidationErrors: returnTrue,
  });

  const value = getCellValue(row.original, column.original);
  const formattedDate = getFormattedDate(value, format as DateFormat, locale);
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <div
      aria-haspopup="dialog"
      className={clsx({
        'warning': Boolean(error),
        'cell-content': true,
        disabled: isDisabled,
      })}
      style={{ paddingLeft: 0, paddingRight: 0 }}
      onClick={(event) => {
        if (isActive || isMultiClickEvent(event)) {
          startEditingCell?.(row, column);
        }
      }}
    >
      <Flex height="100%" width="100%">
        <Tooltip content={formattedDate ?? undefined}>
          <Truncate className="pre-suffix">
            {error ? (
              <>
                <Icon icon="warning" color="warning" mr={2} />
                {error || <EmDash />}
              </>
            ) : (
              formattedDate ?? <EmDash />
            )}
          </Truncate>
        </Tooltip>
        {error ? (
          null
        ) : (
          <div className="suffix">
            <Icon
              icon="calendar-alt"
              color="subtext50"
              fixedWidth
              fontSize={2}
            />
          </div>
        )}
      </Flex>
    </div>
  );
};

export const QuestionDescriptionValueCell = ({
  row,
  column,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
}) => {
  const {
    accessorKey,
  } = column.original;

  const { useShowValidationErrors } = useHooks();
  const showValidationErrors = useShowValidationErrors();
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);
  const error = useCellValidationError({
    fieldName,
    customFieldKey: accessorKey,
    row,
    column: {},
    useShowValidationErrors,
  });

  const value = getCellValue(row.original, column.original) as string | null | undefined;
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <div
      className={clsx({
        'invalid': showValidationErrors && (error || (highlightRowOnError && !isRowValid)),
        'cell-content': true,
        disabled: isDisabled,
      })}
    >
      {showValidationErrors && error ? (
        <ErrorMessage fontSize={2} error={error} />
      ) : (
        <Tooltip content={value ?? undefined}>
          <Truncate>
            {column.original.showIconWhenObsolete && row.original.isObsolete && (
              <ObsoleteIcon display="inline" mr={2} />
            )}
            {value || <EmDash />}
          </Truncate>
        </Tooltip>
      )}
    </div>
  );
};

export const SwitchValueCell = ({
  row,
  column,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
}) => {
  const value = getCellValue(row.original, column.original);
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);
  const { useShowValidationErrors } = useHooks();
  const showValidationErrors = useShowValidationErrors();
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);

  return (
    <div
      className={clsx({
        'invalid': showValidationErrors && highlightRowOnError && !isRowValid,
        'cell-content': true,
        disabled: isDisabled,
      })}
    >
      {!isNil(value) ? (
        <Icon fixedWidth icon={value ? 'check' : 'times'} />
      ) : (
        <EmDash />
      )}
    </div>
  );
};

export const QuestionTypeValueCell = ({
  row,
  column,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
}) => {
  const { t } = useTranslation();
  const questionType = getCellValue(row.original, column.original);
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);
  const { useShowValidationErrors } = useHooks();
  const showValidationErrors = useShowValidationErrors();
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);

  return (

    <div
      className={clsx({
        'invalid': showValidationErrors && highlightRowOnError && !isRowValid,
        'cell-content': true,
        disabled: isDisabled,
      })}
    >
      {questionType ? (
        <Truncate>
          {t(`request.question.questionType.${questionType}`)}
        </Truncate>
      ) : (
        <EmDash />
      )}
    </div>
  );
};

export const QuestionSupplierResponseValueCell = ({ row, column, fieldName = 'exchangeDefs', highlightRowOnError = true }) => {
  const {
    accessorKey,
  } = column.original;
  const exchangeDef = row.original as QuestionExchangeDefinition;

  const { useShowValidationErrors } = useHooks();
  const showValidationErrors = useShowValidationErrors();
  const error = useCellValidationError({
    fieldName,
    customFieldKey: accessorKey,
    row,
    column: {},
    useShowValidationErrors,
  });
  const getSupplierResponseCellValue = useGetSupplierResponseCellValue();
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);

  const value = useMemo(() => getSupplierResponseCellValue(exchangeDef), [getSupplierResponseCellValue, exchangeDef]);
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <div
      className={clsx({
        'invalid': showValidationErrors && (error || (highlightRowOnError && !isRowValid)),
        'cell-content': true,
        disabled: isDisabled,
      })}
    >
      {showValidationErrors && error ? (
        <ErrorMessage fontSize={2} error={error} />
      ) : (
        isNil(value) ? (
          <EmDash />
        ) : (
          <Truncate>{value}</Truncate>
        )
      )}
    </div>
  );
};

export const BulkUploadDescriptionValueCell = ({
  row,
  column,
  isActive,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
}: DataCellProps<EditableGridColumn, any, any> & { fieldName?: string; highlightRowOnError?: boolean }) => {
  const {
    accessorKey,
    startEditingCell,
  } = column.original;

  const error = useCellValidationError({
    fieldName,
    customFieldKey: accessorKey,
    row,
    column: {},
    useShowValidationErrors: returnTrue,
  });

  const value = getCellValue(row.original, column.original) as string | null | undefined;
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <div
      className={clsx({
        'warning': Boolean(error),
        'cell-content': true,
        disabled: isDisabled,
      })}
      onClick={(event) => {
        if (isActive || isMultiClickEvent(event)) {
          startEditingCell?.(row, column);
        }
      }}
    >
      <Tooltip content={value ?? undefined}>
        <Truncate>
          {error ? (
            <>
              <Icon icon="warning" color="warning" mr={2} />
              {error || <EmDash />}
            </>
          ) : (
            value || <EmDash />
          )}
        </Truncate>
      </Tooltip>
    </div>
  );
};

export const TextValueCell = ({
  row,
  column,
  isActive,
  truncate = true,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
}: DataCellProps<EditableGridColumn, any, any> & { fieldName?: string; highlightRowOnError?: boolean }) => {
  const {
    accessorKey,
    startEditingCell,
  } = column.original;

  const { useShowValidationErrors } = useHooks();
  const showValidationErrors = useShowValidationErrors();
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);
  const error = useCellValidationError({
    fieldName,
    customFieldKey: accessorKey,
    row,
    column: {},
    useShowValidationErrors,
  });

  const value = getCellValue(row.original, column.original) as string | null | undefined;
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <div
      className={clsx({
        'invalid': showValidationErrors && (error || (highlightRowOnError && !isRowValid)),
        'cell-content': true,
        disabled: isDisabled,
      })}
      style={{ justifyContent: 'flex-start' }}
      onClick={(event) => {
        if (isActive || isMultiClickEvent(event)) {
          startEditingCell?.(row, column);
        }
      }}
    >
      {showValidationErrors && error ? (
        <ErrorMessage fontSize={2} error={error} />
      ) : truncate ? (
        <Tooltip content={value ?? undefined}>
          <Truncate>
            {column.original.showIconWhenObsolete && row.original.isObsolete && (
              <ObsoleteIcon display="inline" mr={2} />
            )}
            {value || <EmDash />}
          </Truncate>
        </Tooltip>
      ) : (
        <>
          {column.original.showIconWhenObsolete && row.original.isObsolete && (
            <ObsoleteIcon display="inline" sx={{ top: '2px' }} mr={2} />
          )}
          {value || <EmDash />}
        </>
      )}
    </div>
  );
};

export const BulkUploadTextValueCell = ({
  row,
  column,
  isActive,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
}: DataCellProps<EditableGridColumn, any, any> & { fieldName?: string; highlightRowOnError?: boolean }) => {
  const {
    accessorKey,
    startEditingCell,
  } = column.original;

  const error = useCellValidationError({
    fieldName,
    customFieldKey: accessorKey,
    row,
    column: {},
    useShowValidationErrors: returnTrue,
  });

  const value = getCellValue(row.original, column.original) as string | null | undefined;
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <div
      className={clsx({
        'warning': Boolean(error),
        'cell-content': true,
        disabled: isDisabled,
      })}
      onClick={(event) => {
        if (isActive || isMultiClickEvent(event)) {
          startEditingCell?.(row, column);
        }
      }}
    >
      <Tooltip content={value ?? undefined}>
        <Truncate>
          {error ? (
            <>
              <Icon icon="warning" color="warning" mr={2} />
              {error || <EmDash />}
            </>
          ) : (
            value || <EmDash />
          )}
        </Truncate>
      </Tooltip>
    </div>
  );
};

export const getFormattedNumber = (
  value: number | null | undefined,
  format: string,
  locale: string,
  currencyCode?: string,
  decimalPlaces?: number,
) => {
  if (!isNumber(value)) {
    return null;
  } else if (format === 'percent') {
    return localeFormatFactorAsPercent(value, { locale });
  } else if (format === 'money.positive') {
    return localeFormatPrice(value, currencyCode, { locale, hideCurrency: true, decimalPlaces });
  } else {
    return localeFormatNumber(value, { locale });
  }
};

export const NumberValueCell = ({
  row,
  column,
  isActive,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
}: DataCellProps<EditableGridColumn, any, any> & { fieldName?: string; highlightRowOnError?: boolean }) => {
  const {
    prefix,
    accessorKey,
    format,
    startEditingCell,
    hideError,
    decimalPlaces,
    suffix,
  } = column.original;

  const { useShowValidationErrors } = useHooks();
  const showValidationErrors = useShowValidationErrors();
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);
  const error = useCellValidationError({
    fieldName,
    customFieldKey: accessorKey,
    row,
    column: {},
    useShowValidationErrors,
  });
  const locale = useCurrentUserLocale();

  const value = getCellValue(row.original, column.original) as number | null | undefined;

  const effectiveDecimalPlaces = decimalPlaces || row.original.fields?.[column.original._id]?.decimalPlaces;
  const actualPrefix = isFunction(prefix) ? prefix(row.original) : prefix;

  const formattedValue = getFormattedNumber(value, format, locale, actualPrefix, effectiveDecimalPlaces);
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <div
      className={clsx({
        'invalid': showValidationErrors && (error || (highlightRowOnError && !isRowValid)),
        'cell-content': true,
        'with-prefix': actualPrefix,
        'with-suffix': suffix,
        disabled: isDisabled,
      })}
      onClick={(event) => {
        if (isActive || isMultiClickEvent(event)) {
          startEditingCell?.(row, column);
        }
      }}
      style={actualPrefix ? { padding: 0 } : undefined}
    >
      <Tooltip content={formattedValue ? `${actualPrefix ? `${actualPrefix} ` : ''}${formattedValue}` : undefined}>
        {actualPrefix ? (
          <Flex height="100%" width="100%">
            <div className="prefix">
              {actualPrefix}
            </div>
            <Truncate className="post-prefix" style={{ textAlign: 'right' }}>
              {showValidationErrors && !hideError && error ? (
                <Box style={{ textAlign: 'left' }}>
                  <ErrorMessage fontSize={2} error={error} />
                </Box>
              ) : (
                formattedValue ?? <EmDash />
              )}
            </Truncate>
          </Flex>
        ) : suffix ? (
          <Flex height="100%" width="100%">
            <Truncate style={{ textAlign: 'right' }} className="pre-suffix">
              {showValidationErrors && !hideError && error ? (
                <Box style={{ textAlign: 'left' }}>
                  <ErrorMessage fontSize={2} error={error} />
                </Box>
              ) : (
                formattedValue ?? <EmDash />
              )}
            </Truncate>
            <div className="suffix">
              {suffix}
            </div>
          </Flex>
        ) : (
          <Truncate style={{ textAlign: 'right' }}>
            {showValidationErrors && !hideError && error ? (
              <Box style={{ textAlign: 'left' }}>
                <ErrorMessage fontSize={2} error={error} />
              </Box>
            ) : (
              formattedValue ?? <EmDash />
            )}
          </Truncate>
        )}
      </Tooltip>
    </div>
  );
};

export const FormulaValueCell = ({
  row,
  column,
  isActive,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
}: DataCellProps<EditableGridColumn, any, any> & { fieldName?: string; highlightRowOnError?: boolean }) => {
  const {
    prefix,
    accessorKey,
    format,
    hideError,
    decimalPlaces,
    suffix,
  } = column.original;
  const exchangeRates = useExchangeRates();
  const { useShowValidationErrors } = useHooks();
  const showValidationErrors = useShowValidationErrors();
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);
  const error = useCellValidationError({
    fieldName,
    customFieldKey: accessorKey,
    row,
    column: {},
    useShowValidationErrors,
  });
  const locale = useCurrentUserLocale();
  const formula = getCellValue(row.original, column.original) as string | null | undefined;

  const value = useMemo(() => {
    return computeFormulaValue({
      exchange: row.original,
      formula,
      valuesMap: row.original.latestReply,
      exchangeRates,
    });
  }, [row.original, formula, exchangeRates]);

  const effectiveDecimalPlaces = decimalPlaces || row.original.fields?.[column.original._id]?.decimalPlaces;
  const actualPrefix = isFunction(prefix) ? prefix(row.original) : prefix;

  const formattedValue = getFormattedNumber(value, format, locale, actualPrefix, effectiveDecimalPlaces);

  return (
    <div
      className={clsx({
        'invalid': showValidationErrors && (error || (highlightRowOnError && !isRowValid)),
        'cell-content': true,
        'with-prefix': actualPrefix,
        'with-suffix': suffix,
        disabled: true,
      })}
      style={actualPrefix ? { padding: 0 } : undefined}
    >
      <Tooltip content={formattedValue ? `${actualPrefix ? `${actualPrefix} ` : ''}${formattedValue}` : undefined}>
        {actualPrefix ? (
          <Flex height="100%" width="100%">
            <div className="prefix">
              {actualPrefix}
            </div>
            <Truncate className="post-prefix" style={{ textAlign: 'right' }}>
              {showValidationErrors && !hideError && error ? (
                <Box style={{ textAlign: 'left' }}>
                  <ErrorMessage fontSize={2} error={error} />
                </Box>
              ) : (
                formattedValue ?? <EmDash />
              )}
            </Truncate>
          </Flex>
        ) : suffix ? (
          <Flex height="100%" width="100%">
            <Truncate style={{ textAlign: 'right' }} className="pre-suffix">
              {showValidationErrors && !hideError && error ? (
                <Box style={{ textAlign: 'left' }}>
                  <ErrorMessage fontSize={2} error={error} />
                </Box>
              ) : (
                formattedValue ?? <EmDash />
              )}
            </Truncate>
            <div className="suffix">
              {suffix}
            </div>
          </Flex>
        ) : (
          <Truncate style={{ textAlign: 'right' }}>
            {showValidationErrors && !hideError && error ? (
              <Box style={{ textAlign: 'left' }}>
                <ErrorMessage fontSize={2} error={error} />
              </Box>
            ) : (
              formattedValue ?? <EmDash />
            )}
          </Truncate>
        )}
      </Tooltip>
    </div>
  );
};

export const BulkUploadNumberValueCell = ({
  row,
  column,
  isActive,
  fieldName = 'exchangeDefs',
  highlightRowOnError = true,
}: DataCellProps<EditableGridColumn, any, any> & { fieldName?: string; highlightRowOnError?: boolean }) => {
  const {
    prefix,
    accessorKey,
    format,
    startEditingCell,
  } = column.original;

  const error = useCellValidationError({
    fieldName,
    customFieldKey: accessorKey,
    row,
    column: {},
    useShowValidationErrors: returnTrue,
  });
  const locale = useCurrentUserLocale();

  const value = getCellValue(row.original, column.original) as number | null | undefined;
  const decimalPlaces = row.original.fields?.[column.original._id]?.decimalPlaces;
  const actualPrefix = isFunction(prefix) ? prefix(row.original) : prefix;

  const formattedValue = getFormattedNumber(value, format, locale, actualPrefix, decimalPlaces);
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <div
      className={clsx({
        'warning': Boolean(error),
        'cell-content': true,
        disabled: isDisabled,
      })}
      onClick={(event) => {
        if (isActive || isMultiClickEvent(event)) {
          startEditingCell?.(row, column);
        }
      }}
      style={actualPrefix && !error ? { padding: 0 } : undefined}
    >
      <Tooltip content={formattedValue ? `${actualPrefix ? `${actualPrefix} ` : ''}${formattedValue}` : undefined}>
        {actualPrefix && !error ? (
          <Flex height="100%" width="100%">
            <div className="prefix">
              {actualPrefix}
            </div>
            <Truncate className="post-prefix" style={{ textAlign: error ? undefined : 'right' }}>
              {formattedValue ?? <EmDash />}
            </Truncate>
          </Flex>
        ) : (
          <Truncate style={{ textAlign: error ? undefined : 'right' }}>
            {error ? (
              <>
                <Icon icon="warning" color="warning" mr={2} />
                {error || <EmDash />}
              </>
            ) : (
              formattedValue ?? <EmDash />
            )}
          </Truncate>
        )}
      </Tooltip>
    </div>
  );
};

export const RequirementValueCell = ({ row, column, isDropdownButton, fieldName = 'exchangeDefs', isActive,
}: DataCellProps<EditableGridColumn, any, any> & {
  fieldName?: string;
  isDropdownButton?: boolean;
}) => {
  const { t } = useTranslation('translation');
  const requirement = getCellValue(row.original, column.original);
  const isDisabled = row.original.isObsolete || row.original.isLive || column.original.disabled || column.original.isDisabled?.(row.index);
  const { useShowValidationErrors } = useHooks();
  const showValidationErrors = useShowValidationErrors();
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);

  const {
    accessorKey,
    startEditingCell,
  } = column.original;

  const error = useCellValidationError({
    fieldName,
    customFieldKey: accessorKey,
    row,
    column: {},
    useShowValidationErrors,
  });

  return (
    <div
      className={clsx({
        'invalid': showValidationErrors && (error || !isRowValid),
        'cell-content': true,
        disabled: isDisabled,
      })}
      onClick={(event) => {
        if (!isDisabled && (isActive || isMultiClickEvent(event))) {
          startEditingCell?.(row, column);
        }
      }}
    >
      {showValidationErrors && error ? (
        <ErrorMessage fontSize={2} error={error} />
      ) : (
        <Truncate>
          {isDropdownButton ? (
            <IconText
              icon="caret-down"
              iconPosition="right"
              text={
                requirement
                  ? t(`request.documents.requirements.${requirement}`)
                  : t('request.documents.selectARequirement')
              }
            />
          ) : requirement ? (
            t(`request.documents.requirements.${requirement}`)
          ) : (
            <EmDash />
          )}
        </Truncate>
      )}
    </div>
  );
};

export const AttachmentCell = ({ row, column, isActive, fieldName = 'exchangeDefs' }: DataCellProps<EditableGridColumn, any, any> & { fieldName?: string; highlightRowOnError?: boolean }) => {
  const { t } = useTranslation('translation');
  const { isTemplate } = rfx.useState();
  const attachments = getCellValue(row.original, column.original);

  const { useShowValidationErrors } = useHooks();
  const showValidationErrors = useShowValidationErrors();
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);
  const isDisabled = row.original.isObsolete || column.original.disabled || column.original.isDisabled?.(row.index);

  const {
    startEditingCell,
  } = column.original;

  const error = useCellValidationError({
    fieldName,
    customFieldKey: 'attachments',
    row,
    column: {},
    useShowValidationErrors,
  });

  return (
    <div
      className={clsx({
        'invalid': showValidationErrors && (error || !isRowValid),
        'cell-content': true,
        disabled: isDisabled,
      })}
      onClick={(event) => {
        if (isActive || isMultiClickEvent(event)) {
          startEditingCell?.(row, column);
        }
      }}
    >
      {showValidationErrors && error ? (
        <ErrorMessage fontSize={2} error={error} />
      ) : attachments?.length >= 1 ? (
        <InformationModalProvider>
          <RfqAttachment
            truncate
            attachment={attachments[0]}
            isTemplate={isTemplate}
            sx={{
              width: '100%',
              justifyContent: 'space-between',
            }}
          />
        </InformationModalProvider>
      ) : isDocumentRequestExchange(row.original.type) ? (
        <Text color="subtext">{t('request.documents.supplierToUpload')}</Text>
      ) : (
        <EmDash />
      )}
    </div>
  );
};

export const LockCell = ({ row, column, isReadOnly, fieldName = 'exchangeDefs' }: DataCellProps<EditableGridColumn, any, any> & {
  isReadOnly?: boolean;
  fieldName?: string;
}) => {
  const { t } = useTranslation();
  const senders = rfx.useSenders();

  const lock = getCellValue(row.original, column.original);

  const { isLive, isObsolete } = row.original;

  const { useShowValidationErrors } = useHooks();
  const showValidationErrors = useShowValidationErrors();
  const { isValid: isRowValid } = validation.useErrors(`${fieldName}[${row.index}]`);

  const isLockable = lockableExchangeTypes.includes(row.original.type);
  const isDisabled = isObsolete || isLive || !isLockable || column.original.disabled || column.original.isDisabled?.(row.index);

  const {
    startEditingCell,
  } = column.original;

  return (
    <div
      className={clsx({
        'invalid': showValidationErrors && !isRowValid,
        'cell-content': true,
        disabled: isDisabled,
      })}
      onClick={() => {
        if (!isDisabled && isLockable) {
          startEditingCell?.(row, column);
        }
      }}
    >
      {!row.original.type ? (
        <EmDash />
      ) : !isLockable ? (
        <Text>{t('general.notAvailable')}</Text>
      ) : (
        <LockedTooltip lock={lock} senders={senders}>
          <Box display="inline-block">
            <IconText
              icon={lock ? 'lock' : 'unlock'}
              text={
                lock
                  ? isLive || isReadOnly
                    ? t('general.lockSet')
                    : t('general.editLock')
                  : t('general.noLockSet')
              }
            />
          </Box>
        </LockedTooltip>
      )}
    </div>
  );
};
