import * as React from 'react';
import { noop } from 'lodash';
import { Box } from 'rebass/styled-components';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import { Options } from '@popperjs/core';
import { OffsetsFunction } from '@popperjs/core/lib/modifiers/offset';
import useOnClickOutside from 'use-onclickoutside';
import { IS_SSR } from '@deepstream/ui-utils/isSsr';
import { useOpenState } from '../../hooks/useOpenState';
import { useSameWidthModifier } from './useSameWidthModifier';

const DEFAULT_MARGIN = 16;
const OUTSIDE_CLICK_EXCEPTED_ROLES = ['listbox', 'dialog'];

export function mergeRefs<T = any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>,
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}

export const usePopover = ({
  placement,
  alignToWindowEdge,
  sameWidth = false,
  strategy,
  overReferenceElement = false,
  skidding = 0,
  distance = 0,
}: {
  placement?: Options['placement'];
  strategy?: Options['strategy'],
  alignToWindowEdge?: 'left' | 'right';
  sameWidth?: boolean;
  overReferenceElement?: boolean,
  skidding?: number;
  distance?: number;
}) => {
  const { isOpen, toggle, open, close } = useOpenState();
  const [referenceElement, setReferenceElement] = React.useState<HTMLElement>();
  const [popperElement, setPopperElement] = React.useState<HTMLElement>();

  const sameWidthModifier = useSameWidthModifier({ enabled: sameWidth });

  const offset: OffsetsFunction = React.useCallback(
    ({ popper, reference, placement }) => {
      if (!placement) return [0, 0];

      if (overReferenceElement && !alignToWindowEdge) {
        let effectiveDistance = distance;
        let effectiveSkidding = skidding;

        switch (placement) {
          case 'top-start':
          case 'bottom-start':
          case 'right-start':
          case 'right-end':
            effectiveSkidding += -DEFAULT_MARGIN;
            break;
          case 'top-end':
          case 'bottom-end':
          case 'left-start':
          case 'left-end':
            effectiveSkidding += DEFAULT_MARGIN;
            break;
          case 'top':
          case 'bottom':
          case 'right':
          case 'left':
            effectiveSkidding += 0;
            break;
        }

        switch (placement) {
          case 'top':
          case 'top-start':
          case 'top-end':
          case 'bottom':
          case 'bottom-start':
          case 'bottom-end':
            effectiveDistance = -(popper.height + DEFAULT_MARGIN);
            break;
          case 'right':
          case 'right-start':
          case 'right-end':
          case 'left':
          case 'left-start':
          case 'left-end':
            effectiveDistance = -(popper.width + DEFAULT_MARGIN);
            break;
        }
        return [effectiveSkidding, effectiveDistance];
      }
      switch (alignToWindowEdge) {
        case 'left':
          return [-reference.x, 0];
        case 'right':
          return [window.innerWidth - (reference.x + reference.width), 0];
        default:
          return [0, 0];
      }
    },
    [alignToWindowEdge, overReferenceElement, distance, skidding],
  );

  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement,
    strategy,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset,
        },
      },
      sameWidthModifier,
    ],
  });

  return React.useMemo(
    () => ({
      update,
      isOpen,
      toggle,
      open,
      close,
      styles,
      attributes,
      referenceElement,
      setReferenceElement,
      setPopperElement,
    }),
    [
      update,
      isOpen,
      toggle,
      open,
      close,
      styles,
      attributes,
      referenceElement,
      setReferenceElement,
      setPopperElement,
    ],
  );
};

export const Popover = ({
  width,
  height,
  maxWidth,
  isOpen,
  referenceElement,
  setPopperElement,
  styles,
  attributes,
  children,
  onClickOutside = noop,
  sx = {},
}: any) => {
  const popperRef = React.useRef<HTMLElement | null>(null);

  useOnClickOutside(popperRef, event => {
    const composedPath = event.composedPath();

    if (
      // We don't want to consider an event on the reference element (ie: what is
      // triggering the popover to be open) to be "clicking outside." Otherwise,
      // the popover will close on mousedown and immediately re-open on click.
      !composedPath.includes(referenceElement) &&
      // We don't want to consider an event on a select menu popup (rendered in
      // a React portal and thus 'outside' of the reference element in the DOM
      // hierarchy) to be "clicking outside".
      !composedPath.some(eventTarget => {
        if (
          !(eventTarget instanceof Element) ||
          !eventTarget.getAttribute('data-popper-placement')
        ) {
          return false;
        }

        const role = eventTarget.getAttribute('role');

        return role && OUTSIDE_CLICK_EXCEPTED_ROLES.includes(role);
      })
    ) {
      onClickOutside();
    }
  });

  if (IS_SSR) {
    // Prevent accesing `document` object on the server-side
    return null;
  }

  return ReactDOM.createPortal(
    <Box
      hidden={!isOpen}
      ref={mergeRefs([setPopperElement, popperRef])}
      sx={{
        width,
        height,
        maxWidth,
        maxHeight: 'calc(100% - 96px)',
        boxShadow: 'large',
        borderRadius: 'small',
        zIndex: 999,
        backgroundColor: 'transparent',
        margin: `${DEFAULT_MARGIN}px`,
        ...(styles?.popper ?? {}),
        ...sx,
      }}
      {...attributes?.popper ?? {}}
    >
      {children}
    </Box>,
    document.body!,
    'popover',
  );
};
