import { isValid, parse } from 'date-fns';
import { isFinite, isNil, isNumber, isString, sumBy } from 'lodash';
import { EvaluationCriterionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { getDecimalPlaces } from '@deepstream/utils';
import { FieldType } from '@deepstream/common/exchangesConfig';

export const getWeightAsPercent = (defs: EvaluationCriterionExchangeDefinition[]) => (def): number =>
  def.isObsolete
    ? NaN // PercentCell will render NaN as em-dash
    : (def.weight || 0) / sumBy(defs, def => def.weight || 0);

export const isNumericString = (str: unknown) => {
  if (!isString(str)) {
    return false;
  }
  // eslint-disable-next-line no-restricted-properties
  return !global.isNaN(str as any) && !global.isNaN(parseFloat(str));
};

export const parseNumber = (value: unknown): number | null => {
  if (isFinite(value)) {
    return value as number;
  }

  if (isString(value)) {
    const sanitizedValue = value.replace(/[£$€\s]/g, '');

    if (isNumericString(sanitizedValue)) {
      return parseFloat(sanitizedValue);
    }
  }

  return null;
};

export const parseDate = (value: string | number, dateFormat?: string): Date | null => {
  if (isString(value) && dateFormat) {
    try {
      return parse(value, dateFormat, new Date());
    } catch (e) {
      return null;
    }
  } else {
    // fallback to current mechanism of guessing the date format
    return new Date(value);
  }

  return null;
};

const isValidPrice = (parsedValue: number | null, field: { type: FieldType, decimalPlaces?: number }) => {
  return (
    isFinite(parsedValue) &&
    getDecimalPlaces(parsedValue) <= (field.decimalPlaces ?? 2) &&
    parsedValue >= 0
  );
};

const isValidNumber = (parsedValue: number | null, field: { _id: string, type: FieldType }) => {
  if (!isFinite(parsedValue)) {
    return false;
  }

  if (['maxPoints', 'weight'].includes(field._id)) {
    return getDecimalPlaces(parsedValue) === 0 && parsedValue >= 1;
  } else if (field._id === 'quantity') {
    return getDecimalPlaces(parsedValue) <= 10 && parsedValue > 0;
  } else {
    return getDecimalPlaces(parsedValue) <= 10;
  }
};

export const parseCellValue = (
  value: unknown,
  field: { _id: string, type: FieldType, decimalPlaces?: number },
  dateFormat?: string,
) => {
  switch (field.type) {
    case 'string': {
      const parsedValue = isNil(value)
        ? ''
        : String(value);

      return {
        canInsertValue: true,
        cellValue: parsedValue.replace(/[\s]/g, ' '),
        isInputValid: true,
      };
    }
    case 'price': {
      const parsedValue = parseNumber(value);
      const isValid = isValidPrice(parsedValue, field);

      return {
        canInsertValue: true,
        cellValue: isValid ? parsedValue : null,
        isInputValid: (
          isNil(value) ||
          value === '' ||
          isValid
        ),
      };
    }
    case 'percentage':
    case 'number': {
      const parsedValue = parseNumber(value);
      const isValid = isValidNumber(parsedValue, field);

      return {
        canInsertValue: true,
        cellValue: isValid ? parsedValue : null,
        isInputValid: (
          isNil(value) ||
          value === '' ||
          isValid
        ),
      };
    }
    case 'date': {
      const parsedValue = isString(value) || isNumber(value)
        ? parseDate(value, dateFormat)
        : null;
      const isValidDate = isValid(parsedValue);
      return {
        canInsertValue: true,
        cellValue: isValidDate ? parsedValue.toISOString() : null,
        isInputValid: isNil(value) || value === '' || isValidDate,
      };
    }
    case 'unspscCode': {
      const codeMatch = isString(value)
        ? value.match(/^\[(\d{8})\]/)
        : null;
      const unspscCode = codeMatch?.[1] || null;

      return {
        canInsertValue: true,
        cellValue: unspscCode,
        isInputValid: isNil(value) || value === '' || Boolean(unspscCode),
      };
    }
    default:
      return {
        canInsertValue: false,
        cellValue: null,
        isInputValid: false,
      };
  }
};
