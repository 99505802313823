import { isEmpty } from 'lodash';
import { TFunction } from 'i18next';
import { IconValue } from '../icons';
import { Attachment, AuditRole, CompanyId, ExchangeId, PageRole, QuestionExchangeDefinition, QuestionType, TimeUnit, UserMinimized } from '../rfq-utils';

export enum PreQualCategoryType {
  GENERAL = 'general',
  NO_CATEGORY = 'noCategory',
}

export type PreQualSupplier = {
  _id: CompanyId;
  name: string;
  questionnaires: QuestionnaireOverview[];
};

export type PreQualSuppliersResponse = {
  totalItems: number;
  pageCount: number;
  suppliers: PreQualSupplier[];
};

export type QuestionnaireExchangeDefinition = QuestionExchangeDefinition & {
  originalExchangeVersion: number;
};

export const isQuestionnaireExchangeDefinition = (
  exchangeDef: QuestionExchangeDefinition,
): exchangeDef is QuestionnaireExchangeDefinition => {
  return 'originalExchangeVersion' in exchangeDef;
};

export enum QuestionnaireTemplateStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  DELETED = 'deleted',
  ARCHIVED = 'archived',
}

export enum QuestionnaireStatus {
  SENT = 'sent',
  IN_PROGRESS = 'inProgress',
  PENDING_REVIEW = 'pendingReview',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  DECLINED = 'declined',
  OBSOLETE = 'obsolete',
  EXPIRED = 'expired',
}

export type QuestionnaireSummary = {
  name: string;
  description: string;
};

export type QuestionnaireRenewalConfig = {
  isRecurring: boolean;
  frequency: {
    unit: TimeUnit.YEARS | TimeUnit.MONTHS | TimeUnit.DAYS;
    amount: number;
  };
};

export type QuestionnaireExpiryConfig = {
  doesExpire: boolean;
  offset: {
    unit: TimeUnit.YEARS | TimeUnit.MONTHS | TimeUnit.DAYS;
    amount: number;
  };
};

export type QuestionnaireInternalData = {
  purpose: string;
};

export type QuestionnaireTemplateVersion = {
  summary: QuestionnaireSummary;
  renewalConfig: QuestionnaireRenewalConfig;
  expiryConfig: QuestionnaireExpiryConfig;
  internal: QuestionnaireInternalData;
  exchangeDefById: Record<string, QuestionnaireExchangeDefinition>;
  exchangeDefSequence: string[];
  versionCreatorId?: CompanyId;
  publishedAt?: Date;
  publisherId?: string;
  instructions?: string;
  instructionsAttachments: Attachment[];
};

export type QuestionnaireTemplate = QuestionnaireTemplateVersion & {
  meta: {
    creationTimestamp: Date;
    creatorId: CompanyId;
    creatorUserId: string;
    creatorUsername: string;
    lastPublishedTimestamp: Date;
    lastUpdatedTimestamp: Date;
    lastUpdatedBy: UserMinimized;
  };
  _id: string;
  status: QuestionnaireTemplateStatus;
  isDeleted: boolean;
  version: number;
};

interface QuestionnaireTemplateOverviewMeta {
  createdBy: UserMinimized;
  createdAt: Date;
  creatorId: CompanyId;
  lastPublishedAt: Date;
  lastUpdatedAt: Date;
}

export interface QuestionnaireTemplateOverview {
  _id: string;
  meta: QuestionnaireTemplateOverviewMeta;
  name: string;
  status: QuestionnaireTemplateStatus;
  version: number;
  numQuestions: number;
  supplierIds: string[];
  renewalConfig: QuestionnaireRenewalConfig;
  expiryConfig: QuestionnaireExpiryConfig;
  draftQuestionIds: string[];
}

export type QuestionnaireTemplateOverviewsResponse = {
  totalItems: number;
  pageIndex: number;
  overviews: QuestionnaireTemplateOverview[];
};

export interface QuestionnaireTriggeredSystemEvents {
  upcomingDocumentExpiry?: Record<ExchangeId, Date>;
  upcomingRenewal?: Date;
}

interface QuestionnaireOverviewMeta {
  createdBy: UserMinimized;
  createdAt: Date;
  creatorId: CompanyId;
  fromTemplateId: string;
  fromTemplateVersion: number;
}

export enum QuestionnaireReminderType {
  NOT_RESPONDED = 'notResponded', // When suppliers have not responded to the questionnaire
  INCOMPLETE = 'incomplete', // When suppliers have not submitted the questionnaire
}

export interface QuestionnaireOverview {
  _id: string;
  meta: QuestionnaireOverviewMeta;
  name: string;
  status: QuestionnaireStatus;
  recipient: {
    _id: CompanyId;
    name: string;
    userIds: string[];
    ownerIds: string[];
  },
  senders: [{
    _id: CompanyId;
    name: string;
    userIds: string[];
    ownerIds: string[];
  }],
  version: number;
  completion: number;
  numRequirements: number;
  upcomingDocumentExpiryDates: {
    exchangeId: string;
    date: Date;
  }[];
  upcomingReminders: {
    type: QuestionnaireReminderType;
    date: Date;
  }[];
  renewalDate: Date | null;
  expiryDate: Date | null;
  approvalDate: Date | null;
  wasCompletedOnce: boolean;
  triggeredSystemEvents: QuestionnaireTriggeredSystemEvents;
  latestTemplateVersion: number;
}

export type QuestionnaireOverviewsResponse = {
  totalItems: number;
  pageIndex: number;
  overviews: QuestionnaireOverview[];
};

type Icon = {
  value: IconValue;
  color: string;
  isRegular?: boolean;
};

interface TemplateStatusConfig {
  status: QuestionnaireTemplateStatus;
  icon: Icon;
}

export interface TemplateStatusesConfig {
  [key: string]: TemplateStatusConfig;
}

export const questionnaireTemplateStatusConfig: TemplateStatusesConfig = {
  [QuestionnaireTemplateStatus.DRAFT]: {
    status: QuestionnaireTemplateStatus.DRAFT,
    icon: {
      value: 'pencil',
      color: 'subtext',
    },
  },
  [QuestionnaireTemplateStatus.ACTIVE]: {
    status: QuestionnaireTemplateStatus.ACTIVE,
    icon: {
      value: 'circle',
      color: 'success',
    },
  },
  [QuestionnaireTemplateStatus.ARCHIVED]: {
    status: QuestionnaireTemplateStatus.ARCHIVED,
    icon: {
      value: 'archive',
      color: 'subtext',
    },
  },
};

interface QuestionnaireStatusConfig {
  status: QuestionnaireStatus;
  icon: Icon;
}

export interface QuestionnaireStatusesConfig {
  [key: string]: QuestionnaireStatusConfig;
}

export const questionnaireStatusConfig: QuestionnaireStatusesConfig = {
  [QuestionnaireStatus.SENT]: {
    status: QuestionnaireStatus.SENT,
    icon: {
      value: 'paper-plane-top',
      color: 'subtext',
    },
  },
  [QuestionnaireStatus.IN_PROGRESS]: {
    status: QuestionnaireStatus.IN_PROGRESS,
    icon: {
      value: 'spinner',
      color: 'subtext',
    },
  },
  [QuestionnaireStatus.DECLINED]: {
    status: QuestionnaireStatus.DECLINED,
    icon: {
      value: 'do-not-enter',
      color: 'subtext',
    },
  },
  [QuestionnaireStatus.PENDING_REVIEW]: {
    status: QuestionnaireStatus.PENDING_REVIEW,
    icon: {
      value: 'exclamation-triangle',
      color: 'warning',
    },
  },
  [QuestionnaireStatus.REJECTED]: {
    status: QuestionnaireStatus.REJECTED,
    icon: {
      value: 'times',
      color: 'danger',
    },
  },
  [QuestionnaireStatus.APPROVED]: {
    status: QuestionnaireStatus.APPROVED,
    icon: {
      value: 'circle-check',
      color: 'success',
    },
  },
  [QuestionnaireStatus.OBSOLETE]: {
    status: QuestionnaireStatus.OBSOLETE,
    icon: {
      value: 'ban',
      color: 'subtext',
    },
  },
  [QuestionnaireStatus.EXPIRED]: {
    status: QuestionnaireStatus.EXPIRED,
    icon: {
      value: 'clock',
      isRegular: true,
      color: 'subtext',
    },
  },
};

export type Questionnaire = {
  meta: {
    creationTimestamp: Date;
    creatorId: CompanyId;
    creatorUserId: string;
    creatorUsername: string;
    questionnaireTemplateId: string;
    questionnaireTemplateVersion: number;
  };
  _id: string;
  status: QuestionnaireStatus;
  previousStatus: QuestionnaireStatus;
  previousName: string | null;
  version: number;
  summary: QuestionnaireSummary;
  renewalConfig: QuestionnaireRenewalConfig;
  expiryConfig: QuestionnaireExpiryConfig;
  internal: QuestionnaireInternalData;
  instructions?: string;
  instructionsAttachments: Attachment[];
  recipient: {
    _id: CompanyId;
    name: string;
  };
  senders: {
    _id: CompanyId;
    name: string;
  }[];
  senderIds: string[];
  exchangeDefById: Record<string, QuestionnaireExchangeDefinition>;
  exchangeDefSequence: string[];
  exchangeById: Record<string, unknown>;
  teamById: Record<CompanyId, QuestionnaireTeam>;
  numUnresolvedRequirements: number;
  numResolvedRequirements: number;
  numRequirements: number;
  numExpiredDocuments: number;
  onlyExpiredDocumentExchangeResponsesWereReset: boolean;
  hadExpiredDocuments: boolean;
  isUpdating: boolean;
  exchangeIdsUpdatedSinceLastReview: string[] | null;
  renewalDate: Date | null;
  expiryDate: Date | null;
  approvalDate: Date | null;
  wasCompletedOnce: boolean;
  isBeingRenewed: boolean;
  isUpdateCausedByRenewal: boolean;
};

export type QuestionnaireTeamMember = {
  _id: string;
  name: string;
  email?: string;
  roles: QuestionnaireRoles;
  dateAdded: Date;
};

export type UnsavedQuestionnaireTeamMember = Omit<QuestionnaireTeamMember, 'dateAdded'> & { isOwner: boolean };

export type QuestionnaireRoles = Record<string, PageRole>;

export type QuestionnaireTeam = {
  users: Record<QuestionnaireTeamMember['_id'], QuestionnaireTeamMember>;
  owners: QuestionnaireTeamMember['_id'][];
};

export const QUESTIONS_PAGE_ID = 'questions';

export type QuestionnaireRevisionPayload = {
  questionnaireTemplateVersion: number;
  renewalConfig?: QuestionnaireRenewalConfig;
  expiryConfig?: QuestionnaireExpiryConfig;
  internal?: QuestionnaireInternalData;
  instructions?: string;
  instructionsAttachments?: Attachment[];
  addedExchangeDefs: QuestionnaireExchangeDefinition[];
  removedExchangeDefIds: string[];
  updatedExchangeDefs: QuestionnaireExchangeDefinition[];
  exchangeDefSequence: string[];
  /**
   * See comment in `QuestionnaireTemplateChange` for why we need this.
   */
  updatedExchangeDefIdsWithOnlyVersionChange: string[];
  /**
   * When a questionnaire renewal is required (ie the questionnaire expires) and there
   * is a newer template version available, the questionnaire is updated to the newer
   * version. This flag is used to indicate that the renewal was the cause of the update.
   */
  isCausedByRenewal?: boolean;
};

type QuestionnaireHistoryEventBase = {
  meta: {
    user?: {
      _id: string;
      name: string;
    }
    company?: {
      _id: string;
      name: string;
    }
    role?: AuditRole;
    timestamp: Date;
  },
  hiddenFields?: string[];
  fields?: Record<string, any>;
};

export type QuestionnaireRevisedEventFields = {
  previousPurpose?: string;
  updatedPurpose?: string;
  previousInstructions?: {
    instructions: string;
    attachments: Attachment[];
  };
  updatedInstructions?: {
    instructions: string;
    attachments: Attachment[];
  };
  previousRenewalConfig?: QuestionnaireRenewalConfig;
  updatedRenewalConfig?: QuestionnaireRenewalConfig;
  questionsAdded?: number;
  questionsRemoved?: number;
  questionsUpdated?: number;
};

export type QuestionnaireHistoryEvent = QuestionnaireHistoryEventBase & (
  | {
    type: 'cannot-view' // Placeholder event for when a user doesn't have permission to read an event
  }
  | {
    type: 'questionnaire.created';
    fields: {
      userName: string;
      userId: string;
      recipientName: string;
      recipientId: string;
    };
  }
  | { type: 'questionnaire.accepted' }
  | { type: 'questionnaire.declined' }
  | { type: 'questionnaire.submitted' }
  | { type: 'questionnaire.approved' }
  | { type: 'questionnaire.rejected' }
  | { type: 'questionnaire.resubmission-requested' }
  | {
    type: 'questionnaire.expired';
    fields: {
      expiryConfig: QuestionnaireExpiryConfig;
      approvalDate: Date;
      expiryDate: Date;
    };
  }
  | { type: 'questionnaire.marked-as-expired' }
  | {
    type: 'questionnaire.marked-as-obsolete';
    fields: {
      previousStatus: QuestionnaireStatus;
    }
  }
  | { type: 'questionnaire.restored' }
  | {
    type: 'exchange-reply.sent';
    fields: {
      questionType: QuestionType;
      description?: string;
      comment?: string;
      action: string;
      previousResponse?: string;
      updatedResponse?: string;
      exchangeDef?: QuestionExchangeDefinition;
    };
  }
  | {
    type: 'questionnaire.renewal-required';
    fields: {
      updatedStatus: QuestionnaireStatus;
    };
  }
  | {
    type: 'document.expired';
    fields: {
      description: string;
      hasDocument: boolean;
      previousStatus: QuestionnaireStatus;
      updatedStatus: QuestionnaireStatus;
    };
  }
  | {
    type: 'questionnaire.revised';
    fields: QuestionnaireRevisedEventFields;
  }
  | {
    type: 'questionnaire.updated';
    fields: {
      updatedStatus: QuestionnaireStatus;
      previousStatus: QuestionnaireStatus;
    };
  }
  | {
    type: 'questionnaire.reopened';
    fields: {
      updatedStatus: QuestionnaireStatus;
      previousStatus: QuestionnaireStatus;
    };
  }
  | {
    type: 'summary.updated';
    fields: {
      previousName?: string;
      updatedName?: string;
      previousDescription?: string;
      updatedDescription?: string;
    };
  }
);

export const timeUnitToRenewalTranslationKey = {
  [TimeUnit.DAYS]: 'questionnaireRenewal.resubmissionRequiredEveryDayCount',
  [TimeUnit.MONTHS]: 'questionnaireRenewal.resubmissionRequiredEveryMonthCount',
  [TimeUnit.YEARS]: 'questionnaireRenewal.resubmissionRequiredEveryYearCount',
};

export const timeUnitToRenewalFrequencyTranslationKey = {
  [TimeUnit.DAYS]: 'questionnaireRenewal.everyDayCount',
  [TimeUnit.MONTHS]: 'questionnaireRenewal.everyMonthCount',
  [TimeUnit.YEARS]: 'questionnaireRenewal.everyYearCount',
};

export const timeUnitToExpireAfterTranslationKey = {
  [TimeUnit.DAYS]: 'questionnaireRenewal.expireAfterDayCount',
  [TimeUnit.MONTHS]: 'questionnaireRenewal.expireAfterMonthCount',
  [TimeUnit.YEARS]: 'questionnaireRenewal.expireAfterYearCount',
};

export const timeUnitToAfterTranslationKey = {
  [TimeUnit.DAYS]: 'questionnaireRenewal.afterDayCount',
  [TimeUnit.MONTHS]: 'questionnaireRenewal.afterMonthCount',
  [TimeUnit.YEARS]: 'questionnaireRenewal.afterYearCount',
};

export const getInstructionValue = (instructions: string, attachments: Attachment[], t: TFunction) => {
  if (instructions && !isEmpty(attachments)) {
    return `${instructions}. ${t('questionnaireTemplate.documentUploaded')}`;
  } else if (instructions) {
    return instructions;
  } else if (!isEmpty(attachments)) {
    return t('questionnaireTemplate.documentUploaded');
  } else {
    return null;
  }
};
