import * as React from 'react';
import md5 from 'md5';
import { useQuery } from 'react-query';
import { useToaster } from '@deepstream/ui/toast';
import { copyToClipboard } from '@deepstream/ui-utils/clipboard';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { Placement } from '@floating-ui/react';
import { PopoverMenu } from './PopoverMenu';

type User = {
  _id: string;
  email?: string;
  name?: string;
};

type UserMenuProps = {
  placement?: Placement;
  user: User;
  value?: any;
};

export const CopyUserMenu: React.FC<UserMenuProps> = ({
  user: originalUser,
  placement,
  value,
  children,
}) => {
  const toaster = useToaster();
  const adminApi = useAdminApi();

  // We only need to hit the API if the user's email or name are missing
  const { data: user = originalUser } = useQuery(
    ['user', { userId: originalUser._id }],
    wrap(adminApi.getUser),
    {
      enabled: !originalUser.email || !originalUser.name,
    },
  );

  const buttons = React.useMemo(
    () => [
      {
        label: 'Copy ID',
        onClick: () => {
          copyToClipboard(user._id);
          toaster.success('ID copied to clipboard');
        },
      },
      {
        label: 'Copy MD5',
        hidden: !user.email,
        onClick: () => {
          copyToClipboard(`${md5(user.email || '')}@deepstreamtech.com`);
          toaster.success('MD5 email copied to clipboard');
        },
      },
      {
        label: 'Copy email',
        hidden: !user.email,
        onClick: () => {
          copyToClipboard(user.email);
          toaster.success('Email copied to clipboard');
        },
      },
      {
        label: 'Copy name',
        hidden: !user.name,
        onClick: () => {
          copyToClipboard(user.name);
          toaster.success('Name copied to clipboard');
        },
      },
    ],
    [toaster, user],
  );

  if (value) {
    value = (
      // eslint-disable-next-line
      <span aria-haspopup="true" tabIndex={0}>{value}</span>
    );
  }

  return (
    <PopoverMenu buttons={buttons} placement={placement}>
      {value || children}
    </PopoverMenu>
  );
};
