import { Component } from 'react';

export class ErrorBoundary extends Component<Record<string, unknown>, { hasError: boolean }> {
  constructor(props: any) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  override render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div>An unexpected error occured</div>;
    }

    return this.props.children;
  }
}
