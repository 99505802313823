import { Form, Formik } from 'formik';
import { difference, isEmpty, keyBy, mapValues, without } from 'lodash';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { Box, Flex } from 'rebass/styled-components';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps, SaveButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { useApi } from './api';
import { TextField } from './form/TextField';
import { useCurrentCompanyId } from './currentCompanyId';
import { useToaster } from './toast';
import { useMutation } from './useMutation';

type List = {
  _id: string;
  name: string;
};

type ListInputProps = {
  list: List;
  isRemoved: boolean;
  toggleRemovedList: () => void;
};

const ListInput = ({ list, isRemoved, toggleRemovedList }: ListInputProps) => (
  <Flex mb="2">
    <Box width="calc(100% - 48px)">
      <TextField
        name={list._id}
        inputStyle={{ backgroundColor: isRemoved ? '#c8646433 !important' : 'inherit', lineHeight: 1 }}
        disabled={isRemoved}
      />
    </Box>
    <Button
      ml="2"
      variant="wrapper"
      type="button"
      iconLeft={isRemoved ? 'undo' : 'trash' /* FIXME: use trash-alt */}
      fontSize="16px"
      onClick={toggleRemovedList}
      sx={{
        height: '40px',
        width: '40px',
        color: 'darkGray',
        opacity: 0.5,
        transition: `
          color 300ms,
          background-color 300ms,
          border-color 300ms,
          box-shadow 300ms;
        `,
        ':hover': {
          backgroundColor: 'rgba(0, 0, 0, .05)',
        },
      }}
    />
  </Flex>
);

const validateListNames = (values: any, removedListIds: string[], t: TFunction) => {
  const errors: any = {};

  const ids = Object.keys(values);
  const removedNames = removedListIds.map(id => values[id]);
  const remainingNames = difference(Object.values(values), removedNames);

  ids.forEach(id => {
    const duplicates = remainingNames
      .filter(name => name.trim().toLowerCase() === values[id].trim().toLowerCase())
      .length;
    if (duplicates > 1) {
      errors[id] = t('network.errors.listAlreadyExists');
    }
  });

  return errors;
};

type EditSupplierListsProps =
  { supplierLists: any[]; onCancel: any; onSave: any } &
  ModalProps;

export const EditSupplierListsModal: React.FC<EditSupplierListsProps> = ({
  supplierLists,
  onCancel,
  onSave,
  ...props
}) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [removedListIds, setRemovedListIds] = React.useState<string[]>([]);

  const [editSupplierLists] = useMutation(
    api.editSupplierLists,
    {
      onSuccess: callAll(
        () => toaster.success(t('network.toaster.saveListChangesSuccess')),
        () => onSave(removedListIds),
      ),
      onError: () => toaster.error(t('network.toaster.saveListChangesSuccess')),
      onSettled: () => queryClient.invalidateQueries(['supplierLists', { companyId: currentCompanyId }]),
    },
  );

  React.useEffect(() => {
    if (!props.isOpen) {
      setRemovedListIds([]);
    }
  }, [props.isOpen]);

  const initialValues = React.useMemo(
    () => mapValues(keyBy(supplierLists, '_id'), 'name'),
    [supplierLists],
  );

  const yupSchema = React.useMemo(
    () => mapValues(keyBy(supplierLists, '_id'), () => yup.string().trim().required(t('general.required'))),
    [supplierLists, t],
  );

  const validate = React.useCallback(
    (values) => validateListNames(values, removedListIds, t),
    [removedListIds, t],
  );

  return (
    <Modal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      {...props}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={
          yup.object().shape(yupSchema)
        }
        onSubmit={async (values, { setSubmitting }) => {
          const lists = Object
            .entries(values)
            .map(([_id, name]: any[]) => ({ _id, name: name.trim() }));

          await editSupplierLists({
            companyId: currentCompanyId,
            supplierLists: lists,
            removedListIds,
          }, {
            onSettled: () => setSubmitting(false),
          });
        }}
        validate={validate}
      >
        {({ isSubmitting, dirty, setFieldValue, initialValues, validateForm, errors }) => {
          const hasChanged = dirty || !!removedListIds.length;
          const isValid = isEmpty(errors);
          return (
            <Form>
              <ModalHeader>
                {t('network.editLists')}
              </ModalHeader>
              <ModalBody maxHeight="375px" overflowY="auto">
                {supplierLists.map((list: List) => {
                  const { _id: id } = list;
                  const toggleRemovedList = () => {
                    if (removedListIds.includes(id)) {
                      setRemovedListIds(removedListIds => without(removedListIds, id));
                      // Wait for state to be set before validating
                      setTimeout(validateForm, 0);
                    } else {
                      setFieldValue(id, initialValues[id]);
                      setRemovedListIds(removedListIds => [...removedListIds, id]);
                    }
                  };

                  return (
                    <ListInput
                      key={list._id}
                      list={list}
                      isRemoved={removedListIds.includes(list._id)}
                      toggleRemovedList={toggleRemovedList}
                    />
                  );
                })}
              </ModalBody>
              <ModalFooter>
                <CancelButton onClick={onCancel} mr={2} />
                <SaveButton disabled={isSubmitting || !isValid || !hasChanged} />
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
