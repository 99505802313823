import { useMemo } from 'react';
import { isFinite } from 'lodash';
import { useTranslation } from 'react-i18next';
import { localeFormatNumber } from '@deepstream/utils';
import { ONE_DAY } from '@deepstream/common/constants';
import { RequestsReportingConfig } from '@deepstream/common/reporting';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { TotalRequestsByProductOrService } from './TotalRequestsByProductOrService';
import { TeamProductivityTable } from './TeamProductivityTable';
import * as dashboard from './dashboard';
import { useRequestsReporting, RequestsReportingDataContext } from './useRequestsReporting';

export const TeamProductivityTabContent = ({
  config,
  useData = useRequestsReporting,
}: {
  config: RequestsReportingConfig;
  useData?: typeof useRequestsReporting;
}) => {
  const { t } = useTranslation('reporting');
  const locale = useCurrentUserLocale();

  const { data } = useData(config);

  const contextValue = useMemo(
    () => ({ data: data ?? {} }),
    [data],
  );

  return (
    <RequestsReportingDataContext.Provider value={contextValue}>
      {data && (
        <dashboard.Column>
          <dashboard.Row>
            <TeamProductivityTable />
          </dashboard.Row>
          <dashboard.Row>
            <dashboard.Column>
              <dashboard.Value
                icon="clock"
                heading={t('teamProductivity.revisionsPerRequestAvg')}
                headingTestAttribute="revisions-per-request-average"
                value={isFinite(data.averageNumRevisions) ? (
                  localeFormatNumber(data.averageNumRevisions, { locale, minimumFractionDigits: 1, maximumFractionDigits: 1 })
                ) : (
                  t('noData')
                )}
              />
              <dashboard.Value
                icon="clock"
                heading={t('teamProductivity.requestDurationAvg')}
                headingTestAttribute="request-duration-average"
                value={isFinite(data.averageRequestDurationMs) ? (
                  `${localeFormatNumber(Math.floor(data.averageRequestDurationMs / ONE_DAY), { locale })} ${t('supplierEngagement.day', { count: Math.floor(data.averageRequestDurationMs / ONE_DAY) })}`
                ) : (
                  t('noData')
                )}
              />
            </dashboard.Column>
            <dashboard.Column flex={1}>
              <TotalRequestsByProductOrService />
            </dashboard.Column>
          </dashboard.Row>
        </dashboard.Column>
      )}
    </RequestsReportingDataContext.Provider>
  );
};
