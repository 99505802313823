import { MultiStageLineItemsEventType, MultiStageLineItemsTrackedUser } from '@deepstream/common';

import React, { useMemo } from 'react';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { LabeledValue } from '@deepstream/ui/draft/LabeledValue';
import { Flex } from 'rebass/styled-components';

import { Stack } from '@deepstream/ui-kit/elements/Stack';

import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Datetime2Cell } from '@deepstream/ui/DatetimeCell';
import { SimpleUserCell } from '@deepstream/ui/SimpleUserCell';
import { Table } from '@deepstream/ui/Table';
import { IntegrationTableStyle } from '@deepstream/ui/TableStyles';

import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { withProps } from '@deepstream/ui-utils/withProps';
import { CompanyMinimizedNameAndLogoCell } from '@deepstream/ui/CompanyMinimizedNameAndLogoCell';

import { TextCell } from '@deepstream/ui/TextCell';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { DateFormat } from '@deepstream/utils';
import { ProductDashboardFunnelMetric, ProductDashboardSection } from './ProductDashboardUtils';
import { INTEGRATION_TABLE_HEIGHT, INTEGRATION_TABLE_WIDTH } from './constants';
import { renderPercentage } from '../reports/utils';

export const YesNoWithIconCell: React.FC<any> = ({ cell: { value } }: any) => (
  <>
    {value ? (
      <IconText
        icon="arrow-right"
        iconColor="success"
        isIconRegular
        text="Yes"
      />
    ) : (
      <IconText
        icon="times"
        iconColor="danger"
        isIconRegular
        text="No"
      />
    )}
  </>
);

const FunnelUsersTable = ({ uniqueUsers }) => {
  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        id: 'date',
        Header: 'Date / Time',
        accessor: 'date',
        Cell: Datetime2Cell,
        format: DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ,
        sort: 'datetime',
        width: 200,
      },
      {
        Header: 'User',
        accessor: 'name',
        Cell: SimpleUserCell,
      },
      {
        Header: 'Request Creator',
        accessor: 'creatorCompanyName',
        Cell: withProps(CompanyMinimizedNameAndLogoCell, { hasLink: false, companyIdField: 'creatorCompanyId' }),
      },
      {
        Header: 'Request Name',
        accessor: 'requestName',
        Cell: TextCell,
      },
      {
        Header: 'Proceeded?',
        accessor: 'proceeded',
        sortType: 'boolean',
        Cell: YesNoWithIconCell,
        width: 110,
      },
    ],
    [],
  );

  return (
    <IntegrationTableStyle
      bordered
      headerBackgroundColor={theme.colors.lightGray3}
      hoverCursor="default"
      stickyHeader
      height={INTEGRATION_TABLE_HEIGHT}
      width={INTEGRATION_TABLE_WIDTH}
      cellPaddingX="10px"
    >
      <Table
        columns={columns}
        data={uniqueUsers}
        isSortable
        initialSortBy={[{ id: 'date', desc: true }]}
      />
    </IntegrationTableStyle>
  );
};

const FunnelStepDetailsModal = ({
  uniqueUsers,
  ...props
}: Omit<DialogProps, 'body'> & {
  uniqueUsers: MultiStageLineItemsTrackedUser[];
}) => {
  const proceededUsersCount = useMemo(
    () => uniqueUsers.filter((user) => user.proceeded).length,
    [uniqueUsers],
  );
  const notProceededUsersCount = useMemo(
    () => uniqueUsers.filter((user) => !user.proceeded).length,
    [uniqueUsers],
  );

  return (
    <Dialog
      shouldCloseOnEsc
      showCloseIcon
      body={
        <Stack maxWidth="900px" gap={0}>
          <Flex justifyContent="space-between" mb="16px">
            <Flex>
              <LabeledValue
                label="Unique users"
                align="left"
                labelProps={{ color: 'text' }}
                value={uniqueUsers.length}
                mr={4}
              />
              <LabeledValue
                label="Yes"
                align="left"
                labelProps={{ color: 'text' }}
                value={
                  <>
                    <Icon color="success" icon="arrow-right" regular mr={1} />
                    {proceededUsersCount}
                  </>
                }
                mr={4}
              />
              <LabeledValue
                label="No"
                align="left"
                labelProps={{ color: 'text' }}
                value={
                  <>
                    <Icon color="danger" icon="times" regular mr={1} />
                    {notProceededUsersCount}
                  </>
                }
                mr={4}
              />
              <LabeledValue
                label="Rate proceeded"
                align="left"
                labelProps={{ color: 'text' }}
                value={renderPercentage({
                  count: proceededUsersCount,
                  total: uniqueUsers.length,
                })}
              />
            </Flex>
          </Flex>
          <FunnelUsersTable uniqueUsers={uniqueUsers} />
        </Stack>
      }
      okButtonText="Close"
      showCancelButton={false}
      {...props}
    />
  );
};

const FunnelEndDetailsModal = ({
  uniqueUsers,
  firstStepUniqueUsersCount,
  ...props
}: Omit<DialogProps, 'body'> & {
  uniqueUsers: MultiStageLineItemsTrackedUser[],
  firstStepUniqueUsersCount: number,
}) => {
  return (
    <Dialog
      shouldCloseOnEsc
      showCloseIcon
      body={
        <Stack maxWidth="900px" gap={0}>
          <Flex justifyContent="space-between" mb="16px">
            <Flex>
              <LabeledValue
                label="Unique users"
                align="left"
                labelProps={{ color: 'text' }}
                value={uniqueUsers.length}
                mr={4}
              />
              <LabeledValue
                label="Unique users (Step 1)"
                align="left"
                labelProps={{ color: 'text' }}
                value={firstStepUniqueUsersCount}
                mr={4}
              />
              <LabeledValue
                label="Funnel conversion rate"
                align="left"
                labelProps={{ color: 'text' }}
                value={renderPercentage({
                  count: uniqueUsers.length,
                  total: firstStepUniqueUsersCount,
                })}
              />
            </Flex>
          </Flex>
          <FunnelUsersTable uniqueUsers={uniqueUsers} />
        </Stack>
      }
      okButtonText="Close"
      showCancelButton={false}
      {...props}
    />
  );
};

export const MultiStageLineItemsFunnel = ({ funnelData }) => {
  const bannerSeenModal = useModalState();
  const ctaClickedUsersModal = useModalState();
  const featureEnabledModal = useModalState();

  const bannerSeenUsers = funnelData[MultiStageLineItemsEventType.BANNER_SEEN];
  const ctaClickedUsers = funnelData[MultiStageLineItemsEventType.CTA_CLICKED];
  const featureEnabledUsers = funnelData[MultiStageLineItemsEventType.FEATURE_ENABLED];

  return (
    <ProductDashboardSection
      heading="Conversion funnel – suggestion panel"
      tooltip="The funnel counts unique users on unique requests."
      icon="users"
      isIconRegular
    >
      <ProductDashboardFunnelMetric
        label="1. Panel seen"
        mainValue={bannerSeenUsers.total}
        proceededValues={{
          proceeded: bannerSeenUsers.proceeded,
          notProceeded: bannerSeenUsers.notProceeded,
        }}
        onViewMoreClick={bannerSeenModal.open}
        tooltip="Counted when a user loads the review page on a request or template and sees the banner."
      />
      <ProductDashboardFunnelMetric
        label="2. CTA Clicked"
        mainValue={ctaClickedUsers.total}
        proceededValues={{
          proceeded: ctaClickedUsers.proceeded,
          notProceeded: ctaClickedUsers.notProceeded,
        }}
        onViewMoreClick={ctaClickedUsersModal.open}
        tooltip="Counted when a user clicks the “Learn how” link to open the knowledge base article."
      />
      <ProductDashboardFunnelMetric
        label="3. Feature enabled"
        mainValue={featureEnabledUsers.total}
        totalValue={bannerSeenUsers.total}
        onViewMoreClick={featureEnabledModal.open}
        tooltip="Counted when a user issues a request or revision, or saves a template, and has enabled multi-stage line items."
      />

      <FunnelStepDetailsModal
        heading="Conversion funnel – 1. Panel seen"
        uniqueUsers={bannerSeenUsers.uniqueUsers}
        onOk={bannerSeenModal.close}
        onCancel={bannerSeenModal.close}
        isOpen={bannerSeenModal.isOpen}
      />
      <FunnelStepDetailsModal
        heading="Conversion funnel – 2. CTA Clicked"
        uniqueUsers={ctaClickedUsers.uniqueUsers}
        onOk={ctaClickedUsersModal.close}
        onCancel={ctaClickedUsersModal.close}
        isOpen={ctaClickedUsersModal.isOpen}
      />
      <FunnelEndDetailsModal
        heading="Conversion funnel – 3. Feature enabled"
        uniqueUsers={featureEnabledUsers.uniqueUsers}
        firstStepUniqueUsersCount={bannerSeenUsers.uniqueUsers.length}
        onOk={featureEnabledModal.close}
        onCancel={featureEnabledModal.close}
        isOpen={featureEnabledModal.isOpen}
      />
    </ProductDashboardSection>
  );
};
