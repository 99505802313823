import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { QuestionnaireTeamMember } from '@deepstream/common/preQual';
import { Table } from '@deepstream/ui/Table';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { useQuestionnaireData } from '@deepstream/ui/modules/PreQualification/Questionnaire/questionnaireUtils';
import { UserLinkCell } from '../../UserLinkCell';
import { useAdminNavigation } from '../../AppRouting';

export const AdminQuestionnaireUsersTable = () => {
  const { isLarge, isExtraLarge } = useDeviceSize();
  const navigation = useAdminNavigation();

  const questionnaire = useQuestionnaireData();
  const companyId = questionnaire.recipient._id;

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        Cell: UserLinkCell,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: (row: QuestionnaireTeamMember) => questionnaire.teamById[companyId].owners.includes(row._id) ? 'Owner' : 'Team member',
        width: '180',
      },
    ],
    [companyId, questionnaire.teamById],
  );

  return (
    <BasicTableStyles>
      <Table
        isPaginated
        columns={columns}
        data={Object.values(questionnaire.teamById[companyId].users)}
        onRowClick={user => navigation.navigateToCompanyUser(companyId, user._id)}
        hiddenColumns={!isLarge && !isExtraLarge ? ['name'] : []}
        isSortable
      />
    </BasicTableStyles>
  );
};
