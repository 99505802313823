import { useTranslation } from 'react-i18next';
import { isFinite } from 'lodash';
import { RequestsReportingConfig, RequestsSavingsCardsData } from '@deepstream/common/reporting';
import { localeFormatPrice } from '@deepstream/utils';
import { Box } from 'rebass/styled-components';
import { Panel, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Grid } from '@deepstream/ui-kit/elements/Grid';
import * as dashboard from './dashboard';
import { useRequestsReportingCardsData } from './useRequestsReporting';
import { useDeviceSize } from '../../ui/useDeviceSize';
import { SavingsScatterChart } from './SavingsScatterChart';
import { SavingsDoughnutChart } from './SavingsDoughnutChart';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { SavingsByMethodPieChart } from './SavingsByMethodPieChart';
import { SavingsByMethodTable } from './SavingsByMethodTable';

const UnconfirmedSavingsSubtext = ({ amount, currencyCode }: { amount: number; currencyCode: string }) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();

  return (
    <Box color="subtext">
      {isFinite(amount) ? (
        t('request.spendAndSavings.andSavingsUnconfirmed', {
          savings: localeFormatPrice(amount, currencyCode, { locale, notation: 'compact' }),
        })
      ) : (
        t('general.notAvailable')
      )}
    </Box>
  );
};

const getAverage = ({ amount, requestCount }: { amount: number | null; requestCount: number }) => {
  if (!isFinite(amount)) {
    return null;
  }

  return amount / requestCount;
};

const RequestCountSubtext = ({ requestCount }: { requestCount: number }) => {
  const { t } = useTranslation('translation');

  return (
    <Box color="subtext">
      {t('requests.requestCount', { count: requestCount })}
    </Box>
  );
};

export const SavingsCards = ({
  config,
  useData = useRequestsReportingCardsData,
}: { config: RequestsReportingConfig; useData?: typeof useRequestsReportingCardsData }) => {
  const { t } = useTranslation('translation');
  const { isLarge, isExtraLarge } = useDeviceSize();
  const { data, isLoading, isError, isSuccess } = useData<RequestsSavingsCardsData>(config, 'savings');

  const interval = data?.config.interval;

  return (
    <>
      {/* Total savings */}
      <Panel>
        <dashboard.PanelHeading p="20px 20px 10px">
          {t('request.spendAndSavings.totalSavings')}
        </dashboard.PanelHeading>
        <Grid
          p="0 20px 20px"
          sx={{
            gridTemplateColumns: isLarge || isExtraLarge
              ? '1fr 1fr 1fr'
              : '1fr 1fr',
            gridTemplateRows: isLarge || isExtraLarge
              ? '134px'
              : '134px 134px',
          }}
        >
          <dashboard.Card
            as="h4"
            isLoading={isLoading}
            isError={isError}
            heading={t('request.spendAndSavings.allTime')}
          >
            <PanelPadding pt={0}>
              {isSuccess && data && (
                <>
                  <dashboard.Price
                    amount={data.totals.allTime.amount}
                    currencyCode={data.config.currency}
                    isGreenOnIncrease
                  />
                  <UnconfirmedSavingsSubtext
                    amount={data.unconfirmedTotals.allTime.amount}
                    currencyCode={data.config.currency}
                  />
                </>
              )}
            </PanelPadding>
          </dashboard.Card>
          <dashboard.Card
            as="h4"
            isLoading={isLoading}
            isError={isError}
            heading={interval ? t(`request.spendAndSavings.this.${interval}`) : undefined}
          >
            <PanelPadding pt={0}>
              {isSuccess && data && (
                <>
                  <dashboard.Price
                    amount={data.totals.currentInterval.amount}
                    comparisonAmount={data.totals.previousInterval.amount}
                    currencyCode={data.config.currency}
                    isGreenOnIncrease
                  />
                  <UnconfirmedSavingsSubtext
                    amount={data.unconfirmedTotals.currentInterval.amount}
                    currencyCode={data.config.currency}
                  />
                </>
              )}
            </PanelPadding>
          </dashboard.Card>
          <dashboard.Card
            as="h4"
            isLoading={isLoading}
            isError={isError}
            heading={interval ? t(`request.spendAndSavings.last.${interval}`) : undefined}
          >
            <PanelPadding pt={0}>
              {isSuccess && data && (
                <>
                  <dashboard.Price
                    amount={data.totals.previousInterval.amount}
                    currencyCode={data.config.currency}
                    isGreenOnIncrease
                  />
                  <UnconfirmedSavingsSubtext
                    amount={data.unconfirmedTotals.previousInterval.amount}
                    currencyCode={data.config.currency}
                  />
                </>
              )}
            </PanelPadding>
          </dashboard.Card>
        </Grid>

        {/* Average savings per request */}
        <dashboard.PanelHeading p="0 20px 10px">
          {t('request.spendAndSavings.averageSavingsPerRequest')}
        </dashboard.PanelHeading>
        <Grid
          p="0 20px 20px"
          sx={{
            gridTemplateColumns: isLarge || isExtraLarge
              ? '1fr 1fr 1fr'
              : '1fr 1fr',
            gridTemplateRows: isLarge || isExtraLarge
              ? '134px'
              : '134px 134px',
          }}
        >
          <dashboard.Card
            as="h4"
            isLoading={isLoading}
            isError={isError}
            heading={t('request.spendAndSavings.allTime')}
          >
            <PanelPadding pt={0}>
              {isSuccess && data && (
                <>
                  <dashboard.Price
                    amount={getAverage(data.totals.allTime)}
                    currencyCode={data.config.currency}
                    isGreenOnIncrease
                  />
                  <RequestCountSubtext
                    requestCount={data.totals.allTime.requestCount}
                  />
                </>
              )}
            </PanelPadding>
          </dashboard.Card>
          <dashboard.Card
            as="h4"
            isLoading={isLoading}
            isError={isError}
            heading={interval ? t(`request.spendAndSavings.this.${interval}`) : undefined}
          >
            <PanelPadding pt={0}>
              {isSuccess && data && (
                <>
                  <dashboard.Price
                    amount={getAverage(data.totals.currentInterval)}
                    comparisonAmount={getAverage(data.totals.previousInterval)}
                    currencyCode={data.config.currency}
                    isGreenOnIncrease
                  />
                  <RequestCountSubtext
                    requestCount={data.totals.currentInterval.requestCount}
                  />
                </>
              )}
            </PanelPadding>
          </dashboard.Card>
          <dashboard.Card
            as="h4"
            isLoading={isLoading}
            isError={isError}
            heading={interval ? t(`request.spendAndSavings.last.${interval}`) : undefined}
          >
            <PanelPadding pt={0}>
              {isSuccess && data && (
                <>
                  <dashboard.Price
                    amount={getAverage(data.totals.previousInterval)}
                    currencyCode={data.config.currency}
                    isGreenOnIncrease
                  />
                  <RequestCountSubtext
                    requestCount={data.totals.previousInterval.requestCount}
                  />
                </>
              )}
            </PanelPadding>
          </dashboard.Card>
        </Grid>
      </Panel>

      {/* Savings by method */}
      <Grid
        sx={{
          gridTemplateColumns: isLarge || isExtraLarge
            ? '1fr 1fr 1fr'
            : '1fr 1fr',
          gridTemplateRows: isLarge || isExtraLarge
            ? '324px'
            : '324px 324px',
        }}
      >
        <dashboard.Card
          isLoading={isLoading}
          isError={isError}
          heading={t('request.spendAndSavings.savingsByMethod')}
          infoTooltip={t('request.spendAndSavings.savingsByMethodInfo')}
        >
          <PanelPadding pt="15px">
            {isSuccess && data && (
              <SavingsByMethodPieChart data={data} />
            )}
          </PanelPadding>
        </dashboard.Card>
        <dashboard.Card
          isLoading={isLoading}
          isError={isError}
          heading={t('request.spendAndSavings.savingsByMethod')}
          sx={{ gridColumn: 'span 2' }}
        >
          <PanelPadding pt="15px">
            {isSuccess && data && (
              <SavingsByMethodTable data={data} />
            )}
          </PanelPadding>
        </dashboard.Card>
      </Grid>

      {/* Confirmed / unconfirmed savings */}
      <Grid
        sx={{
          gridTemplateColumns: isLarge || isExtraLarge
            ? '1fr 1fr 1fr'
            : '1fr 1fr',
          gridTemplateRows: isLarge || isExtraLarge
            ? '305px'
            : '305px 305px',
        }}
      >
        <dashboard.Card
          isLoading={isLoading}
          isError={isError}
          heading={interval ? t(`request.spendAndSavings.savingsPer.${interval}`) : undefined}
          sx={{ gridColumn: 'span 2' }}
        >
          <PanelPadding pt="10px" sx={{ height: '276px' }}>
            {isSuccess && data && (
              <SavingsScatterChart data={data} />
            )}
          </PanelPadding>
        </dashboard.Card>
        <dashboard.Card
          isLoading={isLoading}
          isError={isError}
          heading={t('request.spendAndSavings.percentOfRequestsWithSavings')}
          infoTooltip={t('request.spendAndSavings.percentOfRequestsWithSavingsInfo')}
        >
          <PanelPadding pt="15px" px={0}>
            {isSuccess && data && (
              <SavingsDoughnutChart data={data} />
            )}
          </PanelPadding>
        </dashboard.Card>
      </Grid>
    </>
  );
};
