import { useQuery } from 'react-query';
import { useAdminApi, wrap } from '@deepstream/ui/api';

export const getAdminContractQueryKey = (contractId: string) =>
  ['adminContract', { contractId }];

export const useAdminContractQueryKey = getAdminContractQueryKey;

export const useAdminContract = (contractId: string) => {
  const adminApi = useAdminApi();
  const queryKey = useAdminContractQueryKey(contractId);

  return useQuery(
    queryKey,
    wrap(adminApi.getAdminContract),
  );
};
