import { get } from 'lodash';
import * as React from 'react';
import { Box, Card, CardProps, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { getRegionName } from '@deepstream/common';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Bold } from './Bold';
import { CompanyLogo } from './CompanyLogo';
import { TableLink } from './TableLink';
import { useSession } from './auth';
import { useCurrentUserLocale } from './useCurrentUser';

export const CompanyInvitedBadge: React.FC<CardProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Tooltip content={t('invite.hasNotSignedUpYet')}>
      <Card
        {...props}
        sx={{
          height: 20,
          fontSize: 1,
          color: 'gray',
          borderWidth: 1,
          borderColor: 'gray',
          borderStyle: 'solid',
          borderRadius: '10px',
          lineHeight: 1,
          minWidth: 57,
        }}
      >
        <Flex alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100%' }}>
          <Bold>
            {t('invite.status.invited')}
          </Bold>
        </Flex>
      </Card>
    </Tooltip>
  );
};

type CompanyListItemProps = {
  company: any;
  className?: string;
  renderActions?: any;
  navigateToCompany: (companyId: string) => void;
};

export const CompanyListItem = ({
  company,
  navigateToCompany,
  renderActions,
  className,
}: CompanyListItemProps) => {
  const { isAppAdmin } = useSession();
  const { t } = useTranslation('general');
  const locale = useCurrentUserLocale();

  const navigate = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      navigateToCompany(company._id);
    },
    [company, navigateToCompany],
  );
  const countryCode = get(company, 'address.country');

  return (
    <Flex as="li" p={20} alignItems="center" className={className} style={{ height: 73 }}>
      <Box mr={10}>
        <CompanyLogo companyId={company._id} size="md" />
      </Box>
      <Box flex={1}>
        <Flex flexDirection="column" justifyContent="center">
          <Flex display="inline-flex" flexDirection="row">
            <Bold lineHeight={1.3} display="inline-block">
              {company.isPending && !isAppAdmin ? (
                <Text color="gray">
                  <Truncate>
                    {company.name}
                  </Truncate>
                </Text>
              ) : (
                <Text color="primary">
                  <Truncate>
                    <TableLink to="" onClick={navigate}>
                      {company.name}
                    </TableLink>
                  </Truncate>
                </Text>
              )}
            </Bold>
            {company.isPending && (
              <CompanyInvitedBadge ml={2} />
            )}
          </Flex>
          {!company.isPending && (
            <Box>
              <Text color="gray" fontSize={2} lineHeight={1.3}>
                <Truncate>
                  <Icon icon={'map-marker' /* FIXME: use alt */} mr="6px" />
                  <Text as="span">
                    {getRegionName(countryCode, locale, t('unknown'))}
                  </Text>
                </Truncate>
              </Text>
            </Box>
          )}
        </Flex>
      </Box>
      {renderActions && (
        <Box ml={2}>
          {renderActions(company)}
        </Box>
      )}
    </Flex>
  );
};

type CompaniesListProps = {
  companies: any[];
  renderActions?: any;
  navigateToCompany: (companyId: string) => void;
};

const StyledCompanyListItem = styled(CompanyListItem)`
  & + & {
    border-top: 1px solid #E2E8EF;
  }
`;

export const CompaniesList: React.FC<CompaniesListProps> = React.memo(({ companies, navigateToCompany, renderActions }) => (
  <Box as="ul" p={0} style={{ listStyle: 'none' }}>
    {companies.map(company => (
      <StyledCompanyListItem
        key={company._id}
        company={company}
        navigateToCompany={navigateToCompany}
        renderActions={renderActions}
      />
    ))}
  </Box>
));

CompaniesList.displayName = 'CompaniesList';
