import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { RequestsReportingConfig } from '@deepstream/common/reporting';
import { Panel, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { SupplierEngagementTable } from './SupplierEngagementTable';
import * as dashboard from './dashboard';
import { DurationCompletionRateChart } from './DurationCompletionRateChart';
import { useRequestsReporting, RequestsReportingDataContext } from './useRequestsReporting';
import { RequestResponseRateChart } from './RequestResponseRateChart';
import { RequestCompletionRateChart } from './RequestCompletionRateChart';

const ResponseRatePanel = () => {
  const { t } = useTranslation('reporting');

  return (
    <Panel padded>
      <dashboard.PanelHeading data-test="request-response-rate">
        {t('supplierEngagement.requestResponseRate')}
      </dashboard.PanelHeading>

      <RequestResponseRateChart />
    </Panel>
  );
};

const RequestCompletionRatePanel = () => {
  const { t } = useTranslation('reporting');

  return (
    <Panel>
      <PanelPadding>
        <dashboard.PanelHeading data-test="request-completion-rate">
          {t('supplierEngagement.requestCompletionRate')}
        </dashboard.PanelHeading>

        <Box mt="20px">
          <RequestCompletionRateChart />
        </Box>
      </PanelPadding>
    </Panel>
  );
};
export const SupplierEngagementTabContent = ({
  config,
  useData = useRequestsReporting,
}: {
  config: RequestsReportingConfig;
  useData?: typeof useRequestsReporting;
}) => {
  const { t } = useTranslation('reporting');

  const { data } = useData(config);

  const contextValue = useMemo(
    () => ({ data: data ?? {} }),
    [data],
  );

  return (
    <RequestsReportingDataContext.Provider value={contextValue}>
      {data && (
        <dashboard.Column>
          <dashboard.Row>
            <SupplierEngagementTable />
          </dashboard.Row>
          <dashboard.Row>
            <dashboard.Column width="400px">
              <RequestCompletionRatePanel />
              <ResponseRatePanel />
            </dashboard.Column>
            <dashboard.Column flex={1}>
              <Panel height="100%">
                <PanelPadding>
                  <dashboard.PanelHeading
                    sx={{ marginLeft: '27px' }}
                    data-test="request-completion-rate-by-duration"
                  >
                    {t('supplierEngagement.requestCompletionRateByDuration')}
                  </dashboard.PanelHeading>
                  <DurationCompletionRateChart
                    completionRateByDuration={data.completionRateByDuration}
                  />
                </PanelPadding>
              </Panel>
            </dashboard.Column>
          </dashboard.Row>
        </dashboard.Column>
      )}
    </RequestsReportingDataContext.Provider>
  );
};
