import { cloneDeep, merge } from 'lodash';
import * as React from 'react';
import ReactModal from 'react-modal';
import { Box, BoxProps, Flex, FlexProps } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '../icon/Icon';
import { truncateStyle } from '../text/Truncate2';
import { Button, ButtonProps } from '../button/Button';
import { Panel, PanelDivider, PanelHeading, PanelText } from '../Panel';

const customStyles: ReactModal.Styles = {
  content: {
    position: 'initial',
    margin: 'auto',
    backgroundColor: 'transparent',
    border: 'none',
    width: 'fit-content',
    minWidth: 'min(500px, calc(100vw - 32px))',
    maxWidth: 'calc(100vw - 32px)',
    padding: 0,
    lineHeight: 'normal',
    paddingTop: '28px',
    paddingBottom: '28px',
    animation: 'slide-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
  },
  overlay: {
    zIndex: 201,
    overflow: 'auto',
  },
};

export type ModalProps =
  { isOpen?: boolean; panelStyle?: any } &
  ReactModal.Props;

export const Modal: React.FC<ModalProps> = ({
  isOpen = false,
  style,
  panelStyle = {},
  children,
  shouldCloseOnOverlayClick = false,
  ...props
}) => {
  const appliedStyle = React.useMemo(
    () => style ? merge(cloneDeep(customStyles), style) : customStyles,
    [style],
  );

  return (
    <ReactModal
      isOpen={isOpen}
      style={appliedStyle}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      {...props}
    >
      <Panel style={{ border: 'none', overflow: 'hidden', ...panelStyle }}>
        {children}
      </Panel>
    </ReactModal>
  );
};

// FIXME: Rename to SubmitButton
export const SaveButton: React.FC<ButtonProps> = ({ label, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button type="submit" {...props}>
      {label || t('general.save')}
    </Button>
  );
};

export const CancelButton: React.FC<ButtonProps> = ({ label, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button type="button" variant="secondary" mr={2} {...props}>
      {label || t('general.cancel')}
    </Button>
  );
};

export const DoneButton: React.FC<ButtonProps> = ({ label, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button type="button" variant="secondary" mr={2} {...props}>
      {label || t('general.done')}
    </Button>
  );
};

export const DiscardButton: React.FC<ButtonProps> = ({ label, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button type="button" variant="danger" mr={2} {...props}>
      {label || t('general.discard')}
    </Button>
  );
};

export const CloseButton = ({ onClick }) => {
  return (
    <Button
      variant="wrapper"
      type="button"
      onClick={onClick}
      sx={{
        height: '30px',
        width: '30px',
        padding: '4px !important',
        color: 'subtext',
        transition: 'color 300ms',
        ':hover:not(:disabled)': {
          opacity: 0.8,
          cursor: 'pointer',
        },
      }}
    >
      <Flex justifyContent="center" alignItems="center">
        <Icon fontSize={18} icon="times" />
      </Flex>
    </Button>
  );
};

// ModalBody max-height is computed by subtracting ModalHeader and ModalFooter height from the Modal content max-height
const modalMargin = 36;
export const modalMaxHeight = `100vh - ${2 * modalMargin}px`;
export const modalHeaderHeight = '55px';
const modalFooterHeight = '73px';
const modalBodyMaxHeight = `calc(${modalMaxHeight} - ${modalHeaderHeight} - ${modalFooterHeight})`;

export type ModalHeaderProps =
  BoxProps & {
    onClose?: () => void;
    divider?: boolean;
    leftActionComponent?: any;
    rightActionComponent?: any;
    headingId?: string;
  };

export const ModalHeader: React.FC<ModalHeaderProps> = React.forwardRef(({
  onClose,
  divider = true,
  leftActionComponent,
  rightActionComponent,
  headingId,
  ...props
}) => (
  <Box height={modalHeaderHeight}>
    <Flex p={3} height={54} alignItems="center">
      {leftActionComponent}
      <Box flex={1}>
        <PanelHeading
          id={headingId}
          p={0}
          pr={3}
          style={{ flex: 1, ...truncateStyle }}
          {...props}
        />
      </Box>
      {rightActionComponent}
      {onClose && (
        <CloseButton onClick={onClose} />
      )}
    </Flex>
    {divider && <PanelDivider />}
  </Box>
));

export type ModalFooterProps = FlexProps;

export const ModalFooter: React.FC<ModalFooterProps> = ({ justifyContent = 'flex-end', ...props }) => (
  <Box height={modalFooterHeight}>
    <PanelDivider />
    <Flex justifyContent={justifyContent} alignItems="center" p={3} {...props} />
  </Box>
);

export type ModalBodyProps = BoxProps;

export const ModalBody: React.FC<ModalBodyProps> = React.forwardRef(({
  maxHeight = modalBodyMaxHeight,
  ...props
}, ref) => (
  <PanelText
    ref={ref}
    p={3}
    overflowY="auto"
    maxHeight={maxHeight}
    {...props}
  />
));
