import { useCallback } from 'react';
import * as React from 'react';
import { useField } from 'formik';
import { Box } from 'rebass/styled-components';
import { isEqual, noop, uniqueId } from 'lodash';
import { Attachment } from '@deepstream/common/rfq-utils';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { FileList, FileListProps } from '../ui/FileList';
import { useUploadApi } from '../ExchangeModal/useUpload';
import { UploadPurpose } from '../types';
import { DownloadFn } from '../ui/types';
import { FieldContainer } from './FieldContainer';
import { ErrorMessage, HelperText } from './Field';

type FilesFieldBaseProps = {
  disabled?: boolean;
  error?: string;
  helperText?: string;
  hideLabel?: boolean;
  hideError?: boolean;
  label?: string;
  max?: number;
  name?: string;
  onChange?: FileListProps['onChange'];
  required?: boolean;
  value?: Attachment[];
  purpose: UploadPurpose;
  initialFiles?: File[];
  onUploadStart?: (file: File) => void;
  download?: DownloadFn;
  details?: React.ReactNode;
  truncateFileName?: boolean;
  /**
   * Optional comma-separated list of one or more file types (or unique file type specifiers) describing which file types to allow.
   */
  accept?: string;
};

export const FilesFieldBase = ({
  disabled,
  error,
  hideError,
  helperText,
  hideLabel,
  label,
  name,
  onChange,
  onUploadStart,
  required,
  max,
  value,
  purpose,
  initialFiles,
  download,
  details,
  truncateFileName,
  accept,
}: FilesFieldBaseProps) => {
  const id = useUniqueId();
  const [upload] = useUploadApi({ purpose });

  return (
    <FieldContainer
      name={name}
      htmlFor={id}
      label={label}
      hideLabel={hideLabel}
      showAsterisk={required}
      width="100%"
    >
      <FileList
        key="editable"
        initialAttachments={value || []}
        initialFiles={initialFiles}
        onChange={onChange}
        onUploadStart={onUploadStart}
        isReadOnly={disabled}
        disabled={disabled}
        limit={max}
        details={details}
        uploadFn={upload}
        downloadFn={download}
        truncateFileName={truncateFileName}
        accept={accept}
      />
      {error && !hideError ? (
        <Box sx={{ wordBreak: 'break-all', textAlign: 'left' }}>
          <ErrorMessage error={error} fontWeight="normal" />
        </Box>
      ) : helperText ? (
        <HelperText text={helperText} />
      ) : (
        null
      )}
    </FieldContainer>
  );
};

export interface FilesFieldProps extends FilesFieldBaseProps {
  name?: string;
  fieldName?: string;
}

/**
 * Formik-aware file upload field component.
 *
 * If you pass a `fieldName` without a `name`, changing the value
 * won't update the corresponding value (this is useful for displaying
 * read-only derived values)
 */
export const FilesField = ({
  name,
  fieldName,
  initialFiles,
  onUploadStart = noop,
  onChange = noop,
  ...props
}: FilesFieldProps) => {
  const resolvedFieldName = fieldName || name;

  const [key, setKey] = React.useState<string>('initial');
  const initialValue = React.useRef(null);

  if (!fieldName && !name) {
    throw new Error('`fieldName` is required if no `name` prop included');
  }

  const [field, meta, formik] = useField({ name: resolvedFieldName! });

  React.useEffect(() => {
    initialValue.current = field.value;
  }, [field.value]);

  // <FilesFieldBase> is an uncontrolled component and only accepts an
  // initial value so in order to restore the initial state when the
  // form gets reset, we set a new key to force <FilesFieldBase> to
  // remount whenever the field value has changed and is identical to
  // the initial value.
  React.useEffect(() => {
    if (initialValue.current && initialValue.current === field.value) {
      setKey(uniqueId());
    }
  }, [field.value]);

  const handleChange = useCallback(
    value => {
      if (!isEqual(value, field.value)) {
        formik.setValue(value);
        onChange(value);
      }
    },
    [formik, field, onChange],
  );

  return (
    <FilesFieldBase
      key={key}
      error={meta.touched && meta.error ? meta.error : undefined}
      name={name}
      onChange={handleChange}
      onUploadStart={onUploadStart}
      value={field.value}
      initialFiles={initialFiles}
      {...props}
    />
  );
};

type FileFieldProps =
  Omit<FilesFieldProps, 'limit' | 'initialFiles'> &
  { initialFile?: File };

/**
 * Single file field
 */
export const FileField = ({ initialFile, ...props }: FileFieldProps) => (
  <FilesField
    max={1}
    initialFiles={initialFile ? [initialFile] : undefined}
    {...props}
  />
);
