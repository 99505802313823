import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { omitBy, isNil } from 'lodash';
import { RfqQuery, CompanyId, ExchangeId, RfqId, SectionId, UserId, Savings, CostAndSavingsByRecipientId, SavingsBySavingsType, SavingsCalculationResultByRecipientIdBySavingsType } from '@deepstream/common/rfq-utils';
import { LockedModelError } from '@deepstream/errors';
import { useApi, wrap } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { ExchangeSnapshot } from './types';
import { useCurrentUser } from './useCurrentUser';
import { usePopulateExchangeActionDisplayProps } from './ExchangeModal/usePopulateExchangeActionDisplayProps';

export const RfqIdContext = React.createContext<string | null>(null);

export const RfqIdProvider: React.FC<{ rfqId: string }> = ({ rfqId, ...props }) => (
  <RfqIdContext.Provider value={rfqId} {...props} />
);

export const RecipientIdContext = React.createContext<string | null>(null);

export const RecipientIdProvider: React.FC<{ recipientId: string }> = ({ recipientId, ...props }) => (
  <RecipientIdContext.Provider value={recipientId} {...props} />
);

/**
 * Define all possible variations so that we get static AND runtime guarantees
 */
export function useRfqId(a: { required: true }): string;
export function useRfqId(): string;
export function useRfqId(a: { required: false }): string | null;
export function useRfqId({ required = true } = {}) {
  const rfqId = React.useContext(RfqIdContext);

  if (required && !rfqId) {
    throw new Error('An `rfqId` has not been set via the `RfqIdProvider` component');
  }

  return rfqId;
}

/**
 * Define all possible variations so that we get static AND runtime guarantees
 */
export function useRecipientId(a: { required: true }): string;
export function useRecipientId(): string;
export function useRecipientId(a: { required: false }): string | null;
export function useRecipientId({ required = true } = {}) {
  const rfqId = React.useContext(RecipientIdContext);

  if (required && !rfqId) {
    throw new Error('A `recipientId` has not been set via the `RfqIdProvider` component');
  }

  return rfqId;
}

export type TransformerParams = RfqQuery;

export type Transformer = (arg: TransformerParams) => any;

/**
 * Creates a function that determines whether the 'exchanges' query contains the
 * corresponding `exchangeId`. This is intended for use with the `queryClient`
 * query filter's `predicate` option.
 *
 * For more info see: https://react-query.tanstack.com/guides/query-filters
 */
export const doExchangesContainExchange = ({ rfqId, recipientId, exchangeId }) => (query) => {
  const params = query.queryKey[1] || {} as any;

  return (
    query.queryKey[0] === 'exchanges' &&
    params.rfqId === rfqId &&
    params.recipientId === recipientId &&
    params.exchangeIds?.includes(exchangeId)
  );
};

/**
 * Creates a function that determines whether the 'exchanges' query matches
 * the provided `sectionId`. This is intended for use with the `queryClient`
 * query filter's `predicate` option.
 *
 * For more info see: https://react-query.tanstack.com/guides/query-filters
 */
export const doesExchangesQueryMatchSectionId = ({ rfqId, recipientId, sectionId }) => (query) => {
  const params = query.queryKey[1] || {} as any;

  return (
    query.queryKey[0] === 'exchanges' &&
    params.rfqId === rfqId &&
    params.recipientId === recipientId &&
    params.sectionIds?.includes(sectionId)
  );
};

/**
 * Creates a function that determines whether the 'exchange' query matches one of
 * the provided `exchangeIds`. This is intended for use with the `queryClient`
 * query filter's `predicate` option.
 *
 * For more info see: https://react-query.tanstack.com/guides/query-filters
 */
export const doesExchangeQueryMatchExchangeIds = ({ rfqId, recipientId, exchangeIds }) => (query) => {
  const params = query.queryKey[1] || {} as any;

  return (
    query.queryKey[0] === 'exchange' &&
    params.rfqId === rfqId &&
    params.recipientId === recipientId &&
    params.exchangeId &&
    exchangeIds.includes(params.exchangeId)
  );
};

export const useRfqExchangesQueryKey = ({
  sectionIds,
  recipientId,
}: {
  sectionIds?: SectionId[];
  recipientId?: CompanyId | null;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId();

  return React.useMemo(
    () => [
      'exchanges',
      omitBy({ sectionIds, rfqId, recipientId, currentCompanyId }, isNil) as any,
    ] as const,
    [rfqId, recipientId, sectionIds, currentCompanyId],
  );
};

export const useRfqExchanges = ({
  sectionIds,
  recipientId,
  enabled = true,
  select,
}: {
  sectionIds?: SectionId[];
  recipientId?: CompanyId | null;
  enabled?: boolean;
  select?: any;
}) => {
  const api = useApi();

  const queryKey = useRfqExchangesQueryKey({
    sectionIds,
    recipientId,
  });

  const query = useQuery(
    queryKey,
    wrap(api.getRfqExchanges),
    {
      staleTime: 20 * 1000,
      enabled: enabled && Boolean(sectionIds?.length),
      select,
    },
  );

  return React.useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};

export const useRfqExchange = ({
  exchangeId,
  recipientId,
  enabled = true,
  onSuccess,
}: {
  exchangeId?: ExchangeId;
  recipientId?: CompanyId | null;
  enabled?: boolean;
  onSuccess?: any;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId();
  const api = useApi();
  const queryClient = useQueryClient();
  const populateExchangeActionDisplayProps = usePopulateExchangeActionDisplayProps();

  const queryKey = React.useMemo(
    () => [
      'exchange',
      omitBy({ rfqId, exchangeId, recipientId, currentCompanyId }, isNil),
    ],
    [rfqId, exchangeId, recipientId, currentCompanyId],
  );

  const query = useQuery(
    queryKey,
    wrap(api.getRfqExchange),
    {
      staleTime: 10 * 1000,
      onSuccess,
      enabled: Boolean(exchangeId) && enabled,
      select: (exchange: ExchangeSnapshot) => populateExchangeActionDisplayProps(exchange, recipientId),
      initialData: () =>
        queryClient
          .getQueryData<ExchangeSnapshot[]>(
            ['exchanges'],
            {
              exact: false,
              // TODO fixme: we don't seem to use 'exchanges' query keys anymore
              // so this predicate never returns true
              predicate: doExchangesContainExchange({ rfqId, recipientId, exchangeId }),
            },
          )
          ?.find(exchange => exchange.def._id === exchangeId),
    },
  );

  return React.useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};

export const useRfqExchangeByRecipientQueryKey = ({
  exchangeIdByRecipientId,
}: {
  exchangeIdByRecipientId: Record<string, string>;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId();

  return React.useMemo(
    () => [
      'exchangeByRecipient',
      omitBy({ rfqId, currentCompanyId, exchangeIdByRecipientId }, isNil) as any,
    ] as const,
    [rfqId, currentCompanyId, exchangeIdByRecipientId],
  );
};

export const useRfqExchangeByRecipient = ({
  exchangeIdByRecipientId,
  enabled = true,
}: {
  exchangeIdByRecipientId: Record<string, string>;
  enabled?: boolean;
}) => {
  const api = useApi();

  const queryKey = useRfqExchangeByRecipientQueryKey({
    exchangeIdByRecipientId,
  });

  const query = useQuery(
    queryKey,
    wrap(api.getRfqExchangeByRecipient),
    {
      staleTime: 20 * 1000,
      enabled,
    },
  );

  return React.useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};

export const getLiveRfqStructureQueryKey = ({
  rfqId,
  currentCompanyId,
  recipientId = null,
}: {
  rfqId: string;
  currentCompanyId: string;
  recipientId?: string;
}) => ['liveRfqStructure', { rfqId, currentCompanyId, recipientId }];

export const useLiveRfqStructureQueryKey = ({
  rfqId: rfqIdProp,
  currentCompanyId: currentCompanyIdProp,
  recipientId: recipientIdProp,
  ignoreRecipientId,
}: {
  rfqId?: RfqId;
  currentCompanyId?: string;
  recipientId?: CompanyId;
  ignoreRecipientId?: boolean;
} = {}) => {
  const providedCurrentCompanyId = useCurrentCompanyId({ required: false });
  const currentCompanyId = currentCompanyIdProp || providedCurrentCompanyId;

  if (!currentCompanyId) {
    throw new Error('A `currentCompanyId` is required');
  }

  const providedRfqId = useRfqId({ required: false });
  const rfqId = rfqIdProp || providedRfqId;

  if (!rfqId) {
    throw new Error('An `rfqId` is required');
  }

  const providedRecipientId = useRecipientId({ required: false });
  const recipientId = ignoreRecipientId
    ? undefined
    : recipientIdProp || providedRecipientId;

  const queryKey = React.useMemo(
    () => getLiveRfqStructureQueryKey({ rfqId, currentCompanyId, recipientId }),
    [rfqId, recipientId, currentCompanyId],
  );

  return queryKey;
};

export const useLiveRfqStructure = ({
  rfqId,
  currentCompanyId,
  recipientId,
  ignoreRecipientId,
  enabled = true,
}: {
  rfqId?: RfqId;
  currentCompanyId?: string;
  recipientId?: CompanyId;
  ignoreRecipientId?: boolean;
  enabled?: boolean;
}) => {
  const queryKey = useLiveRfqStructureQueryKey({
    currentCompanyId,
    rfqId,
    recipientId,
    ignoreRecipientId,
  });

  const api = useApi();
  const query = useQuery(
    queryKey,
    wrap(api.getRfqBidStructure),
    {
      enabled,
      staleTime: 60 * 1000,
    },
  );

  return React.useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};

export const getDraftRfqStructureQueryKey = ({
  rfqId,
  currentCompanyId,
  isTemplate = false,
}: {
  rfqId: string;
  currentCompanyId: string;
  isTemplate: boolean
}) => [
  isTemplate ? 'templateStructure' : 'draftRfqStructure',
  { rfqId, currentCompanyId, isTemplate },
];

export const useDraftRfqStructureQueryKey = ({
  rfqId: rfqIdProp,
  currentCompanyId: currentCompanyIdProp,
  isTemplate = false,
}: {
  rfqId?: RfqId;
  currentCompanyId?: string;
  isTemplate?: boolean;
}) => {
  const providedCurrentCompanyId = useCurrentCompanyId({ required: false });
  const currentCompanyId = currentCompanyIdProp || providedCurrentCompanyId;

  if (!currentCompanyId) {
    throw new Error('A `currentCompanyId` is required');
  }

  const providedRfqId = useRfqId({ required: false });
  const rfqId = rfqIdProp || providedRfqId;

  if (!rfqId) {
    throw new Error('An `rfqId` is required');
  }

  const queryKey = React.useMemo(
    () => getDraftRfqStructureQueryKey({ rfqId, currentCompanyId, isTemplate }),
    [rfqId, currentCompanyId, isTemplate],
  );

  return queryKey;
};

export const useDraftRfqStructure = ({
  rfqId,
  currentCompanyId,
  enabled = true,
  isTemplate,
  refetchInterval = false,
}: {
  rfqId?: RfqId;
  currentCompanyId?: string;
  enabled?: boolean;
  isTemplate?: boolean;
  refetchInterval?: number | false
}) => {
  const queryKey = useDraftRfqStructureQueryKey({
    currentCompanyId,
    rfqId,
    isTemplate,
  });

  const api = useApi();
  const query = useQuery(
    queryKey,
    wrap(api.getRfqDraftStructure),
    {
      enabled,
      staleTime: 60 * 1000,
      refetchInterval,
    },
  );

  return React.useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};

export const useRfqStageApprovalExchanges = ({
  rfqId,
  currentCompanyId,
  enabled = true,
}: {
  rfqId?: RfqId;
  currentCompanyId?: string;
  enabled?: boolean;
}) => {
  const queryKey = React.useMemo(
    () => [
      'rfqApprovalExchanges',
      { rfqId, currentCompanyId },
    ],
    [rfqId, currentCompanyId],
  );

  const api = useApi();
  const query = useQuery<ExchangeSnapshot[]>(
    queryKey,
    wrap(api.getRfqApprovalExchanges),
    {
      enabled,
      staleTime: 60 * 1000,
    },
  );

  return React.useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};

export const getRfqComputedCostAndSavingsQueryKey = ({
  rfqId,
  currentCompanyId,
  awardedSupplierIds,
}: {
  rfqId?: RfqId;
  currentCompanyId?: string;
  awardedSupplierIds?: string[];
}) => {
  return [
    'rfqComputedCostAndSavings',
    { rfqId, currentCompanyId, awardedSupplierIds },
  ];
};

export const useRfqComputedCostAndSavings = ({
  rfqId,
  currentCompanyId,
  awardedSupplierIds,
  enabled = true,
}: {
  rfqId?: RfqId;
  currentCompanyId?: string;
  awardedSupplierIds?: string[];
  enabled?: boolean;
}) => {
  const queryKey = React.useMemo(() => {
    return getRfqComputedCostAndSavingsQueryKey({
      rfqId,
      currentCompanyId,
      awardedSupplierIds,
    });
  }, [rfqId, currentCompanyId, awardedSupplierIds]);

  const api = useApi();
  const query = useQuery(
    queryKey,
    wrap(api.getRfqComputedCostAndSavings),
    {
      enabled,
      staleTime: 60 * 1000,
    },
  );

  return React.useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};

export const useGetRfqComputedCostAndSavings = ({
  rfqId,
  currentCompanyId,
}: {
  rfqId?: RfqId;
  currentCompanyId?: string;
}) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return React.useCallback(async (awardedSupplierIds: string[]): Promise<{
    totalRequestValue: number;
    calculatedSavingsByType: SavingsCalculationResultByRecipientIdBySavingsType;
  }> => {
    const queryKey = getRfqComputedCostAndSavingsQueryKey({
      rfqId,
      currentCompanyId,
      awardedSupplierIds,
    });

    // TODO simplify the section below once we've upgraded to react-query >=4
    // by using queryClient.ensureQueryData

    const queryData = queryClient.getQueryData<any>(queryKey);

    if (queryData !== undefined) {
      return queryData;
    }

    return queryClient.fetchQuery({
      queryKey,
      queryFn: wrap(api.getRfqComputedCostAndSavings),
    });
  }, [queryClient, api, rfqId, currentCompanyId]);
};

export const getRfqCostAndSavingsDataQueryKey = ({
  rfqId,
  currentCompanyId,
}: {
  rfqId?: RfqId;
  currentCompanyId?: string;
}) => {
  return [
    'rfqCostAndSavingsData',
    { rfqId, currentCompanyId },
  ];
};

export const useRfqCostAndSavingsData = ({
  rfqId,
  currentCompanyId,
  enabled = true,
}: {
  rfqId?: RfqId;
  currentCompanyId?: string;
  enabled?: boolean;
}) => {
  const queryKey = React.useMemo(() => {
    return getRfqCostAndSavingsDataQueryKey({
      rfqId,
      currentCompanyId,
    });
  }, [rfqId, currentCompanyId]);

  const api = useApi();
  const query = useQuery(
    queryKey,
    wrap(api.getRfqCostAndSavingsData),
    {
      enabled,
      staleTime: 60 * 1000,
    },
  );

  return React.useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};

export const usePublicRfqStructureQueryKey = ({
  rfqId: rfqIdProp,
  currentCompanyId,
  currentUserId,
}: {
  rfqId?: RfqId;
  currentCompanyId?: CompanyId;
  currentUserId?: UserId;
}) => {
  const providedRfqId = useRfqId({ required: false });
  const rfqId = rfqIdProp || providedRfqId;

  if (!rfqId) {
    throw new Error('An `rfqId` is required');
  }

  const queryKey = React.useMemo(
    () => {
      if (!currentCompanyId && !currentUserId) {
        return ['publicRfqStructure', { rfqId }];
      } else {
        return ['publicRfqStructure', { rfqId, currentCompanyId, currentUserId }];
      }
    },
    [rfqId, currentCompanyId, currentUserId],
  );

  return queryKey;
};

export const FAKE_COMPANY_ID = 'FAKE_COMPANY_ID';
export const usePublicRfqStructure = ({
  rfqId,
  isPreview,
}: {
  rfqId?: RfqId;
  isPreview?: boolean;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: false });
  const currentUser = useCurrentUser();

  const queryKey = usePublicRfqStructureQueryKey({
    rfqId,
    currentUserId: currentUser?._id,
    ...(currentCompanyId !== FAKE_COMPANY_ID && { currentCompanyId }),
  });

  const api = useApi();
  const query = useQuery(
    queryKey,
    wrap(isPreview ? api.getRfqPublicPreviewStructure : api.getRfqPublicStructure),
    {
      staleTime: 60 * 1000,
    },
  );

  return React.useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};

export const useAuditTrailQueryKey = ({
  rfqId: rfqIdProp,
  currentCompanyId: currentCompanyIdProp,
  recipientId: recipientIdProp,
}: {
  rfqId?: RfqId;
  currentCompanyId?: string;
  recipientId?: string;
}) => {
  const providedCurrentCompanyId = useCurrentCompanyId({ required: false });
  const currentCompanyId = currentCompanyIdProp || providedCurrentCompanyId;

  if (!currentCompanyId) {
    throw new Error('A `currentCompanyId` is required');
  }

  const providedRfqId = useRfqId({ required: false });
  const rfqId = rfqIdProp || providedRfqId;

  if (!rfqId) {
    throw new Error('An `rfqId` is required');
  }

  const providedRecipientId = useRecipientId({ required: false });
  const recipientId = recipientIdProp || providedRecipientId;

  const queryKey = React.useMemo(
    () => ['auditTrail', { rfqId, currentCompanyId, recipientId }],
    [rfqId, currentCompanyId, recipientId],
  );

  return queryKey;
};

export const useAuditTrail = ({
  rfqId,
  currentCompanyId,
  enabled = true,
  recipientId,
}: {
  rfqId?: RfqId;
  currentCompanyId?: string;
  enabled?: boolean;
  recipientId?: string;
}) => {
  const queryKey = useAuditTrailQueryKey({
    currentCompanyId,
    rfqId,
    recipientId,
  });

  const [isLocked, setIsLocked] = React.useState(false);

  const api = useApi();
  const query = useQuery(
    queryKey,
    wrap(api.getAuditTrail),
    {
      enabled,
      onSuccess: () => setIsLocked(false),
      onError: (err: any) => setIsLocked(err?.response?.status === LockedModelError.code),
    },
  );

  return React.useMemo(
    () => ({ ...query, queryKey, isLocked }),
    [query, queryKey, isLocked],
  );
};
