import { useField } from 'formik';
import { Box } from 'rebass/styled-components';
import { find, map } from 'lodash';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { ErrorMessage, HelperText } from './Field';
import { FieldContainer } from './FieldContainer';
import { MultiSelect, MultiSelectProps } from '../ui/MultiSelect';
import { getItemLabel, getItemValue } from './SelectField';

export type MultiSelectFieldBaseProps = MultiSelectProps<any> & {
  disabled?: boolean;
  error?: string;
  helperText?: string;
  hideLabel?: boolean;
  hideError?: boolean;
  label?: string;
  name?: string;
  description?: string;
  required?: boolean;
};

export const MultiSelectFieldBase = ({
  error,
  hideError,
  helperText,
  hideLabel,
  label,
  name,
  description,
  required,
  ...props
}: MultiSelectFieldBaseProps) => {
  const id = useUniqueId();

  return (
    <FieldContainer
      name={name}
      description={description}
      htmlFor={id}
      label={label}
      hideLabel={hideLabel}
      showAsterisk={required}
    >
      <MultiSelect
        key={id}
        itemToString={getItemValue}
        renderItem={getItemLabel}
        {...props}
      />
      {error && !hideError ? (
        <Box sx={{ wordBreak: 'break-all', textAlign: 'left' }}>
          <ErrorMessage error={error} fontWeight="normal" />
        </Box>
      ) : helperText ? (
        <HelperText text={helperText} />
      ) : (
        null
      )}
    </FieldContainer>
  );
};

export interface MultiSelectFieldProps extends MultiSelectFieldBaseProps {
  name?: string;
  fieldName?: string;
  // shouldHandleBlur?: boolean;
  onChange?: any;
  items: any[];
}

/**
 * Formik-aware multi-select field component.
 *
 * If you pass a `fieldName` without a `name`, changing the value
 * won't update the corresponding value (this is useful for displaying
 * read-only derived values)
 */
export const MultiSelectField = ({
  name,
  fieldName,
  itemToString,
  items,
  ...props
}: MultiSelectFieldProps) => {
  const resolvedFieldName = fieldName || name;

  if (!fieldName && !name) {
    throw new Error('`fieldName` is required if no `name` prop included');
  }

  const [field, meta, formik] = useField({ name: resolvedFieldName! });

  return (
    <MultiSelectFieldBase
      error={meta.touched && meta.error ? meta.error : undefined}
      name={name}
      onChange={value => { formik.setValue(map(value, itemToString)); }}
      selectedItems={map(
        field.value,
        value => find(
          items,
          item => itemToString(item) === value,
        ),
      )}
      itemToString={itemToString}
      items={items}
      {...props}
    />
  );
};
