import { useCallback, useMemo } from 'react';
import { Box, Text } from 'rebass/styled-components';
import { first, isFinite } from 'lodash';
import * as rfx from '@deepstream/ui/rfx';
import { PropertyList, PropertyListAction, PropertyListActionProps, Skip } from '@deepstream/ui/PropertyList';
import { Datetime } from '@deepstream/ui/Datetime';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { AuctionStatus } from '@deepstream/common/rfq-utils';
import { useCountdown } from '@deepstream/ui/deadline';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { useMutation } from '@deepstream/ui/useMutation';
import { useAdminApi } from '@deepstream/ui/api';
import { useRfqId } from '@deepstream/ui/useRfq';
import { useToaster } from '@deepstream/ui/toast';
import { callAll } from '@deepstream/utils/callAll';
import { useQueryClient } from 'react-query';
import { useAdminRequestQueryKey } from './adminRequest';
import { ExtendAuctionTimerModal } from './ExtendAuctionTimerModal';

const MinutesCount = ({ value }: { value: number }) =>
  isFinite(value) ? (
    <Text>
      {value} {value === 1 ? 'minute' : 'minutes'}
    </Text>
  ) : (
    <EmDash />
  );

type CountdownOrDashProps = {
  value: {
    deadlineDate: Date | null;
    pauseDate?: Date;
  }
};

const CountdownOrDash = ({ value }: CountdownOrDashProps) => value.deadlineDate ? (
  <Countdown {...value} />
) : (
  <EmDash />
);

const Countdown = ({ deadlineDate, pauseDate }: { deadlineDate: Date; pauseDate?: Date }) => {
  const countdown = useCountdown({
    deadline: deadlineDate,
    referenceDate: pauseDate || new Date(),
    isActive: !pauseDate,
  });
  const { days, seconds, minutes, hours } = countdown;

  return !seconds && !minutes && !hours && !days ? (
    <>Passed</>
  ) : !days && hours < 1 ? (
    <>
      {minutes}m {seconds}s
    </>
  ) : !days && hours < 24 ? (
    <>
      {hours}h {minutes}m
    </>
  ) : (
    <>
      {days}d {hours}h
    </>
  );
};

const auctionStatusLabel = {
  [AuctionStatus.PENDING]: 'Upcoming',
  [AuctionStatus.ACTIVE]: 'Live',
  [AuctionStatus.PAUSED]: 'Paused',
  [AuctionStatus.ENDED]: 'Ended',
  [AuctionStatus.CANCELLED]: 'Cancelled',
};

const CancelAuctionButton = (props: Partial<PropertyListActionProps>) => {
  const adminApi = useAdminApi();
  const queryClient = useQueryClient();
  const confirmationDialog = useModalState();
  const toaster = useToaster();
  const rfqId = useRfqId();
  const { auction } = rfx.useStructure();
  const queryKey = useAdminRequestQueryKey(rfqId);

  const [cancelAuction] = useMutation(
    adminApi.cancelAuction,
    {
      onSuccess: () => toaster.success('Auction cancelled successfully'),
      onError: () => toaster.error('Auction could not be cancelled'),
      onSettled: callAll(
        confirmationDialog.close,
        () => queryClient.invalidateQueries(queryKey),
      ),
    },
  );

  const onCancelAuction = useCallback(
    () => cancelAuction({
      rfqId,
      auctionId: auction._id,
    }),
    [cancelAuction, auction, rfqId],
  );

  return (
    <>
      <PropertyListAction
        label="Cancel"
        icon="close"
        variant="danger-outline"
        onClick={confirmationDialog.open}
        {...props}
      />
      <Dialog
        heading="Cancel auction"
        body={(
          <MessageBlock variant="warn" mt={0} maxWidth="470px">
            This will permanently end the auction
          </MessageBlock>
        )}
        okButtonText="Cancel auction"
        okButtonVariant="danger"
        cancelButtonText="Cancel"
        cancelButtonVariant="secondary"
        isOpen={confirmationDialog.isOpen}
        onOk={onCancelAuction}
        onCancel={confirmationDialog.close}
      />
    </>
  );
};

const PauseAuctionButton = () => {
  const adminApi = useAdminApi();
  const queryClient = useQueryClient();
  const confirmationDialog = useModalState();
  const toaster = useToaster();
  const rfqId = useRfqId();
  const { auction } = rfx.useStructure();
  const queryKey = useAdminRequestQueryKey(rfqId);

  const [pauseAuction] = useMutation(
    adminApi.pauseAuction,
    {
      onSuccess: () => toaster.success('Auction paused successfully'),
      onError: () => toaster.error('Auction could not be paused'),
      onSettled: callAll(
        confirmationDialog.close,
        () => queryClient.invalidateQueries(queryKey),
      ),
    },
  );

  const onPauseAuction = useCallback(
    () => pauseAuction({
      rfqId,
      auctionId: auction._id,
    }),
    [pauseAuction, auction, rfqId],
  );

  return (
    <>
      <PropertyListAction
        label="Pause"
        icon="pause"
        variant="primary-outline"
        onClick={confirmationDialog.open}
      />
      <Dialog
        heading="Confirm pause auction"
        body={(
          <MessageBlock variant="info" mt={0} maxWidth="470px">
            If you pause, the auction status will change to paused, the timer will stop and suppliers cannot submit new bids
          </MessageBlock>
        )}
        okButtonText="Pause auction"
        okButtonVariant="primary"
        cancelButtonText="Cancel"
        cancelButtonVariant="secondary"
        isOpen={confirmationDialog.isOpen}
        onOk={onPauseAuction}
        onCancel={confirmationDialog.close}
      />
    </>
  );
};

const ResumeAuctionButton = () => {
  const adminApi = useAdminApi();
  const queryClient = useQueryClient();
  const confirmationDialog = useModalState();
  const toaster = useToaster();
  const rfqId = useRfqId();
  const { auction } = rfx.useStructure();
  const queryKey = useAdminRequestQueryKey(rfqId);

  const [resumeAuction] = useMutation(
    adminApi.resumeAuction,
    {
      onSuccess: () => toaster.success('Auction resumed successfully'),
      onError: () => toaster.error('Auction could not be resumed'),
      onSettled: callAll(
        confirmationDialog.close,
        () => queryClient.invalidateQueries(queryKey),
      ),
    },
  );

  const onResumeAuction = useCallback(
    () => resumeAuction({
      rfqId,
      auctionId: auction._id,
    }),
    [resumeAuction, auction, rfqId],
  );

  return (
    <>
      <PropertyListAction
        label="Resume"
        icon="play"
        variant="primary-outline"
        onClick={confirmationDialog.open}
      />
      <Dialog
        heading="Confirm resume auction"
        body={(
          <MessageBlock variant="info" mt={0} maxWidth="470px">
            If you resume, the auction status will change to live, the timer will resume and suppliers can submit new bids
          </MessageBlock>
        )}
        okButtonText="Resume auction"
        okButtonVariant="primary"
        cancelButtonText="Cancel"
        cancelButtonVariant="secondary"
        isOpen={confirmationDialog.isOpen}
        onOk={onResumeAuction}
        onCancel={confirmationDialog.close}
      />
    </>
  );
};

const ExtendAuctionTimerButton = () => {
  const modal = useModalState();
  const { auction } = rfx.useStructure();
  const firstLot = first(auction.lots);

  const { days, hours, minutes, seconds } = useCountdown({
    deadline: new Date(firstLot.endDate),
    referenceDate: new Date(firstLot.pauseDate),
    isActive: false,
  });

  const minutesCount = minutes + hours * 60 + days * 24 * 60;

  return (
    <>
      <PropertyListAction
        label="Extend"
        icon="plus"
        variant="primary-outline"
        onClick={modal.open}
      />
      <ExtendAuctionTimerModal
        initialMinutesCount={minutesCount}
        initialSecondsCount={seconds}
        isOpen={modal.isOpen}
        onCancel={modal.close}
      />
    </>
  );
};

export const AuctionPropertyList = () => {
  const structure = rfx.useStructure();
  const { auction } = structure;
  const firstLot = first(auction.lots);
  const isPending = auction.status === AuctionStatus.PENDING;
  const isActive = auction.status === AuctionStatus.ACTIVE;
  const isPaused = auction.status === AuctionStatus.PAUSED;
  const deadline = (isActive || isPaused) ? firstLot.endDate : null;

  const generalProperties = useMemo(
    () => {
      const deadlineDate = deadline ? new Date(deadline) : null;
      const timerData = {
        deadlineDate,
        pauseDate: firstLot.pauseDate ? new Date(firstLot.pauseDate) : undefined,
      };

      return [
        { name: 'Auction status', value: auctionStatusLabel[auction.status] },
        { name: 'Start', value: auction.startDate, Component: Datetime },
        { name: 'Initial duration', value: firstLot.rules.duration, Component: MinutesCount },
        { name: 'Auction timer', value: timerData, Component: CountdownOrDash },
      ];
    },
    [auction, firstLot, deadline],
  );

  return (
    <PropertyList properties={generalProperties}>
      <Box>
        {isPending ? (
          <CancelAuctionButton />
        ) : isActive ? (
          <PauseAuctionButton />
        ) : isPaused ? (
          <Box>
            <CancelAuctionButton mr={2} />
            <ResumeAuctionButton />
          </Box>
        ) : (
          null
        )}
      </Box>
      <Skip />
      <Skip />
      {isPaused && (
        <ExtendAuctionTimerButton />
      )}
    </PropertyList>
  );
};
