import { Text } from 'rebass/styled-components';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { callAll } from '@deepstream/utils/callAll';
import { useToaster } from '@deepstream/ui/toast';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { Language } from '@deepstream/common/rfq-utils';
import { ConfirmationPopover } from '@deepstream/ui-kit/elements/popup/ConfirmationPopover';

export const RemoveLanguageButton = ({
  onSave,
  languageCode,
  updateConfig,
  existingLanguages,
}: {
  onSave,
  languageCode: string,
  updateConfig,
  existingLanguages: Language[],
}) => {
  const toaster = useToaster();
  const popover = usePopover({
    placement: 'bottom-start',
    strategy: 'fixed',
    overReferenceElement: true,
  });

  return (
    <>
      <Button
        variant="danger-outline"
        onClick={(event) => {
          if (popover.update) {
            popover.update();
            event.stopPropagation();
          }
          return popover.open();
        }}
        ref={popover.setReferenceElement}
        type="button"
      >
        <Icon icon="close" mr={1} fixedWidth />
        Remove language
      </Button>
      <ConfirmationPopover
        width="100%"
        maxWidth="265px"
        popover={popover}
        heading="Are you sure?"
        body={<Text mb={2}>Any user of this language will be switched to the default.</Text>}
        okButtonText="Remove language"
        okButtonVariant="danger"
        cancelButtonText="Go back"
        onCancel={popover.close}
        onOk={async () => {
          const updatedLanguages = existingLanguages.filter(language => language.code !== languageCode);

          await updateConfig({
            languages: updatedLanguages,
          }, {
            onSuccess: callAll(
              onSave,
              () => toaster.success('Language removed successfully'),
            ),
            onError: () => toaster.error('Language could not be removed'),
          });
        }}
      />
    </>
  );
};
