import { difference, map } from 'lodash';
import * as React from 'react';
import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelPadding, SidebarPanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { AddSupplierListModal } from './AddSupplierListModal';
import { CheckboxList } from './CheckboxList';
import { EditSupplierListsModal } from './EditSupplierListsModal';
import { useModalState } from './ui/useModalState';
import { useSupplierLists } from './useSupplierLists';
import { Loading } from './ui/Loading';
import { ErrorMessage } from './ui/ErrorMessage';

type SupplierListsPanelProps = {
  selectedIds: string[];
  onChange: (item: any) => void;
  selectableSupplierListIds?: string[];
};

export const SupplierListsPanel: React.FC<SupplierListsPanelProps> = ({
  selectedIds,
  onChange,
  selectableSupplierListIds,
}) => {
  const { t } = useTranslation();
  const { supplierLists, supplierListsStatus } = useSupplierLists();

  const disabledSupplierListIds = React.useMemo(
    () => selectableSupplierListIds && supplierLists
      ? difference(map(supplierLists, '_id'), selectableSupplierListIds)
      : [],
    [selectableSupplierListIds, supplierLists],
  );

  const addListModal = useModalState();
  const editListsModal = useModalState();
  const editDisabled = !supplierLists || !supplierLists.length;

  const onEditListsModalSaved = (removedListIds) => {
    onChange(
      difference(selectedIds, removedListIds),
    );
    editListsModal.close();
  };

  return (
    <Panel>
      <SidebarPanelHeader heading={<IconText icon="list-ul" text={t('list_other', { ns: 'general' })} gap={2} />}>
        <Tooltip content={editDisabled ? t('network.noListsToEdit') : ''}>
          <div style={{ display: 'inline-block' }}>
            <Button
              small
              mr={2}
              variant="secondary-outline"
              iconLeft={'pencil' /* FIXME: use alt */}
              disabled={editDisabled}
              onClick={editListsModal.open}
            >
              {t('network.editLists')}
            </Button>
          </div>
        </Tooltip>
        <Button small iconLeft="plus" onClick={addListModal.open}>
          {t('network.addList')}
        </Button>
      </SidebarPanelHeader>
      <PanelPadding pt={0} mt={-2}>
        {supplierListsStatus === 'loading' ? (
          <Loading fontSize={2} />
        ) : supplierListsStatus === 'error' ? (
          <ErrorMessage error={t('network.errors.errorLoadingLists')} fontSize={2} />
        ) : supplierLists && supplierLists.length ? (
          <CheckboxList
            value={selectedIds}
            items={supplierLists}
            onChange={onChange}
            withClearButton
            disabledItems={disabledSupplierListIds}
          />
        ) : supplierLists && !supplierLists.length ? (
          <Text color="subtext" fontSize={2}>{t('network.noListsYet')}</Text>
        ) : (
          null
        )}
      </PanelPadding>
      <EditSupplierListsModal
        onSave={onEditListsModalSaved}
        onCancel={editListsModal.close}
        isOpen={editListsModal.isOpen}
        onRequestClose={editListsModal.close}
        supplierLists={supplierLists}
      />
      <AddSupplierListModal
        supplierLists={supplierLists}
        isOpen={addListModal.isOpen}
        onCancel={addListModal.close}
        onSave={addListModal.close}
        onRequestClose={addListModal.close}
      />
    </Panel>
  );
};
