import { ControllerStateAndHelpers, DownshiftProps, GetItemPropsOptions } from 'downshift';
import { compact, isEmpty, last, noop, reject, size } from 'lodash';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Box, BoxProps, Flex, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import { usePopper } from 'react-popper';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { IS_SSR } from '@deepstream/ui-utils/isSsr';
import { Chip } from '@deepstream/ui-kit/elements/Chip';
import { WrapperButton } from '@deepstream/ui-kit/elements/button/WrapperButton';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import { MultiDownshift } from './MultiDownshift';
import { ToggleButton as SelectToggleButton } from './Select';

const LIST_ITEM_HEIGHT = 40;

type MenuProps = {
  maxHeightInItems: number;
  zIndex?: number;
  width?: string;
  itemHeight: number;
};

const listPaddingY = 8;

const Menu = styled.ul<MenuProps>(props => css`
  box-sizing: border-box;
  position: fixed;
  padding: ${listPaddingY}px 0;
  margin: 0;
  width: ${props.width || 165}px;
  max-height: ${(props.itemHeight || LIST_ITEM_HEIGHT) * props.maxHeightInItems + listPaddingY * 2}px;

  z-index: 2;
  overflow-y: auto;

  color: ${props.theme.colors.text};

  border-radius: 4px;
  border: ${props.theme.borders.lightGray};
  background-color: ${props.theme.colors.white};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);

  ${props.zIndex ? `
    z-index: ${props.zIndex};
  ` : ''}
`);

type ListItemProps = {
  isSelected: boolean;
  isActive: boolean;
  disabled: boolean;
  itemHeight: number;
};

const ListItem = styled.li<ListItemProps>(props => css`
  box-sizing: border-box;
  display: flex;
  align-items: center;

  width: 0;
  height: ${props.itemHeight}px;
  min-width: 100%;
  max-width: 100%;
  padding: 7px 10px;

  font-family: ${props.theme.fonts.primary};
  font-size: ${props.theme.fontSizes[2]}px;

  cursor: ${props.disabled ? 'default' : 'pointer'};
  ${props.disabled ? 'opacity: 0.4;' : ''}

  ${props.isActive ? `
    background-color: ${transparentize(0.90, props.theme.colors.primary)};
  ` : ''}
`);

const Placeholder = styled(Text)`
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  color: ${props => props.theme.colors.subtext};
  padding: 11px 10px;
`;

type IconTextRowProps = {
  icon: IconProps['icon'] | null;
  iconColor?: string;
  text: string | React.ComponentElement<any, any>;
  showCaret?: boolean;
  showIcon?: boolean;
  showDivider?: boolean;
  truncate?: boolean;
};

// This component is used to ensure layout is consistent across the button/menu
const IconTextRow: React.FC<IconTextRowProps> = ({ icon, iconColor, text, showCaret = false, showIcon = true, truncate = true }) => (
  <Flex width="100%" alignItems="center" style={{ lineHeight: 'inherit', textAlign: 'left' }}>
    {showIcon && (
      <Box width={21}>
        {!!icon && (
          <Icon icon={icon} color={iconColor} aria-hidden="true" />
        )}
      </Box>
    )}
    <Box flex={1}>
      {truncate ? <Truncate>{text}</Truncate> : text}
    </Box>
    {showCaret && (
      <Box>
        <Icon icon="caret-down" color="text" aria-hidden="true" ml={2} />
      </Box>
    )}
  </Flex>
);

type ToggleButtonProps = {
  variant?: ButtonProps['variant'];
  icon: IconProps['icon'];
  text: string;
  showCaret: boolean;
  width: BoxProps['width'];
  maxWidth: BoxProps['maxWidth'];
};

// Note: We don't use the child Button's `iconLeft` prop and instead use a child
// IconTextRow component to ensure consistent layout across button and menu
const ToggleButton: React.FC<ToggleButtonProps> = ({
  variant,
  icon,
  text,
  showCaret,
  width,
  maxWidth,
  ...props
}) => (
  <Button variant={variant} width={width} maxWidth={maxWidth} {...props}>
    <IconTextRow icon={icon} text={text} showCaret={showCaret} showIcon={false} />
  </Button>
);

const moveFocus = (buttonElement, numSelectedElements, index, isOpen) => {
  try {
    if (numSelectedElements === 1) {
      // If the only selected element is removed, focus should move on the toggle button and menu should be open
      buttonElement.focus();

      if (!isOpen) {
        buttonElement.click();
      }
    } else if (!index) {
      // If the first selected element is removed, focus should move to the next element's delete button
      setTimeout(() => {
        const nextChip = buttonElement.children[0].children[index];
        const deleteButton = nextChip.getElementsByClassName('chip-close-button')[0] as HTMLDivElement;
        deleteButton.focus();
      }, 0);
    } else {
      // Otherwise, focus should move to the previous element's delete button
      const previousChip = buttonElement.children[0].children[index - 1];
      const deleteButton = previousChip.getElementsByClassName('chip-close-button')[0] as HTMLDivElement;
      deleteButton.focus();
    }
  } catch (error) {} // eslint-disable-line no-empty
};

type ToggleButtonWithChipsProps = ButtonProps & {
  selectedItems: any[];
  itemToString: any;
  isOpen?: boolean;
  getRemoveButtonProps: any;
};

const ToggleButtonWithChips: React.FC<ToggleButtonWithChipsProps> = ({
  placeholder,
  selectedItems,
  itemToString,
  isOpen,
  onChange,
  getRemoveButtonProps,
  ...props
}) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  return (
    <WrapperButton
      ref={buttonRef}
      sx={{
        color: 'text',
        border: 'lightGray2',
        borderRadius: '4px',
        textAlign: 'left',
        maxHeight: '84px',
        overflowY: 'auto',
      }}
      {...props}
    >
      {selectedItems?.length ? (
        <Box px={10} pt={8}>
          {selectedItems.map((item, index) => {
            const onDelete = event => {
              stopEvent(event);

              moveFocus(buttonRef.current, selectedItems.length, index, isOpen);

              if (onChange) {
                onChange(reject(selectedItems, item) as any);
              }
            };

            const onKeyDown = event => {
              if (event.key === 'Enter') {
                onDelete(event);
              }

              if (['ArrowDown', 'ArrowUp', ' '].includes(event.key)) {
                stopEvent(event);
                // Toggle the list and remove focus from the chip button
                if (buttonRef.current) {
                  buttonRef.current.focus();
                  buttonRef.current.click();
                }
              }
            };

            return (
              <Chip
                key={`${itemToString(item)}${index}`}
                removeButtonProps={getRemoveButtonProps({ item, onClick: onDelete, onKeyDown })}
              >
                {itemToString(item)}
              </Chip>
            );
          })}
        </Box>
      ) : (
        <Placeholder>{placeholder}</Placeholder>
      )}
    </WrapperButton>
  );
};

const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.lightGray2};
`;

export type MultiSelectProps<T> = {
  small?: boolean;
  variant?: ButtonProps['variant'];
  items: T[];
  selectedItems?: T[];
  onChange?: DownshiftProps<T[]>['onChange'];
  onSelect?: DownshiftProps<T>['onSelect'];
  onDeselect?: DownshiftProps<T>['onSelect']; // there isn't a `onDeselect` in downshift, but it has the same signature as `onSelect`
  itemToString: NonNullable<DownshiftProps<T>['itemToString']>;
  renderItem?: (item: T | null) => string | React.ComponentElement<T, any>;
  buttonWidth?: React.CSSProperties['width'];
  buttonMaxWidth?: React.CSSProperties['maxWidth'];
  buttonStyle?: React.CSSProperties;
  alwaysShowCaret?: boolean;
  getButtonText?: (items: T[]) => React.ReactNode;
  getButtonIcon?: (items: T[]) => string;
  disabled?: boolean;
  menuMaxHeightInItems?: number;
  menuZIndex?: number;
  menuWidth?: number;
  truncate?: boolean;
  withChips?: boolean;
  withRegularButton?: boolean;
  withSelectButton?: boolean; // Use the ToggleButton from the Select component
  placeholder?: string;
  rightAligned?: boolean;
  isItemDisabled?: (item: T) => boolean;
  showDividerBeforeItem?: (item: T, index: number) => boolean;
  itemHeight?: number;
  header?: React.ReactNode;
  footer?: React.ReactNode;
};

// TODO: find a better type
export const MultiSelect: React.FC<MultiSelectProps<any>> = ({
  small,
  selectedItems,
  variant,
  buttonWidth,
  buttonMaxWidth,
  buttonStyle,
  alwaysShowCaret,
  getButtonText,
  getButtonIcon,
  itemToString,
  renderItem = itemToString,
  items,
  onChange = noop,
  onSelect = noop,
  onDeselect = noop,
  disabled,
  menuMaxHeightInItems = 7.6,
  menuZIndex = 0,
  menuWidth = 165,
  truncate = true,
  withChips,
  withRegularButton,
  withSelectButton,
  placeholder,
  rightAligned,
  isItemDisabled,
  showDividerBeforeItem,
  itemHeight = LIST_ITEM_HEIGHT,
  header,
  footer,
}) => {
  const [referenceElement, setReferenceElement] = React.useState<HTMLElement>();
  const [popperElement, setPopperElement] = React.useState<HTMLElement>();
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: rightAligned ? 'bottom-end' : 'bottom-start',
    modifiers: [{
      name: 'offset',
      options: {
        offset: [0, 2],
      },
    }],
  });

  if (IS_SSR) {
    return null;
  }

  return (
    <Box style={{ position: 'relative' }} ref={setReferenceElement}>
      <MultiDownshift
        onChange={onChange}
        onSelect={onSelect}
        onDeselect={onDeselect}
        itemToString={itemToString}
        selectedItems={selectedItems}
      >
        {({
          getToggleButtonProps,
          getMenuProps,
          isOpen,
          selectedItems,
          getItemProps,
          highlightedIndex,
          getRemoveButtonProps,
        }: ControllerStateAndHelpers<any> & { selectedItems: any[]; removeItem: any; getRemoveButtonProps: any }) => (
          <div>
            {withChips ? (
              <ToggleButtonWithChips
                selectedItems={selectedItems}
                placeholder={placeholder}
                itemToString={itemToString}
                width={buttonWidth}
                maxWidth={buttonMaxWidth}
                isOpen={isOpen}
                onChange={onChange}
                {...getToggleButtonProps({
                  disabled,
                  style: buttonStyle,
                  onClick(event: any) {
                    if (update) update();
                    event.stopPropagation();
                  },
                })}
                getRemoveButtonProps={getRemoveButtonProps}
              />
            ) : withRegularButton ? (
              <Button
                small={small}
                variant={variant}
                iconLeft={getButtonIcon && getButtonIcon(selectedItems)}
                iconRight={alwaysShowCaret || !isEmpty(selectedItems) ? 'caret-down' : undefined}
                mb="2px"
                width={buttonWidth}
                maxWidth={buttonMaxWidth}
                {...getToggleButtonProps({
                  disabled,
                  style: buttonStyle,
                  onClick(event: any) {
                    if (update) update();
                    event.stopPropagation();
                  },
                })}
              >
                {getButtonText && getButtonText(selectedItems)}
              </Button>
            ) : withSelectButton ? (
              <SelectToggleButton
                {...getToggleButtonProps({
                  disabled,
                  style: {
                    fontSize: small ? '12px' : '14px',
                    minHeight: small ? '28px' : '40px',
                    lineHeight: 1.5,
                    width: buttonWidth,
                    ...buttonStyle,
                  },
                  onClick(event: any) {
                    if (update) update();
                    event.stopPropagation();
                  },
                })}
              >
                <Flex alignItems="center" width="100%">
                  {getButtonIcon && <Icon icon={getButtonIcon(selectedItems) as IconProps['icon']} />}
                  <Text flex={1}>
                    {getButtonText && getButtonText(selectedItems)}
                  </Text>
                  {alwaysShowCaret || !isEmpty(selectedItems) ? <Icon icon="caret-down" /> : null}
                </Flex>
              </SelectToggleButton>
            ) : (
              <ToggleButton
                small={small}
                variant={variant}
                icon={getButtonIcon && getButtonIcon(selectedItems)}
                sx={selectedItems.length ? undefined : { color: 'subtext' }}
                text={selectedItems.length && getButtonText ? getButtonText(selectedItems) : placeholder}
                showCaret={alwaysShowCaret || Boolean(selectedItems.length)}
                width={buttonWidth}
                lineHeight={1.5}
                maxWidth={buttonMaxWidth}
                {...getToggleButtonProps({
                  disabled,
                  style: buttonStyle,
                  onClick(event: any) {
                    if (update) update();
                    event.stopPropagation();
                  },
                })}
              />
            )}
            {ReactDOM.createPortal(
              <Menu
                {...getMenuProps({
                  hidden: !isOpen,
                  ref: setPopperElement as any,
                  style: styles.popper,
                  ...attributes.popper,
                })}
                zIndex={menuZIndex}
                maxHeightInItems={menuMaxHeightInItems}
                width={menuWidth}
                itemHeight={itemHeight}
              >
                {header && (
                  <Box>
                    {header}
                    <Divider />
                  </Box>
                )}
                {items.map((item, index) => (
                  <React.Fragment key={`${itemToString(item)}${index}`}>
                    {showDividerBeforeItem?.(item, index) && <Divider my={2} />}
                    <ListItem
                      {...getItemProps({
                        item,
                        index,
                        isActive: highlightedIndex === index,
                        isSelected: selectedItems.includes(item),
                        disabled: isItemDisabled && isItemDisabled(item),
                        itemHeight,
                      } as GetItemPropsOptions<any>)}
                    >
                      <IconTextRow
                        icon={selectedItems.includes(item) ? 'check' : null}
                        iconColor="primary"
                        text={renderItem(item)}
                        truncate={truncate}
                      />
                    </ListItem>
                  </React.Fragment>
                ))}
                {footer && (
                  <Box>
                    <Divider />
                    {footer}
                  </Box>
                )}
              </Menu>,
              document.body!,
              'menu',
            )}
          </div>
        )}
      </MultiDownshift>
    </Box>
  );
};

export const SelectDropdownMenu = <T extends Record<string, unknown>>({
  buttonText,
  buttonIcon,
  disabled,
  multi = false,
  onChange,
  allowEmptySelection = false,
  highlightIncompleteSelection = false,
  showSelectionToggleHeader = false,
  ...props
}: {
  buttonText?: string;
  buttonIcon?: IconProps['icon'];
  multi?: boolean;
  disabled?: boolean;
  onChange: (selectedItems: T[]) => void;
  allowEmptySelection?: boolean;
  highlightIncompleteSelection?: boolean;
  showSelectionToggleHeader?: boolean;
} & Omit<MultiSelectProps<T>, 'onChange'>) => {
  const { t } = useTranslation();

  const handleChange = React.useCallback((selectedItems: T[] | null) => {
    if (!selectedItems) {
      return;
    }

    if (multi) {
      onChange(selectedItems);
    } else {
      const lastSelectedItem = last(selectedItems);

      if (!lastSelectedItem && !allowEmptySelection && props.selectedItems) {
        onChange([...props.selectedItems]);
      } else {
        onChange(compact([lastSelectedItem]));
      }
    }
  }, [allowEmptySelection, multi, onChange, props.selectedItems]);

  const variant = (
    !multi ||
    highlightIncompleteSelection
      ? size(props.selectedItems) === size(props.items)
      : isEmpty(props.selectedItems)
  )
    ? 'secondary-outline'
    : 'primary-outline';

  return (
    <MultiSelect
      onChange={handleChange}
      // keep disabled as long as no items have been loaded
      disabled={disabled || isEmpty(props.items)}
      getButtonText={buttonText ? () => buttonText : undefined}
      getButtonIcon={buttonIcon ? () => buttonIcon : undefined}
      variant={variant}
      small
      buttonWidth="100%"
      buttonStyle={{ marginBottom: 0 }}
      alwaysShowCaret
      withRegularButton
      menuMaxHeightInItems={8.6}
      header={showSelectionToggleHeader ? (
        <Flex padding={1} justifyContent="space-between" alignItems="center" sx={{ gap: 2 }}>
          <Button variant="secondary-outline" small flex={1} onClick={() => handleChange(props.items)} >
            {t('general.showAll')}
          </Button>
          <Button variant="secondary-outline" small flex={1} onClick={() => handleChange([])} >
            {t('general.hideAll')}
          </Button>
        </Flex>
      ) : null}
      {...props}
    />
  );
};
