import * as React from 'react';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { useWatchValue } from '../../hooks/useWatchValue';
import { useHover } from '../../hooks/useHover';
import {
  TExpandableRowDataBase,
  TOriginalSubRowDataBase,
  getGridCellId,
  DataCellProps,
  GridDataAndCommands,
} from '../core/utils';
import { EditableGridColumn } from './utils';

const RenderHoverableGridDataCellBase = <
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
>({
  data,
  rowIndex,
  columnIndex,
  style,
}: {
  data: GridDataAndCommands<EditableGridColumn, TOriginalSubRowData, TOriginalRowData> & {
    DataCell: React.FunctionComponent<DataCellProps<
      EditableGridColumn,
      TOriginalSubRowData,
      TOriginalRowData
    >>;
    bodyPaddingLeft: number;
    frozenLeftColumnCount: number;
  };
  columnIndex: number;
  rowIndex: number;
  style: React.CSSProperties;
}) => {
  const [cellRef, cellHovered] = useHover<HTMLTableCellElement>();
  const row = data.rows[rowIndex];
  const column = data.columns[columnIndex];

  const { DataCell, hoverCellIndices, editedCell } = data;

  useWatchValue(
    cellHovered,
    (hovered) => {
      if (hovered) {
        data.setHoverCellIndices({ rowIndex, columnIndex });
      } else {
        data.setHoverCellIndices((hoverCellIndices) => {
          // only set hoverCellIndices to null when the current value
          // hasn't been set to a different cell
          return isEqual(hoverCellIndices, { rowIndex, columnIndex })
            ? null
            : hoverCellIndices;
        });
      }
    },
  );

  const isHover = Boolean(
    hoverCellIndices &&
    hoverCellIndices.rowIndex === rowIndex,
  );

  if (rowIndex === 0 || !row) {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
    <div
      ref={cellRef}
      id={getGridCellId(data.idPrefix, { rowIndex, columnIndex })}
      aria-rowindex={rowIndex + 1}
      aria-colindex={columnIndex + 1}
      role="gridcell"
      className={clsx(
        isHover && 'hover',
      )}
      style={{
        ...style,
        // TODO simplify!
        top: columnIndex < data.frozenLeftColumnCount ? `${row.top}px` : style.top,
        height: style.height,
        minHeight: style.height,
        cursor: data.onRowClick ? 'pointer' : undefined,
      }}
      onClick={() => {
        data.onRowClick?.(row.original);
      }}
    >
      <DataCell
        row={row}
        column={column}
        isActive={false}
        editedCell={editedCell}
      />
    </div>
  );
};

export const RenderHoverableGridDataCell = React.memo(
  RenderHoverableGridDataCellBase,
) as typeof RenderHoverableGridDataCellBase;
