import * as React from 'react';
import { differenceBy, find, get, isEmpty, values } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { FlexProps } from 'rebass/styled-components';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Loading } from '@deepstream/ui/ui/Loading';
import { Panel, PanelDivider, PanelText, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { useCompanyUsers } from '@deepstream/ui/modules/Company/TeamManagement/useCompanyUsers';
import { ErrorMessage } from '@deepstream/ui/ui/ErrorMessage';
import * as rfx from '@deepstream/ui/rfx';
import { RequestTeamAddUserModal } from '@deepstream/ui/modules/Request/Team/RequestTeamAddUserModal';
import { RfqIdProvider } from '@deepstream/ui/useRfq';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { CompanyLink } from './CompanyLink';
import { useSaveAdminChanges } from './useSaveAdminRequestTeamChanges';
import { useAdminRequest } from './adminRequest';
import * as Title from './title';
import { RequestUsersTable } from './RequestUsersTable';
import { RequestLink } from './RequestLink';
import { RequestExchangesTable } from './RequestExchangesTable';
import { RequestCompanyPropertyList } from './RequestCompanyPropertyList';
import { Page } from './Page';
import { requestCompanyRoute } from './AppRouting';

const PageTitle = ({ flexDirection, requestId, company }: {
  flexDirection?: FlexProps['flexDirection'];
  requestId: string;
  company: CompanyMinimized;
}) => {
  const { isExtraSmall } = useDeviceSize();

  return (
    <Title.Container flexDirection={flexDirection} px={isExtraSmall ? 3 : 0}>
      <CompanyLink
        companyId={company._id}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '50%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              marginRight: isExtraSmall ? 10 : 40,
            }
        }
      >
        <Title.Company
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          mr={flexDirection === 'column' ? 0 : 2}
          size={flexDirection === 'column' ? 'large' : 'small'}
          company={company}
        />
      </CompanyLink>
      <RequestLink
        requestId={requestId}
        style={
          flexDirection === 'column'
            ? undefined
            : {
              minWidth: isExtraSmall ? '50%' : '20%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }
        }
      >
        <Title.Request
          placement={flexDirection === 'column' ? 'left' : 'bottom'}
          size={flexDirection === 'column' ? 'medium' : 'small'}
          requestId={requestId}
        />
      </RequestLink>
    </Title.Container>
  );
};

const RequestUsersPanel = ({
  requestId,
  company,
}: {
  requestId: string;
  company: CompanyMinimized;
}) => {
  const { teamById } = rfx.useStructure();
  const addUserToRequestModal = useModalState();
  const { data: usersInCompany, status } = useCompanyUsers(company._id);
  const [saveChanges] = useSaveAdminChanges({ companyId: company._id });

  const { usersInRequest, usersNotInRequest } = React.useMemo(() => {
    if (!usersInCompany) {
      return {};
    }

    const team = teamById[company._id];
    const users = values(team?.users) ?? [];

    const usersInRequest = users.map(user => ({
      ...user,
      email: get(
        usersInCompany.find(companyUser => companyUser._id.toString() === user._id),
        'email',
      ),
      isOwner: team.owners.includes(user._id),
    }));

    const usersNotInRequest = differenceBy(usersInCompany, usersInRequest, '_id')
      .map((user: any) => ({
        ...user,
        rfqRoles: {},
        isOwner: false,
      }));

    return { usersInRequest, usersNotInRequest };
  }, [teamById, usersInCompany, company]);

  return (
    <rfx.SaveChangesProvider saveChanges={saveChanges}>
      <Panel mb={4}>
        <PanelHeader heading="Team">
          {usersInCompany && (
            <Tooltip content={isEmpty(usersNotInRequest) ? 'No users to add' : ''}>
              <span style={{ display: 'inline-block' }}>
                <Button
                  small
                  iconLeft="plus"
                  onClick={addUserToRequestModal.open}
                  disabled={isEmpty(usersNotInRequest)}
                >
                  Add user
                </Button>
              </span>
            </Tooltip>
          )}
        </PanelHeader>
        <PanelDivider />
        {status === 'loading' ? (
          <PanelPadding>
            <Loading fontSize={2} lineHeight={1.5} />
          </PanelPadding>
        ) : status === 'error' ? (
          <PanelPadding>
            <ErrorMessage fontSize={2} error="Could not fetch company users" />
          </PanelPadding>
        ) : isEmpty(usersInRequest) ? (
          <PanelText color="gray">No team members yet</PanelText>
        ) : (
          <RequestUsersTable requestId={requestId} companyId={company._id} users={usersInRequest} />
        )}
      </Panel>
      {usersInCompany && addUserToRequestModal.isOpen && (
        <RequestTeamAddUserModal
          isOpen={addUserToRequestModal.isOpen}
          close={addUserToRequestModal.close}
          company={company}
        />
      )}
    </rfx.SaveChangesProvider>
  );
};

export const RequestCompanyPage: React.FC = () => {
  const { companyId, requestId } = requestCompanyRoute.useParams();
  const [ref, inView, entry] = useInView();
  const { data, status } = useAdminRequest(requestId);

  const sender = find(data?.structure.senders, { _id: companyId });
  const company = sender?.company ?? find(data?.structure.recipients, { _id: companyId })?.company;
  const isRecipient = !sender;

  return (
    <RfqIdProvider rfqId={requestId}>
      <Page
        subHeading={company && data && entry && !inView && (
          <rfx.StructureProvider structure={data.structure}>
            <PageTitle
              requestId={requestId}
              company={company}
            />
          </rfx.StructureProvider>
        )}
      >
        {status === 'loading' ? (
          <Panel>
            <PanelText><Loading /></PanelText>
          </Panel>
        ) : status === 'error' ? (
          <Panel heading="Error">
            <PanelText>Oh no</PanelText>
          </Panel>
        ) : (data && company) ? (
          <rfx.StructureProvider structure={data.structure}>
            <div ref={ref}>
              <PageTitle
                flexDirection="column"
                requestId={requestId}
                company={company}
              />
            </div>

            <Panel heading="General" mb={4}>
              <RequestCompanyPropertyList company={company} />
            </Panel>

            <RequestUsersPanel
              requestId={requestId}
              company={company}
            />

            {data.structure.summary.isLive && isRecipient && (
              <Panel heading="Exchanges">
                <rfx.ExchangesProvider exchanges={data.exchanges}>
                  <RequestExchangesTable recipientId={companyId} />
                </rfx.ExchangesProvider>
              </Panel>
            )}
          </rfx.StructureProvider>
        ) : null}
      </Page>
    </RfqIdProvider>
  );
};
