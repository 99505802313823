import { Form, Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { useQueryClient, useQuery } from 'react-query';
import * as yup from 'yup';
import { Box, Flex, Text } from 'rebass/styled-components';

import { useAdminApi, useApi, wrap } from '@deepstream/ui/api';
import { PropertyList, PropertyListAction } from '@deepstream/ui/PropertyList';
import { useToaster } from '@deepstream/ui/toast';
import { Button, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelLoadingWrapper } from '@deepstream/ui/ui/Loading';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { Panel, PanelDivider, PanelHeader, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { TextField } from '@deepstream/ui/form/TextField';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { callAll } from '@deepstream/utils/callAll';
import { useMutation } from '@deepstream/ui/useMutation';
import { SwitchField } from '@deepstream/ui/form/SwitchField';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { CompanyFinderField } from '@deepstream/ui/form/CompanyFinderField';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { APP_ADMIN_COMPANY_ID } from '@deepstream/ui/constants';
import { emailStringToArray } from '@deepstream/ui/utils';
import { Tab, TabListPanel, TabPanel, TabPanels, Tabs } from '@deepstream/ui/ui/TabsVertical';
import { SidebarLayout } from '@deepstream/ui/ui/ProfileLayout';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { IconValue } from '@deepstream/common/icons';
import { localeFormatNumber } from '@deepstream/utils';
import { APP_ADMIN_LOCALE, DEFAULT_FEEDBACK_EMAIL_FREQUENCY, DEFAULT_REQUEST_MAX_COMPLEXITY, DEFAULT_REQUEST_MAX_EXCHANGE_DEF_COUNT } from '@deepstream/common/constants';
import { upperFirst } from 'lodash';
import { ConfigProvider, useConfig } from './ConfigProvider';
import * as title from './title';
import { Page } from './Page';
import { AddLanguageModal } from './AddLanguageModal';
import { LanguagesTable } from './LanguagesTable';
import { configRoute, useAdminNavigation } from './AppRouting';

const RestoreDefaultButton = (props) => (
  <Button variant="secondary" {...props}>
    Restore default
  </Button>
);

const EditFeedbackFrequencyModal = ({
  feedbackEmailFrequency,
  onCancel,
  onSave,
  ...props
}: ModalProps & {
  feedbackEmailFrequency?: number;
  onCancel: () => void;
  onSave: () => void;
}) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [updateConfig] = useMutation(
    adminApi.updateConfig,
    {
      onSuccess: callAll(
        onSave,
        () => toaster.success('Product feedback frequency updated successfully'),
      ),
      onError: () => toaster.error('Product feedback frequency could not be updated'),
      onSettled: () => queryClient.invalidateQueries(['config']),
    },
  );

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{
          feedbackEmailFrequency,
        }}
        validationSchema={
          yup.object().shape({
            feedbackEmailFrequency: yup
              .number()
              .min(1, 'Minimum of 1')
              .required('Required'),
          })
        }
        onSubmit={async ({ feedbackEmailFrequency }, { setSubmitting }) => {
          await updateConfig({
            feedbackEmailFrequency,
          }, {
            onSettled: () => setSubmitting(false),
          });
        }}
      >
        {({ isSubmitting, dirty, values, setFieldValue }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              Edit product feedback frequency
            </ModalHeader>
            <ModalBody>
              <TextField
                required
                name="feedbackEmailFrequency"
                label="Minimum frequency"
                format="integer.positive"
                suffix="days"
                inputStyle={{ width: 50 }}
              />
              <MessageBlock variant="info">
                Users will only receive this email once over this period
                regardless of the number of requests they participate in
              </MessageBlock>
            </ModalBody>
            <ModalFooter>
              <RestoreDefaultButton
                onClick={() => setFieldValue('feedbackEmailFrequency', DEFAULT_FEEDBACK_EMAIL_FREQUENCY)}
                disabled={isSubmitting || values.feedbackEmailFrequency === DEFAULT_FEEDBACK_EMAIL_FREQUENCY}
              />
              <Flex flex={1} justifyContent="flex-end">
                <CancelButton onClick={onCancel} mr={2} />
                <SaveButton disabled={isSubmitting || !dirty} />
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const EditEmailDeliveryAllowListModal = ({
  emailDeliveryAllowList,
  onCancel,
  onSave,
  ...props
}: ModalProps & {
  emailDeliveryAllowList?: string[];
  onCancel: () => void;
  onSave: () => void;
}) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [updateConfig] = useMutation(
    adminApi.updateConfig,
    {
      onSuccess: callAll(
        onSave,
        () => toaster.success('Email delivery allow list updated successfully'),
      ),
      onError: () => toaster.error('Email delivery allow list could not be updated'),
      onSettled: () => queryClient.invalidateQueries(['config']),
    },
  );

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{
          emailDeliveryAllowList: emailDeliveryAllowList?.join(',\n'),
        }}
        validationSchema={
          yup.object().shape({
            emailDeliveryAllowList: yup.string()
              .test(
                'emailsFormat',
                'Invalid format',
                value => emailStringToArray(value)
                  .every(item => yup.string().email().isValidSync(item)),
              ),
          })
        }
        onSubmit={async ({ emailDeliveryAllowList }, { setSubmitting }) => {
          await updateConfig({
            emailDeliveryAllowList: emailStringToArray(emailDeliveryAllowList),
          }, {
            onSettled: () => setSubmitting(false),
          });
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              Edit email delivery allow list
            </ModalHeader>
            <ModalBody>
              <TextField
                name="emailDeliveryAllowList"
                label="Email addresses"
                isMultiLine
                helperText="Enter a comma-separated list of valid email addresses"
              />
              <MessageBlock variant="info">
                Emails added here will receive transactional emails
              </MessageBlock>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} mr={2} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const EmailConfigPanel = () => {
  const config = useConfig();
  const editFeedbackFrequencyModal = useModalState();
  const editEmailDeliveryAllowListModal = useModalState();

  const emailsProperties = React.useMemo(
    () => [
      {
        name: 'Product feedback frequency',
        value: `${config?.feedbackEmailFrequency} ${config?.feedbackEmailFrequency === 1 ? 'day' : 'days'}`,
        labelWidth: 300,
      },
      {
        name: 'Email delivery allow list',
        labelWidth: 300,
        heightAuto: true,
        value: config?.emailDeliveryAllowList?.join(', ') || <EmDash />,
      },
    ],
    [config],
  );

  return (
    <>
      <Panel heading="Emails" mb={4}>
        <PropertyList properties={emailsProperties}>
          <PropertyListAction label="Edit" icon="pencil-alt" onClick={editFeedbackFrequencyModal.open} />
          <Box mt="11px">
            <PropertyListAction label="Edit" icon="pencil-alt" onClick={editEmailDeliveryAllowListModal.open} />
          </Box>
        </PropertyList>
      </Panel>
      <EditFeedbackFrequencyModal
        isOpen={editFeedbackFrequencyModal.isOpen}
        onCancel={editFeedbackFrequencyModal.close}
        onSave={editFeedbackFrequencyModal.close}
        onRequestClose={editFeedbackFrequencyModal.close}
        feedbackEmailFrequency={config?.feedbackEmailFrequency}
      />
      <EditEmailDeliveryAllowListModal
        isOpen={editEmailDeliveryAllowListModal.isOpen}
        onCancel={editEmailDeliveryAllowListModal.close}
        onSave={editEmailDeliveryAllowListModal.close}
        onRequestClose={editEmailDeliveryAllowListModal.close}
        emailDeliveryAllowList={config?.emailDeliveryAllowList}
      />
    </>
  );
};

const EditRequestSizeLimitModal = ({
  onCancel,
  onSave,
  propName,
  initialValues,
  ...props
}: ModalProps & {
  onCancel: () => void;
  onSave: () => void;
  propName: 'requestMaxComplexity' | 'requestMaxExchangeDefCount';
  initialValues: {
    requestMaxComplexity: number;
    requestMaxExchangeDefCount: number;
  }
}) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const label = {
    requestMaxComplexity: 'request size limit',
    requestMaxExchangeDefCount: 'maximum exchange definition count',
  }[propName];

  const defaultValue = {
    requestMaxComplexity: DEFAULT_REQUEST_MAX_COMPLEXITY,
    requestMaxExchangeDefCount: DEFAULT_REQUEST_MAX_EXCHANGE_DEF_COUNT,
  }[propName];

  const [updateConfig] = useMutation(
    adminApi.updateConfig,
    {
      onSuccess: callAll(
        onSave,
        () => toaster.success(`${upperFirst(label)} updated successfully`),
      ),
      onError: () => toaster.error(`${upperFirst(label)} could not be updated`),
      onSettled: () => queryClient.invalidateQueries(['config']),
    },
  );

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{
          value: initialValues[propName],
        }}
        validationSchema={
          yup.object().shape({
            value: yup
              .number()
              .min(250, 'Minimum of 250')
              .required('Required'),
          })
        }
        onSubmit={async ({ value }, { setSubmitting }) => {
          await updateConfig({
            [propName]: value,
          }, {
            onSettled: () => setSubmitting(false),
          });
        }}
      >
        {({ isSubmitting, dirty, values, setFieldValue, isValid }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              Edit {label}
            </ModalHeader>
            <ModalBody>
              <TextField
                required
                name="value"
                label={upperFirst(label)}
                format="integer.positive"
              />
              <MessageBlock variant="info">
                This limit will be enforced for all current and
                future requests which do not have an override set
              </MessageBlock>
            </ModalBody>
            <ModalFooter>
              <RestoreDefaultButton
                onClick={() => setFieldValue('value', defaultValue)}
                disabled={isSubmitting || values.value === defaultValue}
              />
              <Flex flex={1} justifyContent="flex-end">
                <CancelButton onClick={onCancel} mr={2} />
                <SaveButton disabled={isSubmitting || !dirty || !isValid} />
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const RequestsConfigPanel = () => {
  const config = useConfig();
  const editRequestSizeLimitModal = useModalState();
  const [requestSizeLimitPropName, setRequestSizeLimitPropName] =
    React.useState<'requestMaxComplexity' | 'requestMaxExchangeDefCount' | null>(null);

  const {
    values,
    requestsProperties,
  } = React.useMemo(() => {
    const values = {
      requestMaxComplexity: config?.requestMaxComplexity || DEFAULT_REQUEST_MAX_COMPLEXITY,
      requestMaxExchangeDefCount: config?.requestMaxExchangeDefCount || DEFAULT_REQUEST_MAX_EXCHANGE_DEF_COUNT,
    };

    return {
      values,
      requestsProperties: [
        {
          name: 'Request size limit',
          value: localeFormatNumber(values.requestMaxComplexity, { locale: APP_ADMIN_LOCALE }),
          labelWidth: 300,
        },
        {
          name: 'Maximum exchange definition count',
          value: localeFormatNumber(values.requestMaxExchangeDefCount, { locale: APP_ADMIN_LOCALE }),
          labelWidth: 300,
        },
      ],
    };
  }, [config]);

  return (
    <>
      <Panel heading="Requests" mb={4}>
        <PropertyList properties={requestsProperties}>
          <PropertyListAction
            label="Edit"
            icon="pencil-alt"
            onClick={() => {
              setRequestSizeLimitPropName('requestMaxComplexity');
              editRequestSizeLimitModal.open();
            }}
          />
          <PropertyListAction
            label="Edit"
            icon="pencil-alt"
            onClick={() => {
              setRequestSizeLimitPropName('requestMaxExchangeDefCount');
              editRequestSizeLimitModal.open();
            }}
          />
        </PropertyList>
      </Panel>
      {requestSizeLimitPropName && (
        <EditRequestSizeLimitModal
          isOpen={editRequestSizeLimitModal.isOpen}
          onCancel={editRequestSizeLimitModal.close}
          onSave={editRequestSizeLimitModal.close}
          onRequestClose={editRequestSizeLimitModal.close}
          initialValues={values}
          propName={requestSizeLimitPropName}
        />
      )}
    </>
  );
};

const EditSourceCompanyModal = ({
  defaultSourceCompany,
  onCancel,
  onSave,
  ...props
}: ModalProps & {
  defaultSourceCompany?: CompanyMinimized;
  onCancel: () => void;
  onSave: () => void;
}) => {
  const adminApi = useAdminApi();
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [updateConfig] = useMutation(
    adminApi.updateConfig,
    {
      onSuccess: callAll(
        onSave,
        () => toaster.success('Default source company updated successfully'),
      ),
      onError: () => toaster.error('Default source company could not be updated'),
      onSettled: () => queryClient.invalidateQueries(['config']),
    },
  );

  const searchCompanies = React.useCallback(
    async (text) => api
      .searchCompanies({ text, pageSize: 7, pageIndex: 0 })
      .then(result => result.companies),
    [api],
  );

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{
          defaultSourceCompany,
        }}
        validationSchema={
          yup.object().shape({
            defaultSourceCompany: yup.object().required('Required'),
          })
        }
        onSubmit={async ({ defaultSourceCompany }, { setSubmitting }) => {
          await updateConfig({
            defaultSourceCompanyId: defaultSourceCompany._id,
          }, {
            onSettled: () => setSubmitting(false),
          });
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              Edit default source company
            </ModalHeader>
            <ModalBody>
              <CompanyFinderField
                name="defaultSourceCompany"
                label="Default source company"
                placeholder="Select a company"
                currentCompanyId={APP_ADMIN_COMPANY_ID}
                disabledCompanies={[]}
                requiredErrorMessage="Required"
                searchCompanies={searchCompanies}
                withResultListSubheader={false}
                required
              />
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} mr={2} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const DefaultValuesPanel = () => {
  const api = useApi();
  const config = useConfig();
  const editSourceCompanyModal = useModalState();

  const { data: company } = useQuery(
    ['publicCompany', { companyId: config.defaultSourceCompanyId }],
    wrap(api.getPublicCompany),
    {
      enabled: Boolean(config.defaultSourceCompanyId),
    },
  );

  const properties = React.useMemo(
    () => [
      {
        name: 'Source company ID',
        value: config.defaultSourceCompanyId || <EmDash />,
        labelWidth: 300,
      },
    ],
    [config],
  );

  return (
    <>
      <Panel heading="Default values" mb={4}>
        <PropertyList properties={properties}>
          <PropertyListAction
            label="Edit"
            icon="pencil-alt"
            onClick={editSourceCompanyModal.open}
          />
        </PropertyList>
      </Panel>
      {editSourceCompanyModal.isOpen && (
        <EditSourceCompanyModal
          isOpen={editSourceCompanyModal.isOpen}
          onCancel={editSourceCompanyModal.close}
          onSave={editSourceCompanyModal.close}
          onRequestClose={editSourceCompanyModal.close}
          defaultSourceCompany={company}
        />
      )}
    </>
  );
};

const LocalizationPanel = () => {
  const config = useConfig();
  const addLanguageModal = useModalState();

  return (
    <>
      <Panel>
        <PanelHeader heading="Languages">
          <Button
            variant="primary"
            iconLeft="plus"
            small
            onClick={addLanguageModal.open}
          >
            Add language
          </Button>
        </PanelHeader>
        <PanelDivider />
        {config?.languages?.length ? (
          <LanguagesTable languages={config.languages} />
        ) : (
          <PanelText color="gray">No languages yet</PanelText>
        )}
      </Panel>
      <AddLanguageModal
        isOpen={addLanguageModal.isOpen}
        onCancel={addLanguageModal.close}
        onSave={addLanguageModal.close}
        onRequestClose={addLanguageModal.close}
      />
    </>
  );
};

type FeatureFlagPanelConfig = {
  accessor: string;
  name: string;
  modalHeading: string;
  infoText: string;
};

const featureFlagPanelConfigs: FeatureFlagPanelConfig[] = [
  {
    accessor: 'auctionsEnabled',
    name: 'Auctions stages',
    modalHeading: 'Edit auctions stages',
    infoText: 'When enabled, auction stages can be added to requests.',
  },
  {
    accessor: 'bulkImportEnabled',
    name: 'Bulk import',
    modalHeading: 'Edit bulk import ',
    infoText:
      'When enabled, Line items and Questions sections will allow bulk importing exchanges from a CSV',
  },
  {
    accessor: 'contractManagementEnabled',
    name: 'Contracts',
    modalHeading: 'Edit contract management',
    infoText: 'When enabled, the "Contracts" tab will be enabled for users',
  },
  {
    accessor: 'discoveryEnabled',
    name: 'Discovery (DeepAI)',
    modalHeading: 'Edit discovery module',
    infoText: 'When enabled, the discovery module will be enabled for users',
  },
  {
    accessor: 'contractChatbotEnabled',
    name: 'Quest chat (DeepAI) - Contract',
    modalHeading: 'Edit Quest (Contract) chat module',
    infoText: 'When enabled, Quest will be enabled for users in the Contracts page',
  },
  {
    accessor: 'driveChatbotEnabled',
    name: 'Quest chat (DeepAI) - Drive',
    modalHeading: 'Edit Quest (Drive) chat module',
    infoText: 'When enabled, Quest will be enabled for users in the Drive page',
  },
  {
    accessor: 'downloadBidAsXlsxEnabled',
    name: 'Download bid as XLSX',
    modalHeading: 'Download bid as XLSX',
    infoText:
      'When enabled, the "Download bid as XLSX" button will be enabled for users on the my bid page',
  },
  {
    accessor: 'evaluationUpdatesEnabled',
    name: 'Evaluation updates',
    modalHeading: 'Evaluation updates',
    infoText:
      'When enabled, the new evaluation updates will be enabled for users',
  },
  {
    accessor: 'lineItemFieldsEnabled',
    name: 'Line item improved fields',
    modalHeading: 'Edit line item fields',
    infoText:
      'When enabled, the "Add field" dropdown will be enabled for users',
  },
  {
    accessor: 'universalBidStatusEnabled',
    name: 'New bid status',
    modalHeading: 'Edit new bid status',
    infoText:
      'When enabled, the new bid status instead of the old one is shown to users.',
  },
  {
    accessor: 'productTagMigrationDone',
    name: 'Product and service tag migration',
    modalHeading: 'Edit product and service tag migration',
    infoText:
      'When migration is done old tags will no longer be shown in any context',
  },
  {
    accessor: 'managementReportingEnabled',
    name: 'Reporting',
    modalHeading: 'Edit management reporting',
    infoText:
      'When enabled, the "Reporting" tab will be enabled for users with the "Manage users" permission',
  },
  {
    accessor: 'requiredTermsAndNameEnabled',
    name: 'Require user name and terms agreement on signup',
    modalHeading: 'Edit require user name and terms agreement',
    infoText:
      'When enabled, users must provide the full name and agree to the terms of service in order to use the app.',
  },
  {
    accessor: 'contractReportingEnabled',
    name: 'Enable reporting for contracts',
    modalHeading: 'Edit contract reporting',
    infoText: 'When enabled, the Reporting module will include both requests and contracts.',
  },
  {
    accessor: 'contractAuditEnabled',
    name: 'Enable audit trail for contracts',
    modalHeading: 'Edit contract audit trail',
    infoText: 'When enabled, the Audit tab will be visible for live contracts.',
  },
  {
    accessor: 'newPreQualEnabled',
    name: 'Enable new pre-qualification',
    modalHeading: 'Edit new pre-qualification',
    infoText: 'When enabled, the new pre-qualification will be enabled and the old one will be deprecated.',
  },
  {
    accessor: 'contractESignatureEnabled',
    name: 'Enable e-signatures for contracts',
    modalHeading: 'Edit contract e-signature',
    infoText: 'When enabled, e-signatures will be available for contract exchanges.',
  },
  {
    accessor: 'contractTemplatesEnabled',
    name: 'Enable contract templates',
    modalHeading: 'Edit contract templates',
    infoText: 'When enabled, the "Templates" tab will be enabled for users.',
  },
  {
    accessor: 'lotsEnabled',
    name: 'Enable lots and new supplier-side structure',
    modalHeading: 'Edit lots and new supplier-side structure',
    infoText:
      'When enabled, buyers can configure lots and supplier-side request pages follow a new structure',
    },
    {
    accessor: 'questionnaireAuditEnabled',
    name: 'Enable audit trail for questionnaires',
    modalHeading: 'Edit questionnaire audit trail',
    infoText: 'When enabled, the Audit tab will be visible for questionnaires.',
  },
];

const EditFeatureFlagModal = ({
  panelConfig,
  value,
  onCancel,
  onSave,
  ...props
}: ModalProps & {
  panelConfig: FeatureFlagPanelConfig;
  value: boolean;
  onCancel: () => void;
  onSave: () => void;
}) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [updateConfig] = useMutation(
    adminApi.updateConfig,
    {
      onSuccess: callAll(
        onSave,
        () => toaster.success('Feature flag updated successfully'),
      ),
      onError: () => toaster.error('Feature flag could not be updated'),
      onSettled: () => queryClient.invalidateQueries(['config']),
    },
  );

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{ value }}
        validationSchema={yup.object().shape({ value: yup.boolean() })}
        onSubmit={async ({ value }, { setSubmitting }) => {
          await updateConfig({
            featureFlags: {
              [panelConfig.accessor]: value,
            },
          }, {
            onSettled: () => setSubmitting(false),
          });
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {panelConfig.modalHeading}
            </ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                <SwitchField name="value" label="Enabled" />
                <MessageBlock variant="info" mt={0}>
                  {panelConfig.infoText}
                </MessageBlock>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} mr={2} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const FeatureFlagsPanel: React.FC<{ featureFlagsConfig: FeatureFlagPanelConfig[] }> = ({ featureFlagsConfig }) => {
  const config = useConfig();
  const editFeatureFlagModal = useModalState();
  const [selectedFeatureFlag, setSelectedFeatureFlag] = useState<FeatureFlagPanelConfig>();

  const properties = useMemo(() => {
    return featureFlagsConfig.map((flagConfig) => {
      return {
        name: flagConfig.name,
        value: config?.featureFlags?.[flagConfig.accessor] ? 'Enabled' : 'Disabled',
        labelWidth: 300,
        };
      });
    },
    [config, featureFlagsConfig],
  );

  const ValueComponent = ({ value }) => (
    <Flex alignItems="center">
      <Icon icon={value === 'Enabled' ? 'check' : 'close'} mr={2} fixedWidth />
      <Text flex={1}>{value}</Text>
    </Flex>
  );

  return (
    <>
      <Panel heading="Feature flags" mb={4}>
        <PropertyList properties={properties} DefaultComponent={ValueComponent}>
          {featureFlagsConfig.map(featureFlag => (
            <PropertyListAction
              key={featureFlag.accessor}
              label="Edit"
              icon="pencil-alt"
              onClick={() => {
                setSelectedFeatureFlag(featureFlag);
                editFeatureFlagModal.open();
              }}
            />
        ))}
        </PropertyList>
      </Panel>
      <EditFeatureFlagModal
        isOpen={editFeatureFlagModal.isOpen}
        onCancel={editFeatureFlagModal.close}
        onSave={editFeatureFlagModal.close}
        onRequestClose={editFeatureFlagModal.close}
        panelConfig={selectedFeatureFlag}
        value={Boolean(config?.featureFlags?.[selectedFeatureFlag?.accessor])}
      />
    </>
  );
};

const configPageTabs = {
  emails: 'emails',
  requests: 'requests',
  featureFlags: 'feature-flags',
  localization: 'localization',
  defaultValues: 'default-values',
};

const tabsSidebarConfig : Record<string, {
  text: string;
  icon: IconValue;
}> = {
  [configPageTabs.emails]: {
    text: 'Email',
    icon: 'envelope',
  },
  [configPageTabs.requests]: {
    text: 'Requests',
    icon: 'file-text-o',
  },
  [configPageTabs.featureFlags]: {
    text: 'Feature flags',
    icon: 'flag',
  },
  [configPageTabs.localization]: {
    text: 'Localization',
    icon: 'earth-americas',
  },
  [configPageTabs.defaultValues]: {
    text: 'Default values',
    icon: 'sliders-simple',
  },
};

const getConfigTab = (index: number) => Object.values(configPageTabs)[index];
const getConfigTabIndex = (tab: string) => Object.values(configPageTabs).indexOf(tab);

export const ConfigPage = () => {
  const adminApi = useAdminApi();
  const navigation = useAdminNavigation();
  const { tab } = configRoute.useSearch();

  const { data: config, status } = useQuery(
    ['config'],
    adminApi.getConfig,
  );

  const { isExtraSmall, isSmall } = useDeviceSize();

  useEffect(() => {
    if (!tab) {
      navigation.navigateToConfig(configPageTabs.emails, true);
    }
  }, [navigation, tab]);

  return (
    <Page>
      <title.Container>
        <title.IconText icon="cog" size="large">
          Configuration
        </title.IconText>
      </title.Container>
      <PanelLoadingWrapper
        status={status}
        data={config}
        errorMessage="An unexpected error occurred"
      >
        <ConfigProvider config={config}>
          <Tabs
            index={getConfigTabIndex(tab)}
            onChange={index => navigation.navigateToConfig(getConfigTab(index))}
          >
            <SidebarLayout
              sidebar={
                <TabListPanel>
                  {Object.entries(tabsSidebarConfig).map(([tabKey, config]) => (
                    <Tab key={tabKey}>
                      <Flex alignItems="center">
                        <Icon
                          icon={config.icon}
                          mr={2}
                          fixedWidth
                        />
                        <Text flex={1}>{config.text}</Text>
                      </Flex>
                    </Tab>
                  ))}
                </TabListPanel>
              }
              main={
                <TabPanels>
                  <TabPanel key={configPageTabs.emails}>
                    <EmailConfigPanel />
                  </TabPanel>
                  <TabPanel key={configPageTabs.requests}>
                    <RequestsConfigPanel />
                  </TabPanel>
                  <TabPanel key={configPageTabs.featureFlags}>
                    <FeatureFlagsPanel featureFlagsConfig={featureFlagPanelConfigs} />
                  </TabPanel>
                  <TabPanel key={configPageTabs.localization}>
                    <LocalizationPanel />
                  </TabPanel>
                  <TabPanel key={configPageTabs.defaultValues}>
                    <DefaultValuesPanel />
                  </TabPanel>
                </TabPanels>
              }
              sidebarStyle={!isExtraSmall && !isSmall ? (
                { maxWidth: '232px', flex: '0 0 auto' }
              ) : (
                undefined
              )}
              mainStyle={!isExtraSmall && !isSmall ? (
                 { flex: '1 1 auto' }
              ) : (
                undefined
              )}
            />
          </Tabs>
        </ConfigProvider>
      </PanelLoadingWrapper>
    </Page>
  );
};
