import { useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMachine, useActor } from '@xstate/react';
import { useQuery } from 'react-query';
import { size, pick, uniqBy, compact, unary, uniq } from 'lodash';
import { Box, Flex, Heading, Text } from 'rebass/styled-components';
import { Form, Formik, useField, useFormikContext } from 'formik';
import * as yup from 'yup';
import { IconValue } from '@deepstream/common';
import { getPrimarySubtag } from '@deepstream/utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalHeader, Modal, ModalBody } from '@deepstream/ui-kit/elements/popup/Modal';
import { InvitedPerson, Invitation, InviteModalResponse } from './types';
import { useMutation } from './useMutation';
import { useCurrentCompanyId } from './currentCompanyId';
import { useApi, wrap } from './api';
import { TextField } from './form/TextField';
import { FormTableStyles } from './TableStyles';
import { Table } from './Table';
import { TextFieldCell } from './draft/cell';
import { Required } from './Required';
import { inviteModalMachine, inviteModalInitialContext, InviteModalActions } from './inviteModalMachine';
import { useToaster } from './toast';
import { CompanyFinderField } from './form/CompanyFinderField';
import { useModalState } from './ui/useModalState';
import { SelectField } from './form/SelectField';
import { useCurrentUserLocale } from './useCurrentUser';
import { StepFooter } from './StepFooter';

export const SubtleText = withProps(Text, { color: 'subtext' });

type InviteProxyProps = {
  user: any;
  company: any;
};

const InviteProxyContext = React.createContext<InviteProxyProps | null>(null);

export const InviteProxyProvider: React.FC<{ user: any; company: any }> = ({ user, company, ...props }) => {
  const contextValue = React.useMemo(
    () => ({ user, company }),
    [user, company],
  );

  return (
    <InviteProxyContext.Provider value={contextValue} {...props} />
  );
};

export const useInviteProxy = () => React.useContext(InviteProxyContext);

const ServiceContext = React.createContext(null as any);

type SendInviteButtonProps = {
  onInvitesSent: (successfulInvites: InviteModalResponse[]) => void;
  label?: string;
  isLive?: boolean;
  isNetwork?: boolean;
  isRequest?: boolean;
  isQuestionnaire?: boolean;
  rfqName?: string;
  small?: boolean;
  iconLeft?: IconValue;
};

export const SendInviteButton: React.FC<SendInviteButtonProps> = ({
  onInvitesSent,
  label,
  isLive,
  isNetwork,
  isRequest,
  isQuestionnaire,
  rfqName,
  small,
  iconLeft = 'envelope',
}) => {
  const { t } = useTranslation();
  const inviteCompaniesModalState = useModalState();

  let customizedMessage;
  if (isNetwork) {
    customizedMessage = t('invite.customizedMessages.forNetwork');
  } else if (isRequest && rfqName && isLive) {
    customizedMessage = t('invite.customizedMessages.forLiveRequest', { requestTitle: rfqName });
  } else if (isRequest && rfqName) {
    customizedMessage = t('invite.customizedMessages.forRequestWithTitle', { requestTitle: rfqName });
  } else if (isRequest) {
    customizedMessage = t('invite.customizedMessages.forRequestWithoutTitle');
  } else if (isQuestionnaire) {
    customizedMessage = t('invite.customizedMessages.forQuestionnaire');
  }

  return (
    <>
      <Button
        small={!!small}
        variant={isNetwork ? 'primary' : 'secondary'}
        iconLeft={iconLeft}
        mr={2}
        onClick={() => inviteCompaniesModalState.open()}
        id="reactSendInviteButton"
      >
        {label || t('invite.sendAnInvite')}
      </Button>
      <InviteModal
        isOpen={inviteCompaniesModalState.isOpen}
        close={inviteCompaniesModalState.close}
        customizedMessage={customizedMessage}
        onInvitesSent={onInvitesSent}
        isQuestionnaire={isQuestionnaire}
      />
    </>
  );
};

export const InviteModalCompanyFinderFieldCell = ({
  row,
  column,
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const {
    setSelectedCompanies,
    setCreatedCompanies,
    searchCompanies,
  } = column;

  return (
    <Box maxWidth="400px">
      <CompanyFinderField
        currentCompanyId={currentCompanyId}
        disabledCompanies={[]}
        name={`invitedPersons[${row.index}].company`}
        onCreateCompany={company => {
          setCreatedCompanies(companies => [...companies, company]);
        }}
        onSelect={company => {
          setSelectedCompanies(companies => uniqBy(compact([...companies, company]), '_id'));
        }}
        placeholder={(t('companyFinder.searchByCompanyName'))}
        searchCompanies={searchCompanies}
        disabled={column.disabled}
        popoverWidth={300}
        popoverPlacement="bottom-end"
      />
    </Box>
  );
};

const ModalFieldColumnHeader = ({ column }) => (
  <>
    {column.label}
    {column.required && <Required />}
  </>
);

const RemoveInvitedPersonButtonCell = ({ row, column }) => {
  const [{ value: invitedPersons },, formik] = useField<any[]>('invitedPersons');

  const onRemove = React.useCallback(
    () => {
      const newInvitedPersons = invitedPersons.slice();
      newInvitedPersons.splice(row.index, 1);
      formik.setValue(newInvitedPersons);
    },
    [row, invitedPersons, formik],
  );

  // First element of the table cannot be removed
  return (
    <>
      {row.index > 0 ? (
        <Button
          type="button"
          variant="secondary-outline"
          iconLeft="trash-o"
          width={40}
          onClick={onRemove}
          disabled={column.disabled}
        />
      ) : (
        null
      )}
    </>
  );
};

const ChoosePersonsForm = ({ inviteModalSteps, isLoadingBulkValidation }) => {
  const { t } = useTranslation();
  const api = useApi();
  const [createdCompanies, setCreatedCompanies] = useState<Array<{ _id: null; name: string }>>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<Array<{ _id: null; name: string }>>([]);
  const { isValid, setErrors, setTouched } = useFormikContext();
  const [{ value: invitedPersons },, formik] = useField<any[]>('invitedPersons');

  const searchCompanies = React.useCallback(
    async (text) => {
      if (!text) {
        // This branch is intended to immediately display all of the selected companies when the
        // company finder is first focused (ie: the input is empty)
        return selectedCompanies;
      }

      const result = await api.searchCompanies({ text, pageSize: 20, pageIndex: 0 });
      const companies = result.companies.map(company => pick(company, ['_id', 'name', 'address']));

      const filteredCreatedCompanies = createdCompanies
        ? createdCompanies.filter(({ name }) => name.toLowerCase().match(text.toLowerCase()))
        : [];

      return [
        ...filteredCreatedCompanies,
        ...companies,
      ];
    },
    [api, createdCompanies, selectedCompanies],
  );

  const columns = React.useMemo(
    () => [
      {
        id: 'email',
        Header: ModalFieldColumnHeader,
        label: t('general.email'),
        accessor: 'email',
        Cell: withProps(TextFieldCell, { fieldName: 'invitedPersons' }),
        required: true,
        disabled: isLoadingBulkValidation,
      },
      {
        id: 'firstName',
        Header: ModalFieldColumnHeader,
        label: t('general.firstNameOptional'),
        accessor: 'firstName',
        Cell: withProps(TextFieldCell, { fieldName: 'invitedPersons' }),
        disabled: isLoadingBulkValidation,
      },
      {
        id: 'lastName',
        Header: ModalFieldColumnHeader,
        label: t('general.lastNameOptional'),
        accessor: 'lastName',
        Cell: withProps(TextFieldCell, { fieldName: 'invitedPersons' }),
        disabled: isLoadingBulkValidation,
      },
      {
        id: 'company',
        Header: ModalFieldColumnHeader,
        label: t('general.company'),
        accessor: 'company',
        Cell: InviteModalCompanyFinderFieldCell,
        createdCompanies,
        setCreatedCompanies,
        selectedCompanies,
        setSelectedCompanies,
        searchCompanies,
        required: true,
        disabled: isLoadingBulkValidation,
      },
      {
        width: 48,
        id: 'removeButton',
        Cell: RemoveInvitedPersonButtonCell,
        disabled: isLoadingBulkValidation,
      },
    ],
    [t, createdCompanies, selectedCompanies, searchCompanies, isLoadingBulkValidation],
  );

  const service = React.useContext(ServiceContext);
  const [currentState] = useActor(service);
  const { context }: { context: any } = currentState as any;

  const invalidInviteEmails = React.useMemo(() => context.invalidInvites.map(row => row.email), [context.invalidInvites]);

  React.useEffect(
    () => {
      if (invalidInviteEmails.length) {
        // Display error messages on Formik, based on invalidInvites validations received from backend
        const errorRows = invitedPersons.map((row) => !invalidInviteEmails.includes(row.email) ? undefined : { email: t('invite.emailAlreadyRegistered') });
        const touchRows = invitedPersons.map((row) => !invalidInviteEmails.includes(row.email) ? undefined : { email: true });

        setErrors({
          invitedPersons: errorRows,
        });

        setTouched({
          invitedPersons: touchRows,
        }, false);
      }
    }, [invalidInviteEmails, invitedPersons, isLoadingBulkValidation, setErrors, setTouched, t],
  );

  return (
    <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Flex flex="1 0 0" overflowY="auto" px={3}>
        <Box>
          <Heading as="h2" fontSize={6} lineHeight="normal" fontWeight="500" mt={20} mb={30}>
            {t('invite.inviteAddPersons')}
          </Heading>
          <FormTableStyles>
            <Table columns={columns} data={invitedPersons} />
          </FormTableStyles>
          <AddPersonButton
            isLoadingBulkValidation={isLoadingBulkValidation}
            onClick={() => formik.setValue([...invitedPersons, { email: '', firstName: '', lastName: '', company: null }])}
            mt={2}
            mb={3}
          />
        </Box>
      </Flex>
      <StepFooter
        steps={inviteModalSteps}
        activeState="choosePersons"
        isBackVisible={false}
        onBack={() => { service.send(InviteModalActions.BACK); }}
        onContinue={() => ({})}
        isNextDisabled={!isValid || isLoadingBulkValidation}
      />

    </Form>
  );
};

const ChoosePersons = ({ invitedPersons, setInvitedPersons, inviteModalSteps, isLoadingBulkValidation }) => {
  const { t } = useTranslation();
  const service = React.useContext(ServiceContext);

  const initialValues = {
    invitedPersons,
  };

  return (
    <Flex height="100%" flexDirection="column">
      <Formik
        validateOnBlur
        enableReinitialize
        initialValues={initialValues}
        validationSchema={
          yup.object().shape({
            invitedPersons: yup.array().of(
              yup.object().shape({
                email: yup.string()
                  .email(t('errors.invalidFormat'))
                  .required(t('general.required')),
                firstName: yup.string(),
                lastName: yup.string(),
                company: yup.object().shape({
                  _id: yup.string().required(t('general.required')),
                  name: yup.string().required(t('general.required')),
                }).nullable().required(t('general.required')),
              }),
            ),
          })
        }
        onSubmit={(values) => {
          setInvitedPersons(values.invitedPersons);
          service.send(InviteModalActions.ADD_INVITED_PERSONS, { data: values.invitedPersons });
        }}
      >
        <ChoosePersonsForm inviteModalSteps={inviteModalSteps} isLoadingBulkValidation={isLoadingBulkValidation} />
      </Formik>
    </Flex>
  );
};

type InviteModalReviewContainerProps = {
  currentSubState: string;
  heading: string;
  operationStatus: string;
  firstTooltipItems?: string[];
  secondTooltipItems?: string[];
  firstDescriptionText?: string;
  secondDescriptionText?: string;
  isProcessing: boolean;
  addPaddingLeft?: boolean;
};

export const InviteModalReviewContainer = ({
  currentSubState,
  heading,
  operationStatus,
  firstTooltipItems,
  secondTooltipItems,
  firstDescriptionText,
  secondDescriptionText,
  isProcessing,
  addPaddingLeft,
}: InviteModalReviewContainerProps) => (
  <Flex pl={addPaddingLeft ? 4 : ''} flexDirection="column" width="100%" justifyContent="center">
    <Heading fontSize={6} fontWeight={500} mb="8px" mt="44px" pr={0}>
      {heading}
    </Heading>
    {size(firstTooltipItems) > 0 && (
      <InviteModalReviewItems
        iconLeft="envelope"
        iconRight={currentSubState === 'preparePayload' ? 'blank' : operationStatus === 'success' ? 'check-circle' : 'exclamation-circle'}
        descriptionText={firstDescriptionText}
        tooltipItems={firstTooltipItems}
        isProcessing={isProcessing}
      />
    )}

    {size(secondTooltipItems) > 0 && (
      <InviteModalReviewItems
        iconLeft="building"
        iconRight={currentSubState === 'preparePayload' ? 'blank' : operationStatus === 'success' ? 'check-circle' : 'exclamation-circle'}
        descriptionText={secondDescriptionText}
        tooltipItems={secondTooltipItems}
        isProcessing={isProcessing}
      />
    )}
  </Flex>
);

interface InterfaceInviteModalSteps {
  choosePersons: string;
  customizeMessage: string;
  review: string;
}

type ReviewProps = {
  handleClose: () => void;
  inviteModalSteps: InterfaceInviteModalSteps;
  isSubmittingReviewedInvites: boolean;
};

const Review = ({
  handleClose,
  inviteModalSteps,
  isSubmittingReviewedInvites,
}: ReviewProps) => {
  const { t } = useTranslation();

  const service = React.useContext(ServiceContext);
  const [currentState] = useActor(service) as any;
  const { context }: { context: any } = currentState;

  const emails: string[] = context?.invitedPersons.map((row) => row.email);
  const successfulInviteEmails: string[] = emails.filter(email => !context?.failedInvites?.includes(email));

  const newCompanies: string[] = uniq(
    context?.invitedPersons
      .filter(row => row.company._id.includes('temp-id-'))
      .map(row => row.company.name),
  );

  let currentSubState;
  let heading;
  let operationStatus;

  let firstDescriptionText;
  let secondDescriptionText;

  let firstTooltipItems;
  let secondTooltipItems;

  // Declarations for second container use-case
  let showExtraContainer = false;
  let extraHeading;
  let extraOperationStatus;

  let extraFirstTooltipItems;
  let extraSecondTooltipItems;

  let extraFirstDescriptionText;
  let extraSecondDescriptionText;

  if (currentState?.matches({ review: 'preparePayload' }) || isSubmittingReviewedInvites) {
    currentSubState = 'preparePayload';
    heading = isSubmittingReviewedInvites ? t('invite.processingInvites') : t('invite.reviewAndSend');

    firstTooltipItems = emails.slice();
    secondTooltipItems = newCompanies.slice();

    firstDescriptionText = t('invite.inviteWillBeSentCount', { count: size(firstTooltipItems) });
    secondDescriptionText = t('invite.newCompanyWillBeCreatedCount', { count: size(secondTooltipItems) });
  } else if (currentState?.matches({ review: 'postingFinishedNoErrors' })) {
    currentSubState = 'postingFinishedNoErrors';
    heading = t('invite.allInvitesSentSuccessfully');
    operationStatus = 'success';

    firstTooltipItems = successfulInviteEmails.slice();
    secondTooltipItems = newCompanies.slice();

    firstDescriptionText = t('invite.inviteSentCount', { count: size(firstTooltipItems) });
    secondDescriptionText = t('invite.newCompanyCreatedCount', { count: size(secondTooltipItems) });
  } else if (currentState?.matches({ review: 'postingFinishedWithErrors' }) && successfulInviteEmails.length === 0) {
    currentSubState = 'postingFinishedWithErrors';
    heading = t('invite.noInvitesCouldBeSent');
    operationStatus = 'error';

    firstTooltipItems = context?.failedInvites.slice();
    secondTooltipItems = context?.failedAddNewCompany.slice();

    firstDescriptionText = t('invite.inviteCouldNotBeSentCount', { count: size(firstTooltipItems) });
    secondDescriptionText = t('invite.newCompanyCouldNotBeCreatedCount', { count: size(secondTooltipItems) });
  } else if (currentState?.matches({ review: 'postingFinishedWithErrors' }) && successfulInviteEmails.length > 0) {
    currentSubState = 'postingFinishedWithErrors';

    // Data for first InviteModalReviewContainer
    heading = t('invite.successfulInvites');
    operationStatus = 'success';

    firstTooltipItems = successfulInviteEmails.slice();
    secondTooltipItems = newCompanies.slice();

    firstDescriptionText = t('invite.inviteSentCount', { count: size(firstTooltipItems) });
    secondDescriptionText = t('invite.newCompanyCreatedCount', { count: size(secondTooltipItems) });

    // Data for extra/error InviteModalReviewContainer
    showExtraContainer = true;
    extraHeading = t('general.errors');
    extraOperationStatus = 'error';

    extraFirstTooltipItems = context?.failedInvites.slice();
    extraSecondTooltipItems = context?.failedAddNewCompany.slice();

    extraFirstDescriptionText = t('invite.inviteCouldNotBeSentCount', { count: size(extraFirstTooltipItems) });
    extraSecondDescriptionText = t('invite.newCompanyCouldNotBeCreatedCount', { count: size(extraSecondTooltipItems) });
  }

  return (
    <Flex height="100%" flexDirection="column">
      <Flex flex={1} mx={3} mt={3} justifyContent="center">
        <Box width="350px">
          <InviteModalReviewContainer
            currentSubState={currentSubState}
            heading={heading}
            operationStatus={operationStatus}
            firstTooltipItems={firstTooltipItems}
            secondTooltipItems={secondTooltipItems}
            firstDescriptionText={firstDescriptionText}
            secondDescriptionText={secondDescriptionText}
            isProcessing={isSubmittingReviewedInvites}
          />
        </Box>

        {showExtraContainer && (
          <Box width={2 / 5} sx={{ borderLeft: 'lightGray2' }} ml={4}>
            <InviteModalReviewContainer
              currentSubState={currentSubState}
              heading={extraHeading}
              operationStatus={extraOperationStatus}
              firstTooltipItems={extraFirstTooltipItems}
              secondTooltipItems={extraSecondTooltipItems}
              firstDescriptionText={extraFirstDescriptionText}
              secondDescriptionText={extraSecondDescriptionText}
              isProcessing={isSubmittingReviewedInvites}
              addPaddingLeft
            />
          </Box>
        )}
      </Flex>

      {currentSubState === 'preparePayload' && !isSubmittingReviewedInvites ? (
        <StepFooter
          steps={inviteModalSteps}
          activeState="review"
          isBackVisible={true}
          onBack={() => { service.send(InviteModalActions.BACK); }}
          onContinue={() => { service.send(InviteModalActions.POST_DATA); }}
          isNextDisabled={isSubmittingReviewedInvites}
          primaryButtonText={t('invite.sendInvite', { count: size(emails) })}
        />
      ) : currentSubState === 'postingFinishedNoErrors' ? (
        <StepFooter
          steps={inviteModalSteps}
          activeState="review"
          isBackVisible={false}
          onContinue={() => handleClose()}
          primaryButtonText={t('general.dismiss')}
        />
      ) : currentSubState === 'postingFinishedWithErrors' ? (
        <StepFooter
          steps={inviteModalSteps}
          activeState="review"
          isBackVisible={true}
          onBack={() => { service.send(InviteModalActions.BACK); }}
          onContinue={() => handleClose()}
          primaryButtonText={t('general.dismiss')}
        />
      ) : (
        null
      )}
    </Flex>
  );
};

const CustomizeMessageForm = ({ inviteModalSteps, locale, enabledLanguages }) => {
  const { t } = useTranslation();

  const service = React.useContext(ServiceContext);

  const { isValid, submitForm } = useFormikContext();
  const { values }: { values: any } = useFormikContext();

  const changeStep = (step: string) => {
    submitForm();

    if (step === InviteModalActions.NEXT) {
      service.send(InviteModalActions.NEXT, { data: { ...values } });
    } else if (step === InviteModalActions.BACK) {
      service.send(InviteModalActions.BACK, { data: { ...values } });
    }
  };

  const languageNames = new Intl.DisplayNames([locale], { type: 'language' });

  return (
    <Form style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <Flex flexDirection="column" flex="1 0 0">
        <Heading as="h1" fontSize={6} lineHeight="normal" fontWeight="500" ml={3} mt={20} mb={30}>
          {t('invite.inviteCustomizeMessage')}
        </Heading>
        <Flex mx={3} mb={1}>
          <Box flex="1" mr="20px">
            <TextField isMultiLine required={true} name="message" label={t('invite.yourMessage')} rows={6} />
          </Box>
          <Box width="175px">
            <SelectField
              required
              name="locale"
              label={t('invite.language')}
              items={
                enabledLanguages
                  ? enabledLanguages.map((language) => {
                      return {
                        label: languageNames.of(getPrimarySubtag(language.code)),
                        value: language.code,
                      };
                    })
                  : []
              }
              helperText={t('invite.languageDropdownDescription')}
            />
          </Box>
        </Flex>

        <Flex mx={3} mb={20}>
          <MessageBlock variant="info" mt={3} fontSize={2} width="100%">
            {t('invite.inviteCustomizeMessageBlock')}
          </MessageBlock>
        </Flex>
      </Flex>

      <StepFooter
        steps={inviteModalSteps}
        activeState="customizeMessage"
        isBackVisible={true}
        onBack={() => changeStep(InviteModalActions.BACK)}
        onContinue={() => changeStep(InviteModalActions.NEXT)}
        isNextDisabled={!isValid}
      />
    </Form>
  );
};

const CustomizeMessage = ({ invitation, setInvitation, inviteModalSteps }) => {
  const { t } = useTranslation();
  const api = useApi();
  const locale = useCurrentUserLocale();

  const { data: enabledLanguages } = useQuery(
    ['enabledLanguages'],
    wrap(api.getEnabledLanguages),
  );

  return (
    <Formik
      validateOnBlur
      enableReinitialize
      initialValues={{
        message: invitation.message,
        locale,
      }}
      validationSchema={
        yup.object().shape({
          message: yup.string().required(t('general.required')),
          locale: yup.string().required(t('general.required')),
        })
      }
      onSubmit={(values, { setSubmitting }) => {
        setInvitation({ message: values.message, locale: values.locale });
        setSubmitting(false);
      }}
    >
      <CustomizeMessageForm inviteModalSteps={inviteModalSteps} locale={locale} enabledLanguages={enabledLanguages} />
    </Formik>
  );
};

type InviteModalReviewItemsProps = {
  tooltipItems?: string[];
  descriptionText?: string;
  iconLeft: IconValue;
  iconRight?: IconValue | null;
  isProcessing?: boolean;
};

const InviteModalReviewItems: React.FC<InviteModalReviewItemsProps> = ({
  tooltipItems,
  descriptionText,
  iconLeft,
  iconRight,
  isProcessing,
}) => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center" mt={3} width="100%" >
      <Box width="30px" >
        <Icon
          icon={iconLeft}
          mr={1}
          fontSize={2}
        />
      </Box>

      <Box width="240px">
        <Flex flexDirection="column">
          <Text fontSize={2} fontWeight={500}>
            {descriptionText}
          </Text>
          <Text fontSize="11px">
            {tooltipItems && tooltipItems[0]}

            <Tooltip content={
              size(tooltipItems) <= 1 ? '' : (
                tooltipItems && tooltipItems.slice(1).map((row, index) => (
                  <React.Fragment key={index}>
                    <span key={index}>{row}</span>
                    <br />
                  </React.Fragment>
                ))
              )}
            >
              <Text
                display="inline"
                color="primary"
                ml={1}
                tabIndex={0}
                fontWeight={500}
              >
                {size(tooltipItems) > 1 &&
                  t('invite.plusXMore', { count: size(tooltipItems) - 1 })
                }
              </Text>
            </Tooltip>
          </Text>
        </Flex>
      </Box>

      {isProcessing && (
        <Box width={1 / 10}>
          <Icon
            icon="spinner"
            color="gray"
            ml={2}
            solid
            spin
            fontSize={2}
          />
        </Box>
      )}

      {!isProcessing && iconRight && (
        <Box width={1 / 10}>
          <Icon
            fixedWidth
            icon={iconRight}
            ml={2}
            color={iconRight === 'check-circle' ? 'success' : 'danger'}
            solid
            fontSize={2}
          />
        </Box>
      )}

    </Flex>
  );
};

type AddPersonButtonProps = {
  isLoadingBulkValidation: boolean;
} & ButtonProps;

const AddPersonButton = ({
  isLoadingBulkValidation,
  ...props
}: AddPersonButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      type="button"
      small
      variant="secondary"
      iconLeft="plus"
      width="fit-content"
      {...props}
      disabled={isLoadingBulkValidation}
    >
      {t('invite.addPerson')}
    </Button>
  );
};

type InviteModalProps = {
  onInvitesSent?: (successfulInvites: InviteModalResponse[]) => void;
  close: any;
  customizedMessage: string;
  isOpen: boolean;
  props?: any;
  isQuestionnaire?: boolean;
};

export const InviteModal: React.FC<InviteModalProps> = ({
  onInvitesSent,
  close,
  customizedMessage,
  isOpen,
  isQuestionnaire,
  ...props
}) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const api = useApi();

  const inviteModalSteps = {
    choosePersons: 'choosePersons',
    customizeMessage: 'customizeMessage',
    review: 'reviewAndSend',
  };

  const currentCompanyId = useCurrentCompanyId({ required: true });
  const initInviteModalInitialContext = {
    ...inviteModalInitialContext,
    companyId: currentCompanyId,
  };

  const [invitedPersons, setInvitedPersons] = useState<InvitedPerson[]>([{ email: '', firstName: '', lastName: '', companyInput: '', company: null }]);

  const [invitation, setInvitation] = useState<Invitation>({ message: customizedMessage, locale: '' });

  const [isSubmittingReviewedInvites, setIsSubmittingReviewedInvites] = useState(false);

  const [callBulkValidation, { isLoading: isLoadingBulkValidation }] = useMutation(
    api.bulkValidateInviteModal,
    {
      onError: () => toaster.error(t('invite.bulkValidationEndpointError')),
    },
  );

  const prepareBulkValidation = async (context) => {
    const payload = {
      companyId: currentCompanyId,
      invites: context.invitedPersons.map((row) => ({
        email: row.email,
        companyId: row.company._id,
      })),
    };

    return callBulkValidation(payload);
  };

  const proxy = useInviteProxy();

  const [sendInvites] = useMutation(({ company, users, message, locale }: any) =>
    api.sendInvites({
      company,
      users,
      message,
      locale,
      companyId: proxy?.company._id || currentCompanyId,
      proxyUserId: proxy?.user._id,
      invitedFor: isQuestionnaire ? 'preQualification' : 'requests',
    }),
  );

  const postMultipleInvites: any = async (context) => {
    setIsSubmittingReviewedInvites(true);

    await Promise.all(context.payload.map(async (values) => {
      let statusReportObject;
      try {
        const response = await sendInvites(values);
        statusReportObject = {
          users: response.users,
          invitedCompany: response.company,
          statusReport: response.statusReport,
        };
      } catch (error) {
        // if we catch an error here, we assume that invites for all users for this company have failed and that all operations have failed
        statusReportObject = {
          invitedCompany: values.company,
          statusReport: {
            'failedInvites': values.users.map(row => row.email),
            'companyCreate': 'failed',
          },
        };
      }
      // send has to be called here, because postMultipleInvites, this function, is required as a service below in useMachine
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      send(InviteModalActions.HANDLE_STATUS_REPORT, { data: statusReportObject });
    }));

    setIsSubmittingReviewedInvites(false);
  };

  const [currentState, send, service] = useMachine(
    inviteModalMachine.withContext(initInviteModalInitialContext),
    {
      services: {
        postToInviteBulkValidation: unary(prepareBulkValidation),
        sendAllInvites: unary(postMultipleInvites),
      },
    });

  const { context }: { context: any } = currentState;

  const handleClose = React.useCallback(
    () => {
      if (currentState?.matches({ review: 'postingFinishedNoErrors' }) || currentState?.matches({ review: 'postingFinishedWithErrors' })) {
        if (context?.successfulInvites.length > 0) {
          onInvitesSent?.(context?.successfulInvites);
        }
      }

      send(InviteModalActions.RESET_MACHINE);
      setInvitedPersons([{ email: '', firstName: '', lastName: '', company: null }]);
      close();
    },
    [close, context, currentState, onInvitesSent, send],
  );

  return (
    <Modal style={{ content: { width: '900px' } }} {...props} isOpen={isOpen}>
      <ModalHeader onClose={() => handleClose()} >
        <Flex>
          {t('invite.newInvite')}
          {proxy && (
            <SubtleText fontSize={2} fontWeight="normal" ml={2} mt="2px" >
              {`(${proxy.company.name} • ${proxy.user.name || proxy.user.email})`}
            </SubtleText>
          )}
        </Flex>
      </ModalHeader>
      <ModalBody p={0} height="450px" maxHeight="450px" overflowY="auto">
        <ServiceContext.Provider value={service}>
          {currentState.matches('choosePersons') ? (
            <ChoosePersons
              invitedPersons={invitedPersons}
              setInvitedPersons={setInvitedPersons}
              inviteModalSteps={inviteModalSteps}
              isLoadingBulkValidation={isLoadingBulkValidation}
            />
          ) : currentState.matches('customizeMessage') ? (
            <CustomizeMessage
              invitation={invitation}
              setInvitation={setInvitation}
              inviteModalSteps={inviteModalSteps}
            />
          ) : currentState.matches('review') ? (
            <Review
              isSubmittingReviewedInvites={isSubmittingReviewedInvites}
              inviteModalSteps={inviteModalSteps}
              handleClose={handleClose}
            />
          ) : (
            null
          )}
        </ServiceContext.Provider>
      </ModalBody>

    </Modal>
  );
};
