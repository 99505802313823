import { isEmpty } from 'lodash';
import { FlexProps, Text } from 'rebass/styled-components';
import { useInView } from 'react-intersection-observer';
import { Panel, PanelDivider, PanelHeader, PanelPadding, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { QuestionnaireIdProvider, QuestionnaireProvider } from '@deepstream/ui/modules/PreQualification/Questionnaire/questionnaireUtils';
import { QuestionnaireTemplateAttachment } from '@deepstream/ui/modules/PreQualification/QuestionnaireTemplate/QuestionnaireTemplateAttachment';
import { QuestionnaireTemplateIdProvider } from '@deepstream/ui/modules/PreQualification/QuestionnaireTemplate/questionnaireTemplateUtils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { TeamUserModal } from '@deepstream/ui/modules/PreQualification/Questionnaire/Team/TeamUserModal';
import { Page } from '../../Page';
import * as Title from '../../title';
import { questionnaireRoute } from '../../AppRouting';
import { useAdminQuestionnaire } from './adminPreQual';
import { AdminQuestionnairePropertyList } from './AdminQuestionnairePropertyList';
import { AdminQuestionnaireUsersTable } from './AdminQuestionnaireUsersTable';
import { AdminQuestionnaireQuestionsTable } from './AdminQuestionnaireQuestionsTable';

export const PageTitle = ({
  flexDirection,
}: {
  flexDirection?: FlexProps['flexDirection'];
}) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.Questionnaire
      size={flexDirection === 'column' ? 'large' : 'small'}
    />
  </Title.Container>
);

export const AdminQuestionnairePage = () => {
  const { questionnaireId } = questionnaireRoute.useParams();
  const [ref, inView, entry] = useInView();
  const { isExtraSmall } = useDeviceSize();
  const addUserModal = useModalState();

  if (!questionnaireId) {
    throw new Error('A questionnaireId is required');
  }

  const { data: questionnaire, error, status } = useAdminQuestionnaire();

  return (
    <Page
      subHeading={questionnaire && entry && !inView && (
        <QuestionnaireProvider questionnaire={questionnaire}>
          <PageTitle />
        </QuestionnaireProvider>
      )}
    >
      {status === 'loading' ? (
        <Panel>
          <PanelText><Loading /></PanelText>
        </Panel>
      ) : status === 'error' ? (
        <Panel heading="Error">
          <PanelText color="danger">
            <Icon icon="exclamation-circle" mr={2} />{(error as any)?.toString()}
          </PanelText>
        </Panel>
      ) : questionnaire ? (
        <QuestionnaireIdProvider questionnaireId={questionnaireId}>
          <QuestionnaireTemplateIdProvider templateId={questionnaire.meta.questionnaireTemplateId}>
            <QuestionnaireProvider questionnaire={questionnaire}>
              <div ref={ref}>
                <PageTitle flexDirection="column" />
              </div>
              <Stack gap={4}>
                <Panel heading="General">
                  <AdminQuestionnairePropertyList />
                </Panel>

                <Panel>
                  <PanelHeader heading="Team">
                    <Button
                      ml={isExtraSmall ? 3 : '24px'}
                      variant="primary"
                      iconLeft="plus"
                      onClick={addUserModal.open}
                      small
                    >
                      Add user
                    </Button>
                  </PanelHeader>
                  <AdminQuestionnaireUsersTable />
                  {addUserModal.isOpen && (
                    <TeamUserModal
                      close={addUserModal.close}
                      companyId={questionnaire.recipient._id}
                    />
                  )}
                </Panel>

                <Panel heading="Questions">
                  <PanelPadding>
                    <Text color="lightNavy" fontWeight={500} fontSize={2} mb={2}>
                      Instructions
                    </Text>
                    <Stack gap={2}>
                      {questionnaire.instructions ? (
                        <Text color="darkGray2" fontSize={1}>{questionnaire.instructions}</Text>
                      ) : isEmpty(questionnaire.instructionsAttachments) ? (
                        <Text fontSize={1} color="subtext">No instructions</Text>
                      ) : (
                        null
                      )}
                      {questionnaire.instructionsAttachments.map((attachment) => (
                        <QuestionnaireTemplateAttachment
                          key={attachment._id}
                          attachment={attachment}
                        />
                      ))}
                    </Stack>
                  </PanelPadding>
                  <PanelDivider />
                  <AdminQuestionnaireQuestionsTable />
                </Panel>
              </Stack>
            </QuestionnaireProvider>
          </QuestionnaireTemplateIdProvider>
        </QuestionnaireIdProvider>
      ) : null}
    </Page>
  );
};
