import { useMemo } from 'react';
import { Text } from 'rebass/styled-components';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { QuestionnaireOverview } from '@deepstream/common/preQual';
import { QuestionnaireRenewalDateCell } from '@deepstream/ui/modules/PreQualification/Questionnaire/QuestionnaireRenewalDateCell';
import { useTranslation } from 'react-i18next';
import { useCurrentUserLocale } from '@deepstream/ui/useCurrentUser';
import { localeFormatFactorAsPercent } from '@deepstream/utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import { CompanyMinimizedNameAndLogoCell } from '@deepstream/ui/CompanyMinimizedNameAndLogoCell';
import { QuestionnaireExpiryDateCell } from '@deepstream/ui/modules/PreQualification/Questionnaire/QuestionnaireExpiryDateCell';

export const AdminQuestionnaireTemplateSuppliersTable = ({
  suppliers,
}: {
  suppliers: QuestionnaireOverview[],
}) => {
  const { t } = useTranslation('preQualification');
  const locale = useCurrentUserLocale();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Company',
        accessor: 'recipient.name',
        Cell: withProps(
          CompanyMinimizedNameAndLogoCell,
          { companyIdField: 'recipient._id' },
        ),
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: ({ status }: QuestionnaireOverview) => t(`questionnaireStatus.${status}`),
      },
      {
        id: 'completion',
        Header: 'Completion',
        accessor: ({ completion }: QuestionnaireOverview) => localeFormatFactorAsPercent(completion, { locale, decimalPlaces: 0 }),
      },
      {
        id: 'renewalDate',
        Header: 'Renewal date',
        accessor: 'renewalDate',
        Cell: QuestionnaireRenewalDateCell,
      },
      {
        id: 'expiryDate',
        Header: 'Expiry date',
        accessor: 'expiryDate',
        Cell: QuestionnaireExpiryDateCell,
      },
    ],
    [locale, t],
  );

  return suppliers.length ? (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={suppliers}
      />
    </StaticTableStyles>
  ) : (
    <PanelPadding>
      <Text color="subtext">No suppliers</Text>
    </PanelPadding>
  );
};
