import * as React from 'react';
import { filter, ListIterator, IterateeShorthand, noop, map,
  castArray, isFunction, iteratee, overSome } from 'lodash';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { callAll } from '@deepstream/utils/callAll';
import { useMutation } from '../../useMutation';
import { useApi, wrap } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useToaster } from '../../toast';
import { Notification } from './types';

type NotificationsContextType = {
  notifications: Notification[];
  refresh: () => void;
};

const NotificationsContext = React.createContext<NotificationsContextType>({
  notifications: [],
  refresh: noop,
});

type NotificationsProviderProps = {
  children: React.ReactNode;
};

export const NotificationsProvider = (props: NotificationsProviderProps) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();

  const { data, refetch } = useQuery({
    queryKey: ['notifications', { companyId: currentCompanyId, filter: { seen: false, deleted: false } }],
    queryFn: wrap(api.getNotifications),
    refetchInterval: 15 * 1000,
    initialData: { notifications: [], totalItems: 0 },
    enabled: Boolean(currentCompanyId),
  });

  const value = React.useMemo(
    () => ({ refresh: refetch, notifications: data.notifications }),
    [refetch, data],
  );

  return (
    <NotificationsContext.Provider value={value} {...props} />
  );
};

type FilterIteratee = ListIterator<Notification, boolean> | IterateeShorthand<Notification>;

export const useNotifications = (filterIteratee?: FilterIteratee) => {
  const { notifications } = React.useContext(NotificationsContext);

  return React.useMemo(
    () => {
      const activeNotifications = notifications.filter(({ seen, deleted }) => !seen && !deleted);

      const filterFnArray = map(
        castArray(filterIteratee),
        currentFilter => isFunction(currentFilter) ? currentFilter : iteratee(currentFilter),
      );

      return filter(
        activeNotifications,
        // TODO: revisit this.
        overSome<any>(filterFnArray),
      );
    },
    [notifications, filterIteratee],
  );
};

export const useReadNotifications = (filterIteratee?: FilterIteratee) => {
  const { notifications } = React.useContext(NotificationsContext);

  return React.useMemo(
    () => {
      if (!notifications) {
        return [];
      }

      const readNotifications = filter(notifications, 'seen');

      const filterFnArray = map(
        castArray(filterIteratee),
        currentFilter => isFunction(currentFilter) ? currentFilter : iteratee(currentFilter),
      );

      return filter(
        readNotifications,
        // TODO: revisit this
        overSome<any>(filterFnArray),
      );
    },
    [notifications, filterIteratee],
  );
};

type NotificationSuccessCallbacks = (() => void)[];

export const useMarkAsReadNotificationsMutation = (onSuccess: NotificationSuccessCallbacks = []) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(({ notificationIds }: { notificationIds: string[] }) => api.markNotificationsAsRead({ notificationIds }), {
    onSuccess: callAll(
      () => queryClient.invalidateQueries('notificationsTable'),
      () => queryClient.invalidateQueries('unreadNotificationsCount'),
      () => queryClient.invalidateQueries('notifications'),
      ...onSuccess,
    ),
  });
};

export const useMarkAsUnreadNotificationsMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(({ notificationIds }: { notificationIds: string[] }) => api.markNotificationsAsUnread({ notificationIds }), {
    onSuccess: callAll(
      () => queryClient.invalidateQueries('notificationsTable'),
      () => queryClient.invalidateQueries('unreadNotificationsCount'),
      () => queryClient.invalidateQueries('notifications'),
    ),
  });
};

export const useDeleteNotificationsMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(({ notificationIds }: { notificationIds: string[] }) => api.deleteNotifications({ notificationIds }), {
    onSuccess: callAll(
      () => queryClient.invalidateQueries('notificationsTable'),
      () => queryClient.invalidateQueries('unreadNotificationsCount'),
      () => queryClient.invalidateQueries('notifications'),
    ),
  });
};

export const useMarkAllNotificationsAsRead = () => {
  const api = useApi();
  const { t } = useTranslation();
  const companyId = useCurrentCompanyId({ required: true });
  const queryClient = useQueryClient();
  const toaster = useToaster();

  return useMutation(
    () => api.markAllNotificationsAsRead({ companyId }),
    {
      onSuccess: callAll(
        () => toaster.success(t('notificationsPage.toaster.markAsReadSuccess')),
        () => queryClient.invalidateQueries('notificationsTable'),
        () => queryClient.invalidateQueries('unreadNotificationsCount'),
        () => queryClient.invalidateQueries('notifications'),
      ),
      onError: () => toaster.error(t('notificationsPage.toaster.markAsReadError')),
    },
  );
};

export const useUnreadNotificationsCount = (): number => {
  const api = useApi();
  const companyId = useCurrentCompanyId({ required: true });

  const { data } = useQuery<{ totalItems: number; notifications: Notification[] }>(
    ['unreadNotificationsCount', { companyId, filter: { seen: false, deleted: false } }],
    wrap(api.getNotifications),
  );

  return data?.totalItems || 0;
};
