import * as React from 'react';
import { useQuery } from 'react-query';
import { Flex } from 'rebass/styled-components';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { ChatbotChatHistory } from '@deepstream/ui/modules/AI/chatbot/ChatbotChat';
import { PropertyList } from '@deepstream/ui/PropertyList';
import { Column } from '@deepstream/ui/modules/Reporting/dashboard';
import { Page } from '../Page';
import { reportsChatbotChatRoute } from '../AppRouting';

export const AdminChatbotChatPage: React.FC = () => {
  const adminApi = useAdminApi();
  const { chatId } = reportsChatbotChatRoute.useParams();

  if (!chatId) {
    throw new Error('A chatId is required');
  }

  const { data: chat, error, status } = useQuery(
    ['chatbotChat', { chatId }],
    wrap(adminApi.getChatbotChat),
  );

  const properties = chat ? [
    { name: 'Company ID', value: chat.company._id },
    { name: 'Company name', value: chat.company.name },
    { name: 'User ID', value: chat.user._id },
    { name: 'User name', value: chat.user.name },
  ] : null;

  return (
    <Page>
      {status === 'loading' ? (
        <Panel padded>
          <Loading />
        </Panel>
      ) : status === 'error' ? (
        <Panel heading="Error" padded>
          <Icon icon="exclamation-circle" mr={2} />{(error as any)?.toString()}
        </Panel>
      ) : chat ? (
        <Flex sx={{ gap: 4 }}>
          <Column width={450}>
            <Panel>
              <PropertyList properties={properties} />
            </Panel>
          </Column>
          <Column flex={1}>
            <Panel padded>
              <ChatbotChatHistory
                history={chat.history}
                userName={chat.user.name}
                showErrorDisclosures={true}
              />
            </Panel>
          </Column>
        </Flex>
      ) : null}
    </Page>
  );
};
