import { useMemo, useState } from 'react';
import { compact } from 'lodash';
import { Box } from 'rebass/styled-components';
import { DEFAULT_ROW_HEIGHT, EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { SortableHeader } from '@deepstream/ui/ui/ExchangeDefsGrid/header';
import { BORDER_ADJUSTMENT, DEFAULT_FROZEN_HEADER_FIRST_LINE_HEIGHT } from '@deepstream/ui-kit/grid/core/constants';
import { getScrollbarSize } from '@deepstream/ui-utils/getScrollbarSize';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { DataCellProps } from '@deepstream/ui-kit/grid/core/utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { TextOrDashCell } from '@deepstream/ui/ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { useSortedGridData } from '@deepstream/ui-kit/grid/EditableGrid/useSortedGridData';
import { SortConfigProvider } from '@deepstream/ui-kit/grid/EditableGrid/sortConfig';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { getCellValue } from '@deepstream/ui-kit/grid/EditableGrid/getCellValue';

const viewportHeightDelta = 270;

const frozenLeftColumnIds = ['name'];

export type FeatureAdoption = {
  _id: string;
  name: string;
  hasAdoptedFeature: boolean;
  details?: string | number;
};

const AdoptionStateCell = ({ row, column }: DataCellProps<EditableGridColumn, FeatureAdoption, never>) => {
  const hasAdoptedFeature = getCellValue(row.original, column.original);

  return (
    <div className="cell-content">
      <Box>
        {hasAdoptedFeature ? (
          <>
            <Icon color="success" icon="circle-check" regular mr={1} />
            Yes
          </>
        ) : (
          <>
            <Icon color="danger" icon="circle-xmark" regular mr={1} />
            No
          </>
        )}
      </Box>
    </div>
  );
};

export const ClientAdoptionGrid = ({
  data,
  adoptedColumnLabel = 'Adopted?',
  detailsColumnLabel,
  hideAdoptedColumn,
}: {
  data: FeatureAdoption[],
  adoptedColumnLabel?: string,
  detailsColumnLabel?: string,
  hideAdoptedColumn?: boolean,
}) => {
  const hasDetails = data.some(({ details }) => details);

  const columns: EditableGridColumn[] = useMemo(() => {
    return compact([
      {
        _id: 'name',
        accessorKey: 'name',
        Header: SortableHeader,
        label: 'Company',
        width: 340,
        sortType: 'caseInsensitive',
        ValueCell: TextOrDashCell,
      },
      !hideAdoptedColumn && {
        _id: 'hasAdoptedFeature',
        accessorKey: 'hasAdoptedFeature',
        Header: SortableHeader,
        label: adoptedColumnLabel,
        width: 127,
        ValueCell: AdoptionStateCell,
      },
      hasDetails && {
        _id: 'details',
        accessorKey: 'details',
        Header: SortableHeader,
        label: detailsColumnLabel,
        width: 127,
        ValueCell: TextOrDashCell,
      },
    ]);
  }, [adoptedColumnLabel, detailsColumnLabel, hideAdoptedColumn, hasDetails]);

  const maxGridHeight = (
    DEFAULT_FROZEN_HEADER_FIRST_LINE_HEIGHT +
    BORDER_ADJUSTMENT +
    DEFAULT_ROW_HEIGHT * data.length +
    getScrollbarSize()
  );

  const [sortConfig, setSortConfig] = useState({
    columnId: null,
    direction: null,
  });

  const sortedData = useSortedGridData(sortConfig, data, columns);

  return (
    <SortConfigProvider sortConfig={sortConfig} setSortConfig={setSortConfig}>
      <GridIdPrefixProvider>
        <DefaultEditableGridStyles
          style={{ width: '100%', height: `min(100vh - ${viewportHeightDelta}px, ${maxGridHeight}px)` }}
          isReadOnly
          highlightOnHover
        >
          <ReadOnlyGrid
            frozenLeftColumnIds={frozenLeftColumnIds}
            columns={columns}
            rowData={sortedData as any}
          />
        </DefaultEditableGridStyles>
      </GridIdPrefixProvider>
    </SortConfigProvider>
  );
};
