import { useRef, useState, useEffect, useCallback } from 'react';
import { GridPasteMethod } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { useModalState } from '../useModalState';

export const useAddGridRowsState = () => {
  const addGridRowsModal = useModalState();

  const resolveWithPasteMethod = useRef<((pasteMethod: GridPasteMethod | null) => void) | null>(null);

  const [additionalRowCount, setAdditionalRowCount] = useState(null);

  useEffect(() => {
    return () => {
      if (resolveWithPasteMethod.current) {
        resolveWithPasteMethod.current(null);
        resolveWithPasteMethod.current = null;
      }
    };
  }, []);

  const selectPasteMethod = useCallback(async (additionalRowCount: number): Promise<GridPasteMethod | null> => {
    addGridRowsModal.open();
    setAdditionalRowCount(additionalRowCount);

    return new Promise(resolve => {
      resolveWithPasteMethod.current = resolve;
    });
  }, [addGridRowsModal, setAdditionalRowCount]);

  return {
    addGridRowsModal,
    additionalRowCount,
    selectPasteMethod,
    resolveWithPasteMethod,
  };
};
