import * as React from 'react';
import { CellProps } from 'react-table';
import { upperFirst } from 'lodash';
import { isAfter, isBefore } from 'date-fns';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { ValueOrDashCell } from '@deepstream/ui/ValueOrDashCell';
import { User } from '@deepstream/ui/types';
import { RightAlignedCell } from '@deepstream/ui/RightAlignedCell';
import { withProps } from '@deepstream/ui-utils/withProps';
import { renderIf } from '@deepstream/ui-utils/renderIf';
import { Claim, Company } from '@deepstream/ui/ui/types';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { CapitalizeCell } from '../../CapitalizeCell';
import { UserLinkCell } from '../../UserLinkCell';
import { UserLink } from '../../UserLink';
import { formatAdminDate } from '../../formatDate';
import { CompanyClaimActionsCell } from './CompanyClaimActionsCell';

export type ClaimWithUser = {
  _id: string;
  claim: Claim;
  user: User;
};

type TableProps = {
  company: Company;
  claimsWithUsers: ClaimWithUser[];
};

const wasUserInitiallyPendingCreation = (user: User) =>
  user.pendingCreation !== undefined;

const wasClaimAddedBeforeSignUp = (claim: Claim, user: User) =>
  !user.pendingCreation
    ? isBefore(new Date(claim.date), new Date(user.signedUpAt))
    : false;

const wasClaimAddedAfterSignUp = (claim: Claim, user: User) =>
  !user.pendingCreation
    ? isAfter(new Date(claim.date), new Date(user.signedUpAt))
    : false;

const getResolutionDate = ({ claim, user }) => {
  if (claim.decisionDate) {
    return claim.decisionDate;
  }

  if (wasUserInitiallyPendingCreation(user) && wasClaimAddedBeforeSignUp(claim, user)) {
    return user.signedUpAt;
  }

  return null;
};

/**
 * Indicates whether the admin shall be able to resolve the claim.
 *
 * Newly invited companies have a special case where they are pre-populated with a claim that
 * is intended to be resolved when the corresponding user signs up. In other words, when a claim
 * is pending and the corresponding user has not signed up, the claim is NOT meant to be resolved
 * by an admin.
 *
 * However, if a previously invited user creates a new company (distinct from their original one)
 * then that company verification is meant to be resolved by an admin. To identify this case, we
 * compare the claim date for the new company against the user's sign up date -- if the claim date
 * comes after we know it's an "organically" created company that requires verification from an app
 * admin.
 */
const canAdminResolveClaim = (props: CellProps<ClaimWithUser>) => {
  const { claim, user } = props.row.original;

  return (
    claim.status === 'pending' &&
    (!wasUserInitiallyPendingCreation(user) || wasClaimAddedAfterSignUp(claim, user))
  );
};

const ResolvedByCell: React.FC<CellProps<ClaimWithUser>> = ({ row, cell }) => {
  const { claim, user } = row.original;

  if (cell.value) {
    return (
      // this is based on the assumption that when there is a claim.decisionBy.name
      // then there is necessarily also a claim.decisionBy._id
      <UserLink userId={claim!.decisionBy!._id!}>
        {cell.value}
      </UserLink>
    );
  }

  if (wasUserInitiallyPendingCreation(user) && wasClaimAddedBeforeSignUp(claim, user)) {
    return <>Sign up</>;
  }

  return <EmDash />;
};

export const CompanyClaimsTable: React.FC<TableProps> = ({ company, claimsWithUsers }) => {
  const { isExtraLarge, isLarge } = useDeviceSize();

  const columns = React.useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'user.name',
        Cell: nestCells(
          TruncateCell,
          withProps(
            UserLinkCell,
            {
              getUserId: ({ claim }: ClaimWithUser) => claim.userId,
            },
          ),
        ),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Status',
        accessor: 'claim.status',
        Cell: CapitalizeCell,
        Filter: withProps(FilterSelect, { itemToString: upperFirst }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        Header: 'Added',
        accessor: 'claim.date',
        Cell: DatetimeCell,
        sort: 'datetime',
        width: isExtraLarge ? 224 : 164,
        Filter: withProps(FilterSelect, { itemToString: formatAdminDate, transformSelectedValues: formatAdminDate }),
        filter: filterMultipleValues,
      },
      {
        id: 'resolvedBy',
        Header: 'Resolved by',
        accessor: 'claim.decisionBy.name',
        Cell: nestCells(TruncateCell, ResolvedByCell),
        sortType: 'caseInsensitive',
      },
      {
        id: 'resolved',
        Header: 'Resolved',
        accessor: getResolutionDate,
        Cell: DatetimeCell,
        sort: 'datetime',
        width: isExtraLarge ? 224 : 164,
        Filter: withProps(FilterSelect, { itemToString: formatAdminDate, transformSelectedValues: formatAdminDate }),
        filter: filterMultipleValues,
      },
      {
        id: 'tag',
        Header: 'Tag',
        accessor: 'claim.autogenerated.tag',
        Cell: ValueOrDashCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'resolveClaimActions',
        Cell: nestCells(
          RightAlignedCell,
          renderIf(
            canAdminResolveClaim,
            withProps(
              CompanyClaimActionsCell,
              { company },
            ),
          ),
        ),
        width: 130,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [company, isExtraLarge],
  );

  return (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={claimsWithUsers}
        hiddenColumns={isExtraLarge ? (
          []
        ) : isLarge ? (
          ['tag']
        ) : (
          ['resolvedBy', 'resolved', 'tag']
        )}
        isSortable
        noFilteredDataPlaceholder="No emails match chosen filters"
      />
    </StaticTableStyles>
  );
};
