import { TFunction } from 'i18next';
import { documentExchangeTypes, exchangesConfig, informationRequestExchangeTypes } from '../exchangesConfig';
import {
  AnyScope,
  AuctionInformationExchangeDefinition,
  AuctionLineItemExchangeDefinition,
  AuctionTermsExchangeDefinition,
  CurrencyExchangeDefinition,
  ExchangeDefinition,
  ExchangeType,
  FeesExchangeDefinition,
  HirePeriodExchangeDefinition,
  InclusionsExchangeDefinition,
  LineItemExchangeDefinition,
  LinkedAuctionLineItemExchangeDefinition,
  Live,
  StandaloneAuctionLineItemExchangeDefinition,
  TermsExchangeDefinition,
  Lock,
  DocumentExchangeDefinition,
  LinkedEvaluationCriterionExchangeDefinition,
} from './types';

export const isLineItemExchangeDef = (exchangeDef: ExchangeDefinition): exchangeDef is LineItemExchangeDefinition =>
  exchangeDef.type === ExchangeType.LINE_ITEM;
export const isCurrencyExchangeDef = (exchangeDef: ExchangeDefinition<AnyScope>): exchangeDef is CurrencyExchangeDefinition =>
  exchangeDef.type === ExchangeType.CURRENCY;
export const isInclusionExchangeDef = (exchangeDef: ExchangeDefinition): exchangeDef is InclusionsExchangeDefinition =>
  exchangeDef.type === ExchangeType.INCLUSIONS;
export const isTermExchangeDef = (exchangeDef: ExchangeDefinition): exchangeDef is TermsExchangeDefinition =>
  exchangeDef.type === ExchangeType.TERMS;
export const isHirePeriodExchangeDef = (exchangeDef: ExchangeDefinition): exchangeDef is HirePeriodExchangeDefinition =>
  exchangeDef.type === ExchangeType.HIRE_PERIOD;
export const isFeeExchangeDef = (exchangeDef: ExchangeDefinition): exchangeDef is FeesExchangeDefinition =>
  exchangeDef.type === ExchangeType.FEES;
export const isAuctionTermsExchangeDef = (exchangeDef: ExchangeDefinition):
  exchangeDef is AuctionTermsExchangeDefinition =>
  exchangeDef.type === ExchangeType.AUCTION_TERMS;
export const isAuctionInformationExchangeDef = (exchangeDef: ExchangeDefinition):
  exchangeDef is AuctionInformationExchangeDefinition =>
  exchangeDef.type === ExchangeType.AUCTION_INFORMATION;
export const isAuctionLineItemExchangeDef = <Scope extends AnyScope = Live>(exchangeDef: ExchangeDefinition<Scope>):
  exchangeDef is AuctionLineItemExchangeDefinition<Scope> =>
  exchangeDef.type === ExchangeType.AUCTION_LINE_ITEM;
export const isLinkedAuctionLineItemExchangeDef = (exchangeDef: ExchangeDefinition<AnyScope>):
  exchangeDef is LinkedAuctionLineItemExchangeDefinition => (
    exchangeDef.type === ExchangeType.AUCTION_LINE_ITEM &&
    'linkedExchangeDefId' in exchangeDef
  );
export const isStandaloneAuctionLineItemExchangeDef = (exchangeDef: ExchangeDefinition<AnyScope>):
  exchangeDef is StandaloneAuctionLineItemExchangeDefinition => (
    exchangeDef.type === ExchangeType.AUCTION_LINE_ITEM &&
    !('linkedExchangeDefId' in exchangeDef)
  );
export const isLinkedEvaluationCriterionExchangeDef = (exchangeDef: ExchangeDefinition<AnyScope>):
  exchangeDef is LinkedEvaluationCriterionExchangeDefinition => (
    exchangeDef.type === ExchangeType.EVALUATION_CRITERION &&
    'linkedExchangeDefId' in exchangeDef
  );
export const isDocumentExchangeDef = (exchangeDef: ExchangeDefinition):
  exchangeDef is DocumentExchangeDefinition =>
  documentExchangeTypes.includes(exchangeDef.type);

export const getAuctionLineItemExchangeDef = (
  exchangeDef: AuctionLineItemExchangeDefinition,
  exchangeDefById: Record<ExchangeDefinition['_id'], ExchangeDefinition>,
) => {
  return isLinkedAuctionLineItemExchangeDef(exchangeDef)
    ? exchangeDefById[exchangeDef.linkedExchangeDefId] as LineItemExchangeDefinition
    : exchangeDef as StandaloneAuctionLineItemExchangeDefinition;
};

export const getDisplayName = (exchangeDef: ExchangeDefinition, t: TFunction) => {
  const config = exchangesConfig[exchangeDef.type];

  const nameField = config.fields.find(field => field.isDisplayName);

  return nameField
    ? exchangeDef[nameField.key]
    : t(`request.exchangeType.${exchangeDef.type}`);
};

export const getProvidedName = (exchangeDef: ExchangeDefinition) => {
  const config = exchangesConfig[exchangeDef.type];

  const nameField = config.fields.find(field => field.isDisplayName);

  return nameField
    ? exchangeDef[nameField.key]
    : undefined;
};

// Order is important for UI purpose
export enum Requirement {
  ACCEPT = 'accept',
  ACCEPT_OR_DEVIATE = 'acceptOrDeviate',
  COMPLETE = 'complete',
  COMPLETE_OR_DEVIATE = 'completeOrDeviate',
  REQUEST = 'request',
  INFORMATION = 'information',
}

export const getRequirementFromExchangeType = ({ type }: { type: ExchangeType }) => {
  if (!type) return;

  switch (type) {
    case ExchangeType.ACCEPT_CLOSED:
      return Requirement.ACCEPT;
    case ExchangeType.ACCEPT:
      return Requirement.ACCEPT_OR_DEVIATE;
    case ExchangeType.COMPLETE_OR_SIGN:
      return Requirement.COMPLETE_OR_DEVIATE;
    case ExchangeType.COMPLETE_OR_SIGN_CLOSED:
    case ExchangeType.COMPLETE_OR_SIGN_LOCKED:
      return Requirement.COMPLETE;
    case ExchangeType.INFORMATION:
      return Requirement.INFORMATION;
    case ExchangeType.DOCUMENT_REQUEST:
    case ExchangeType.DOCUMENT_REQUEST_LOCKED:
    case ExchangeType.DOCUMENT_REQUEST_CLOSED: // @deprecated
      return Requirement.REQUEST;
    default:
      throw new Error(`Cannot map exchange type to requirement: ${type}`);
  }
};

export const isDocumentRequestExchange = (exchangeType: ExchangeType) => informationRequestExchangeTypes.includes(exchangeType);

// Exchange types that have locks or can be converted to an exchange type with locks
export const lockableExchangeTypes = [
  ExchangeType.DOCUMENT_REQUEST,
  ExchangeType.DOCUMENT_REQUEST_CLOSED,
  ExchangeType.DOCUMENT_REQUEST_LOCKED,
  ExchangeType.COMPLETE_OR_SIGN_CLOSED,
  ExchangeType.COMPLETE_OR_SIGN_LOCKED,
];

export const getExchangeTypeFromRequirement = (requirement: Requirement, lock: Lock) => {
  switch (requirement) {
    case Requirement.ACCEPT:
      return ExchangeType.ACCEPT_CLOSED;
    case Requirement.ACCEPT_OR_DEVIATE:
      return ExchangeType.ACCEPT;
    case Requirement.COMPLETE:
      return lock
        ? ExchangeType.COMPLETE_OR_SIGN_LOCKED
        : ExchangeType.COMPLETE_OR_SIGN_CLOSED;
    case Requirement.COMPLETE_OR_DEVIATE:
      return ExchangeType.COMPLETE_OR_SIGN;
    case Requirement.INFORMATION:
      return ExchangeType.INFORMATION;
    case Requirement.REQUEST:
      return lock
        ? ExchangeType.DOCUMENT_REQUEST_LOCKED
        : ExchangeType.DOCUMENT_REQUEST;
    default:
      throw new Error(`Cannot map document requirement to exchange type: ${requirement}`);
  }
};

export const getExchangeTypeFromLock = (currentType: ExchangeType, lock: Lock | null) => {
  switch (currentType) {
    case ExchangeType.DOCUMENT_REQUEST:
    case ExchangeType.DOCUMENT_REQUEST_LOCKED:
      return lock
        ? ExchangeType.DOCUMENT_REQUEST_LOCKED
        : ExchangeType.DOCUMENT_REQUEST;
    case ExchangeType.DOCUMENT_REQUEST_CLOSED: // @deprecated
      return lock
        ? ExchangeType.DOCUMENT_REQUEST_LOCKED
        : ExchangeType.DOCUMENT_REQUEST_CLOSED;
    case ExchangeType.COMPLETE_OR_SIGN_CLOSED:
    case ExchangeType.COMPLETE_OR_SIGN_LOCKED:
      return lock
        ? ExchangeType.COMPLETE_OR_SIGN_LOCKED
        : ExchangeType.COMPLETE_OR_SIGN_CLOSED;
    default:
      throw new Error(`Cannot derive new exchange type from ${currentType}`);
  }
};
