import * as React from 'react';
import styled from 'styled-components';
import { Flex, Text } from 'rebass/styled-components';
import { capitalize } from 'lodash';
import { ToggleAllButton } from './ToggleAllButton';

const SubCategory = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`;

type Level2TagProps = {
  tagId: string;
  name: string;
  isCaseSensitive?: boolean;
  textFilter?: string;
  selectAll: (tagId: string) => void;
  deselectAll: (tagId: string) => void;
  areAllChildrenSelected: (tagId: string) => boolean;
};

const Level2Tag: React.FC<Level2TagProps> = ({
  children,
  tagId,
  name,
  isCaseSensitive,
  textFilter,
  selectAll,
  deselectAll,
  areAllChildrenSelected,
}) => (
  <div style={{ marginBottom: 24 }}>
    {name && (
      <Flex marginBottom={12}>
        <SubCategory>{isCaseSensitive ? name : capitalize(name)}</SubCategory>
        {!textFilter && (
          <ToggleAllButton
            tagId={tagId}
            areAllChildrenSelected={areAllChildrenSelected}
            selectAll={selectAll}
            deselectAll={deselectAll}
            ml={2}
            style={{ flexShrink: 0 }}
          />
        )}
      </Flex>
    )}
    {children}
  </div>
);

export default Level2Tag;
