import { useMemo } from 'react';

import { IntegrationTableStyle } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Datetime2Cell } from '@deepstream/ui/DatetimeCell';
import { SimpleUserCell } from '@deepstream/ui/SimpleUserCell';
import { CompanyMinimizedNameAndLogoCell } from '@deepstream/ui/CompanyMinimizedNameAndLogoCell';
import { withProps } from '@deepstream/ui-utils/withProps';

import { DateFormat } from '@deepstream/utils';
import { YesNoCell } from '../YesNoCell';
import { INTEGRATION_TABLE_HEIGHT, INTEGRATION_TABLE_WIDTH } from './constants';

export const PageOpenedUsersTable = ({
  uniqueUsers,
}) => {
  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        id: 'date',
        Header: 'Date / Time',
        accessor: 'date',
        Cell: Datetime2Cell,
        format: DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ,
        sort: 'datetime',
        width: 230,
      },
      {
        Header: 'User',
        accessor: 'name',
        Cell: SimpleUserCell,
      },
      {
        Header: 'Company',
        accessor: 'company.name',
        Cell: withProps(CompanyMinimizedNameAndLogoCell, { hasLink: false, companyIdField: 'companyId' }),
      },
      {
        Header: 'Proceeded?',
        accessor: 'proceeded',
        sortType: 'boolean',
        Cell: YesNoCell,
      },
    ],
    [],
  );

  return (
    <IntegrationTableStyle
      bordered
      headerBackgroundColor={theme.colors.lightGray3}
      hoverCursor="default"
      stickyHeader
      height={INTEGRATION_TABLE_HEIGHT}
      width={INTEGRATION_TABLE_WIDTH}
    >
      <Table
        columns={columns}
        data={uniqueUsers}
        isSortable
        initialSortBy={[{ id: 'date', desc: true }]}
      />
    </IntegrationTableStyle>
  );
};
