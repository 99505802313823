export const APP_ADMIN_COMPANY_ID = '_app';

// Navbar
export const NAVBAR_HEIGHT = 60;
export const BANNER_HEIGHT = 40;

// Page header
export const SMALL_PAGE_HEADER_HEIGHT = 56;
export const LARGE_PAGE_HEADER_HEIGHT = 72;

// Page header tabs
export const PAGE_TABS_HEIGHT = 34;

export const DEFAULT_TABLE_PAGE_SIZES = [5, 10, 20, 50, 100];
