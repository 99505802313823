export enum CsvSeparator {
  COMMA = 'comma',
  SEMICOLON = 'semicolon',
  TAB = 'tab',
}

export enum EmailNotificationCategory {
  ACCOUNT = 'account',
  COMPANY = 'company',
  CONTRACTS = 'contracts',
  FEEDBACK = 'feedback',
  PRE_QUALIFICATION = 'preQualification',
  REQUESTS = 'requests',
  SUPPORT = 'support',
}

export enum EmailNotificationOption {
  ALL = 'all',
  NONE = 'none',
}

export enum MembershipDecision {
  ACCEPT = 'accept',
  REJECT = 'reject',
}

export type EmailNotificationMap = {
  [EmailNotificationCategory.ACCOUNT]: EmailNotificationOption;
  [EmailNotificationCategory.COMPANY]: EmailNotificationOption;
  [EmailNotificationCategory.CONTRACTS]: EmailNotificationOption;
  [EmailNotificationCategory.FEEDBACK]: EmailNotificationOption;
  [EmailNotificationCategory.PRE_QUALIFICATION]: EmailNotificationOption;
  [EmailNotificationCategory.REQUESTS]: EmailNotificationOption;
  [EmailNotificationCategory.SUPPORT]: EmailNotificationOption;
};

export enum RoleRestrictionProfile {
  SUPER_USER = 'superUser',
  FULL_USER = 'fullUser',
  AUTHORIZED_STAKEHOLDER = 'authorizedStakeholder',
}

/**
 * Holds information about a user in relation to a request/contract/questionnaire
 * that he is a member of.
 */
export type EntityTeamMemberOverview = {
  _id: string; // Entity ID (ie request ID, contract ID or questionnaire ID)
  type: 'request' | 'contract' | 'questionnaire';
  subject?: string;
  isSender: boolean;
  isOwner: boolean;
  isOnlyOwner: boolean;
  fromQuestionnaireTemplateId?: string; // Only used for questionnaires
};

export const isOnlyRequestOwner = ({ type, isOnlyOwner }: EntityTeamMemberOverview) => (
  isOnlyOwner && type === 'request'
);

export const isOnlyContractOwner = ({ type, isOnlyOwner }: EntityTeamMemberOverview) => (
  isOnlyOwner && type === 'contract'
);

export const isOnlyQuestionnaireOwner = ({ type, isOnlyOwner }: EntityTeamMemberOverview) => (
  isOnlyOwner && type === 'questionnaire'
);

export const isEntityOwner = ({ isOwner }: EntityTeamMemberOverview) => isOwner;

export const getDelimiter = (csvSeparator: string | CsvSeparator) => {
  switch (csvSeparator) {
    case CsvSeparator.COMMA:
      return ',';
    case CsvSeparator.SEMICOLON:
      return ';';
    case CsvSeparator.TAB:
      return '\t';
    default:
      throw Error(`CSV separator not supported: ${csvSeparator}`);
  }
};

export interface Roles {
  admin: boolean;
  editor: boolean;
  managePreQual: boolean;
  sendQuestionnaires: boolean;
  receiveQuestionnaires: boolean;
  sendRFQ: boolean;
  receiveRFQ: boolean;
  sendContracts: boolean;
  receiveContracts: boolean;
  accessReportingForRequests: boolean;
  accessReportingForContracts: boolean;
}
