import { useMemo } from 'react';

import { IntegrationTableStyle } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { TextCell } from '@deepstream/ui/TextCell';
import { Datetime2Cell } from '@deepstream/ui/DatetimeCell';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';

import { DateFormat } from '@deepstream/utils';
import { YesNoCell } from '../YesNoCell';
import { INTEGRATION_TABLE_HEIGHT, INTEGRATION_TABLE_WIDTH } from './constants';

export const LatestIntegrationExecutionsTable = ({
  integrationExecutions,
}) => {
  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        Header: 'Company',
        accessor: 'companyName',
        Cell: TextCell,
      },
      {
        Header: 'Integration',
        accessor: 'instance',
        Cell: TextCell,
        id: 'instance',
      },
      {
        Header: 'Date / Time',
        Cell: Datetime2Cell,
        id: 'lastExecutedAt',
        accessor: 'lastExecutedAt',
        format: DateFormat.DD_MMM_YYYY,
        sort: 'datetime',
        width: 230,
      },
      {
        Header: 'Is successful?',
        accessor: 'isSuccessful',
        sortType: 'boolean',
        Cell: ({ cell, value }) => {
          if (value === null) {
            return (
              <EmDash />
            );
          }

          return (
            <YesNoCell cell={cell} />
          );
        },
      },
    ],
    [],
  );

  return (
    <IntegrationTableStyle
      bordered
      headerBackgroundColor={theme.colors.lightGray3}
      hoverCursor="default"
      stickyHeader
      height={INTEGRATION_TABLE_HEIGHT}
      width={INTEGRATION_TABLE_WIDTH}
    >
      <Table
        columns={columns}
        data={integrationExecutions}
        isSortable
        initialSortBy={[{ id: 'date', desc: true }]}
      />
    </IntegrationTableStyle>
  );
};
