import * as React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { first } from 'lodash';
import { ProductTag } from '@deepstream/common/products';
import { Button, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalHeader, ModalBody, ModalFooter, ModalProps, Modal } from '@deepstream/ui-kit/elements/popup/Modal';
import { useProducts } from '../../useProducts';
import { FilteredProductsPanel } from '../../FilteredProductsList';

type EditUnspscCodeModalProps = ModalProps &
  {
    onClose: any;
    onSubmit: (product: ProductTag | null) => void;
    unspscCode: string | null;
  };

export const EditUnspscCodeModal: React.FC<EditUnspscCodeModalProps> = ({
  onClose,
  onSubmit,
  unspscCode,
  ...props
}) => {
  const { t } = useTranslation();

  const initialProductIds = React.useMemo(
    () => unspscCode ? [unspscCode] : [],
    [unspscCode],
  );

  const {
    state,
    actions,
    showSearchResults,
    products,
    isProductsLoading,
    isProductsError,
    isSearching,
    isSearchError,
    refetchProducts,
    refetchSearch,
  } = useProducts({
    selectedProductIds: initialProductIds,
    multiSelect: false,
  });

  const { productById, selectedProductIds, searchResultIds } = state;

  const handleSubmitClick = React.useCallback(() => {
    const selectedProductId = first(selectedProductIds);

    onSubmit(productById[selectedProductId] || null);
  }, [onSubmit, productById, selectedProductIds]);

  return (
    <Modal
      onRequestClose={onClose}
      style={{ content: { width: '900px' } }}
      {...props}
    >
      <ModalHeader onClose={onClose}>
        {t('productsAndServices.productsAndServices')}
      </ModalHeader>
      <ModalBody>
        <MessageBlock variant="warn" mb={3}>
          {t('request.lineItems.youCanOnlySelectOneTag')}
        </MessageBlock>
        <Flex alignItems="center" justifyContent="space-between" height="40px" mb="12px">
          <Text>
            {!isProductsLoading && (
              t('general.tagCount', { count: showSearchResults ? searchResultIds.length : products.length })
            )}
          </Text>
          {Boolean(selectedProductIds.length) && (
            <Button
              small
              type="button"
              variant="secondary-outline"
              iconLeft="times"
              onClick={actions.deselectAll}
            >
              {t('general.deselect')}
            </Button>
          )}
        </Flex>
        <FilteredProductsPanel
          productsState={state}
          productsActions={actions}
          showSearchResults={showSearchResults}
          products={products}
          isProductsLoading={isProductsLoading}
          isProductsError={isProductsError}
          isSearching={isSearching}
          isSearchError={isSearchError}
          refetchProducts={refetchProducts}
          refetchSearch={refetchSearch}
        />
      </ModalBody>
      <ModalFooter justifyContent="flex-end">
        <CancelButton onClick={onClose} mr={2} />
        <Button
          variant="primary"
          type="button"
          onClick={handleSubmitClick}
        >
          {t('general.submit')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
