import * as React from 'react';
import { CountriesProvider } from './ui/countries';
import { useApi } from './api';

export const CountriesProviderWrapper: React.FC = (props) => {
  const api = useApi();

  const getCountryCodes = React.useCallback(
    () => api.getCountryCodes(),
    [api],
  );

  return (
    <CountriesProvider getCountryCodes={getCountryCodes} {...props} />
  );
};
