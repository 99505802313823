import * as React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { TextField } from './form/TextField';
import { useApi } from './api';
import { useToaster } from './toast';
import { useMutation } from './useMutation';
import { useCurrentCompanyId } from './currentCompanyId';

const useAddSupplierList = () => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  return useMutation(
    api.addSupplierList,
    {
      onSuccess: () => toaster.success(t('network.toaster.addSupplierListSuccess')),
      onError: () => toaster.error(t('network.toaster.addSupplierListError')),
      onSettled: () => queryClient.invalidateQueries(['supplierLists', { companyId: currentCompanyId }]),
    },
  );
};

type Props =
  { supplierLists: any[]; onCancel: any; onSave: any } &
  ModalProps;

export const AddSupplierListModal: React.FC<Props> = ({ supplierLists, onCancel, onSave, ...props }) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [addSupplierList] = useAddSupplierList();

  const validate = React.useCallback(
    (values) => {
      const listNames = supplierLists.map(list => list.name.trim().toLowerCase());

      if (listNames.includes(values.name.trim().toLowerCase())) {
        return {
          name: t('network.errors.listAlreadyExists'),
        };
      }

      return {};
    },
    [supplierLists, t],
  );

  return (
    <Modal onRequestClose={onCancel} {...props}>
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={
          yup.object().shape({
            name: yup.string().trim().required(t('general.required')),
          })
        }
        onSubmit={({ name }) =>
          addSupplierList({
            companyId: currentCompanyId,
            name: name.trim(),
          }, {
            onSuccess: onSave,
          })
        }
        validate={validate}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {t('network.dialog.addSupplierList.heading')}
            </ModalHeader>
            <ModalBody>
              <TextField name="name" label={t('network.dialog.addSupplierList.fields.name')} required />
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <Button type="submit" disabled={isSubmitting || !dirty || !isValid} width={70}>
                {t('general.add')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
