import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { companyRoute } from './AppRouting';
import { AdminTableLink, AdminTableLinkProps } from './AdminTableLink';

type CompanyLinkProps =
  Omit<AdminTableLinkProps, 'to' | 'params'> &
  { companyId: string };

export const CompanyLink = ({ companyId, ...props }: CompanyLinkProps) => (
  <AdminTableLink
    to={companyRoute.to}
    params={{ companyId }}
    onClick={stopPropagation}
    {...props}
  />
);
