import * as React from 'react';
import {
  assign,
  filter,
  find,
  findIndex,
  findLast,
  first,
  identity,
  keyBy,
  noop,
  slice,
  map,
  uniq,
  includes,
  mapValues,
  toArray,
} from 'lodash';
import {
  CollaboratorInviteStatus,
  ExchangeDefinition,
  Page,
  ExchangeType,
  SectionType,
  isLineItemExchangeDef,
  ActionType,
  PageRole,
  getPagePermissions,
} from '@deepstream/common/rfq-utils';
import { MutateOptions } from 'react-query';
import { Contract, ContractPage, ContractSection, ContractStatus, Milestone, REMINDERS_PAGE_ID, SUMMARY_PAGE_ID } from '@deepstream/common/contract/contract';
import { isCompanySuperUser } from '@deepstream/common/user-utils';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { ExchangeSnapshot, ApprovalExchangeSnapshot } from '../../types';
import { useCurrentUser } from '../../useCurrentUser';
import { useSendContractExchangeReply } from './useSendContractExchangeReply';
import { SendExchangeReplyPayload } from '../../ExchangeModal/useSendExchangeReply';
import { useExchange } from '../../useExchange';

const ContractIdContext = React.createContext<string | null>(null);
const ContractContext = React.createContext<Contract | null>(null);
const ContractPageContext = React.createContext<Page | null>(null);
const ContractSectionContext = React.createContext<ContractSection | null>(null);
const ContractSectionsContext = React.createContext<ContractSection[] | null>(null);

export const ContractIdProvider: React.FC<{ contractId: string }> = ({ contractId, ...props }) => (
  <ContractIdContext.Provider value={contractId} {...props} />
);

export const ContractProvider = React.memo<{ contract: Contract; children: React.ReactNode }>(({ contract, ...props }) => (
  <ContractContext.Provider value={contract} {...props} />
));

export const ContractPageProvider = React.memo<{ page: Page; children: React.ReactNode }>(({ page, ...props }) => (
  <ContractPageContext.Provider value={page} {...props} />
));

export const ContractSectionProvider = React.memo<{
  section: ContractSection | null;
  children: React.ReactNode }
>(({ section, ...props }) => (
  <ContractSectionContext.Provider value={section} {...props} />
));

export const ContractSectionsProvider = React.memo<{
  sections: ContractSection[];
  children: React.ReactNode }
>(({ sections, ...props }) => (
  <ContractSectionsContext.Provider value={sections} {...props} />
));

/**
 * Define all possible variations so that we get static AND runtime guarantees
 */
 export function useContractId(a: { required: true }): string;
 export function useContractId(): string;
 export function useContractId(a: { required: false }): string | null;
 export function useContractId({ required = true } = {}) {
   const contractId = React.useContext(ContractIdContext);

   if (required && !contractId) {
     throw new Error('A `contractId` has not been set via the `ContractIdProvider` component');
   }

   return contractId;
 }

export const useContractData = ({ required = true } = {}) => {
  const contract = React.useContext<Contract | null>(ContractContext);
  if (required && !contract) throw new Error('No contract context found');
  return contract;
};

export const useContractPage = () => {
  const page = React.useContext<Page | null>(ContractPageContext);
  if (!page) throw new Error('No page found');
  return page;
};

export function useContractSection<TSection extends ContractSection>(): TSection {
  const section = React.useContext(ContractSectionContext);
  if (!section) throw new Error('No section found');
  return section as TSection;
}

export function useContractSections<TSection extends ContractSection>(): TSection[] {
  const sections = React.useContext(ContractSectionsContext);
  if (!sections) throw new Error('No section found');
  return sections as TSection[];
}

/*
 * Contexts and hooks for managing a contract's state.
 */

type ContractStateContextType = {
  isLive: boolean;
  isDraft: boolean;
  isRevising: boolean;
  isAmending: boolean;
  isReview: boolean;
  isTemplate: boolean;
  isTemplatePreview: boolean;
  editingPanelId: string | null;
};

const ContractStateContext = React.createContext<ContractStateContextType | null>(null);

type ContractActionsContextType = {
  startEditing: (panelId: string) => void;
  stopEditing: () => void;
};

const ContractActionsContext = React.createContext<ContractActionsContextType | null>(null);

export const ContractStateProvider = ({
  isLive = false,
  isRevising = false,
  isAmending = false,
  isReview = false,
  isTemplate = false,
  isTemplatePreview = false,
  children,
}: {
  isLive?: boolean;
  isRevising?: boolean;
  isAmending?: boolean;
  isReview?: boolean;
  isTemplate?: boolean;
  isTemplatePreview?: boolean;
  children: React.ReactNode;
}) => {
  const [editingPanelId, setEditingPanelId] = React.useState<string | null>(null);

  const state = React.useMemo(
    () => ({
      isLive,
      isDraft: !isLive,
      isRevising,
      isAmending,
      isReview,
      isTemplate,
      isTemplatePreview,
      editingPanelId,
    }),
    [isLive, isRevising, isAmending, isReview, isTemplate, isTemplatePreview, editingPanelId],
  );

  const actions = React.useMemo(
    () => ({
      startEditing: (panelId: string) => {
        setEditingPanelId(panelId);
      },
      stopEditing: () => {
        setEditingPanelId(null);
      },
    }),
    [],
  );

  return (
    <ContractStateContext.Provider value={state}>
      <ContractActionsContext.Provider value={actions}>
        {children}
      </ContractActionsContext.Provider>
    </ContractStateContext.Provider>
  );
};

export function useContractState(): ContractStateContextType | undefined;
export function useContractState(a: { required: true }): ContractStateContextType;
export function useContractState(a: { required: false }): ContractStateContextType | undefined;
export function useContractState({ required = true } = {}) {
  const state = React.useContext(ContractStateContext);

  if (required && !state) throw new Error('No contract state found');

  if (!state) return {};

  const { isLive, isDraft, isRevising, isAmending, isReview, editingPanelId } = state;

  if (isLive && (isDraft || isRevising || isAmending || isReview || (editingPanelId && editingPanelId !== 'reminders'))) {
    throw new Error('Invalid state for a live contract');
  }

  return state;
}

export const useContractActions = () => {
  const actions = React.useContext(ContractActionsContext);

  if (!actions) throw new Error('No contract actions found');

  return actions;
};

export const useSenders = () => {
  const { senders } = useContractData();

  return senders;
};

export const useIsSuperUserOrOwner = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);
  const { teamById } = useContractData();

  return isSuperUser || teamById[currentCompanyId].owners.includes(currentUser._id);
};

export const useIsLinkedToRequest = () => {
  const { requestId, recipients } = useContractData();

  return Boolean(requestId && first(recipients));
};

export const useIsSender = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { senders } = useContractData();

  return Boolean(find(senders, sender => sender._id === currentCompanyId));
};

export const useIsRecipient = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { recipients } = useContractData();

  return recipients[0]?._id === currentCompanyId;
};

export const useIsPendingCollaborator = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const senders = useSenders();
  const sender = find(senders, { _id: currentCompanyId });

  return sender?.inviteStatus === CollaboratorInviteStatus.PENDING;
};

export const useVisiblePages = () => {
  const { pages } = useContractData();
  const pageRoles = usePagesPermissions();

  return pages.filter(page => pageRoles[page._id].canRead);
};

export const getSectionExchangeDefs = (section: ContractSection, contract: Contract) =>
  section.exchangeDefIds.map(exchangeDefId => contract.exchangeDefById[exchangeDefId]);

export const useSectionExchangeDefs = () => {
  const contract = useContractData();
  const section = useContractSection();

  return React.useMemo(
    () => getSectionExchangeDefs(section, contract),
    [section, contract],
  );
};

export const useExchanges = () => {
  const contract = useContractData();

  return React.useMemo(() => {
    // Map to array to be consistent with rfx exchanges
    return toArray(contract.exchangeById) as ExchangeSnapshot[];
  }, [contract.exchangeById]);
};

export const useOtherSectionLineItemFields = () => {
  const contract = useContractData();
  const section = useContractSection();

  return React.useMemo(() => {
    const sectionNamesByFieldId : Record<string, string[]> = {};
    const sectionFields = Object.values(contract.sectionById)
      .filter(({ _id, type }) => type === SectionType.LINE_ITEMS && _id !== section._id)
      .flatMap(section => {
        const sectionExchangeDefs = getSectionExchangeDefs(section, contract);
        const firstLineItemExchangeDef = sectionExchangeDefs.find(isLineItemExchangeDef as any);
        const fields = firstLineItemExchangeDef ? [firstLineItemExchangeDef.fields] : [];

        for (const field of fields) {
          Object.keys(field).forEach(key => {
            const fieldId = key;
            if (sectionNamesByFieldId[fieldId]) {
              sectionNamesByFieldId[fieldId].push(section.name);
            } else {
              sectionNamesByFieldId[fieldId] = [section.name];
            }
          });
        }

        return fields;
      });

    const sectionFieldsWithNames = sectionFields.map((field) => {
      return assign(
        {},
        ...Object.entries(field).map(([key, value]) => {
          return {
            [key]: {
              ...value,
              sectionNames: sectionNamesByFieldId[key],
            },
          };
        }),
      );
    });

    return assign({}, ...sectionFieldsWithNames);
  }, [contract, section._id]);
};

export const useGeneralPages = () => {
  const contract = useContractData();

  return React.useMemo(
    () => filter(
      contract.pages,
      page => !page.type,
    ),
    [contract],
  );
};

export const useTeam = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { teamById } = useContractData();

  return React.useMemo(
    () => teamById[currentCompanyId],
    [teamById, currentCompanyId],
  );
};

export const useContractPermissions = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);
  const team = useTeam();

  return React.useMemo(() => {
    const isSuperUserOrOwner = (
      isSuperUser ||
      team.owners.includes(currentUser._id)
    );

    return {
      canPublish: isSuperUserOrOwner,
      canApprovePage: isSuperUserOrOwner,
      canManagePages: isSuperUserOrOwner,
      canEditTeam: isSuperUserOrOwner,
      canCounterSign: isSuperUserOrOwner,
      canTerminate: isSuperUserOrOwner,
      canMarkAsFailed: isSuperUserOrOwner,
      canDeleteContract: isSuperUserOrOwner,
      canDownloadReports: isSuperUserOrOwner,
    };
  }, [currentUser, team.owners, isSuperUser]);
};

export const usePagesPermissions = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const { pageById } = useContractData();
  const { isTemplate } = useContractState();
  const team = useTeam();
  const user = team.users[currentUser._id];

  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);
  // Templates should be editable by all members of a company but the template
  // structure doesn't contain ownership / team membership information in
  // `teamById` which we use to determine page permissions
  const allowEdit = isSuperUser || isTemplate;

  return React.useMemo(() => ({
      // Mock summary and reminders pages
      [SUMMARY_PAGE_ID]: getPagePermissions(allowEdit ? PageRole.EDITOR : user.contractRoles[SUMMARY_PAGE_ID]),
      [REMINDERS_PAGE_ID]: getPagePermissions(allowEdit ? PageRole.EDITOR : user.contractRoles[REMINDERS_PAGE_ID]),
      ...mapValues(
        pageById,
        page => getPagePermissions(allowEdit ? PageRole.EDITOR : user.contractRoles[page._id]),
      ),
    }),
    [allowEdit, user?.contractRoles, pageById],
  );
};

export const usePagePermissions = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const team = useTeam();
  const page = useContractPage();
  const { isTemplate } = useContractState();

  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);

  return React.useMemo(
    () => {
      if (isSuperUser || isTemplate) {
        return getPagePermissions(PageRole.EDITOR);
      } else if (!team.users[currentUser._id]) {
        return getPagePermissions(PageRole.NONE);
      } else {
        const user = team.users[currentUser._id];
        const pageRole = user.contractRoles[page._id];
        return getPagePermissions(pageRole);
      }
    },
    [isSuperUser, isTemplate, team.users, currentUser._id, page._id],
  );
};

export const useNextVisiblePage = (pageId: string): ContractPage | undefined => {
  const { pages } = useContractData();

  return React.useMemo(
    () => {
      const pageIndex = findIndex(pages, page => page._id === pageId);

      const nextPages = slice(pages, pageIndex + 1);

      // TODO Permissions
      return find(nextPages, page => !page.type);
    },
    [pages, pageId],
  );
};

export const usePreviousVisiblePage = (pageId: string): ContractPage | undefined => {
  const { pages } = useContractData();

  return React.useMemo(
    () => {
      const pageIndex = findIndex(pages, page => page._id === pageId);

      const previousPages = slice(pages, 0, pageIndex);

      // TODO Permissions
      return findLast(previousPages, page => !page.type);
    },
    [pages, pageId],
  );
};

/**
 * Gets the section's exchange defs which were added by the `group`. An optional
 * `filter` function can be used to further refine the returned exchange defs.
 */
 export const useSectionExchangeDefsByCreator = <T extends ExchangeDefinition> ({
  filter = identity,
  group,
}: {
  filter?: (def: ExchangeDefinition) => boolean;
  group: 'sender' | 'recipient';
}) => {
  const { senders, recipients, exchangeDefById } = useContractData();
  const section = useContractSection();
  const companies = group === 'sender' ? senders : recipients;

  const exchangeDefs = React.useMemo(
    () => {
      const companiesById = keyBy(companies, '_id');

      return section
        .exchangeDefIds
        .map(exchangeDefId => exchangeDefById[exchangeDefId] as T)
        .filter(exchangeDef => {
          const { creatorId } = exchangeDef as any;

          return companiesById[creatorId];
        })
        .filter(filter);
    },
    [companies, exchangeDefById, filter, section.exchangeDefIds],
  );

  return exchangeDefs;
};

export const useSummary = () => {
  const { summary } = useContractData();

  return summary;
};

export const useExchangeDefById = () => {
  const { exchangeDefById } = useContractData();

  return exchangeDefById;
};

export const useApprovalExchange = () => {
  const page = useContractPage();
  const contract = useContractData();

  const approvalExchangeId = contract.approvalByPage[page._id];

  const approvalExchange = find(
    contract.exchangeById as Record<ExchangeSnapshot['_id'], ExchangeSnapshot>,
    (exchange): exchange is ApprovalExchangeSnapshot | undefined => exchange._id === approvalExchangeId,
  );

  if (!approvalExchange) {
    throw new Error('Missing approval exchange');
  }

  return approvalExchange;
};

export const useApprovalExchangeReply = (
  value: ActionType.ACCEPT | ActionType.REJECT,
  options?: MutateOptions<void, any, SendExchangeReplyPayload, any>,
) => {
  const contract = useContractData();
  const approvalExchange = useApprovalExchange();

  const [sendContractExchangeReply, mutationProps] = useSendContractExchangeReply({
    contractId: contract._id,
    exchangeId: approvalExchange._id,
  });

  return [
    async () => sendContractExchangeReply({ value }, options),
    mutationProps,
  ] as const;
};

export const useContractExchange = () => {
  const contract = useContractData();

  const contractExchangeDef = find(
    contract.exchangeDefById,
    (exchangeDef) => exchangeDef.type === ExchangeType.CONTRACT,
  );

  return (contract.exchangeById as Record<ExchangeSnapshot['_id'], ExchangeSnapshot>)[contractExchangeDef._id];
};

export const useApprovalDirtySectionIds = () => {
  const approvalExchange = useApprovalExchange();
  const exchangeDefById = useExchangeDefById();

  return React.useMemo(() => {
    const sections = map(
      approvalExchange.dirtyExchangeIds,
      (dirtyExchangeId) => exchangeDefById[dirtyExchangeId].sectionId,
    );

    return uniq(sections);
  }, [
    approvalExchange.dirtyExchangeIds,
    exchangeDefById,
  ]);
};

export const useIsSectionDirty = () => {
  const section = useContractSection();
  const dirtySectionIds = useApprovalDirtySectionIds();

  return includes(dirtySectionIds, section._id);
};

const ApprovalsContext = React.createContext<{
  onlyDirtyExchanges: boolean;
  toggleDirtyExchanges:() => void;
  removeFilter: () => void;
} | null>(null);

export const ApprovalsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [filter, setFilter] = React.useState<'dirtyExchanges' | undefined>(undefined);

  const toggleDirtyExchanges = React.useCallback(() => {
    setFilter((value) => {
      return value === 'dirtyExchanges' ? undefined : 'dirtyExchanges';
    });
  }, []);

  const removeFilter = React.useCallback(() => {
    setFilter(undefined);
  }, []);

  const value = React.useMemo(() => ({
    onlyDirtyExchanges: filter === 'dirtyExchanges',
    toggleDirtyExchanges,
    removeFilter,
  }), [
    filter,
    toggleDirtyExchanges,
    removeFilter,
  ]);

  return (
    <ApprovalsContext.Provider value={value}>
      {children}
    </ApprovalsContext.Provider>
  );
};

export const useApprovalsContext = () => React.useContext(ApprovalsContext);

export const useMilestonesList = ({
  milestones,
  Component,
}: {
  milestones: Milestone[],
  Component: (props: any) => React.ReactNode,
}) => {
  const properties = React.useMemo(() => {
    return milestones.map(milestone => ({
      _id: milestone._id,
      name: milestone.name,
      value: milestone.date,
      internalOnly: milestone.isHidden,
      Component,
      truncateLabel: true,
    }));
  }, [milestones, Component]);

  return properties;
};

export const useBidProgress = () => {
  const { bidProgress } = useContractData();

  return bidProgress;
};

export const useStatus = () => {
  const { status } = useContractData();

  return status;
};

export const canESignContract = (userId: string, exchange: ExchangeSnapshot, contractStatus: ContractStatus) => {
  const { verified } = exchange;

  if (contractStatus !== ContractStatus.NEGOTIATION || !verified) return false;

  return exchange.isAwaitingSubmitterESignature
    ? Boolean(verified.recipientSigners.find(signer => signer._id === userId && !signer.hasSigned))
    : exchange.isAwaitingCounterESignature
      ? Boolean(verified.senderSigners.find(signer => signer._id === userId && !signer.hasSigned))
      : false;
};

export const useIsIncompleteSenderSigner = () => {
  const currentUser = useCurrentUser();
  const exchange = useExchange();
  const { verified } = exchange;

  if (!verified) return false;

  return verified.senderSigners.some(signer => signer._id === currentUser._id && !signer.hasSigned);
};
